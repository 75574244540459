import React from 'react';

interface IProps {
  meta: any;
  intl: any;
  className?: string;
}
const FormikFormErrorHandler: React.FC<IProps> = props => {
  const _error = props.meta.error
  ? props.intl.formatMessage({ id: props.meta.error })
  : props.meta.error;
  return (
    <>
      {props.meta.touched &&
        ((props.meta.error && <span data-testid="testError" className= {props.className? props.className: "text-danger errorMessage"}>{_error}</span>) ||
          (props.meta.warning && <span data-testid="testWarn" className="text-danger">{props.meta.warning}</span>))}
    </>
  );
};

export default FormikFormErrorHandler;
