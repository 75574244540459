export const ModuleName = {
    Dashboard: "Dashboard",
    TicketManagement: "TicketManagement",
    Alarms: "Alarms",
    Reports: "Reports",
    VehicleManagement: "VehicleManagement",
    DriverManagement: "DriverManagement",
    TankManagement: "TankManagement",
    LiveTracking: "LiveTracking",
    Clients: "Clients",
    User: "User",
    Customer: "Customer",
    Roles: "Roles",
    AddTransaction: "AddTransaction",
    SuperAdminDashboard: "SuperAdminDashboard",
    CustomerDashboard: "CustomerDashboard",
    Supplier: "Supplier",
}