/*    
<summary>
  This functional component "AddUpdateCustomer" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { customerIdList } from '../../../constants/common-constant';
import { useStore } from '../../../contexts/StoreProvider';
import { IOption } from '../../../models/ICommon';
import { IAddEditCustomer } from '../../../models/response/ICustomerResponse';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateCustomerForm from '../forms/add-edit-customer-form';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateCustomer = (props: IProps) => {

  const { customerStore, currencyStore } = useStore();
  const { GetCustomerByIdService, resetAddUpdateCustomerState, resetGetCustomerDetail, customerDetailState, getCustomerDetail, addUpdateCustomerState, AddCustomerService, UpdateCustomerService } = customerStore;
  const { GetAllCountryCurrencyService, countryCurrencyState, resetCountryCurrencyState, allCountryCurrencyOptions } = currencyStore;
  const initialValues: IAddEditCustomer = getCustomerDetail;
  const { id, modalClosed } = props

  const allocationPeriodTypes: IOption[] = [
    { Id: -1, Name: "please_select" },
    { Id: 1, Name: "daily" },
    { Id: 2, Name: "weekly" },
    { Id: 3, Name: "monthly" },
  ];

  const onModalClosed = useCallback(() => {
    resetGetCustomerDetail();
    resetAddUpdateCustomerState();
    modalClosed();
  }, [resetGetCustomerDetail, resetAddUpdateCustomerState, modalClosed])

  useEffect(() => {
    if (id > 0) {
      GetCustomerByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateCustomerState.success) {
      if (id === -1)
        toast.success(formatMessage("added_success_otp_sent"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateCustomerState.success]);

  useEffect(() => {
    if (addUpdateCustomerState.error) {
      toast.error(formatMessage(addUpdateCustomerState.error));
      resetAddUpdateCustomerState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateCustomerState.error]);

  const onSubmitForm = (values: IAddEditCustomer) => {
    if (id > 0)
      UpdateCustomerService(id, values);
    else
      AddCustomerService(id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateCustomerForm
        initialValues={initialValues}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={customerDetailState.inProgress || countryCurrencyState.inProgress}
        addUpdateInProgress={addUpdateCustomerState.inProgress}
        customerIdList={customerIdList}
        currencyOptions={allCountryCurrencyOptions}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateCustomer);
