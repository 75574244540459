/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date:15-May-2024
</summary>
*/
export enum TransactionReportTypeEnum {
   TRUCK_WISE,
   CUSTOMER_WISE,
   DEPARTMENT_WISE,
   VEHICLE_WISE,
   PAYMENT_WISE
}