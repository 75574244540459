import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { toast } from "react-toastify";
import { DATE_FORMAT_BACKEND, DATE_TIME_FORMAT_FILENAME, DATE_TIME_FORMAT_TO_BACKEND, emptyValue } from "../../constants/common-constant";
import UrlConstants from "../../constants/url.constant";
import { getAmountDecimal, getClientId } from "../../helpers/localStorages.helper";
import { IObservableInitialState } from "../../models/ICommon";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IPDFSetting } from "../../models/response/ICustomerResponse";
import { IGetSupplier, IGetSupplierList, ISupplier } from "../../models/response/ISupplierResponse";
import { ISupplierState } from "../../models/state/ISupplierState";
import { formatMessage } from "../../translations/formatMessage";
import * as services from '../service/base-service';
import { getSupplierListState } from "../initialState/get-supplier-list-state";
import { ISupplierFilter, ISupplierInitialFilterState } from "../../models/IFilter";
import { supplierInitialFilterState } from "../initialState/initial-filter-states";
import { addEditSupplierState } from "../initialState/add-edit-supplier-state";


export class SupplierStore implements ISupplierState {
    inProgress = false;
    error = '';
    supplierList: IGetSupplierList = getSupplierListState;
    supplierData: ISupplier[] = [];

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    addUpdateSupplierState: IObservableInitialState = { ...this.initialStateValue };
    DeleteSupplierState: IObservableInitialState = { ...this.initialStateValue };
    deleteSupplierRateState = { ...this.initialStateValue }

    supplierDetail: ISupplier | undefined = undefined;
    totalSupplier: number = 0;
    supplierDetailState: IObservableInitialState = { ...this.initialStateValue };

    settingDetail: IPDFSetting | undefined = undefined;
    settingDetailState: IObservableInitialState = { ...this.initialStateValue };

    addUpdateSettingState: IObservableInitialState = { ...this.initialStateValue };

    filter: ISupplierFilter = {
        ...supplierInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            supplierList: observable,
            addUpdateSupplierState: observable,
            addUpdateSettingState: observable,
            DeleteSupplierState: observable,
            supplierDetail: observable,
            supplierDetailState: observable,
            settingDetail: observable,
            settingDetailState: observable,
            filter: observable,
            supplierData: observable,
            GetSupplierListService: action,
            GetSupplierByIdService: action,
            AddSupplierService: action,
            UpdateSupplierService: action,
            DeleteSupplierService: action,
            reset: action,
            resetStore: action,
            resetGetSupplierDetail: action,
            setFilterDetail: action,
            resetAddUpdateSupplierState: action,
            resetDeleteSupplierState: action,
            getSupplierDetail: computed,
            getSupplierList: computed,
        });
    }

    GetSupplierListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, clientId: number, filter: ISupplierInitialFilterState) => {
        this.inProgress = true;
        let requestBody = {
            ...filter
        }
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = UrlConstants.GetSupplierList + '?clientId=' + clientIdFromLocalStorage + '&pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column=' + column + '&orderBy=' + orderBy;
        // const url = `${UrlConstants.GetSupplierList}?clientId=${clientIdFromLocalStorage}`;
        return services.post(url, { ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.supplierList = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getSupplierList(): IGetSupplier[] {
        let decimalValue = getAmountDecimal();
        if (this.supplierList?.SupplierList && this.supplierList.SupplierList?.length > 0) {
            return this.supplierList.SupplierList.map((supplier: IGetSupplier) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...supplier,
                    PricePerL: supplier.PricePerL !== null ? Number(supplier.PricePerL).toFixed(decimalValue) : emptyValue,
                    PricePerGallon: supplier.PricePerGallon !== null ? Number(supplier.PricePerGallon).toFixed(decimalValue) : emptyValue,
                    Id: supplier.Id,
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetSupplierByIdService = (id: number) => {
        this.supplierDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetSupplierById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // const tempDetail = this.SupplierList.Suppliers.filter((d:ISupplier)=>d.SupplierId === id);
        // this.SupplierDetail = tempDetail.length > 0 ? {...tempDetail[0]} : undefined;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.supplierDetail = response.data.Data.Supplier;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.SupplierDetailState.error = err;
            }).finally(action(() => { this.supplierDetailState.inProgress = false; }));
    }

    get getSupplierDetail(): ISupplier {
        if (this.supplierDetail) {
            return {
                ...this.supplierDetail,
                SupplierName: this.supplierDetail.SupplierName !== null ? this.supplierDetail.SupplierName : "",
                PhoneNo: this.supplierDetail.PhoneNo !== null ? this.supplierDetail.PhoneNo : "",
                ContactPersonName: this.supplierDetail.ContactPersonName !== null ? this.supplierDetail.ContactPersonName : "",
                ContactPersonPhoneNo: this.supplierDetail.ContactPersonPhoneNo !== null ? this.supplierDetail.ContactPersonPhoneNo : "",
                ContactPersonEmail: this.supplierDetail.ContactPersonEmail !== null ? this.supplierDetail.ContactPersonEmail : "",
                Address: this.supplierDetail.Address !== null ? this.supplierDetail.Address : "",
                UpdatedAt: this.supplierDetail?.UpdatedAt ? moment(this.supplierDetail?.UpdatedAt).format(DATE_TIME_FORMAT_TO_BACKEND) : "",
                TaxRegistrationNo: this.supplierDetail.TaxRegistrationNo !== null ? this.supplierDetail.TaxRegistrationNo : "",
                PricePerL: this.supplierDetail.PricePerL !== null ? this.supplierDetail.PricePerL : "",
                PricePerGallon: this.supplierDetail.PricePerGallon !== null ? this.supplierDetail.PricePerGallon : "",
            };
        }
        return addEditSupplierState;
    }

    resetGetSupplierDetail = () => {
        this.supplierDetail = undefined;
        this.supplierDetailState = { ...this.initialStateValue };
    }

    AddSupplierService = (id: number, data: ISupplier) => {
        let requestBody = {
            SupplierName: data.SupplierName,
            PhoneNo: data.PhoneNo,
            ContactPersonName: data.ContactPersonName,
            ContactPersonPhoneNo: data.ContactPersonPhoneNo,
            ContactPersonEmail: data.ContactPersonEmail,
            Address: data.Address,
            TaxRegistrationNo: data.TaxRegistrationNo,
            PricePerL: Number(data.PricePerL),
            PricePerGallon: Number(data.PricePerGallon),
        }
        this.addUpdateSupplierState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.AddSupplier}?clientId=${clientIdFromLocalStorage}`;
        return services.post(url, { ...requestBody })
            .then(() => {
                this.addUpdateSupplierState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateSupplierState.error = err;
            })
            .finally(action(() => { this.addUpdateSupplierState.inProgress = false; }));
    }

    UpdateSupplierService = (id: number, data: ISupplier) => {
        let requestBody = {
            SupplierName: data.SupplierName,
            PhoneNo: data.PhoneNo,
            ContactPersonName: data.ContactPersonName,
            ContactPersonPhoneNo: data.ContactPersonPhoneNo,
            ContactPersonEmail: data.ContactPersonEmail,
            Address: data.Address,
            TaxRegistrationNo: data.TaxRegistrationNo,
            PricePerL: Number(data.PricePerL),
            PricePerGallon: Number(data.PricePerGallon),
        }
        this.addUpdateSupplierState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateSupplier}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // let url = `${UrlConstants.UpdateSupplier}?clientId=${clientIdFromLocalStorage}`;
        return services.put(url, { ...requestBody })
            .then(() => {
                this.addUpdateSupplierState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateSupplierState.error = err;
            })
            .finally(action(() => { this.addUpdateSupplierState.inProgress = false; }));
    }

    resetAddUpdateSupplierState = () => {
        this.addUpdateSupplierState = { ...this.initialStateValue }
    }

    DeleteSupplierService = (id: number) => {
        this.DeleteSupplierState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteSupplier}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteSupplierState.success = true;
            })
            .catch((err: string) => {
                this.DeleteSupplierState.error = err;
            })
            .finally(action(() => { this.DeleteSupplierState.inProgress = false; }));
    }

    resetDeleteSupplierState = () => {
        this.DeleteSupplierState = { ...this.initialStateValue }
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.supplierList = getSupplierListState;

        this.supplierDetail = undefined;
        this.supplierDetailState = { ...this.initialStateValue }

        this.addUpdateSupplierState = { ...this.initialStateValue }

        this.DeleteSupplierState = { ...this.initialStateValue }
    }

    /**
     * This function is used to convert Supplier list data to the corresponding CSV format
     * @param data : Supplier List data
     * @returns csvData suitable for creating blob object
     */
    convertToCSV = (data: any[]): string => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };

    /**
     * This function downloads the CSV by dynamically making an <a> tag. 
     * @param data : Supplier List data
     */
    downloadSupplierCSV = (data: ISupplier[]) => {
        const currentDate = new Date();
        if (data.length > 0) {
            const csvData = this.convertToCSV(this.exportSupplierCSV(data));
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = moment(currentDate).format(DATE_TIME_FORMAT_FILENAME) + " Suppliers.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else
            toast.warn(formatMessage("no_data_to_export"));
    };

    /**
     * This function is used to convert the SupplierDetailsList to the required data suitable for convertToCSV function.
     * @param SupplierDataList : Supplier List
     * @returns CSV data suitable for convertToCSV function
     */
    exportSupplierCSV(SupplierDataList: any): IGetSupplier[] {
        if (SupplierDataList && SupplierDataList?.length > 0)
            return SupplierDataList?.map((Supplier: IGetSupplier) => {
                return {
                    Id: Supplier.Id,
                    SupplierName: Supplier.SupplierName,
                    PhoneNo: Supplier.PhoneNo,
                    ContactPersonName: Supplier.ContactPersonName,
                    ContactPersonPhoneNo: Supplier.ContactPersonPhoneNo,
                    ContactPersonEmail: Supplier.ContactPersonEmail,
                    Address: Supplier.Address,
                }
            })
        return [];
    }

    /*
    This function is used to set the filter details to the billingFilter observable.  
    */
    setFilterDetail = (filter: ISupplierFilter) => {
        this.filter = { ...filter };
    }

    /*
    This function is used to download CSVFile.  
    we can use this code when we get the csv data from API
    */
    // CSVFileDownloadService = () => {
    //     const url = URLConstants.DownloadSupplier;
    //     const token = new AuthStore().getToken;
    //     this.csvDownloadState.inProgress = true;
    //     axiosDownload({
    //         url: baseURL + url,
    //         method: 'GET',
    //         responseType: 'blob',
    //         headers: {
    //             'authorization': 'Bearer ' + token
    //         }
    //     }).then((response: any) => {
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', 'Supplier_' + moment(Date.now()).format(DATE_TIME_FORMAT_FILENAME) + '.csv');
    //         document.body.appendChild(link);
    //         link.click();
    //         toast.success(formatMessage("downloaded_started_successfully"));
    //     }).catch((err: any) => {
    //         toast.error(formatMessage(errorMessage.ErrorOccured));
    //     })
    //         .finally(action(() => { this.csvDownloadState.inProgress = false; }));
    // }
}

export default new SupplierStore();