/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "react-tooltip";
import { amountDecimalTypes, DATE_FORMAT_BACKEND } from "../../../constants/common-constant";
import { IUser } from "../../../models/IUser";
import { IAddEditClient } from "../../../models/response/IClientResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import {
  addClientValidateSchema,
  updateClientValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormInputForPhone from "../../../shared-components/FormikFormHandlers/formik-form-input-for-number";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/formatMessage";

interface IProps {
  id: number;
  submitHandler: (data: IAddEditClient) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditClient;
}

const AddUpdateClientForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered size="lg">
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_client" />
            ) : (
              <FormattedMessage id="update_client" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={!(id > 0) ? addClientValidateSchema : updateClientValidateSchema}
          onSubmit={async (values: any) => {
            let StartDate =
              new Date(values.StartDate?.$d).toString() !== "Invalid Date"
                ? moment(values.StartDate.$d).format(DATE_FORMAT_BACKEND)
                : values.StartDate;
            let EndDate =
              new Date(values.EndDate.$d).toString() !== "Invalid Date"
                ? moment(values.EndDate.$d).format(DATE_FORMAT_BACKEND)
                : values.EndDate;
            let tempValues = {
              Name: values.Name ? values.Name.trim() : values.Name,
              PhoneNo: values.PhoneNo ? values.PhoneNo.trim() : values.PhoneNo,
              ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : values.ContactPersonName,
              ContactPersonPhoneNo: values.ContactPersonPhoneNo ? values.ContactPersonPhoneNo.trim() : values.ContactPersonPhoneNo,
              ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : values.ContactPersonEmail,
              City: values.City ? values.City.trim() : values.City,
              State: values.State ? values.State.trim() : values.State,
              Country: values.Country ? values.Country.trim() : values.Country,
              Address: values.Address ? values.Address.trim() : values.Address,
              StartDate: StartDate,
              EndDate: EndDate,
              IsActive: values.IsActive,
              ClientAdminName: values.ClientAdminName ? values.ClientAdminName.trim() : values.ClientAdminName,
              Email: values.Email ? values.Email.trim() : values.Email,
            }
            submitHandler({
              ...tempValues,
              EnableLimitSettings: values.EnableLimitSettings,
              EnableFuelSensorSettings: values.EnableFuelSensorSettings,
              EnableGpsSettings: values.EnableGpsSettings,
              EnableDriverIdSettings: values.EnableDriverIdSettings,
              EnableNozzleRfidSetting: values.EnableNozzleRfidSetting,
              EnableAlarmSettings: values.EnableAlarmSettings,
              AmountDecimal: values.AmountDecimal,
            });
          }}
        >
          {({ values }) => (
            <Form className="user-form client-modal p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col lg={4} md={4} xs={5}>
                    <div className="form-group">
                      <Label
                        className="form-label mt-3"
                        required={true}
                        label="client_name"
                      />
                      <div className="d-inline tooltip-info-client">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle ml-2"
                          viewBox="0 0 16 16"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html={formatMessage("account_name_valid_chars")}
                          data-tooltip-variant="dark"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </div>
                      <Tooltip id="my-tooltip" place="right" />
                    </div>

                  </Col>
                  <Col lg={8} md={8} xs={7}>
                    <div className="form-group">
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="Name"
                        type="text"
                        invalidcharacter={[" ", ":", ";", ",", "+", "-", "(", ")", "&", "$", "#", "[", "]", "/", ".", "@"]}
                        placeholder="client_name"
                        as={FormikFormInput}
                        disabled={id > 0 ? true : false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="border-box position-relative border mt-2">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="contact_person_details" />
                    </p>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-group">
                      <Label className="form-label" label="contact_person" />
                      <Field
                        data-testid="testContactPersonName"
                        className="form-control"
                        name="ContactPersonName"
                        type="text"
                        placeholder="contact_person"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        label="contact_person_phone_no"
                      />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testContactPersonPhoneNo"
                        className="form-control"
                        name="ContactPersonPhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="contact_person_phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-group">
                      <Label className="form-label" label="email" />
                      <Field
                        data-testid="testContactPersonEmail"
                        className="form-control"
                        name="ContactPersonEmail"
                        type="text"
                        placeholder="email"
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                        tolowercase={+true}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="client_address_info" />
                    </p>
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="city"
                      />
                      <Field
                        data-testid="testCity"
                        className="form-control"
                        name="City"
                        type="text"
                        placeholder="city"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <div className="form-group">
                      <Label className="form-label" label="state" />
                      <Field
                        data-testid="testState"
                        className="form-control"
                        name="State"
                        type="text"
                        placeholder="state"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="country"
                      />
                      <Field
                        data-testid="testCountry"
                        className="form-control"
                        name="Country"
                        type="text"
                        placeholder="country"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col lg={3} md={6} xs={6}>
                    <div className="form-group">
                      <Label className="form-label"
                        required={false}
                        label="phone_no" />
                      {/* <InfoTooltip Info="phone_format_info" /> */}
                      <Field
                        data-testid="testPhoneNo"
                        className="form-control"
                        name="PhoneNo"
                        type="tel"
                        validcharacter={["+", "-"]}
                        placeholder="contact_person_phone_no"
                        as={FormikFormInputForPhone}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="address"
                      />
                      <Field
                        data-testid="testAddress"
                        className="form-control"
                        name="Address"
                        type="text"
                        placeholder="address"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="border-box position-relative border client-setting-detail">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="client_setting_details" />
                    </p>
                  </Col>
                  <Col lg={4} md={4} xs={12} className="mb-2">
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        required={true}
                        label="start_date"
                      />
                      <Field name="StartDate"
                        as={FormikDateFieldMUI}
                        min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                      {/* <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="StartDate"
                        // type="text"
                        placeholder="start_date"
                        minDate={new Date().setDate(new Date().getDate())}
                        as={FormikFormDatePickerMUI}
                      /> */}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={12} className="mb-2">
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        required={true}
                        label="end_date"
                      />
                      <Field name="EndDate"
                        as={FormikDateFieldMUI}
                        min={moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                      {/* <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="EndDate"
                        // type="text"
                        placeholder="end_date"
                        minDate={new Date().setDate(new Date().getDate() + 1)}
                        as={FormikFormDatePickerMUI}
                      /> */}
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        label="amount_decimal"
                      />
                      <Field
                        name="AmountDecimal"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={amountDecimalTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableLimitSettings"
                        type="checkbox"
                        id="LimitEnabled"
                      />
                      <label
                        htmlFor="LimitEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="limit_enabled" />
                      </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableFuelSensorSettings"
                        type="checkbox"
                        id="FuelSensorEnabled"
                      />
                      <label
                        htmlFor="FuelSensorEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="fuel_sensor_enabled" />
                      </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableGpsSettings"
                        type="checkbox"
                        id="GPSEnabled"
                      />
                      <label
                        htmlFor="GPSEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="gps_enabled" />
                      </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableDriverIdSettings"
                        type="checkbox"
                        id="DriverIdEnabled"
                      />
                      <label
                        htmlFor="DriverIdEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="driver_id_enabled" />
                      </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableNozzleRfidSetting"
                        type="checkbox"
                        id="NozzleRFIDEnabled"
                      />
                      <label
                        htmlFor="NozzleRFIDEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="nozzle_rfid_enabled" />
                      </label>
                    </div>
                  </Col>
                  <Col lg={4} md={6} xs={12}>
                    <div className="form-check form-switch mb-4">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="EnableAlarmSettings"
                        type="checkbox"
                        id="AlarmEnabled"
                      />
                      <label
                        htmlFor="AlarmEnabled"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="alarm_enabled" />
                      </label>
                    </div>
                  </Col>
                </Row>

                {id < 0 && (
                  <Row className="border-box position-relative border">
                    <Col xs={12} className="pb-3">
                      <p className="h6 mt-3 fw-semi-bold heading-title">
                        <FormattedMessage id="client_admin_details" />
                      </p>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="admin_name"
                        />
                        <Field
                          data-testid="testClientName"
                          className="form-control"
                          name="ClientAdminName"
                          type="text"
                          placeholder="admin_name"
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="label_email"
                        />
                        <Field
                          data-testid="testEmail"
                          className="form-control"
                          name="Email"
                          type="text"
                          invalidcharacter={[" "]}
                          validcharacter={["-", "_", "[", "]", "(", ")",
                            ":", "#", "@", ".",]}
                          placeholder="label_email"
                          tolowercase={+true}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    {/* <Col xs={6}>
                      <div className="mb-3 position-relative">
                        <Label
                          className="form-label"
                          required={true}
                          label="user_label_password"
                        />
                        <Field
                          data-testid="testEmail"
                          className="form-control"
                          name="Password"
                          type="password"
                          autoComplete="new-password"
                          invalidcharacter={[" "]}
                          placeholder="user_label_password"
                          as={FormikFormPasswordField}
                        />
                      </div>
                    </Col> */}
                  </Row>
                )}
                <Row>
                  <Col xs={4}>
                    <div className="form-check mt-2">
                      <Field
                        data-testid="testRememberMe"
                        className="form-check-input"
                        name="IsActive"
                        type="checkbox"
                        id="IsActive"
                      />
                      <label
                        htmlFor="IsActive"
                        className="form-check-label mt-1"
                      >
                        <FormattedMessage id="is_active" />
                      </label>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateClientForm;
