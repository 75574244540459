import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import FormikFormErrorHandler from './FormikFormErrorHandler';
import { useField } from 'formik';

interface IProps {
  children: React.ReactNode;
  className: string;
  "data-testid"?: string;
  intl: any;//IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  value: string;
  type: string;
  disabled: boolean;
  readOnly: boolean;
  spaceallowed: boolean;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
  maxLength: number;
  ismacaddress: boolean;
  tolowercase: boolean;
}

const FormikFormInput = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;
  const { validcharacter, invalidcharacter } = props;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;

  useEffect(() => {
    if (field.value?.length > props.maxLength) {
      helpers.setValue(field.value.slice(0, props.maxLength))
    }
  }, [field.value, helpers, props.maxLength])

  let subStrArr = ["?", ">", "<", "'", '"', "*", "^", "%","{", "}", "|", "=", '~', '\\', '`', '!']
  if (validcharacter)
    subStrArr = subStrArr.filter(el => !validcharacter.includes(el));
  if (invalidcharacter)
    invalidcharacter.forEach(el => subStrArr.push(el));

  return (
    <>
      <input
        {...field} {...props}
        placeholder={_placeholder}
        className={props.className + ' ' + errorClass}
        disabled={props.disabled}
        readOnly={props.readOnly}
        // {...valueprop}
        type={props.type ? props.type : 'text'}
        onChange={event => {
          let value = props.tolowercase ? event.target.value.toLowerCase() : event.target.value;
          if (value.length > props.maxLength)
            value = value.slice(0, props.maxLength);
          if (props.type === 'tel') {
            !(/[a-z,' ',$&,:;=?@#|'<>.^*()%!,",{,},_,]/i.exec(value)) && helpers.setValue(value);
            return;
          }
          if (!props.spaceallowed && (value === '' || !subStrArr.some((subStr: any) => value.includes(subStr)))) {
            value = value.replace(/ +(?= )/g, '');
            if (props.ismacaddress && value === '') {
              value = value.replace(/ +(?= )/g, '');
            }
            else if (props.ismacaddress) {
              value = value.toUpperCase();
            }
            helpers.setValue(value);
          } else if ((props.spaceallowed === undefined || props.spaceallowed) && (value === '' || !subStrArr.some((subStr: any) => value.includes(subStr)))) {
            helpers.setValue(value);
          }
        }}
      />
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormInput);