/*    
<summary>
   This file is used to provide initial values or state to the client Data observables.
   Developer:Ashish Singh, Created Date:04-March-2024
</summary>
*/
import { IAddEditClient } from "../../models/response/IClientResponse";
import { ISetting } from "../../models/response/IClientResponse";

export const initialClientSetting: ISetting = {
      EnableLimitSettings: false,
      EnableFuelSensorSettings: false,
      EnableGpsSettings: false,
      EnableDriverIdSettings: false,
      EnableNozzleRfidSetting: false,
      EnableAlarmSettings: false,
      AmountDecimal: 0
}

export const addEditClientState: IAddEditClient = {
      Name: "",
      PhoneNo: "",
      ContactPersonName: "",
      ContactPersonPhoneNo: "",
      ContactPersonEmail: "",
      City: "",
      State: "",
      Country: "",
      Address: "",
      StartDate: "",
      EndDate: "",
      ...initialClientSetting,
      IsActive: true,
      Email: "",
      ClientAdminName: "",
}