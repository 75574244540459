import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/StoreProvider";
import config from '../../helpers/config.helper';
import { getToken, getUserType } from "../../helpers/localStorages.helper";
import ILogin from "../../models/ILogin";
import { formatMessage } from "../../translations/formatMessage";
import "./Login.scss";
import LoginForm from "./forms/LoginForm";
import { ICommonState } from "../../models/state/ICommonState";
import { IAuthState } from "../../models/state/IAuthState";
import withRouter from "../../shared-components/Routes/WithRouter";
import UserType from "../../constants/userType.constant";

interface IProps {
  // history: any;
}

const appConfig = config();
export const baseURL = appConfig.API_URL;

const Login = (props: IProps) => {
  const navigate = useNavigate();
  const { authStore, languageStore, } = useStore();
  const { error, inProgress }: ICommonState = authStore;
  const { isAuthenticated, login, userType, getUserType } = authStore;
  const { language } = languageStore;

  const handleLogin = (values: ILogin) => {
    // values.isTenant = window.location.pathname?.split('/')[1]?.toLowerCase() !== 'admin';
    login(values);
  };

  // useEffect(() => {
  //   if (error) {
  //     toast.error(formatMessage(error));
  //   }
  // }, [error]);

  useEffect(() => {
    if (isAuthenticated) {
      if((userType === UserType.SuperAdmin || getUserType === UserType.SuperAdmin ))
        navigate(RoutesConstants.Clients);
      else if((userType === UserType.CustomerAdmin || getUserType === UserType.CustomerAdmin ))
        navigate(RoutesConstants.Vehicles);
      else
        navigate(RoutesConstants.Dashboard);
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   localStorage.setItem('secure_fuel_language', language);
  // }, []);

  return (
    <>
      {/* {inProgress && <div className='main-content' id='loader'><SpinnerLoader /></div>} */}
      <div className="login-bg d-flex align-items-center justify-content-center ">
          <div className="bg-white p-3 loginFrom">
            <LoginForm isLoading={inProgress} loginHandler={handleLogin} />
          </div>
      </div>
    </>
  );
};

export default withRouter(observer(Login));
