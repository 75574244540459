import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withSize } from "react-sizeme";
import SearchField from "../../shared-components/SearchField/SearchField";
import Header from "../../shared-components/Header/Header";
import MainTabsView from "../TabControl/TabsView";
import { formatMessage } from "../../translations/formatMessage";
import { FormattedMessage } from "react-intl";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import { ModuleName } from "../../constants/moduleName.constant";
import NoData from "../../assets/Images/no_record.png";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import { Button, Col, Container, Pagination, Row } from "react-bootstrap";
import "./Reports.scss";
import Plants from "../../assets/Images/customer-wise-transaction.png";
import Trucks from "../../assets/Images/truck_1.png";
import Drivers from "../../assets/Images/department.png";
import Payment from "../../assets/Images/payment.png";
import vehicles from "../../assets/Images/vehicle.png";
import SlidingPanel from "react-sliding-side-panel";
import { PageLimitOptions } from "../../constants/pageLimit.constant";
import ReportDetail from "./components/report-detail";
import { TransactionReportTypeEnum } from "../../constants/enums/transaction-report-type-enum";
import ReportsFilterForm from "./forms/Reports-filter";
import { useStore } from "../../contexts/StoreProvider";
import { toast } from "react-toastify";
import moment from "moment";
import { DATE_TIME_FORMAT_FILENAME, getLabelOfTransactionReport } from "../../constants/common-constant";
import { addReportInitialData } from "../../core/initialState/add-report-state";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import RoutesConstants from "../../constants/routes.constant";
import ReportPreview from "./report-preview";
import ViewDetailsPopupHOC from "../../shared-components/Popup/Hoc/ViewDetailsPopupHoc";
import UserType from "../../constants/userType.constant";
import { getUserType } from "../../helpers/localStorages.helper";
interface IProps {
  size: {
    height: number;
  };
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showViewDetailsPopup: boolean;
  viewDetailsToggleHandler: () => void;
}

const Reports = (props: IProps) => {
  let t: NodeJS.Timeout;
  const { reportStore, preferencesStore } = useStore();
  const { GetTruckSummaryService, GetCustomerSummaryService, GetVehicleSummaryService, GetDepartmentSummaryService,
    getTruckOptions, getCustomerOptions, getVehicleOptions, getDepartmentOptions, resetSummaryState,
    customerSummaryState, truckSummaryState, vehicleSummaryState, departmentSummaryState } = preferencesStore
  const { resetReportCountState, GetReportCountService, getReportCount, reportCountState, AddReportService,
    resetReportViewDataState, addReportState, resetAddReportState, inProgress, GetReportListService,
    getReportList, downloadReportState, resetDownloadReportState, GetAllReportViewDataService, reportViewDataState, deleteReportState } = reportStore;
  const { showFilterPopup, filterToggleHandler, showViewDetailsPopup, viewDetailsToggleHandler } = props;

  const [openPanel, setOpenPanel] = useState(false);
  const [transactionReportType, setTransactionReportType] = useState<TransactionReportTypeEnum>(TransactionReportTypeEnum.TRUCK_WISE);

  const [selectedGenerateViewData, setSelectedGenerateViewData] = useState<any>({
    ...addReportInitialData,
    FileName: getLabelOfTransactionReport(transactionReportType) + "_wise_report"
  })

  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);

  const openFilterHandler = () => {
    closePanelHandler();
    filterToggleHandler();
  };

  const sideViewHandler = () => {
    setAutoRefresh(false);
    // closePanelHandler();
    setOpenPanel(true);
  };

  const toggleSidePanel = () => {
    setOpenPanel(!openPanel);
  };

  const closePanelHandler = () => {
    // setAutoRefresh(true);
    setOpenPanel(false);
  };

  const openAddTransactionReportHandler = (transactionReportType: TransactionReportTypeEnum) => {
    closePanelHandler();
    setTransactionReportType(transactionReportType);
    filterToggleHandler();
  };

  const addTranastionReportHandler = (values: any) => {
    if (values.ActionType == 2) {
      const state = {
        SearchId: values.SearchId === "Select" ? "" : values.SearchId,
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        FileName: values.FileName,
        ReportType: transactionReportType,
        CustomerID1: "cust admin"
      }
      setSelectedGenerateViewData(values);
      filterToggleHandler();
      viewDetailsToggleHandler();
      // GetAllReportViewDataService(values);
      // localStorage.setItem("report_values", JSON.stringify(state))
      // window.open(RoutesConstants.ReportPreview,'_blank')
    }
    else
      AddReportService(values, transactionReportType);
  };

  const GetReporyListServiceAPI = useCallback(() => {
    if (openPanel)
      GetReportListService();
  }, [GetReportListService, openPanel])

  useEffect(() => {
    if (addReportState.success) {
      setTimeout(() => {
        GetReportCountService();
      }, 3000)
      toast.success(formatMessage("report_preparation_started"));
      resetAddReportState();
      setTransactionReportType(TransactionReportTypeEnum.TRUCK_WISE);
      filterToggleHandler();
      GetReporyListServiceAPI();
    }
  }, [addReportState.success, GetReporyListServiceAPI]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "autoRefresh" of the useEffect changed.
   */
  useEffect(() => {
    if (autoRefresh) {
      t = setInterval(() =>
        callReportCountService(), 60000);
    }
    else clearInterval(t);
    return () => {
      if (showViewDetailsPopup) {
        reportPreviewCloseHandler();
      }
      if (showFilterPopup) {
        onModalClosed();
      }
      clearInterval(t);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  useEffect(() => {
    if (reportViewDataState.success) {
      setAutoRefresh(false);
    }
  }, [reportViewDataState.success, GetReporyListServiceAPI]);

  useEffect(() => {
    if (reportCountState.success) {
      // setAutoRefresh(true);
      resetReportCountState();
    }
  }, [reportCountState.success]);

  useEffect(() => {
    if (reportViewDataState.error) {
      toast.error(formatMessage(reportViewDataState.error));
      resetReportViewDataState();
    }
  }, [reportViewDataState.error]);

  useEffect(() => {
    if (reportCountState.error) {
      toast.error(formatMessage(reportCountState.error));
      resetReportCountState();
    }
  }, [reportCountState.error, resetReportCountState]);

  useEffect(() => {
    if (deleteReportState.success) {
      setTimeout(() => {
        GetReportCountService()
      }, 3000)
    }
  }, [deleteReportState.success]);

  const callReportCountService = () => {
    GetReportCountService();
  }
  useEffect(() => {
    callReportCountService();
  }, [GetReportCountService])

  useEffect(() => {
    GetReporyListServiceAPI();
  }, [GetReporyListServiceAPI])

  const getIdOptionsOnReportType = () => {
    switch (transactionReportType) {
      case TransactionReportTypeEnum.TRUCK_WISE:
        return getTruckOptions;
      case TransactionReportTypeEnum.CUSTOMER_WISE:
        return getCustomerOptions;
      case TransactionReportTypeEnum.VEHICLE_WISE:
        return getVehicleOptions;
      case TransactionReportTypeEnum.DEPARTMENT_WISE:
        return getDepartmentOptions;
      case TransactionReportTypeEnum.PAYMENT_WISE:
        return [];
    };
  }

  const onModalClosed = () => {
    // setAutoRefresh(true);
    resetSummaryState();
    filterToggleHandler();
  }

  const reportPreviewCloseHandler = () => {
    // setAutoRefresh(true);
    resetReportViewDataState();
    viewDetailsToggleHandler();
  }

  useEffect(
    () => {
      if (!showFilterPopup && !openPanel && !showViewDetailsPopup) {
        setAutoRefresh(true);
      }
    }, [showFilterPopup, openPanel, showViewDetailsPopup]
  )

  return (
    <React.Fragment>
      {(showViewDetailsPopup) && (
        <ReportPreview
          data={selectedGenerateViewData}
          onModalClosed={reportPreviewCloseHandler}
          transactionReportType={transactionReportType}
          actionInProgress={customerSummaryState.inProgress || vehicleSummaryState.inProgress || truckSummaryState.inProgress || departmentSummaryState.inProgress}
        />
      )}
      {showFilterPopup && (
        <ReportsFilterForm
          initialValues={{
            ...addReportInitialData,
            FileName: getLabelOfTransactionReport(transactionReportType) + "_wise_report"
          }}
          submitHandler={addTranastionReportHandler}
          onModalClosed={onModalClosed}
          actionInProgress={addReportState.inProgress || customerSummaryState.inProgress || vehicleSummaryState.inProgress || truckSummaryState.inProgress || departmentSummaryState.inProgress || reportViewDataState.inProgress}
          idLabel={getLabelOfTransactionReport(transactionReportType) + "_id"}
          transactionReportType={transactionReportType}
          label={getLabelOfTransactionReport(transactionReportType)}
          IdOptions={getIdOptionsOnReportType()}
        />
      )}
      <div className={`main-content`}>
        <SubHeader
          moduleNameString={"reports_title"}
          moduleName={ModuleName.Reports}
          filterHandler={openFilterHandler}
          isAdd={false}
          isSideView={true}
          sideViewHandler={sideViewHandler}
          addButtonTitle="add report"
          isFullView={false}
          isDownload={false}
          isRefresh={false}
          isFilter={false}
          isDateFilter={false}
        />
      </div>
      {(downloadReportState.inProgress || reportCountState.inProgress) && <SpinnerLoader />}
      <Container fluid className="p-0">
        <div className="reportWrapper" id="moveContent">
          <div className={`${openPanel ? "paneIsOpen" : ""}`}>
            <div className="pt-3 px-3 height-page">
              {/* <Button
                variant="btn-secondary bg-light btn-light ms-2"
                onClick={toggleSidePanel}
              >
                <FormattedMessage id="button_cancel" />
              </Button> */}
              <div className="vehicles row row-cols-1 row-cols-md-4 g-4">
                {(getUserType() !== UserType.CustomerAdmin) &&
                  <div className={"col-lg-3 col-md-6 col-sm-6"}>
                    <div className="rounded h-100  bg-tanks-green  text-center py-5 px-3">
                      <div className="text-center">
                        <span className="badge circle-icon badge-green" onClick={() => {
                          sideViewHandler();
                        }}>{getReportCount.TruckWiseReportCount}</span>
                        <div className="truck-wise-transaction pt-3">
                          {" "}
                          <img src={Trucks} alt="Trucks " />{" "}

                        </div>
                        <p className="fs-5 fw-bolder">
                          {/* <FormattedMessage id="Truck Wise Transaction" /> */}
                          <FormattedMessage id="truck_title" />
                          <br /> <FormattedMessage id="wise_report" />
                        </p>
                        <div className="btn btn-lg text-white addbutton mt-1" onClick={() => {
                          setAutoRefresh(false);
                          GetTruckSummaryService();
                          openAddTransactionReportHandler(TransactionReportTypeEnum.TRUCK_WISE)
                        }}>
                          <span className="me-1">
                            <svg
                              enableBackground="new 0 0 512 512"
                              height="13"
                              fill="#fff"
                              viewBox="0 0 512 512"
                              width="13"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path d="m467 211h-166v-166c0-24.853-20.147-45-45-45s-45 20.147-45 45v166h-166c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45v-166h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" />
                              </g>
                            </svg>
                          </span>
                          <FormattedMessage id={"generate"} />
                        </div>
                      </div>
                    </div>
                  </div>}
                <div className={"col-lg-3 col-md-6 col-sm-6"}>
                  <div className="rounded h-100 bg-customer-orange text-center py-5 px-3">
                    <div className="text-center">
                      <span className="badge circle-icon badge-yellow " onClick={() => {
                        sideViewHandler();
                      }}>{getReportCount.CustomerWiseReportCount}</span>
                      <div className="truck-wise-transaction pt-3">

                        {" "}
                        <img src={Plants} alt="Plants" />{" "}

                      </div>
                    </div>
                    <p className="fs-5 fw-bolder">
                      <FormattedMessage id="customer__report_title" />
                      <br /> <FormattedMessage id="wise_report" />
                    </p>
                    <div className="btn btn-lg text-white addbutton mt-1" onClick={() => {
                      setAutoRefresh(false);
                      GetCustomerSummaryService();
                      openAddTransactionReportHandler(TransactionReportTypeEnum.CUSTOMER_WISE)
                    }}
                    >
                      <span className="me-1">
                        <svg
                          enableBackground="new 0 0 512 512"
                          height="13"
                          fill="#fff"
                          viewBox="0 0 512 512"
                          width="13"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path d="m467 211h-166v-166c0-24.853-20.147-45-45-45s-45 20.147-45 45v166h-166c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45v-166h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" />
                          </g>
                        </svg>
                      </span>
                      <FormattedMessage id={"generate"} />
                    </div>
                  </div>
                </div>
                <div className={"col-lg-3 col-md-6 col-sm-6"}>
                  <div className="rounded h-100 bg-vehicles-purple py-5 px-3">
                    <div className="text-center">
                      <span className="badge circle-icon badge-red" onClick={() => {
                        sideViewHandler();
                      }}>{getReportCount.VehicleWiseReportCount}</span>
                      <div className="truck-wise-transaction pt-3">
                        {" "}
                        <img src={vehicles} alt="vehicles" />{" "}

                      </div>
                      <p className="fs-5 fw-bolder">
                        <FormattedMessage id="vehicle_report_title" />
                        <br /> <FormattedMessage id="wise_report" />
                      </p>
                      <div className="btn btn-lg text-white addbutton mt-1 " onClick={() => {
                        setAutoRefresh(false);
                        GetVehicleSummaryService();
                        openAddTransactionReportHandler(TransactionReportTypeEnum.VEHICLE_WISE)
                      }}>
                        <span className="me-1">
                          <svg
                            enableBackground="new 0 0 512 512"
                            height="13"
                            fill="#fff"
                            viewBox="0 0 512 512"
                            width="13"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path d="m467 211h-166v-166c0-24.853-20.147-45-45-45s-45 20.147-45 45v166h-166c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45v-166h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" />
                            </g>
                          </svg>
                        </span>
                        <FormattedMessage id={"generate"} />
                      </div>

                    </div>
                  </div>
                </div>
                <div className={"col-lg-3 col-md-6 col-sm-6"}>
                  <div className="rounded h-100 bg-department-blue text-center py-5 px-3">
                    <div className="text-center">
                      <span className="badge circle-icon badge-blue" onClick={() => {
                        sideViewHandler();
                      }}>{getReportCount.DepartmentWiseReportCount}</span>
                      <div className="truck-wise-transaction pt-3">
                        {" "}
                        <img src={Drivers} alt="Drivers" />

                      </div>
                      <p className="fs-5 fw-bolder">
                        <FormattedMessage id="department_title" />
                        <br /> <FormattedMessage id="wise_report" />
                      </p>
                      <div className="btn btn-lg text-white addbutton mt-1 ms-2" onClick={() => {
                        setAutoRefresh(false);
                        GetDepartmentSummaryService();
                        openAddTransactionReportHandler(TransactionReportTypeEnum.DEPARTMENT_WISE)
                      }}>
                        <span className="me-1">
                          <svg
                            enableBackground="new 0 0 512 512"
                            height="13"
                            fill="#fff"
                            viewBox="0 0 512 512"
                            width="13"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path d="m467 211h-166v-166c0-24.853-20.147-45-45-45s-45 20.147-45 45v166h-166c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45v-166h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" />
                            </g>
                          </svg>
                        </span>
                        <FormattedMessage id={"generate"} />
                      </div>
                    </div>
                  </div>
                </div>
                {((getUserType() === UserType.CustomerAdmin || getUserType() === UserType.CustomerUser)) &&
                  <div className={"col-lg-3 col-md-6 col-sm-6"}>
                    <div className="rounded h-100 bg-payment-yellow text-center py-5 px-3">
                      <div className="text-center">
                        <span className="badge circle-icon badge-brown" onClick={() => {
                          sideViewHandler();
                        }}>{getReportCount.PaymentWiseReportCount}</span>
                        <div className="truck-wise-transaction pt-3">
                          {" "}
                          <img src={Payment} alt="Payment" />

                        </div>
                        <p className="fs-5 fw-bolder">
                          <FormattedMessage id="payment_title" />
                          <br /> <FormattedMessage id="wise_report" />
                        </p>
                        <div className="btn btn-lg text-white addbutton mt-1 ms-2" onClick={() => {
                          setAutoRefresh(false);
                          openAddTransactionReportHandler(TransactionReportTypeEnum.PAYMENT_WISE)
                        }}>
                          <span className="me-1">
                            <svg
                              enableBackground="new 0 0 512 512"
                              height="13"
                              fill="#fff"
                              viewBox="0 0 512 512"
                              width="13"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path d="m467 211h-166v-166c0-24.853-20.147-45-45-45s-45 20.147-45 45v166h-166c-24.853 0-45 20.147-45 45s20.147 45 45 45h166v166c0 24.853 20.147 45 45 45s45-20.147 45-45v-166h166c24.853 0 45-20.147 45-45s-20.147-45-45-45z" />
                              </g>
                            </svg>
                          </span>
                          <FormattedMessage id={"generate"} />
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>

          <SlidingPanel
            type={"right"}
            isOpen={openPanel}
            noBackdrop={false}
            size={100}
          >
            <div>
              {inProgress && <SpinnerLoader />}
              <div className="row g-0 border-bottom pt-2 pb-1 ps-3">
                <Col lg={8} md={6} xs={6}>
                  <h2 className="fs-4 fw-bolder text-break m-0">
                    {" "}
                    <FormattedMessage id="downloads" />
                  </h2>
                </Col>

                <Col className="text-end cross-icon" lg={4} md={6} xs={6}>
                  <span title="Refresh" className="refresh-report">
                    <svg
                      width="20"
                      onClick={GetReportListService}
                      height="20"
                      fill="currentColor"
                      className="bi bi-arrow-repeat m-0 mr-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                      <path
                        fillRule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                      />
                    </svg>
                  </span>

                  <span title="Close">
                    <svg
                      onClick={closePanelHandler}
                      role="button"
                      xlinkTitle="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </Col>
              </div>
              <div className="group_detailsWrapper">
                <ReportDetail />
              </div>
            </div>
          </SlidingPanel>
        </div>
      </Container>

      <Footer />
    </React.Fragment>
  );
};
export default withSize({ monitorHeight: true })(
  ViewDetailsPopupHOC(FilterPopupHOC(observer(Reports)))
);
