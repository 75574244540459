/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import DateRangeFilter from "../../Filter/Filter";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import { IClientInitialFilterState } from "../../../models/IFilter";
import { clientInitialFilterState } from "../../../core/initialState/initial-filter-states";
import { toast } from "react-toastify";
import { formatMessage } from "../../../translations/formatMessage";
import _ from "lodash";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: IClientInitialFilterState;
}

const ClientFilterForm = (props: IProps) => {
  const { submitHandler, onModalClosed, initialValues, actionInProgress } =
    props;

  // const [err, setErr] = useState<boolean>(false)

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            // let tempValues: IClientInitialFilterState = {
            //   ...values,
            //   City: values.City ? values.City.trim() : values.City,
            //   UserName: values.UserName ? values.UserName.trim() : values.UserName,
            //   ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : values.ContactPersonName,
            //   ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : values.ContactPersonEmail,
            // }
            // if(JSON.stringify(values) === JSON.stringify(_.omit(clientInitialFilterState, ["isFilterActive"])))
            // {
            //   // toast.warn(formatMessage("please_select_at_least_1_field"));
            //   setErr(true);
            //   setTimeout(()=>{setErr(false)}, 3000);
            // }
            // else{
            //   setErr(false);
            let tempValues = {
              City: values.City ? values.City.trim() : null,
              ContactPersonName: values.ContactPersonName ? values.ContactPersonName.trim() : null,
              IsActive: values.IsActive,
              ContactPersonEmail: values.ContactPersonEmail ? values.ContactPersonEmail.trim() : null,
              UserName: values.UserName ? values.UserName.trim() : null,
            }
            props.submitHandler(tempValues);
            // }
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col xs={12} lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" label="client_name" />
                      <Field
                        data-testid="testUserName"
                        className="form-control"
                        name="UserName"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="client_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12} lg={6}>
                    <div className="mb-3">
                      <Label className="form-label" label="city" />
                      <Field
                        data-testid="testCity"
                        className="form-control"
                        name="City"
                        type="text"
                        placeholder="city"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} lg={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="contact_person_name"
                      />
                      <Field
                        data-testid="testContactPersonName"
                        className="form-control"
                        name="ContactPersonName"
                        type="text"
                        placeholder="contact_person_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12} lg={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="contact_person_email"
                      />
                      <Field
                        data-testid="testContactPersonEmail"
                        className="form-control"
                        name="ContactPersonEmail"
                        autoComplete="new-email"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                        placeholder="contact_person_email"
                        tolowercase={+true}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mb-3 form-group d-flex align-items-center mt-3">
                  <Col xs={3} lg={2}>
                    <label className="form-label">
                      {" "}
                      <FormattedMessage id="status" />
                    </label>
                  </Col>
                  <Col xs={8} lg={10}>
                    <Row>
                      <Col xs={5} lg={3}>
                        <div className="form-check">
                          <Field
                            data-testid="testIsActive"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="active"
                            value="0"
                          />
                          <label
                            htmlFor="isActive"
                            className="form-check-label mt-1"
                          >
                            <FormattedMessage id="active" />
                          </label>
                        </div>
                      </Col>
                      <Col xs={5} lg={3}>
                        <div className="form-check">
                          <Field
                            data-testid="testIsNotActive"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="inactive"
                            value="1"
                          />
                          <label
                            htmlFor="isNotActive"
                            className="form-check-label mt-1 "
                          >
                            <FormattedMessage id="inactive" />
                          </label>
                        </div>
                      </Col>
                      <Col xs={2} lg={3}>
                        <div className="form-check">
                          <Field
                            data-testid="testAll"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="all"
                            value="2"
                          />
                          <label
                            htmlFor="all"
                            className="form-check-label  mt-1"
                          >
                            <FormattedMessage id="all" />
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* {
                  err &&
                  <span data-testid="testError" className= "text-danger errorMessage">
                    <FormattedMessage id="please_select_at_least_1_field" />
                  </span>
                } */}
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ClientFilterForm;
