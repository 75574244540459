import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import RoutesConstants from "../../../constants/routes.constant";
import { useStore } from "../../../contexts/StoreProvider";
import config from '../../../helpers/config.helper';
import { getToken } from "../../../helpers/localStorages.helper";
import ILogin from "../../../models/ILogin";
import { formatMessage } from "../../../translations/formatMessage";
import "../Login.scss";
import { ICommonState } from "../../../models/state/ICommonState";
import withRouter from "../../../shared-components/Routes/WithRouter";
import VerifyEmailForm from "../forms/verify-email-form";

interface IProps {
  // history: any;
}

const appConfig = config();
export const baseURL = appConfig.API_URL;

const VerifyEmail = (props: IProps) => {
  const { state } = useLocation();
  const { verifyEmailStore} = useStore();
  const { error, inProgress }: ICommonState = verifyEmailStore;

  // useEffect(() => {
  //   if (error) {
  //     toast.error(formatMessage(error));

  //   }
  // }, [error]);

  return (
    <>
      {/* {inProgress && <div className='main-content' id='loader'><SpinnerLoader /></div>} */}
      <div className="login-bg d-flex align-items-center justify-content-center ">
          <div className="bg-white p-3 loginFrom">
            <VerifyEmailForm  email={state.Email} isLoading={inProgress} />
          </div>
      </div>
    </>
  );
};

export default withRouter(observer(VerifyEmail));
