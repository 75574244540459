/*    
<summary>
   This functional common component provides us custom input box & has error handler that shows validation error. 
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";

interface IProps {
  children: React.ReactNode;
  className: string;
  errclassname: string | undefined;
  "data-testid"?: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  handleFileUpload: () => void;
  placeholder: string;
  value: string;
  disabled: boolean;
  setCSVFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const FormikFormFileInput = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? "error" : ""}`;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;

  return (
    <>
      <input
        {...field}
        {...props}
        placeholder={_placeholder}
        className={props.className + " " + errorClass}
        disabled={props.disabled}
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const reader = new FileReader();
          if (e.target.files != null && e.target.files.length > 0) {
            const file = e.target.files[0];
            props.setCSVFile(file);
            reader.onload = props.handleFileUpload;
            reader.readAsText(e?.target?.files[0]);
            helpers.setValue(e.target?.value);
          }
          else
          helpers.setValue("");
        }}
      />
        <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormFileInput);
