import { IGetSupplier } from "../../models/response/ISupplierResponse";

export const addEditSupplierState: IGetSupplier = {
    SupplierName: "",
    PhoneNo: "",
    ContactPersonName: "",
    ContactPersonPhoneNo: "",
    ContactPersonEmail: "",
    Address: "",
    PricePerL: "",
    PricePerGallon: "",
    TaxRegistrationNo: "",
    Id: -1,
}