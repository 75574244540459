/*    
<summary>
  This functional component "VehicleFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import { allocationPeriodTypes, customerIdList, registerExpiryDuration, userTypeList } from "../../../constants/common-constant";
import DateRangeFilter from "../../Filter/Filter";
import FormikFormDatePicker from "../../../shared-components/FormikFormHandlers/FormikFormDatePicker";
import { IVehicleInitialFilterState } from "../../../models/IFilter";
import { vehicleInitialFilterState } from "../../../core/initialState/initial-filter-states";
import _ from "lodash";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: IVehicleInitialFilterState;
}


const VehicleFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,

  } = props;

  const [err, setErr] = useState<boolean>(false);

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal filter-vehicle" show={true} centered>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='filter_title' />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            if (JSON.stringify(values) === JSON.stringify(_.omit(vehicleInitialFilterState, ["isFilterActive"]))) {
              // toast.warn(formatMessage("please_select_at_least_1_field"));
              setErr(true);
              setTimeout(() => { setErr(false) }, 3000);
            }
            else {
              let tempValues = {
                RFID: values.RFID ? values.RFID.trim() : null,
                RegistrationNo: values.RegistrationNo ? values.RegistrationNo.trim() : null,
                RegistrationExpiryDateInDays: values.RegistrationExpiryDateInDays
              }
              setErr(false);
              props.submitHandler(tempValues);
            }
          }} >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="rfid"
                      />
                      <Field
                        data-testid="testRFID"
                        className="form-control"
                        name="RFID"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="rfid"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="registration_no"
                      />
                      <Field
                        data-testid="testRegistrationNo"
                        className="form-control"
                        name="RegistrationNo"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="registration_no"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3 ">
                      <Label
                        className="form-label"
                        label="registration_expiry_in_days"
                      />
                      <Field
                        name="RegistrationExpiryDateInDays"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        placeholder="registration_expiry_in_days"
                        typeofoptionid="number"
                        options={registerExpiryDuration}
                      />
                    </div>
                  </Col>
                  {/* <Col xs={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="truck_allocation"
                      />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="TruckAllocation"
                        type="number"
                        placeholder="truck_allocation"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col> */}

                  {/* <Col xs={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="allocation_per_fill"
                      />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="AllocationPerFill"
                        type="number"
                        placeholder="allocation_per_fill"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col> */}
                  {/* <Col xs={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="lower_consumption_limit"
                      />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="LowerConsumptionLimit"
                        type="number"
                        placeholder="lower_consumption_limit"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="higher_consumption_limit"
                      />
                      <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="HigherConsumptionLimit"
                        type="number"
                        placeholder="higher_consumption_limit"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col> */}
                </Row>

                {
                  err &&
                  <div className="error-text text-center w-100">
                    <span data-testid="testError" className="text-danger errorMessage">
                      <FormattedMessage id="please_select_at_least_1_field" />
                    </span>
                  </div>
                }

              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal >
    </>
  );
};

export default VehicleFilterForm;
