import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IAddUser } from "../../../models/IUser";
import { formatMessage } from "../../../translations/formatMessage";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { RoleEnum } from "../../../constants/enums/roleEnum";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import Label from "../../../shared-components/label/label";
import FormikFormInputForPhone from "../../../shared-components/FormikFormHandlers/formik-form-input-for-number";
import FormikFormImagePicker from "../../../shared-components/FormikFormHandlers/FormikFormImagePicker";
import { customerIdList } from "../../../constants/common-constant";
import { IOption } from "../../../models/ICommon";
import { addUserValidateSchema, editUserValidateSchema,} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import UserType from "../../../constants/userType.constant";
import { ModuleName } from "../../../constants/moduleName.constant";
import { getUserId, getUserType } from "../../../helpers/localStorages.helper";
import { InfoTooltip } from "../../../helpers/common-helper-components";

interface IProps {
  id: number;
  submitHandler: (data: IAddUser) => void;
  userType: string;
  onModalClosed: () => void;
  getUserLoading: boolean;
  addUserLoading: boolean;
  initialValues: IAddUser;
  userTypeOptions: IOption[];
  customerList: IOption[];
  roleList: IOption[];
  from: string;
}

const AddUserForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getUserLoading,
    addUserLoading,
    initialValues,
    roleList,
    userTypeOptions,
    customerList,
    userType
  } = props;
  const userIdfromLocalStorage = getUserId() ? getUserId() : -1;
  const columns = [
    {
      accessorKey: "Id",
      header: "Id",
      hidden: true,
    },
    {
      accessorKey: "Name",
      header: formatMessage("label_selectall"),
    },
  ];
  const [selectedRole, setSelectedRole] = useState<Array<number>>([]);
  const userId = getUserId() ? parseInt(getUserId()!) : -1;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IAddUser>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal user-modal" show={true} centered>
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="user_title_add" />
            ) : (
              <FormattedMessage id="user_title_edit" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getUserLoading ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={
            !(id > 0) ? addUserValidateSchema : editUserValidateSchema
          }
          onSubmit={async (values) => {
            let tempRoleId = values.UserType == 2 ? values.RoleId : -1;
            let tempCustomerId = (values.UserType == 0 || values.UserType == 1) ? values.CustomerId : -1;
            let tempValues = {
              UserName: values.UserName ? values.UserName.trim() : values.UserName,
              Email: values.Email ? values.Email.trim() : values.Email,
              PhoneNo: values.PhoneNo ? values.PhoneNo.trim() : values.PhoneNo,
              WhatsAppNo: values.WhatsAppNo ? values.WhatsAppNo.trim() : values.WhatsAppNo,
              Address: values.Address ? values.Address.trim() : values.Address,
              City: values.City ? values.City.trim() : values.City,
              State: values.State ? values.State.trim() : values.State,
              Country: values.Country ? values.Country.trim() : values.Country,
              ProfileImage: values.ProfileImage ? values.ProfileImage.trim() : values.ProfileImage,
              UserType: values.UserType,
              RoleId: values.RoleId,
              CustomerId: values.CustomerId,
              IsActive: values.IsActive,
            }
            submitHandler(props.from == ModuleName.User ? {
              ...tempValues,
              RoleId: tempRoleId,
              CustomerId: tempCustomerId,
            } : { ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form" noValidate>
              <FormObserver />
              <Modal.Body>
                <Container>
                  {/* <Col xs={8}>
                    <div className="mb-3">    
                    <p className="fs-4 fw-bolder">Profile</p>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="Enter Full Name"
                      />
                      <Field
                        name="UserName"
                        type="text"
                        className="form-control"
                        maxLength={100}
                        as={FormikFormInput}
                      />
                    </div>
                    </div>
                    </Col> */}

                  <Row className="profile pt-3">
                    <Col lg={2} md={2} sm={2} xs={3}>
                      <Field
                        name="ProfileImage"
                        type="text"
                        as={FormikFormImagePicker}
                      />
                    </Col>
                    <Col className="user-type">
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="user_label_username"
                        />
                        <Field
                          className="form-control"
                          data-testid="testNameField"
                          name="UserName"
                          type="text"
                          placeholder="user_label_username"
                          // invalidcharacter={nameInvalidCharacters}
                          // validcharacter={nameValidCharacters}
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          maxLength={50}
                          as={FormikFormInput}
                        // disabled={id===userId?true:false}
                        />
                      </div>

                    </Col>

                    <Col>
                      <div className="mb-3 form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="user_label_user_type"
                        />
                        <Field
                          name="UserType"
                          type="number"
                          className="form-control"
                          as={FormikFormSelectField}
                          // label='User_Label_Role'
                          typeofoptionid="number"
                          options={userTypeOptions}
                          disabled={(id > 0 || props.from !== ModuleName.User) || getUserType() == UserType.CustomerAdmin}
                        />
                      </div>
                    </Col>
                    {
                      props.from === ModuleName.User &&

                      <>

                        {values.UserType == 2 && (
                          <Col>
                            <div className="mb-3 form-group">
                              <Label
                                className="form-label"
                                required={true}
                                label="user_label_roleid"
                              />
                              <Field
                                name="RoleId"
                                type="number"
                                className="form-control"
                                as={FormikFormSelectField}
                                label="user_label_roleid"
                                typeofoptionid="number"
                                // disabled={(id > 0)}
                                options={roleList}
                              />
                            </div>
                          </Col>
                        )}
                        {/* {(values.UserType == 1 && userType != UserType.CustomerAdmin) && ( */}
                        {((values.UserType == 0 || values.UserType == 1) && getUserType() != UserType.CustomerAdmin) && (
                          <Col>
                            <div className="mb-3 form-group">
                              <Label
                                className="form-label"
                                required={true}
                                label="customer_id"
                              />
                              <Field
                                className="form-control form-select"
                                aria-label="Default select example"
                                name="CustomerId"
                                type="number"
                                as={FormikFormSelectField}
                                typeofoptionid="string"
                                disabled={id > 0}
                                options={customerList}
                              />
                            </div>
                          </Col>
                        )}

                      </>

                    }
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label className="form-label"
                          required={false}
                          label="user_label_contactnumber"
                        />
                        <Field
                          data-testid="testPhoneNo"
                          className="form-control"
                          name="PhoneNo"
                          placeholder="user_label_contactnumber"
                          type="tel"
                          validcharacter={["+", "-"]}
                          as={FormikFormInputForPhone}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={false}
                          label="user_label_whatsappnumber"
                        />
                        <Field
                          data-testid="testWhatsAppNo"
                          className="form-control"
                          name="WhatsAppNo"
                          placeholder="user_label_whatsappnumber"
                          type="tel"
                          validcharacter={["+", "-"]}
                          as={FormikFormInputForPhone}
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={6} xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          required={true}
                          label="user_label_email"
                        />
                        <Field
                          className="form-control"
                          name="Email"
                          type="email"
                          placeholder="user_label_email"
                          invalidcharacter={[" "]}
                          validcharacter={[
                            "-", "_", "[", "]", "(", ")", ":",
                            "#", "@", ".", "$"
                          ]}
                          disabled={id == userIdfromLocalStorage ? true : false}
                          maxLength={100}
                          tolowercase={+true}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="form-group">
                        <Label
                          className="form-label"
                          // required={true}
                          label="user_label_address"
                        />
                        <Field
                          className="form-control"
                          placeholder="user_label_address"
                          name="Address"
                          type="text"
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          as={FormikFormTextArea}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          // required={true}
                          label="user_label_city"
                        />
                        <Field
                          name="City"
                          className="form-control"
                          placeholder="user_label_city"
                          type="text"
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          maxLength={100}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>

                    <Col xs={4}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          // required={true}
                          label="user_label_state"
                        />
                        <Field
                          className="form-control"
                          name="State"
                          placeholder="user_label_state"
                          type="text"
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          maxLength={100}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          // required={true}
                          label="user_label_country"
                        />
                        <Field
                          className="form-control"
                          name="Country"
                          placeholder="user_label_country"
                          validcharacter={['@', '#', '$', '-', '_', '.']}
                          type="text"
                          maxLength={100}
                          as={FormikFormInput}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {
                      (props.from === ModuleName.User && id !== userId) &&
                      <Col>
                        <div className="form-check mt-2">
                          <Field
                            data-testid="testRememberMe"
                            className="form-check-input"
                            name="IsActive"
                            type="checkbox"
                            id="IsActive"
                          />
                          <label
                            htmlFor="IsActive"
                            className="form-check-label mt-1"
                          >
                            <FormattedMessage id="is_active" />
                          </label>
                        </div>
                      </Col>
                    }
                  </Row>
                </Container>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUserLoading}
                    disabled={addUserLoading}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUserForm;


