/*    
<summary>
  This functional component "customerFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import DateRangeFilter from "../../Filter/Filter";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import {
  customerIdList,
  userTypeList,
} from "../../../constants/common-constant";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: any;
}

const CustomerFilterForm = (props: IProps) => {
  const { submitHandler, onModalClosed, initialValues, actionInProgress } =
    props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            let tempValues = {
              SupplierName: values.SupplierName ? values.SupplierName.trim() : null,
            }
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
              <Row className="mb-3 form-group d-flex align-items-center">
              <Col xs={4}>
              <Label className="form-label" label="supplier_name" />
              </Col>
                  
                  <Col xs={8} lg={8}>
                    <div className="mb-3">
                    
                      <Field
                        data-testid="testUserName"
                        className="form-control"
                        name="SupplierName"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="supplier_name"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CustomerFilterForm;
