/*    
<summary>
   This functional common component provides us custom DateTime form Field  and has error handler that shows 
   validation error. 
   Developer: Aashish Singh, Updated Date: 20-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { formatMessage } from '../../translations/formatMessage';
import FormikFormErrorHandler from './FormikFormErrorHandler';

export default function FormikFormDateTimePicker(props:any) {
  const { disabled, min, max , label} = props;
  const [ field, meta, helpers ] = useField(props);
  const { touched, error } = meta;

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     <Stack spacing={3} className="dateTimePicker">
        <DateTimePicker
          renderInput={(params:any) => <TextField {...params} />}
          label={label ? label : ""}
          value={field.value ? dayjs(field.value) : null}
          ampm={false}
          views={['year', 'day', 'hours', 'minutes', 'seconds']}
          inputFormat="YYYY/MM/DD HH:mm:ss"
          onChange={(newValue:any) => {
            helpers.setValue(newValue);
          }}
          minDateTime={ min ? dayjs(min): undefined}
          maxDateTime={ max ? dayjs(max): undefined}
          disabled={props.disabled}
        />
      </Stack>
    </LocalizationProvider>
    {/* <FormikFormErrorHandler intl={props.intl} meta={meta} /> */}
      {(error) &&
        (error && <span data-testid="testError" className= "text-danger errorMessage">{formatMessage(error)}</span>)}
    {/* { 
        (error && <span data-testid="testError" className="errMessageRange text-danger errorMessage">{formatMessage(error)}</span>)} */}
    </>
  );
}
