import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import FormikFormErrorHandler from './FormikFormErrorHandler';
import { useField } from 'formik';
import Dropdown from "react-bootstrap/Dropdown";
import Grid from '../Grid/Grid';
import { Row } from '@tanstack/react-table';

interface IProps {
    children: React.ReactNode;
    className: string;
    "data-testid"?: string;
    intl: any;//IntlConfig;
    name: string;
    onBlur: () => void;
    onChange: () => void;
    placeholder: string;
    value: string;
    disabled: boolean;
    data: Array<{ Id: number, Name: string }>;
    columns: Array<{ dataField: string, text: string, hidden?: boolean }>;
    hiddenColumns: Array<string>;
    keyField: string;
    label?: string;
    searchEnabled?: boolean;
    rowSelectionEnabled?: ((row: Row<any>) => boolean) | boolean;
    isTankLevel:boolean;
}

const FormikFormDropdownTable = (props: IProps) => {
    const { data, columns, disabled, hiddenColumns, keyField, label, rowSelectionEnabled, searchEnabled } = props;
    const [field, meta, helpers] = useField(props);
    const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;

    return (
        <>
            <Dropdown
                // data-testid={props['data-testid']}
                className={`multi-select-dropdown multi-select-dropdown-table dashboard-filter-all ${errorClass}`}>
                <Dropdown.Toggle disabled={disabled} className="pt-0" data-testid={props['data-testid']} variant="white">
                  
                  <p className='please-select'>  {label ? 
                  <FormattedMessage id={label} /> : <FormattedMessage id='please_select' />}
                  </p>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="dropdown-checkbox-scroll fuel-filter">
                        {data && <Grid data={data} columns={columns}
                            hiddenColumns={hiddenColumns}
                            rowSelectionEnabled={rowSelectionEnabled ?? true}
                            selectedRows={field.value}
                            setSelectedRows={helpers.setValue}
                            keyField={keyField}
                            searchEnabled={searchEnabled}
                            // showSearchPlaceholder={true}
                        />}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            <FormikFormErrorHandler intl={props.intl} meta={meta} />
        </>
    );
};

export default injectIntl(FormikFormDropdownTable);