/*    
<summary>
   This file is used to provide initial values or state to the pdf setting Data observables.
   Developer:Ashish Singh, Created Date:05-June-2024
</summary>
*/
import { IPDFSetting } from "../../models/response/ICustomerResponse";

export const addEditPDFSettingState: IPDFSetting = {
    TicketTitle: "please_select",
    Header1: "",
    Header2: "",
    Header3: "",
    Header4: "",
    Header5: "",
    Header6: "",
    CustomerId1Title:"Customer ID1",
    CustomerId2Title:"Customer ID2",
    CustomerId3Title:"Customer ID3",
    CustomerId4Title:"Customer ID4",
    CustomerId5Title:"Customer ID5",
    CustomerId6Title:"Customer ID6",
    VatInfo:"",
    Footer1:"",
    Footer2:"",
}