/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date:13-May-2024
</summary>
*/
export enum AlarmTypeEnum {
   LOWER_CONSUMPTION_ALARM,
   HIGHER_CONSUMPTION_ALARM,
   ALLOCATION_PER_FILL_ALARM_FOR_DRIVER,
   ALLOCATION_VOLUME_ALARM_FOR_DRIVER,
   ALLOCATION_PER_FILL_ALARM_FOR_VEHICLE,
   ALLOCATION_VOLUME_ALARM_FOR_VEHICLE,
   CUSTOMER_THRESHOLD_LIMIT_ALARM,
   CUSTOMER_VOLUME_LIMIT_ALARM
}

