/*    
<summary>
   This file is used to provide initial values or state to the Vehicle Module observables.
    Developer:Aashish Singh, Created Date:04-March-2024
</summary>
*/
import { IGetVehicleList } from "../../models/response/IVehicleResponse";

export const getVehicleListState: IGetVehicleList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Vehicles: [],
    TotalFilteredVehicles: 0
}