import { useField } from "formik";
import React from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from './FormikFormErrorHandler';
import { formatMessage } from "../../translations/formatMessage";
import preferencesStore from "../../core/stores/preferences-store";

interface IProps {
  children: React.ReactNode;
  className: string;
  "data-testid"?: string;
  intl: any;//IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  typeofoptionid: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  options: Array<{ Id: number | string, Name: string, hidden: boolean, disabled?: boolean }>
  changeHandler?: (value: any) => void
  isName: boolean
  isaccount: boolean
  decimalChangeHandler?: (value: any) => void
  isdecimal: boolean
}

const FormikFormSelectField = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;
  return (
    <>
      <select
        // {...props}
        data-testid={props["data-testid"]}
        {...field}
        disabled={props.disabled}
        className={props.className + ' ' + errorClass}
        onChange={e => {
          const value = props.typeofoptionid === 'number' ? parseInt(e.target.value) : e.target.value;
          props.changeHandler?.(value);
          helpers.setValue(value);
          if (props.isdecimal) {
            let selectedClientId = props.options.filter(e => e.Name === String(value))[0]
            props.decimalChangeHandler?.(Number(selectedClientId ? selectedClientId.Id : 0));
          }
        }}
      >
        {props.options?.map((data) => {
          return <option key={props.isName ? data.Name : data.Id} value={props.isName ? data.Name : data.Id} hidden={data.hidden} data-testid={props.isName ? data.Name : data.Id} disabled={data.disabled}>{props.isaccount && data.Name !== "please_select" ? data.Name : formatMessage(data.Name)}</option>;
        })}
      </select>
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormSelectField);