import React, { ReactElement } from 'react';
// import '../../assets/css/style.css';
import Popup from './Popup';

interface Props {
    modalSubmit: () => void;
    modalClosed: () => void;
    isLoading: boolean;
    message: string;
    submitBtnText: string;
    cancelBtnText: string;
    modalTitle: string;
    children?: ReactElement;
}

const DeletePopup: React.FC<Props> = props => {
    return (
        <Popup {...{
            ...props, icon: <span className="material-symbols-outlined fs-5 text-danger me-1" style={{marginTop: '1px'}}>
                delete
            </span>
        }}>
            {props.children}
        </Popup>
    );
};

export default DeletePopup;
