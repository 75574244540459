import { IRoutesConstants } from "../models/IRoutesConstants";


const RoutesConstants: IRoutesConstants = {
  Login: '/login',
  Dashboard: '/dashboard',
  Home: '/home',
  Alarms: '/alarms',
  Transactions: '/transactions',
  Reports: '/reports',
  Vehicles: '/vehicle',
  Drivers: '/drivers',
  Trucks: '/assets',
  LiveTracking: '/livetracking',
  Clients: '/clients',
  User: '/user',
  Customer: '/customer',
  Roles: '/roles',
  ForgotPassword: '/forgot-password',
  VerifyEmail: '/verify-email',
  AddTransaction: '/add-transaction',
  SuperAdminDashboard: '/dashboard',
  CustomerDashboard: '/dashboard',
  ReportPreview: '/report-preview',
  Supplier: '/supplier',
};

export default RoutesConstants;