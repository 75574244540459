/*    
<summary>
  This functional component "AddUpdateSupplier" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { IOption } from '../../../models/ICommon';
import { ISupplier } from '../../../models/response/ISupplierResponse';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateSupplierForm from '../forms/add-edit-supplier-form';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateSupplier = (props: IProps) => {

  const { supplierStore } = useStore();
  const { GetSupplierByIdService, resetAddUpdateSupplierState, resetGetSupplierDetail, supplierDetailState, getSupplierDetail, addUpdateSupplierState, AddSupplierService, UpdateSupplierService } = supplierStore;
  const initialValues: ISupplier = getSupplierDetail;
  const { id, modalClosed } = props

  const allocationPeriodTypes: IOption[] = [
    { Id: -1, Name: "please_select" },
    { Id: 1, Name: "daily" },
    { Id: 2, Name: "weekly" },
    { Id: 3, Name: "monthly" },
  ];

  const onModalClosed = useCallback(() => {
    resetGetSupplierDetail();
    resetAddUpdateSupplierState();
    modalClosed();
  }, [resetGetSupplierDetail, resetAddUpdateSupplierState, modalClosed])

  useEffect(() => {
    if (id > 0) {
      GetSupplierByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateSupplierState.success) {
      if (id === -1)
        toast.success(formatMessage("added_success"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateSupplierState.success]);

  useEffect(() => {
    if (addUpdateSupplierState.error) {
      toast.error(formatMessage(addUpdateSupplierState.error));
      resetAddUpdateSupplierState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateSupplierState.error]);

  const onSubmitForm = (values: ISupplier) => {
    if (id > 0)
      UpdateSupplierService(id, values);
    else
      AddSupplierService(id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateSupplierForm
        initialValues={initialValues}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={supplierDetailState.inProgress}
        addUpdateInProgress={addUpdateSupplierState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateSupplier);
