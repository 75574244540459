import moment from "moment";
import { IPayment } from "../../models/response/IPaymentResponse";
import { DATE_FORMAT_BACKEND } from "../../constants/common-constant";

export const addPaymentState: IPayment = {
    Id: 0,
    PaymentDate: moment(new Date()).format(DATE_FORMAT_BACKEND),
    Description: "",
    AmountCr: "",
    ModeOfPayment: "",
}