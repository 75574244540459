/*    
<summary>
   This file is used to provide initial values or state to the customer Data observables.
   Developer:Ashish Singh, Created Date:15-May-2024
</summary>
*/
import { IReportInfo } from "../../models/response/IReportResponse";

export const addReportInitialData: IReportInfo = {
    FileName: "",
    SearchId: "",
    ActionType: 2,
    StartDate: new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
    EndDate: new Date(new Date().setHours(23, 59, 59, 999)),
}