import { initialClientSetting } from "../core/initialState/add-edit-client-state";
import { initialCustomerDashboardFilter } from "../core/initialState/initial-filter-states";

export const getUserType = () => localStorage.getItem('secure_fuel_userType');

export const getToken = () => localStorage.getItem('secure_fuel_token');

export const getRefreshToken = () => localStorage.getItem('secure_fuel_refreshToken');

export const getUserPermissions = () => localStorage.getItem('secure_fuel_userPermissions');

export const getUserName = () => localStorage.getItem('secure_fuel_userName');

export const getUserId = () => localStorage.getItem('secure_fuel_userId');

export const getCustomerId = () => localStorage.getItem('secure_fuel_customerId');

export const getParamType = () => localStorage.getItem('secure_fuel_paramType');

export const getTimeZone = () => localStorage.getItem('secure_fuel_timezoneId');

export const getdebuglogsfilter = () => localStorage.getItem('secure_fuel_debugLogFilter');

export const getTableView = () => localStorage.getItem('secure_fuel_tableView');

export const getClientId = () => localStorage.getItem('secure_fuel_clientId');

export const getAmountDecimal = () => {
    let decimalValue = localStorage.getItem('secure_fuel_amount_decimal');
    let value = (decimalValue !== null && decimalValue !== "") ? decimalValue : "2";
    return parseInt(value)
};

export const getVersion = () => localStorage.getItem('secure_fuel_version');

export const getProfileImage = () => localStorage.getItem('secure_fuel_profileImage');

export const getClientSettings = () => localStorage.getItem('secure_fuel_settings');

export const getClientLimitSetting = () => {
    let clientSettingfromLocalStorage = localStorage.getItem('secure_fuel_settings');
    let clientSetting = (clientSettingfromLocalStorage !== null) ? JSON.parse(clientSettingfromLocalStorage!) : initialClientSetting;
    return clientSetting;
};

export const getCustomerDashboardFilterDetails = () => {
    let filterfromLocalStorage = localStorage.getItem('secure_fuel_customer_dashboard_filter');
    let filterSetting = (filterfromLocalStorage !== null) ? JSON.parse(filterfromLocalStorage!) : initialCustomerDashboardFilter;
    return filterSetting;
};