import * as React from "react";
import "leaflet/dist/leaflet.css";
import { useRef } from "react";
import * as ReactDOMServer from "react-dom/server";
import { FormattedMessage } from "react-intl";
import L, { Icon, LatLng, LatLngLiteral } from "leaflet";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from "react-leaflet";
import { demoLiveTracking } from "../../../core/stores/data";
import navigationIconURL from "../../../assets/Images/navigation.svg";
import fuelTruck from "../../../assets/Images/fuelTruck.svg";
interface IProps{
  data:Array<any>;
}

const OSMMap = (props: IProps) => {

  const truckIcon = new Icon({
    // iconUrl: truckImg,
    iconUrl: fuelTruck,
    iconSize: [45, 45],
  });
  const navigationIcon = new Icon({
    iconUrl: navigationIconURL,
    iconSize: [30, 30],
    customClass:"mapmaker"
  });

  const { data } = props;
  const locationsList = demoLiveTracking;
  const mapRef = useRef<L.Map | null>(null);
  const zoom: number = 10;
  const [mapLoaded, setMapLoaded] = React.useState<boolean>(false);
  const centerPosition : LatLngLiteral = {
    lat: data[0].Heartbeat[0].Lat,
    lng: data[0].Heartbeat[0].Lng,
  };

  const MapView = () => {
    let map = useMap();
    mapRef.current = map;
    if(mapRef.current){
        if(!mapLoaded){
          const LatLngTuple:any = locationsList.map((location: any) => {
            return location.Heartbeat.map((hb:any)=>{
              return [
                hb.Lat,
                hb.Lng,
              ]
          })
          });
          const bound = map.getBounds();
          bound.extend([...LatLngTuple]);
          mapRef.current.fitBounds(bound);
          const newCenter = mapRef.current.getCenter();
          if (!newCenter) return null;
          mapRef.current.setView({ lat: newCenter.lat, lng: newCenter.lng });
          setMapLoaded(true)
        }
    }
    return null;
  }

  return (
    <div className="live-tracking map-gray m-1 ">
    <MapContainer
        center={centerPosition}
        zoom={zoom}
        scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}"
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
      />
      {locationsList.map((location: any, i: number) => {
        return location.Heartbeat.map((hb:any,hbIndex:number)=>{
            return(
              <>
                { (hbIndex === location.Heartbeat.length-1 || hbIndex === 0) && 
                  <Marker
                      key={i}
                      position={{ lat: hb.Lat, lng: hb.Lng }}
                      icon={hbIndex === 0  ? navigationIcon :  truckIcon}
                      
                    >
                      <Popup>
                        <span>{location.DriverName}</span><br/>
                        <span><FormattedMessage id="speed" />: {hb.Speed}</span>
                      </Popup>
                  </Marker>
                }
              </>
            )
          })
      })}
      <MapView/>
      {
        locationsList.map((location: any, i: number) => {
          const routeCoordinates:LatLngLiteral[] =  [];
          location.Heartbeat.map((hb:any)=>{
            routeCoordinates.push({lat:hb.Lat, lng:hb.Lng})
          })
          return (
            <Polyline positions={routeCoordinates} color="blue" />
          )
        })
      }
    </MapContainer>
    </div>
  );
};

export default OSMMap;
