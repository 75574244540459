import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginLayoutRoute from "./LoginLayoutRoute";
import LayoutRoute from "./LayoutRoute";
import { observer } from "mobx-react";
import withRouter from "./WithRouter";
import RoutesConstants from "../../constants/routes.constant";
import Login from "../../modules/Auth/Login";
import Dashboard from "../../modules/Dashboard/Dashboard";
import Vehicle from "../../modules/vehicle/vehicle";
import Transaction from "../../modules/Transactions/Transaction";
import Alarm from "../../modules/alarm/alarm";
import Reports from "../../modules/Reports/Reports";
import Driver from "../../modules/driver/driver";
import Tanks from "../../modules/Tanks/Tanks";
import LiveTracking from "../../modules/live-tracking/live-tracking";
import SuperAdminDashboard from "../../modules/superadmin-dashboard/superadmin-dashboard";
import Client from "../../modules/client/client";
import User from "../../modules/User/User";
import { useStore } from "../../contexts/StoreProvider";
import UserType from "../../constants/userType.constant";
import Customer from "../../modules/customer/customer";
import ForgotPassword from "../../modules/Auth/components/forgot-password";
import { isRoutePermitted, redirectToDefaultRoute } from "../../helpers/routesFunctions.helper";
import { ModuleName } from "../../constants/moduleName.constant";
import Role from "../../modules/role/role";
import { getUserType } from "../../helpers/localStorages.helper";
import AddTransaction from "../../modules/Transactions/components/add-transaction";
import VerifyEmail from "../../modules/Auth/components/verify-email";
import ReportPreview from "../../modules/Reports/report-preview";
import CustomerDashboard from "../../modules/customer-dashboard/customer-dashboard";
import Supplier from "../../modules/supplier/supplier";

const RenderRoutes = () => {
  const { authStore } = useStore();
  // const { } = authStore;
  const userType = getUserType() !== null ? getUserType() : "";
  const userIsSystemAdmin = (userType === UserType.SuperAdmin);
  const userIsClientAdmin = (userType === UserType.CleintAdmin);
  const userIsCustomerAdmin = (userType === UserType.CustomerAdmin);
  return (
    <React.Fragment>
      <Routes>
        <Route path={RoutesConstants.Login} element={<LoginLayoutRoute Component={<Login />} />} />
        <Route path={RoutesConstants.ForgotPassword} element={<LoginLayoutRoute Component={<ForgotPassword />} />} />
        <Route path={RoutesConstants.VerifyEmail} element={<LoginLayoutRoute Component={<VerifyEmail />} />} />
        {(isRoutePermitted(ModuleName.Dashboard)) && <Route path={RoutesConstants.Dashboard} element={<LayoutRoute Component={<Dashboard />} />} />}
        {(isRoutePermitted(ModuleName.TicketManagement)) && <Route path={RoutesConstants.Transactions} element={<LayoutRoute Component={<Transaction />} />} />}
        {(isRoutePermitted((userType == UserType.ClientUser) ? ModuleName.TicketManagement : ModuleName.Alarms)) && <Route path={RoutesConstants.Alarms} element={<LayoutRoute Component={<Alarm />} />} />}
        {(isRoutePermitted(ModuleName.Reports)) && <Route path={RoutesConstants.Reports} element={<LayoutRoute Component={<Reports />} />} />}
        {/* {(isRoutePermitted(ModuleName.Reports)) && <Route path={RoutesConstants.ReportPreview} element={ <ReportPreview />} />} */}
        {(isRoutePermitted(ModuleName.TankManagement)) && <Route path={RoutesConstants.Trucks} element={<LayoutRoute Component={<Tanks />} />} />}
        {(isRoutePermitted(ModuleName.LiveTracking)) && <Route path={RoutesConstants.LiveTracking} element={<LayoutRoute Component={<LiveTracking />} />} />}
        {(isRoutePermitted(ModuleName.VehicleManagement)) && <Route path={RoutesConstants.Vehicles} element={<LayoutRoute Component={<Vehicle />} />} />}
        {(isRoutePermitted(ModuleName.DriverManagement)) && <Route path={RoutesConstants.Drivers} element={<LayoutRoute Component={<Driver />} />} />}
        {(isRoutePermitted(ModuleName.User)) && <Route path={RoutesConstants.User} element={<LayoutRoute Component={<User />} />} />}
        {(isRoutePermitted(ModuleName.Customer)) && <Route path={RoutesConstants.Customer} element={<LayoutRoute Component={<Customer />} />} />}
        {(isRoutePermitted(ModuleName.Roles)) && <Route path={RoutesConstants.Roles} element={<LayoutRoute Component={<Role />} />} />}
        {(isRoutePermitted(ModuleName.Clients)) && <Route path={RoutesConstants.Clients} element={<LayoutRoute Component={<Client />} />} />}
        {(isRoutePermitted(ModuleName.AddTransaction)) && <Route path={RoutesConstants.AddTransaction} element={<LayoutRoute Component={<AddTransaction />} />} />}
        {(isRoutePermitted(ModuleName.SuperAdminDashboard)) && <Route path={RoutesConstants.SuperAdminDashboard} element={<LayoutRoute Component={<SuperAdminDashboard />} />} />}
        {(isRoutePermitted(ModuleName.CustomerDashboard)) && <Route path={RoutesConstants.CustomerDashboard} element={<LayoutRoute Component={<CustomerDashboard />} />} />}
        {(isRoutePermitted(ModuleName.Supplier)) && <Route path={RoutesConstants.Supplier} element={<LayoutRoute Component={<Supplier />} />} />}
        {/* <Route path="*" element={<Navigate to={RoutesConstants.Dashboard} />} /> */}
        <Route path="*" element={<Navigate to={redirectToDefaultRoute()} />} />
      </Routes>
    </React.Fragment>
  )
};

export default withRouter(observer(RenderRoutes));
