import { ColumnDef, Row, VisibilityState, flexRender, getCoreRowModel, ExpandedState, getFilteredRowModel, useReactTable, RowData, Table, getExpandedRowModel } from "@tanstack/react-table";
import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState, useMemo } from "react";
import IndeterminateCheckbox from "../IndeterminateCheckbox/IndeterminateCheckbox";
import { formatMessage } from "../../translations/formatMessage";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { IColoredAndShowIcon, IFormatter } from "../../models/IFormatter";
import { ActionFormatter, AddFormatter, ColoredIconFormatter, CheckboxGroupFormatter, ComponentFormatter, DateTimeFormatter, DetailFormatter, NumberInputFormatter, TextInputFormatter, CheckboxInputFormatter, HeaderIconFormatter, LineProgressBarFormatter, RadioGroupFormatter, SortingFormatter, TimePickerFormatter, TwoSetOfIconsFormatter, SearchSelectFormatter, SelectCheckBoxFormatter, TextFormatter, SelectAllCheckboxFormatter, SelectAllCheckboxFormatterForHeader, clickFormatter, JsxFormatter, CustomDivAlarmFormatter } from "./GridFormatter";
import { ISorting } from "../../models/ISorting";
import { useVirtualizer } from '@tanstack/react-virtual';
import { ISearchSelectOption } from "../../models/ISearchSelectOption";
import Skeleton from "@mui/material/Skeleton";
import { FormattedMessage } from "react-intl";
import Search from "../../assets/Images/search.svg";

interface IProps<TData extends RowData> {
    data: Array<any>;
    columns: Array<any>;
    rowSelectionEnabled?: boolean | ((row: Row<TData>) => boolean);
    selectedRows?: Array<number>;  //for row selection
    setSelectedRows?: Dispatch<SetStateAction<number[]>>;  //for row selection
    searchEnabled?: boolean;
    searchText?: string; //for controlled search input
    handleSearchTextChange?: (value: string) => void; //for controlled search input
    hiddenColumns?: Array<string>;
    keyField?: string;  //for row selection
    selectableRows?: Array<TData>;
    isInProgress?: boolean;
    columnInProgress?: boolean;
    tableContainerRef?: any
    RowExpandEnabled?: boolean;
    subRowsKeyField?: string;
    searchWidthClass?: string;
    draggable?: boolean
}

export interface IPhoneId {

    DeviceFriendlyName: string
    DeviceId: number
    ScheduleName: string


}

export interface IDataCheckBox extends IPhoneId {

    DeviceFriendlyName: string
    DeviceGroupId: number
    DeviceId: number

    IsEnabled: boolean | null
    PhoneId: Array<IPhoneId>
    Schedule: string
    ScheduleId: number

}

interface IHeaderProps<T1,> {
    selectedRows: Array<T1>;
    setSelectedRows: Dispatch<SetStateAction<T1[]>>;
    rowIds: Array<T1>;

}


interface IHeaderPropsTable<T1> {
    selectedRows: Array<T1>;
    setSelectedRows: Dispatch<SetStateAction<T1[]>>;
    Table: Table<T1>
}

interface CreatedObject {
    parentId: number | null,
    childId: number[]
}


const HeaderCheckbox = <T,>(props: IHeaderProps<T>) => {

    const { selectedRows, setSelectedRows, rowIds } = props;

    const onSelectAll = (event: FormEvent<HTMLInputElement>) => {
        const checked = event.currentTarget.checked;
        if (checked) {
            setSelectedRows([...rowIds]);
        } else {
            setSelectedRows([]);
        }
    };


    const checked = !rowIds.find(row => !selectedRows.includes(row));

    return <IndeterminateCheckbox
        {...{
            checked: selectedRows.length > 0 && rowIds.length > 0 && checked,
            indeterminate: selectedRows.length > 0 && !checked && rowIds.some(row => selectedRows.includes(row)),
            onChange: onSelectAll,
        }
        }
    />

}

const HeaderCheckboxExpand = <T,>(props: IHeaderPropsTable<T>) => {

    const { selectedRows, setSelectedRows, Table } = props;

    const HeaderSelectAll = (table: any) => {

        if (selectedRows.length === 0)
            return setSelectedRows(table.options.data.map((item: IDataCheckBox) => {
                return {
                    "parentId": item.DeviceId,
                    "childId": item.PhoneId.map((item: IPhoneId) => item.DeviceId)
                }
            }))
        else {
            setSelectedRows([])
        }

    }

    const isChecked = (table: Table<T>) => {

        if (selectedRows.length === table.options.data.length)
            return true
        else return false

    }

    return <>  <div className="d-flex align-items-center">

        <IndeterminateCheckbox
            {...{
                checked: isChecked(Table),
                indeterminate: Table.getIsSomeRowsSelected(),
                onChange: () => HeaderSelectAll(Table),
            }}
        />

        {

            Table.getIsAllRowsExpanded() ? null : <button
                {...{
                    onClick: Table.getToggleAllRowsExpandedHandler(),
                    style: { height: '18px', marginLeft: '5px' },
                }}
            >
                {Table.getIsAllRowsExpanded() ? <span className="material-symbols-outlined fs-6" title={formatMessage('DeviceSchedule_Title_CollapseAllRows')}>
                    double_arrow
                </span> : <span className="material-symbols-outlined fs-6" title={formatMessage('DeviceSchedule_Title_ExpandAllRows')}>
                    keyboard_double_arrow_down
                </span>}

            </button>


        }

        <button
            {...{
                onClick: () => Table.resetExpanded(),
                style: { height: '18px', marginLeft: '5px' },
            }}
        >
            {Table.getIsSomeRowsExpanded() ? <span className="material-symbols-outlined fs-6" title={formatMessage('DeviceSchedule_Title_CollapseAllRows')}>
                double_arrow
            </span> : null}

        </button>

    </div>
    </>

}


interface IRowCheckboxProps<T1 extends { Id: T2 }, T2> {
    selectedRows: Array<T2>;
    setSelectedRows: Dispatch<SetStateAction<T2[]>>;
    row: Row<T1>;
    keyField: string;

}

interface IRowCheckboxExpansionProps<T1 extends { Id: T2 }, T2> {
    selectedRows: Array<T2>;
    setSelectedRows: Dispatch<SetStateAction<T2[]>>;
    row: Row<T1>;
    keyField: string;

}

interface IHeaderStyle {
    headerStyle?: object;
}

interface ICommonHeader extends IHeaderStyle {
    accessorKey: string;
    displayName: string;
    columnType: number;
}

interface IFormattedDateTime extends ICommonHeader {
    columnType: ColumnType.FormattedDateTimeWithSorting;
    sortingName: string;
}

interface ISelectAllCheckbox extends ICommonHeader {
    columnType: ColumnType.SelectAllCheckbox;
    handler: (event: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => void;
    handlerForHeader?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disableSelectedRows?: Array<number>;
    selectHeaderCheckbox?: boolean;
    setSelectedlectHeaderCheckbox?: Dispatch<SetStateAction<number[]>>
    showCheckboxOnHeader?: boolean
    headerCheckbox?: boolean
}
const RowCheckbox = <T extends { Id: T2 }, T2>(props: IRowCheckboxProps<T, T2>) => {

    const { selectedRows, setSelectedRows, row, keyField } = props;

    const onSelect = (event: FormEvent<HTMLInputElement>, id: T2) => {

        const isChecked = event.currentTarget.checked;

        if (isChecked) {
            const temp = [...selectedRows, id];
            setSelectedRows([...temp]);
        }
        else {
            const temp = selectedRows.filter((rowId) => rowId !== id);
            setSelectedRows([...temp]);
        }
    };

    return <IndeterminateCheckbox
        {...{
            checked: selectedRows.includes(row.original[keyField as keyof unknown]),
            disabled: !row.getCanSelect(),
            // indeterminate: row.getIsSomeSelected(),
            onChange: (event) => { onSelect(event, row.original[keyField as keyof unknown]) },
        }}
    />
}

const RowCheckboxExpand = (props: any) => {

    const { selectedRows, setSelectedRows, keyField } = props;
    const row: Row<any> = props.row;

    const updateChildRowChecked = (id: number, parentId: number | undefined) => {
        if (selectedRows.find((row: CreatedObject) => row.parentId === parentId)) {
            setSelectedRows((selectedRows: CreatedObject[]) => {
                const newList = selectedRows.map((obj) => {
                    if (obj.parentId === parentId) {
                        return { ...obj, childId: [...obj.childId, id] }
                    }
                    return obj;
                });

                return newList;
            });
        }
        else {
            setSelectedRows((selectedRows: CreatedObject[]) => {
                const newList = [...selectedRows, {
                    parentId: parentId,
                    childId: [id]
                }]

                return newList;
            });
        }
    }

    const updateParentRowChecked = (id: number, subRows: Row<any>[]) => {
        if (selectedRows.find((row: CreatedObject) => row.parentId === id)) {
            setSelectedRows((selectedRows: CreatedObject[]) => {
                const newList = selectedRows.filter((obj) => {
                    return obj.parentId !== id;
                });

                return newList;
            });
        }
        else {
            setSelectedRows((selectedRows: CreatedObject[]) => {
                const newList = [...selectedRows, {
                    parentId: id,
                    childId: subRows.map(row => row.original[keyField])
                }]

                return newList;
            });
        }
    }

    const updateCheckedState = (id: number, parentId: number | undefined, subRows: Row<any>[]) => {
        parentId ? updateChildRowChecked(id, parentId) : updateParentRowChecked(id, subRows);
    }

    const updateChildRowUnchecked = (id: number, parentId: number | undefined) => {
        setSelectedRows(
            selectedRows.map((data: CreatedObject) => {
                if (data.parentId === parentId) {

                    return { ...data, "childId": data.childId.filter((childId: number) => childId !== id) }
                }
                return data
            }).filter((data: CreatedObject) => data.childId.length > 0)

        )
    }

    const updateParentRowUnchecked = (id: number) => {
        setSelectedRows((selectedRows: CreatedObject[]) => {
            return selectedRows.filter((obj) => {
                if (obj.parentId === id) {

                    return false
                }
                return true
            })

        })
    }

    const updateUncheckedState = (id: number, parentId: number | undefined) => {
        parentId ? updateChildRowUnchecked(id, parentId) : updateParentRowUnchecked(id);
    }

    const onSelect = (event: FormEvent<HTMLInputElement>, id: number, parentId: number | undefined, subRows: Row<any>[]) => {
        const isChecked = event.currentTarget.checked;
        isChecked ? updateCheckedState(id, parentId, subRows) : updateUncheckedState(id, parentId);
    }

    const isChecked = (id: number, parentId: number | undefined, allRow: any) => {

        if (parentId) {

            if (selectedRows.find((row: CreatedObject) => row.parentId === parentId)?.childId.includes(id)) {
                return true;
            }
            else return false;
        }
        else {
            if (selectedRows.find((row: CreatedObject) => row.parentId === id)) {

                let FinalLength = selectedRows.filter((row: CreatedObject) => row.parentId === id)

                if (FinalLength[0].childId.length === allRow.PhoneId.length) {
                    return true;
                }
                return false
            }
            else return false;
        }
    }

    const isIndeterminate = (id: number) => {

        if (selectedRows.find((row: CreatedObject) => row.parentId === id)) {

            return true
        }
        else return false;

    }

    return <div className="d-flex align-items-center justify-content-center">

        <IndeterminateCheckbox
            {...{
                checked: isChecked(row.original[keyField as keyof unknown], row.getParentRow()?.original[keyField as keyof unknown], row.original),
                indeterminate: isIndeterminate(row.original[keyField as keyof unknown]),
                onChange: (event) => onSelect(event, row.original[keyField as keyof unknown], row.getParentRow()?.original[keyField as keyof unknown], row.subRows)
            }}
        />


        {
            row.getCanExpand() ? (
                <button className="me-auto"
                    {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { height: '18px', marginLeft: '5px' },
                    }}
                >
                    {row.getIsExpanded() ? <span className="material-symbols-outlined fs-6">
                        arrow_downward
                    </span> : <span className="material-symbols-outlined fs-6">
                        arrow_forward
                    </span>}
                </button>
            ) : null

        }

    </div>

}

interface IHeaderStyle {
    headerStyle?: object;
}

interface ICommonHeader extends IHeaderStyle {
    accessorKey: string;
    displayName: string;
    columnType: number;
}

interface ITextHeader extends ICommonHeader {
    columnType: ColumnType.TextHeader;
}
interface IDeviceTextHeader extends ICommonHeader {
    columnType: ColumnType.DeviceTextHeader;
    pretext: string;
}

interface IHiddenColumn {
    accessorKey: string;
    columnType: ColumnType.Hidden
}

interface IIconFormatterColumn extends IHeaderStyle {
    columnType: ColumnType.Icons;
    icons: IFormatter[];
    headerIcon?: IFormatter[];
    isHeaderIconDisabled?: boolean;
}

interface ISortingHeader extends ICommonHeader {
    sortingName: string;
    columnType: ColumnType.Sorting;
}

interface IDetailFormatter extends IHeaderStyle {
    columnType: ColumnType.Detail;
    text: string;
    handler: (row: any) => void;
}

interface IClickFormatterWithSorting extends ICommonHeader {
    columnType: ColumnType.ClickFormatterWithSorting;
    sortingName: string;
    clickHandler: (row: any) => void;
}

interface INotAlwaysEditableColumn {
    notAlwaysEditable?: boolean,
    inEditModeIndex?: number
}

interface IActionsFormatter extends IHeaderStyle {
    columnType: ColumnType.ActionsFormatter;
    icons: IFormatter[];
    headerIcon?: IFormatter[];
    isHeaderIconDisabled?: boolean;
    displayName: string;
}

interface IAction extends ICommonHeader {
    columnType: ColumnType.Action;
    text: string;
    handler: (row: any) => void;
    sortingName: string;
}

interface IFormattedCellValue extends ICommonHeader {
    columnType: ColumnType.FormattedCellValue;
}

interface IJsxElement extends ICommonHeader {
    columnType: ColumnType.JsxElement;
    jsxElement: JSX.Element;
    className: string;
}

interface ICustomDivAlarmFormatter extends ICommonHeader {
    columnType: ColumnType.CustomDivAlarmFormatter;
}


interface ICheckboxGroupFormatter extends ICommonHeader {
    columnType: ColumnType.CheckboxGroup;
    options: Array<{ Id: string, Name: string }>;
    handler: (e: React.ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => void;
    errorMessage?: string;
    flagFieldName?: string;
}

interface ITimePickerFormatter extends ICommonHeader {
    columnType: ColumnType.TimePicker;
    handler: (e: moment.Moment, row: any, rowIndex: number) => void;
    errorMessage?: string;
    errorFieldName?: string;
}

interface IRadioGroupFormatter extends ICommonHeader {
    columnType: ColumnType.RadioGroupFormatter;
    options: Array<{ Id: string, Name: string }>;
    handler: (e: React.ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => void;
    errorMessage?: string;
    flagFieldName?: string;
}

interface INumberInputFormatter extends ICommonHeader {
    columnType: ColumnType.NumberInput;
    handler: (e: React.ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => void;
    errorMessage?: string;
    errorFieldName?: string;
    min: number;
    max: number;
}

interface ITextInputFormatter extends ICommonHeader, INotAlwaysEditableColumn {
    columnType: ColumnType.TextInput;
    handler: (e: React.ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => void;
    errorMessage?: string;
    errorFieldName?: string;
    min?: number;
    max?: number;
    disableSelectedRows?: Array<number>;
}

interface ICheckboxInputFormatter extends ICommonHeader, INotAlwaysEditableColumn {
    columnType: ColumnType.CheckboxInput;
    handler: (e: React.ChangeEvent<HTMLInputElement>, row: any, rowIndex: number) => void;
    disableSelectedRows?: Array<number>;
}

interface ITwoSetOfIcons {
    columnType: ColumnType.TwoSetOfIcons;
    firstSetOfIcons: IFormatter[];
    secondSetOfIcons: IFormatter[];
    iconsChangeRowIndex: number;
}
interface ISearchSelect extends ICommonHeader, INotAlwaysEditableColumn {
    accessorKey2?: string;
    columnType: ColumnType.SearchSelect,
    options: Array<ISearchSelectOption>,
    handler: (value: string | number | undefined, rowIndex: number) => void;
    disableSelectedRows?: Array<number>,
    options2?: Array<ISearchSelectOption>,
    optionChangeRowIndex?: number;
    setAccesorFn?: (originalRow: any, index: number) => any;
    setOptionRender?: (index: number) => any;
}

interface ICheckSelect extends ICommonHeader, INotAlwaysEditableColumn {
    columnType: ColumnType.CheckBoxSelect,
    data: Array<any>,
    columns: Array<any>,
    handler: (selectedRows: number[], rowIndex: number) => void;
    keyField: string;
    hiddenColumns: Array<string>;
    nameField: string;
    disableSelectedRows?: Array<number>,
}

interface ILineProgressBar extends ICommonHeader {
    columnType: ColumnType.LineProgressBar;
}

interface IColoredIconFormatter extends IHeaderStyle {
    columnType: ColumnType.ColoredIcons;
    icons: Array<IColoredAndShowIcon>;
}

type MyColumnDef<T> = ColumnDef<T, any> & {
    headerStyle?: object;
    className?: string;
};

export const getColumns = <T extends { Id: T2; }, T2>(headers: Array<ISortingHeader | ISearchSelect | ICheckSelect | IIconFormatterColumn | IHiddenColumn | ITextHeader | IDetailFormatter | IClickFormatterWithSorting | IFormattedCellValue | IActionsFormatter | ICheckboxGroupFormatter | ITimePickerFormatter | IRadioGroupFormatter | INumberInputFormatter | ITextInputFormatter | ICheckboxInputFormatter | ITwoSetOfIcons | IFormattedDateTime | ILineProgressBar | IColoredIconFormatter | IAction | IDeviceTextHeader | ISelectAllCheckbox | IJsxElement | ICustomDivAlarmFormatter>, sortingState?: ISorting, sortingHandler?: (accessor: string) => void, shouldNotShowIfDisabled?: boolean) => {
    const columns: MyColumnDef<T>[] = [];

    headers.forEach(header => {
        switch (header.columnType) {
            case ColumnType.Hidden:
                columns.push({
                    accessorKey: header.accessorKey
                });
                break;
            case ColumnType.TextHeader:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.DeviceTextHeader:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    headerStyle: header.headerStyle,
                    cell: TextFormatter(header.pretext),
                })
                break;
            case ColumnType.Sorting:
                if (sortingHandler && sortingState) columns.push({
                    accessorKey: header.accessorKey,
                    header: SortingFormatter(sortingHandler, sortingState.column, sortingState.orderBy, header.sortingName, header.displayName),
                    headerStyle: header.headerStyle,
                    className: 'sorting-head position-relative',

                })
                break;
            case ColumnType.ClickFormatterWithSorting:
                if (sortingHandler && sortingState) columns.push({
                    accessorKey: header.accessorKey,
                    header: SortingFormatter(sortingHandler, sortingState.column, sortingState.orderBy, header.sortingName, header.displayName),
                    cell: clickFormatter(header.clickHandler),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.Icons:
                const isHeaderIconDisabled = header.isHeaderIconDisabled ? header.isHeaderIconDisabled : false;
                columns.push({
                    accessorKey: ' ',
                    header: header.headerIcon ? HeaderIconFormatter(header.headerIcon, isHeaderIconDisabled) : '',
                    cell: AddFormatter(header.icons),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.Detail:
                columns.push({
                    accessorKey: ' ',
                    header: '',
                    cell: DetailFormatter(header.handler, header.text),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.FormattedCellValue:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: () => <FormattedMessage id={header.displayName} />,
                    cell: (row: any) => formatMessage(row.getValue(header.accessorKey))
                })
                break;
            case ColumnType.ActionsFormatter: {
                const isHeaderIconDisabled = header.isHeaderIconDisabled ? header.isHeaderIconDisabled : false;
                columns.push({
                    accessorKey: ' ',
                    header: header.headerIcon ? HeaderIconFormatter(header.headerIcon, isHeaderIconDisabled) : formatMessage(header.displayName),
                    cell: ComponentFormatter(header.icons, shouldNotShowIfDisabled),
                    headerStyle: header.headerStyle,
                })
                break;
            }
            case ColumnType.FormattedDateTimeWithSorting:
                if (sortingHandler && sortingState) columns.push({
                    accessorKey: header.accessorKey,
                    header: SortingFormatter(sortingHandler, sortingState.column, sortingState.orderBy, header.sortingName, header.displayName),
                    cell: DateTimeFormatter(),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.LineProgressBar:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: LineProgressBarFormatter(),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.ColoredIcons:
                columns.push({
                    id: `${columns.length}`,
                    header: '',
                    cell: ColoredIconFormatter(header.icons),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.Action:
                if (sortingHandler && sortingState) columns.push({
                    cell: ActionFormatter(header.handler, header.text),
                    accessorKey: header.accessorKey,
                    header: SortingFormatter(sortingHandler, sortingState.column, sortingState.orderBy, header.sortingName, header.displayName),
                    headerStyle: header.headerStyle
                })
                break;
            case ColumnType.CheckboxGroup:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: CheckboxGroupFormatter(header.options, header.handler, header.errorMessage, header.flagFieldName)
                })
                break;
            case ColumnType.TimePicker:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: TimePickerFormatter(header.handler, header.errorMessage, header.errorFieldName)
                })
                break;
            case ColumnType.RadioGroupFormatter:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: RadioGroupFormatter(header.options, header.handler, header.errorMessage, header.flagFieldName)
                })
                break;
            case ColumnType.NumberInput:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: NumberInputFormatter(header.handler, header.min, header.max, header.errorMessage, header.errorFieldName)
                })
                break;
            case ColumnType.TwoSetOfIcons:
                columns.push({
                    accessorKey: ' ',
                    header: '',
                    cell: TwoSetOfIconsFormatter(header.firstSetOfIcons, header.secondSetOfIcons, header.iconsChangeRowIndex)
                })
                break;
            case ColumnType.SearchSelect:
                let setAccesorFnc = (originalRow: any, index: number) => {
                    if (header.setAccesorFn) {
                        return header.setAccesorFn(originalRow, index);
                    }
                    else {
                        return header.accessorKey2 ? (index === header.optionChangeRowIndex ? originalRow[header.accessorKey2] : originalRow[header.accessorKey]) : originalRow[header.accessorKey];
                    }
                }
                columns.push({
                    // accessorKey: header.accessorKey,
                    accessorFn: setAccesorFnc,
                    header: formatMessage(header.displayName),
                    cell: SearchSelectFormatter(header.options, header.handler, header.notAlwaysEditable, header.inEditModeIndex, header.disableSelectedRows, header.options2, header.optionChangeRowIndex, header.setOptionRender)
                })
                break;
            case ColumnType.CheckBoxSelect:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: SelectCheckBoxFormatter({ data: header.data, column: header.columns }, header.handler,
                        header.keyField, header.hiddenColumns, header.nameField,
                        header.notAlwaysEditable, header.inEditModeIndex, header.disableSelectedRows)
                })
                break;
            case ColumnType.TextInput:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: TextInputFormatter(header.handler, header.disableSelectedRows, header.min, header.max, header.notAlwaysEditable, header.inEditModeIndex, header.errorMessage, header.errorFieldName)
                })
                break;
            case ColumnType.CheckboxInput:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: formatMessage(header.displayName),
                    cell: CheckboxInputFormatter(header.handler, header.notAlwaysEditable, header.inEditModeIndex, header.disableSelectedRows)
                })
                break;
            case ColumnType.SelectAllCheckbox:
                columns.push(
                    {
                        accessorKey: header.accessorKey,
                        header: header.handlerForHeader ? SelectAllCheckboxFormatterForHeader(header.handlerForHeader, formatMessage(header.displayName), header.headerCheckbox) : formatMessage(header.displayName),
                        cell: SelectAllCheckboxFormatter(header.handler, header.disableSelectedRows)
                    })
                break;
            case ColumnType.JsxElement:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: () => <FormattedMessage id={header.displayName} />,
                    cell: JsxFormatter(header.jsxElement)
                })
                break;
            case ColumnType.CustomDivAlarmFormatter:
                columns.push({
                    accessorKey: header.accessorKey,
                    header: () => <FormattedMessage id={header.displayName} />,
                    cell: CustomDivAlarmFormatter()
                })
                break;
        }

    })
    return columns;
}

const Grid = <T,>(props: IProps<T>) => {

    const { tableContainerRef, data, rowSelectionEnabled, selectedRows, selectableRows, setSelectedRows, searchEnabled, searchText, handleSearchTextChange,
        hiddenColumns, keyField, isInProgress, columnInProgress, RowExpandEnabled, subRowsKeyField, searchWidthClass, draggable } = props;

    let columns = props.columns;
    let columnVisibility: VisibilityState = {};
    if (hiddenColumns) {
        hiddenColumns.forEach(hiddenColumn => columnVisibility[hiddenColumn] = false);
    }

    if (rowSelectionEnabled && selectedRows && setSelectedRows && keyField) {

        columns = [{
            id: 'select',
            header: ({ table }: { table: Table<T> }) => {
                const rowIds = table.getRowModel().rows.filter(row => row.getCanSelect()).map(row => row.original[keyField as keyof unknown]);
                return HeaderCheckbox({
                    selectedRows: selectedRows, setSelectedRows: setSelectedRows, rowIds: rowIds
                })
            },
            cell: ({ row }: { row: Row<any> }) => RowCheckbox({ selectedRows: selectedRows, setSelectedRows: setSelectedRows, row: row, keyField: keyField })
        }, ...columns]
    }

    else if (RowExpandEnabled && selectedRows && setSelectedRows && keyField) {

        columns = [{
            id: 'select',
            header: ({ table }: { table: Table<any> }) => HeaderCheckboxExpand({ selectedRows: selectedRows, setSelectedRows: setSelectedRows, Table: table }),
            cell: ({ row }: { row: Row<any> }) => RowCheckboxExpand({ selectedRows: selectedRows, setSelectedRows: setSelectedRows, row: row, keyField: keyField })
        }, ...columns]
    }


    const tableData = useMemo(
        () => (isInProgress ? Array(10).fill({}) : data),
        [isInProgress, data]
    );

    const tableColumns = useMemo(
        () =>
            (columnInProgress === undefined) ? (isInProgress ? columns.map((column) => ({
                ...column,
                cell: <Skeleton />
            })) : columns) :
                ((isInProgress || columnInProgress) ? columns.map((column) => ({
                    ...column,
                    header: <Skeleton />,
                    cell: <Skeleton />,
                })) : columns),
        [isInProgress, columns, columnInProgress]
    );

    const [expanded, setExpanded] = useState<ExpandedState>({})

    const table = useReactTable({
        data: tableData,
        columns: tableColumns,
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            columnVisibility,
            expanded
        },
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row: Row<any> | any) => row[subRowsKeyField as keyof unknown],
        enableRowSelection: rowSelectionEnabled,

    })

    const { rows } = table.getRowModel();
    const virtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => tableContainerRef?.current,
        estimateSize: () => 34,
        overscan: 20,
    })

    const [searchTextValue, setSearchTextValue] = useState(searchText);

    const columnToFilter = useMemo(() => table.getHeaderGroups()[0]?.headers[1]?.column, [table]);

    useEffect(() => {
        columnToFilter?.setFilterValue(searchTextValue)
    }, [columnToFilter, searchTextValue, handleSearchTextChange]);
    return (
        <>
            {searchEnabled &&
                <div className="form-group table-search">
                    <input
                        data-testid="testSearchInput"
                        type="text"
                        value={(searchTextValue ?? '') as string}
                        onChange={e => {
                            handleSearchTextChange && handleSearchTextChange(e.target.value);
                            setSearchTextValue(e.target.value);
                            columnToFilter.setFilterValue(e.target.value);
                        }}
                    />
                    <img src={Search} alt="Search" className="search" />
                </div>
            }
            {/* <div style={tableContainerRef ? { height: `${virtualizer?.getTotalSize()}px` } : {}}> */}
            <table className="table table-bordered table-hover table-striped">
                <thead className="sticky-top">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                const headerColumn: any = header.column.columnDef;
                                return (
                                    <th key={header.id} style={headerColumn.headerStyle} className={headerColumn.className}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {tableContainerRef ? virtualizer.getVirtualItems().map((virtualRow, index) => {
                        const row = rows[virtualRow.index] as Row<any>
                        return (
                            <tr key={row.id}
                                style={{
                                    height: `${virtualRow.size}px`,
                                    transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                                }}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    }) :
                        // draggable ?
                        //     table.getRowModel().rows.map((row, i) => {
                        //         const rowData: any = row.original;
                        //         return (
                        //             <Draggable draggableId={row.id} index={i}>
                        //                 {provided => (
                        //                     <tr key={row.id} ref={provided.innerRef}
                        //                         {...provided.draggableProps}
                        //                         {...provided.dragHandleProps}>
                        //                         {row.getVisibleCells().map(cell => (
                        //                             <td key={cell.id}>
                        //                                 {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        //                             </td>
                        //                         ))}
                        //                     </tr>
                        //                 )}
                        //             </Draggable>
                        //         )
                        //     })
                        //     :
                        table.getRowModel().rows.map(row => {
                            const rowData: any = row.original;
                            return <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        })}
                </tbody>
            </table>
            {/* </div > */}
        </>
    );
}

export default Grid;