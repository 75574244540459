/*    
<summary>
   This file is used to provide initial values or state to the Report Module observables.
    Developer:Aashish Singh, Created Date:04-March-2024
</summary>
*/

import { IGetPaymentReport, IGetReport, IReport } from "../../models/response/IReportResponse";

export const getPaymentReportListState: IGetPaymentReport = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Payments: [],
    TotalPayments: 0
}
export const getReportViewListState: IGetReport = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Ticket: [],
    TotalTransactions: 1,
    TotalVolumeDelivered: 0,
    TotalVat: 0,
    TotalAmountDue: 0
}

export const getReportListState: Array<IReport> = []
