export const ErrorMessage = {
    ErrorOccured: 'message_some_error_occured',
    Unauthorized: "Unauthorized",
    UnauthorizedAccess: "unauthorized_access",
    TokenExpired: 'TokenExpired',
    NameAlreadyExists: 'NameAlreadyExists',
    InvalidUsernameOrPassword: "InvalidEmailOrPassword",
    IdNotFound : "IdNotFound",
    EmailAlreadyExists:"EmailAlreadyExists",
    Forbidden:"Forbidden",
    RefreshTokenExpired: 'RefreshTokenExpired',
    RefreshTokenNotInDatabase: 'RefreshTokenNotInDatabase',
}
export const RestMethodConstants = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
}
