import { AxiosRequestConfig } from 'axios';
import * as axios from '../interceptor/interceptor';

let config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

let multiPartConfigconfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const uploadConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

const downloadConfig: AxiosRequestConfig = {
    responseType: 'blob'
};

export const get = (url: string) => {
    return axios.baseAPI.get(url, config)
};

export const post = (url: string, data: any, tempconfig:any= config) => {
    return axios.baseAPI.post(url, data, tempconfig)
};

export const postMultipart = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, multiPartConfigconfig)
};

export const put = (url: string, data: any) => {
    return axios.baseAPI.put(url, data, config)
};

export const deleteapi = (url: string, data?: any) => {
    return data ? axios.baseAPI.delete(url, { ...config, data: { ...data } }) : axios.baseAPI.delete(url, config);
};

export const postUpload = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, uploadConfig)
};

export const downloadFile = (url: string) => {
    return axios.baseAPI.get(url, downloadConfig);
}

export const importFile = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, uploadConfig);
}

export const exportFile = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, downloadConfig);
}

//not using postUpload for this because from backend 18 digit number is returned in response 
//which is manipulated by js(upto 16 digits it works fine.)
export const uploadOutgoingEmail = (url: string, data: any) => {
    return axios.baseAPI.post(url, data, { ...uploadConfig, transformResponse: [data => data] })
}