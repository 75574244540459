import React, { useState } from 'react';

const AboutSupportPopupHOC = (Component: any) =>
function Comp(props:any){
    const [showAboutSupportPopup,setShowAboutSupportPopup] = useState<boolean>(false);

    const aboutSupportToggleHandler = () => {
        setShowAboutSupportPopup(!showAboutSupportPopup);
    }
	
	return <Component showAboutSupportPopup={showAboutSupportPopup} aboutSupportToggleHandler={aboutSupportToggleHandler} {...props} />;
};

export default AboutSupportPopupHOC;