/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { ErrorMessage } from "../../../constants/error.constant";
import {
  customerIdList,
  userTypeList,
} from "../../../constants/common-constant";
import { getUserType } from "../../../helpers/localStorages.helper";
import { IOption } from "../../../models/ICommon";
import UserType from "../../../constants/userType.constant";

interface IProps {
  submitHandler: (values: any) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: any;
  roleList: Array<{
    Id: number;
    Name: string;
    IsInUse?: boolean;
  }>;
}

const UserFilterForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
    roleList,
  } = props;

  const userType = getUserType();
  const userTypeOptions: IOption[] = [
    { Id: -1, Name: "all", disabled: false },
    { Id: 0, Name: "customer_user", disabled: false },
  ];

  /**Dynamically adding user types in userTypeOptions on the basis of "userType" */
  if (userType == UserType.SuperAdmin || userType == UserType.CleintAdmin) {
    userTypeOptions.push({ Id: 1, Name: "customer_admin" });
    userTypeOptions.push({ Id: 2, Name: "client_user" });
    userTypeOptions.push({ Id: 3, Name: "client_admin" });
  }
  if (userType == UserType.CustomerAdmin) {
    userTypeOptions.push({ Id: 1, Name: "customer_admin" });
  }

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal" show={true} centered>
        <Modal.Header>
          <Modal.Title>
            {/* id='filter_title' */}
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values) => {
            let tempValue = {
              UserName: values.UserName ? values.UserName : null,
              Email: values.Email ? values.Email : null,
              IsActive: values.IsActive,
              UserType: values.UserType
            }
            props.submitHandler(tempValue);
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                {/* <p className="fs-4 fw-semi-bold mb-4">
              <FormattedMessage id='filter_message'  /></p> */}

                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="user_label_username"
                      />
                      <Field
                        className="form-control"
                        data-testid="testNameField"
                        name="UserName"
                        type="text"
                        placeholder="user_label_username"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="user_label_email"
                      />
                      <Field
                        className="form-control"
                        name="Email"
                        type="email"
                        placeholder="user_label_email"
                        invalidcharacter={[" "]}
                        validcharacter={[
                          "-",
                          "_",
                          "[",
                          "]",
                          "(",
                          ")",
                          ":",
                          "#",
                          "@",
                          ".",
                        ]}
                        maxLength={100}
                        tolowercase={+true}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="mb-3 form-group d-flex align-items-center">
                  <Col xs={12}>
                    <Label
                      className="form-label"
                      label="user_label_user_type"
                    />
                    <Field
                      name="UserType"
                      type="number"
                      className="form-control"
                      as={FormikFormSelectField}
                      typeofoptionid="number"
                      options={userTypeOptions}
                    />
                  </Col>
                </Row>

                <Row className="mb-3 form-group d-flex align-items-center mt-1">
                  <Col xs={3} lg={2}>
                    <label className="form-label">
                      {" "}
                      <FormattedMessage id="status" />
                    </label>
                  </Col>
                  <Col xs={8} lg={10}>
                    <Row>
                      <Col xs={5} lg={3}>
                        <div className="form-check">
                          <Field
                            data-testid="testIsActive"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="active"
                            value="0"
                          />
                          <label
                            htmlFor="isActive"
                            className="form-check-label mt-1"
                          >
                            <FormattedMessage id="active" />
                          </label>
                        </div>
                      </Col>
                      <Col xs={5} lg={3}>
                        <div className="form-check">
                          <Field
                            data-testid="testIsNotActive"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="inactive"
                            value="1"
                          />
                          <label
                            htmlFor="isNotActive"
                            className="form-check-label mt-1 "
                          >
                            <FormattedMessage id="inactive" />
                          </label>
                        </div>
                      </Col>
                      <Col lg={3} xs={2}>
                        <div className="form-check">
                          <Field
                            data-testid="testAll"
                            className="form-check-input"
                            name="IsActive"
                            type="radio"
                            id="all"
                            value="2"
                          />
                          <label
                            htmlFor="all"
                            className="form-check-label  mt-1"
                          >
                            <FormattedMessage id="all" />
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>


              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UserFilterForm;
