import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  DATE_FORMAT_BACKEND,
  floatRegex
} from "../../../constants/common-constant";
import { addPaymentState } from "../../../core/initialState/add-payment-state";
import { IPayment } from "../../../models/response/IPaymentResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import formikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { addPaymentSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import { getAmountDecimal } from "../../../helpers/localStorages.helper";

interface IProps {
  submitHandler: (data: IPayment) => void;
  addUpdateInProgress: boolean;
  actionSuccess: boolean;
}

const AddPaymentForm: React.FC<IProps> = (props: IProps) => {
  const {
    submitHandler,
    addUpdateInProgress,
    actionSuccess
  } = props;

  let resetPresForm = () => { };
  const [initialValues, setInitialValues] = useState<IPayment>({ ...addPaymentState })
  let decimalValue: number = getAmountDecimal();

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IPayment>();
    return null;
  };

  /**
 * This functional component stores the reset form function in the resetPresentForm variable
 */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  useEffect(() => {
    if (actionSuccess) {
      setInitialValues({ ...addPaymentState });
      resetPresForm();
    }
  }, [actionSuccess])

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        validationSchema={addPaymentSchema(decimalValue)}
        onSubmit={async (values: any) => {
          submitHandler({
            ...values, PaymentDate:
              new Date(values.PaymentDate?.$d).toString() !== "Invalid Date"
                ? moment(values.PaymentDate.$d).format(DATE_FORMAT_BACKEND)
                : values.PaymentDate
          });
        }}
      >
        {({ values }) => (
          <>
            <Form className="customer-form p-3 user-form pb-0" noValidate>
              <FormObserver />
              <Modal.Body className="pb-0">
                <Row className="position-relative">
                  <Col xs={12} lg={3} md={4} xl="4">
                    <div className="form-group payment-date">
                      <Label
                        className="form-label"
                        required={true}
                        label="payment_date"
                      />
                      <Field
                        className="form-control"
                        name="PaymentDate"
                        // min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                        as={FormikDateFieldMUI}
                      />
                    </div>
                  </Col>

                  <Col xs={12} lg={4} md={4} xl="4">
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="payment_amount_credited"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="AmountCr"
                        placeholder="payment_amount_credited"
                        regex={floatRegex}
                        as={formikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={4} md={4} xl="4">
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        label="payment_mode"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="ModeOfPayment"
                        placeholder="payment_mode"
                        as={FormikFormInput}
                      // min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                      {/* <Field
                        data-testid="testEmail"
                        className="form-control"
                        name="StartDate"
                        // type="text"
                        placeholder="start_date"
                        minDate={new Date().setDate(new Date().getDate())}
                        as={FormikFormDatePickerMUI}
                      /> */}
                    </div>
                  </Col>
                  <Col xs={12} lg={12} md={12} xl="12">
                    <div className="form-group payment-description">
                      <Label
                        className="form-label"
                        // required={true}
                        label="payment_description"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="Description"
                        placeholder="payment_description"
                        // regex={floatRegex}
                        as={FormikFormTextArea}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  pt-0 border-0 mt-1">
                <div className="">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text="add"
                  />
                </div>
              </div>

            </Form>
            <ResettingForm />
          </>
        )}
      </Formik>

    </>
  );
};

export default AddPaymentForm;
