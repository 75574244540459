import { IGetClientList } from "../../models/response/IClientResponse"

export const demoCustomer = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Customers: [
        {
            Id: 1,
            CustomerId: 1,
            Name: "Johny",
            PhoneNo: "1234567890",
            ContactPersonName: "cp customer",
            ContactPersonPhoneNo: "6767676767",
            ContactPersonEmail: "cpjohny@gmail.com",
            City: "karnal",
            State: "Hr",
            Country: "Ind",
            Address: "123 Main St Apt 4",
            IsActive: false,
            CustomerAdminName: "customer admin",
            Email: "johny@gmail.com",
            PricePerL: 0,
            Vat: 0
        }
    ]
}
export const demoRole = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Roles: [
        {
            Id: 1,
            RoleName: "Team Lead",
            DriverManagementPermission: "READ_WRITE",
            VehicleManagementPermission: "READ_WRITE",
            TankManagementPermission: "READ_WRITE",
            TicketManagementPermission: "READ_WRITE",
        }
    ]
}
export const demoLiveTracking = [
    {
        "DriverId": 1,
        "DriverName": "Driver 1",
        "Heartbeat": [
            {
                "TruckId": 1,
                "HBTimestamp": "2024-02-22",
                "Lat": 19.7515,
                "Lng": 75.7139,
                "Speed": 20
            },
            {
                "TruckId": 1,
                "HBTimestamp": "2024-02-22",
                "Lat": 30.7333,
                "Lng": 76.7794,
                "Speed": 20
            },
        ]
    },
    {
        "DriverId": 2,
        "DriverName": "Driver 2",
        "Heartbeat": [
            {
                "TruckId": 2,
                "HBTimestamp": "2024-02-22",
                "Lat": 22.309425,
                "Lng": 72.136230,
                "Speed": 20
            },
            {
                "TruckId": 2,
                "HBTimestamp": "2024-02-22",
                "Lat": 21.1458,
                "Lng": 79.0882,
                "Speed": 20
            },
            {
                "TruckId": 3,
                "HBTimestamp": "2024-02-22",
                "Lat": 22.5726,
                "Lng": 88.3639,
                "Speed": 20
            },
        ]
    },
]
export const demoClient: IGetClientList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Clients: [
        {
            Id: 1,
            ClientId: 1,
            Name: "Client.1",
            Email: "ram@gmail.com",
            ClientAdminName: "ramesh",
            PhoneNo: "6767675454",
            ContactPersonPhoneNo: "9897685645",
            ContactPersonName: "Ram",
            ContactPersonEmail: "ram@gmail.com",
            Address: "add1",
            City: "city1",
            State: "state1",
            Country: "country1",
            StartDate: "2024-02-22",
            EndDate: "2024-02-22",
            EnableLimitSettings: false,
            EnableFuelSensorSettings: false,
            EnableGpsSettings: false,
            EnableDriverIdSettings: false,
            EnableNozzleRfidSetting: false,
            EnableAlarmSettings: false,
            IsActive: false,
            AmountDecimal: 1,
        }
    ]
}

export const demoDriver = {
    "TotalRecords": 1,
    "TotalPages": 1,
    "CurrentPage": 1,
    "PageSize": 10,
    "Drivers": [
        {
            "Id": 1,
            "DriverId": 1,
            "RFID": "1",
            "DriverName": "Mr. 1",
            "DLNo": "1",
            "DLExpiryDate": new Date("2024-02-22"),
            "BelongToCustomer": true,
            "CustomerId": "22",
            "TruckAllocation": 1,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": "Weekly",
            "AllocationVolume": 100,
            "RemainingVolume": 55,
        },

    ]
}
export const demoVehicle = {
    "TotalRecords": 1,
    "TotalPages": 1,
    "CurrentPage": 1,
    "PageSize": 10,
    "Vehicles": [
        {
            "Id": 1,
            "VehicleId": 1,
            "RFID": "01920AF0",
            "Description": "Bus Daewoo BF105 Series / 2001",
            "RegistrationNo": "65618",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 1,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 1,
            "AllocationVolume": 100,
            "RemainingVolume": 10,
            "LowerConsumptionLimit": 10,
            "HigherConsumptionLimit": 10,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 10,
            "CustId5": 10,
            "CustId6": 10,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 2,
            "VehicleId": 2,
            "RFID": "2",
            "Description": "Mr. 2",
            "RegistrationNo": "2",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 2,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 2,
            "AllocationVolume": 200,
            "RemainingVolume": 20,
            "LowerConsumptionLimit": 20,
            "HigherConsumptionLimit": 20,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 20,
            "CustId5": 20,
            "CustId6": 20,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 3,
            "VehicleId": 3,
            "RFID": "3",
            "Description": "Mr. 3",
            "RegistrationNo": "3",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 3,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 2,
            "AllocationVolume": 300,
            "RemainingVolume": 30,
            "LowerConsumptionLimit": 30,
            "HigherConsumptionLimit": 30,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 30,
            "CustId5": 30,
            "CustId6": 30,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 4,
            "VehicleId": 4,
            "RFID": "4",
            "Description": "Mr. 4",
            "RegistrationNo": "4",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 4,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 2,
            "AllocationVolume": 400,
            "RemainingVolume": 40,
            "LowerConsumptionLimit": 40,
            "HigherConsumptionLimit": 40,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 40,
            "CustId5": 40,
            "CustId6": 40,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 5,
            "VehicleId": 5,
            "RFID": "5",
            "Description": "Mr. 5",
            "RegistrationNo": "5",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 5,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 2,
            "AllocationVolume": 500,
            "RemainingVolume": 50,
            "LowerConsumptionLimit": 50,
            "HigherConsumptionLimit": 50,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 50,
            "CustId5": 50,
            "CustId6": 50,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 6,
            "VehicleId": 6,
            "RFID": "6",
            "Description": "Mr. 6",
            "RegistrationNo": "6",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 6,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 1,
            "AllocationVolume": 600,
            "RemainingVolume": 60,
            "LowerConsumptionLimit": 60,
            "HigherConsumptionLimit": 60,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 60,
            "CustId5": 60,
            "CustId6": 60,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 7,
            "VehicleId": 7,
            "RFID": "7",
            "Description": "Mr. 7",
            "RegistrationNo": "7",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 7,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 0,
            "AllocationVolume": 700,
            "RemainingVolume": 70,
            "LowerConsumptionLimit": 70,
            "HigherConsumptionLimit": 70,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 70,
            "CustId5": 70,
            "CustId6": 70,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 8,
            "VehicleId": 8,
            "RFID": "8",
            "Description": "Mr. 8",
            "RegistrationNo": "8",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 8,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 0,
            "AllocationPeriod": 1,
            "AllocationVolume": 800,
            "RemainingVolume": 80,
            "LowerConsumptionLimit": 80,
            "HigherConsumptionLimit": 80,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 80,
            "CustId5": 80,
            "CustId6": 80,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 9,
            "VehicleId": 9,
            "RFID": "9",
            "Description": "Mr. 9",
            "RegistrationNo": "9",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 9,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 1,
            "AllocationVolume": 900,
            "RemainingVolume": 90,
            "LowerConsumptionLimit": 90,
            "HigherConsumptionLimit": 90,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 90,
            "CustId5": 90,
            "CustId6": 90,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
        {
            "Id": 10,
            "VehicleId": 10,
            "RFID": "10",
            "Description": "Mr. 10",
            "RegistrationNo": "10",
            "RegistrationExpiryDate": new Date("2024-02-22"),
            "TruckAllocation": 10,
            "FuelDeliveryEnabled": true,
            "AllocationPerFill": 2,
            "AllocationPeriod": 10,
            "AllocationVolume": 1000,
            "RemainingVolume": 100,
            "LowerConsumptionLimit": 100,
            "HigherConsumptionLimit": 100,
            "BelongToCustomer": true,
            "CustomerId": "22",
            "CustId2": 100,
            "CustId5": 100,
            "CustId6": 100,
            "CreationDate": "2024-02-22",
            "ModificationExpiryDate": "2024-02-22",
        },
    ]
}
export const demoAlarm = {
    "TotalRecords": 1,
    "TotalPages": 1,
    "CurrentPage": 1,
    "PageSize": 10,
    "Alarms": [
        {
            Id: 1,
            AlarmId: 1,
            AlarmType: 1,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 2,
            AlarmId: 2,
            AlarmType: 2,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 3,
            AlarmId: 3,
            AlarmType: 3,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 4,
            AlarmId: 4,
            AlarmType: 0,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 5,
            AlarmId: 5,
            AlarmType: 1,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 6,
            AlarmId: 6,
            AlarmType: 2,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 7,
            AlarmId: 7,
            AlarmType: 3,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 8,
            AlarmId: 8,
            AlarmType: 0,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 9,
            AlarmId: 9,
            AlarmType: 3,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
        {
            Id: 10,
            AlarmId: 10,
            AlarmType: 0,
            AlarmTimestamp: "2024-02-22",
            ActionTaken: "none",
            AlarmDetail: "red",
            RFID: "4567ytfhgj"
        },
    ]
}

export const demoCurrecy = [
    {
        "Id": 1,
        "CountryName": "India",
        "CurrencyName": "Indian Rupee",
        "Symbol": "₹",
        "Abbrevation": "INR"
    },
    {
        "Id": 2,
        "CountryName": "Japan",
        "CurrencyName": "Japanese Yen",
        "Symbol": "¥",
        "Abbrevation": "JPY"
    },
    {
        "Id": 3,
        "CountryName": "United States",
        "CurrencyName": "United States Dollar",
        "Symbol": "$",
        "Abbrevation": "USD"
    }
]