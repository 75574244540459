/*    
<summary>
   This class component is all about Managing client data functionality.
   Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { getClientListState } from "../initialState/get-client-list-state";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState } from "../../models/ICommon";
import moment from "moment";
import { addEditClientState as initialState } from "../initialState/add-edit-client-state";
import { IGetClientList, IClient, IClientList, IAddEditClient } from "../../models/response/IClientResponse";
import { DATE_FORMAT_BACKEND, emptyValue } from "../../constants/common-constant";
import { toast } from "react-toastify";
import { IClientState } from "../../models/state/IClientState";
import { clientInitialFilterState } from "../initialState/initial-filter-states";
import { IClientFilter, ICustomerInitialFilterState } from "../../models/IFilter";
import { formatMessage } from "../../translations/formatMessage";

export class ClientStore implements IClientState {
    inProgress = false;
    error = '';
    clientList: IGetClientList = getClientListState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    addUpdateClientState: IObservableInitialState = { ...this.initialStateValue };
    DeleteClientState: IObservableInitialState = { ...this.initialStateValue };

    clientDetail: IClient | undefined = undefined;
    clientDetailState: IObservableInitialState = { ...this.initialStateValue };

    filter: IClientFilter = {
        ...clientInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            clientList: observable,
            addUpdateClientState: observable,
            DeleteClientState: observable,
            clientDetail: observable,
            clientDetailState: observable,
            filter: observable,
            GetClientListService: action,
            GetClientByIdService: action,
            AddClientService: action,
            UpdateClientService: action,
            DeleteClientService: action,
            reset: action,
            resetStore: action,
            resetGetClientDetail: action,
            resetAddUpdateClientState: action,
            resetDeleteClientState: action,
            setFilterDetail: action,
            getClientDetail: computed,
            getClientList: computed,
        });
    }

    GetClientListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, filter: ICustomerInitialFilterState) => {
        this.inProgress = true;
        const url = UrlConstants.GetClientList + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&column=' + column + '&orderBy=' + orderBy;
        let requestBody = {
            ...filter, IsActive: filter.IsActive == "2" ? null : (filter.IsActive == "1" ? false : true)
        }
        return services.post(url, { ...requestBody })
            .then((response: IApiResponse<IApiSuccessResponse<IGetClientList>>) => {
                this.clientList = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getClientList(): IClientList[] {
        if (this.clientList?.Clients && this.clientList.Clients?.length > 0) {
            return this.clientList.Clients.map((client: IClient) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                return {
                    ...client,
                    Id: client.ClientId,
                    StartDate: moment(client.StartDate).format(DATE_FORMAT_BACKEND),
                    EndDate: moment(client.EndDate).format(DATE_FORMAT_BACKEND),
                    ContactPersonName: client.ContactPersonName ? client.ContactPersonName : emptyValue,
                    ContactPersonEmail: client.ContactPersonEmail ? client.ContactPersonEmail : emptyValue,
                    ContactPersonPhoneNo: client.ContactPersonPhoneNo ? client.ContactPersonPhoneNo : emptyValue,
                    State: client.State ? client.State : emptyValue,
                    PhoneNo: client.PhoneNo ? client.PhoneNo : emptyValue,
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetClientByIdService = (id: number) => {
        this.clientDetailState.inProgress = true;
        const url = `${UrlConstants.GetClientById}?clientId=${id}`;
        // const tempDetail = demoClient.Clients.filter((d:IClient)=>d.Id === id);
        // this.clientDetail = tempDetail.length > 0 ? {...tempDetail[0]} : undefined;
        // this.clientDetailState.inProgress = false;
        return services.get(url)
            .then((response: IApiResponse<any>) => {
                this.clientDetail = response.data.Data.Client;
            })
            .catch((err: string) => {
                this.clientDetailState.error = err;
                toast.error(formatMessage(err));
            }).finally(action(() => { this.clientDetailState.inProgress = false; }));
    }

    get getClientDetail(): IAddEditClient {

        if (this.clientDetail) {
            return {
                ...this.clientDetail,
                StartDate: moment(this.clientDetail.StartDate).format(DATE_FORMAT_BACKEND),
                EndDate: moment(this.clientDetail.EndDate).format(DATE_FORMAT_BACKEND),
                Name: this.clientDetail.Name !== null ? this.clientDetail.Name : "",
                PhoneNo: this.clientDetail.PhoneNo !== null ? this.clientDetail.PhoneNo : "",
                ContactPersonPhoneNo: this.clientDetail.ContactPersonPhoneNo !== null ? this.clientDetail.ContactPersonPhoneNo : "",
                ContactPersonName: this.clientDetail.ContactPersonName !== null ? this.clientDetail.ContactPersonName : "",
                ContactPersonEmail: this.clientDetail.ContactPersonEmail !== null ? this.clientDetail.ContactPersonEmail : "",
                City: this.clientDetail.City !== null ? this.clientDetail.City : "",
                State: this.clientDetail.State !== null ? this.clientDetail.State : "",
                Country: this.clientDetail.Country !== null ? this.clientDetail.Country : "",
                Address: this.clientDetail.Address !== null ? this.clientDetail.Address : "",
                Email: this.clientDetail.Email !== null ? this.clientDetail.Email : "",
                ClientAdminName: this.clientDetail.ClientAdminName !== null ? this.clientDetail.ClientAdminName : "",
            };
        }
        return { ...initialState, StartDate: moment(new Date()).format(DATE_FORMAT_BACKEND), EndDate: moment(new Date()).format(DATE_FORMAT_BACKEND) };
    }

    resetGetClientDetail = () => {
        this.clientDetail = undefined;
        this.clientDetailState = { ...this.initialStateValue };
    }

    AddClientService = (id: number, data: IAddEditClient) => {
        this.addUpdateClientState.inProgress = true;
        let url = UrlConstants.AddClient;
        let clientData = {
            ...data,
            StartDate: moment(data.StartDate).format(DATE_FORMAT_BACKEND) + "T00:00:00.000Z",
            EndDate: moment(data.EndDate).format(DATE_FORMAT_BACKEND) + "T23:59:59.000Z"
        }
        return services.post(url, { ...clientData })
            .then(() => {
                this.addUpdateClientState.success = true;
            })
            .catch((err: string) => {
                this.addUpdateClientState.error = err;
            })
            .finally(action(() => { this.addUpdateClientState.inProgress = false; }));
    }

    UpdateClientService = (id: number, data: IAddEditClient) => {
        this.addUpdateClientState.inProgress = true;
        let clientData = {
            ...data,
            StartDate: moment(data.StartDate).format(DATE_FORMAT_BACKEND) + "T00:00:00.000Z",
            EndDate: moment(data.EndDate).format(DATE_FORMAT_BACKEND) + "T23:59:59.000Z",
            ClientAdminName: undefined,
            Email: undefined,
        }
        let url = `${UrlConstants.UpdateClientById}?clientId=${id}`;
        return services.put(url, { ...clientData })
            .then(() => {
                this.addUpdateClientState.success = true;
            })
            .catch((err: string) => {
                // toast.error(formatMessage(err));
                this.addUpdateClientState.error = err;
            })
            .finally(action(() => { this.addUpdateClientState.inProgress = false; }));
    }

    resetAddUpdateClientState = () => {
        // this.clientDetail = undefined;
        this.addUpdateClientState = { ...this.initialStateValue }
    }

    DeleteClientService = (id: number) => {
        this.DeleteClientState.inProgress = true;
        let url = `${UrlConstants.DeleteClientById}?clientId=${id}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteClientState.success = true;
            })
            .catch((err: string) => {
                this.DeleteClientState.error = err;
            })
            .finally(action(() => { this.DeleteClientState.inProgress = false; }));
    }

    resetDeleteClientState = () => {
        this.DeleteClientState = { ...this.initialStateValue }
    }

    /*
    This function is used to set the filter details to the billingFilter observable.  
    */
    setFilterDetail = (filter: IClientFilter) => {
        this.filter = { ...filter };
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.clientList = getClientListState;

        this.clientDetail = undefined;
        this.clientDetailState = { ...this.initialStateValue }

        this.addUpdateClientState = { ...this.initialStateValue }

        this.DeleteClientState = { ...this.initialStateValue }
    }
}

export default new ClientStore();