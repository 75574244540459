/*    
<summary>
  This functional component "AddUpdateCustomerSetting" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { addPaymentState } from '../../../core/initialState/add-payment-state';
import { IPayment } from '../../../models/response/IPaymentResponse';
import DeletePopupHOC from '../../../shared-components/Popup/Hoc/DeletePopupHoc';
import { formatMessage } from '../../../translations/formatMessage';
import AddPaymentForm from '../forms/add-edit-payment-details-form';
import PaymentDetails from './payment-details';
import { initialPageLimit } from '../../../constants/pageLimit.constant';
import IPageInfo from '../../../models/IPageInfo';
import { ISorting } from '../../../models/ISorting';
import _ from 'lodash';
import FilterPopupHOC from '../../../shared-components/Popup/Hoc/FilterPopupHoc';
import PaymentFilterForm from '../forms/payment-filter-form';
import { paymentInitialFilterState } from '../../../core/initialState/initial-filter-states';

interface IProps {
  id: number
  modalClosed: () => void;
  selectedCustomer: string;
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}

const AddPaymentDetails = (props: IProps) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);
  const { paymentStore, preferencesStore } = useStore();
  const { addPaymentDataState,
    paymentListState,
    GetPaymentListService,
    resetPaymentListState,
    AddPaymentDataService,
    resetAddPaymentDataState, deletePaymentDataState, resetDeletePaymentDataState, filter, setFilterDetail, getPaymentListData, paymentList } = paymentStore;
  // const initialValues: IAddCustomerRate = addCustomerRateState;
  const { id, modalClosed, selectedCustomer, showDeletePopup, deleteToggleHandler, showFilterPopup, filterToggleHandler } = props
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const [pageLimit, setPageLimit] =
    useState<number>(initialPageLimit);
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });
  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);
  const onPageLimitChanged = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
  };
  const onModalClosed = useCallback(() => {
    modalClosed();
  }, [modalClosed])
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [sorting, setSorting] = useState<ISorting>({
    column: "userName",
    orderBy: "asc",
  });

  const callGetPaymentList = useCallback(() => {
    setDisablePagination(true);
    GetPaymentListService(
      id,
      selectedCustomer,
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column,
      _.omit(filter, ["isFilterActive"])
    );
    setIsApiCalled(true);
  }, [
    GetPaymentListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    filter,
  ]);

  useEffect(() => {
    return () => {
      resetFilterHandler();
    }
  }, []);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: paymentList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getPaymentListData.PaymentList, paymentList.TotalPages]);

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const openFilterHandler = () => {
    filterToggleHandler();
  };

  const filterSubmitHandler = (values: any) => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    filterToggleHandler();
  };

  const resetFilterHandler = () => {
    // closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...paymentInitialFilterState, isFilterActive: true });
    // setFilterData({
    //   ...clientInitialFilterState
    // });
  };
  const resetFilter = () => {
    // closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...paymentInitialFilterState, StartDate: null, EndDate: null, isFilterActive: false });
    // setFilterData({
    //   ...clientInitialFilterState
    // });
  };

  useEffect(() => {
    if (filter.isFilterActive) {
      callGetPaymentList();
    }
    else
      callGetPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, callGetPaymentList]);
  useEffect(() => {
    if (addPaymentDataState.success) {
      // toast.success(formatMessage(addCustomerRateStateSuccessMessage));
      callGetPaymentList();
      resetAddPaymentDataState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPaymentDataState.success, resetAddPaymentDataState, callGetPaymentList]);

  useEffect(() => {
    if (paymentListState.error) {
      toast.error(formatMessage(paymentListState.error));
      resetPaymentListState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentListState.error, resetPaymentListState]);

  useEffect(() => {
    if (deletePaymentDataState.error) {
      toast.error(formatMessage(deletePaymentDataState.error));
      resetDeletePaymentDataState();
    }
  }, [deletePaymentDataState.error, resetDeletePaymentDataState]);

  useEffect(() => {
    if (deletePaymentDataState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeletePaymentDataState();
      deleteToggleHandler();
      callGetPaymentList();
    }
  }, [deletePaymentDataState.success, resetDeletePaymentDataState]);

  const onSubmitForm = (values: IPayment) => {
    let finalValues = {
      Id: values.Id,
      CustomerId: selectedCustomer,
      CustomerIdLong: id,
      PaymentDate: values.PaymentDate,
      Description: values.Description,
      AmountCr: values.AmountCr,
      ModeOfPayment: values.ModeOfPayment,
    }
    AddPaymentDataService(finalValues);
  }

  return (
    <React.Fragment>
      {showFilterPopup && (
        <PaymentFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}
      <Modal
        className="add-customer-modal add-user-modal invoice"
        show={true}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            <FormattedMessage id="payment_details" />
          </Modal.Title>
          <div className=' d-flex justify-content-end'>
            {filter.isFilterActive && (
              <ListGroup.Item
                className="border-0 bg-transparent p-0 me-2"
                onClick={() => {
                  resetFilter();
                }}
                title="Reset Filter"
              >
                <div className="filter modal-filter-icon">
                  <div className="circle-icon yellow">
                    <svg
                      enableBackground="new 0 0 512.004 512.004"
                      fill="#fff"
                      className="clearfilter"
                      viewBox="0 0 512.004 512.004"
                      height="20"
                      width="20"
                    >
                      <g>
                        <path d="m361.183 0c-54.059 0-99.836 36.049-114.505 85.331h-192.948c-18.024 0-28.614 20.339-18.285 35.119.16.231-5.363-7.31 129.747 177.039 2.714 3.951 4.148 8.57 4.148 13.367v177.688c0 19.435 22.224 30.24 37.473 18.754l57.593-43.518c8.614-6.415 13.754-16.655 13.754-27.409v-125.515c0-4.798 1.435-9.417 4.149-13.369l46.497-63.451c76.139 21.439 151.81-36.022 151.81-114.791.001-65.752-53.577-119.245-119.433-119.245zm-103.192 279.919c-5.835 7.968-9.831 19.1-9.831 30.938 0 136.483.734 127.081-1.68 128.869-1.91 1.421 10.835-8.188-47.14 35.618v-164.488c0-11.012-3.327-21.608-9.622-30.646-.169-.242 4.923 6.71-120.835-164.88h172.938c-1.457 44.852 22.126 84.961 58.678 106.581zm103.192-71.428c-49.314 0-89.434-40.035-89.434-89.246 0-49.21 40.12-89.245 89.434-89.245 49.313 0 89.433 40.035 89.433 89.245.001 49.211-40.119 89.246-89.433 89.246z" />
                        <path d="m400.201 80.298c-5.854-5.864-15.35-5.872-21.213-.02l-17.805 17.773-17.805-17.773c-5.863-5.853-15.361-5.846-21.213.02-5.853 5.862-5.844 15.36.019 21.213l17.767 17.735-17.767 17.735c-5.863 5.853-5.872 15.351-.019 21.213 5.833 5.844 15.331 5.891 21.213.02l17.805-17.773 17.805 17.773c5.845 5.834 15.343 5.862 21.213-.02 5.853-5.862 5.844-15.36-.019-21.213l-17.767-17.735 17.767-17.735c5.863-5.853 5.872-15.351.019-21.213z" />
                      </g>
                    </svg>
                  </div>
                </div>
              </ListGroup.Item>
            )}
            <ListGroup.Item
              className="border-0 bg-transparent p-0 me-2"
              onClick={() => {
                openFilterHandler();
              }}
              title="Filter"
            >
              <div className="filter modal-filter-icon">
                <div className="circle-icon  green">
                  <svg
                    fill="#fff"
                    height="20"
                    width="20"
                    viewBox="0 0 32 32"
                  >
                    <path
                      clipRule="evenodd"
                      d="m5.26238 4.5c-.00016 0 .00016 0 0 0h21.47502c.4849.00015.9598.14134 1.3659.40635s.7264.64242.9218 1.08623.2575.93488.1788 1.41337c-.0786.47733-.2939.92158-.6199 1.27892-.0008.00088-.0016.00176-.0024.00263l-8.0817 8.8936v6.876c.0024.4158-.0994.8255-.2963 1.1918-.1964.3657-.4812.6762-.8283.9036l-4.0003 2.6627c-.3751.2471-.8099.3885-1.2586.4094-.4487.021-.8947-.0793-1.2912-.2904-.3965-.211-.7288-.525-.962-.9089-.2332-.3838-.3587-.8235-.3632-1.2726l-.0001-.0152v-9.5564l-8.08175-8.8936c-.0007-.00077-.0014-.00154-.0021-.0023-.32615-.3574-.5416-.80178-.62017-1.27925-.07875-.47849-.01663-.96955.17878-1.41337.19541-.44381.51568-.82122.92179-1.08623.40546-.26459.87902-.40575 1.36315-.40635m1.13306 3 7.44944 8.1978c.4264.4611.6616 1.0671.6578 1.6953v8.8008l3-1.9969v-6.8039c-.0038-.6282.2314-1.2342.6578-1.6953l7.4494-8.1978z"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </ListGroup.Item>
            <div onClick={onModalClosed} className="icons8-close">
              <span className="material-symbols-outlined fs-5 text-dark">
                close
              </span>
            </div>
          </div>
        </Modal.Header>
        <AddPaymentForm
          submitHandler={onSubmitForm}
          actionSuccess={addPaymentDataState.success}
          addUpdateInProgress={addPaymentDataState.inProgress}
        />
        <PaymentDetails id={id}
          showDeletePopup={showDeletePopup}
          deleteToggleHandler={deleteToggleHandler}
          onPageLimitChanged={onPageLimitChanged}
          pageLimit={pageLimit}
          pageInfo={pageInfo}
          onPageChanged={onPageChanged}
          disablePagination={disablePagination}
          sorting={sorting}
          handleSortingChange={handleSortingChange}
        />
      </Modal>
    </React.Fragment>
  );
};

export default FilterPopupHOC(DeletePopupHOC(observer(AddPaymentDetails)));
