/*    
<summary>
  This functional component shows the vehicle details.
  Developer:Aashish Singh, Created Date: 20-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { IVehicleList } from "../../../models/response/IVehicleResponse";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { allocationPeriodColorSchemeVehicle, DATE_FORMAT_BACKEND, emptyValue, notAvailable } from "../../../constants/common-constant";
import { AllocationPeriodEnum } from "../../../constants/enums/allocation-period-enum";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import { formatMessage } from "../../../translations/formatMessage";



interface IProps {
  selectedData: IVehicleList | undefined;
}

const VehicleDetails = (props: IProps) => {
  const { vehicleStore } = useStore();
  const { GetVehicleByIdService, getVehicleDetail, vehicleDetailState } = vehicleStore;

  /**
   * This function calls the "GetVehicleByIdService" store funtion that get group data from API
   */
  const callGetVehicleById = () => {
    if (props.selectedData)
      GetVehicleByIdService(props?.selectedData.Id);
  };
  const volumePercentage: number = (getVehicleDetail.RemainingVolume != "" && getVehicleDetail.AllocationVolume != "") ? (parseInt(getVehicleDetail.RemainingVolume) * 100) / parseInt(getVehicleDetail.AllocationVolume) : 0;
  useEffect(() => {
    if (props?.selectedData && (props?.selectedData?.Id > 0)) callGetVehicleById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData?.Id]);

  return (
    <React.Fragment>
      {vehicleDetailState.inProgress && <SpinnerLoader />}

      {getVehicleDetail && (
        <>
          <Row>
            <Col lg={6}>
              <p className="text-muted mb-2">
                <i className={`fa fa-circle ${getVehicleDetail?.FuelDeliveryEnabled ? "text-success" : "text-danger"}`}></i> &nbsp;
                <FormattedMessage
                  id={
                    getVehicleDetail?.FuelDeliveryEnabled
                      ? "fuel_delivery_enabled"
                      : "fuel_delivery_disabled"
                  }
                />
              </p>
              <p className="text-break">{getVehicleDetail?.Description}</p>
            </Col>
            {
              (getVehicleDetail.CustomerName) &&
              <Col lg={6}>
                <p className="text-muted mb-2 text-end">
                  <FormattedMessage id="customer_name" />
                </p>
                <p className="text-end">
                  {getVehicleDetail.CustomerName}
                </p>
              </Col>
            }
          </Row>


          <div className="progress_parent detail_view">
            <div className="progress">
              <div
                role="progressbar"
                className={`progress-bar ${volumePercentage > 25
                  ? volumePercentage < 75
                    ? "bg-warning"
                    : "bg-success"
                  : "bg-danger"
                  }`}
                style={{
                  width: `${volumePercentage}%`,
                }}
              ></div>
              <span className="volume_fig text-break">
                <strong>
                  {getVehicleDetail?.RemainingVolume}
                </strong>
                /{getVehicleDetail?.AllocationVolume}
              </span>
            </div>
          </div>
          <div className="">

            <div className="row g-0 mt-5">
              <div className="col-12">
                <div className="timeline horizontal">
                  <div className="timeline-item">
                    <small className="text-muted">
                      <FormattedMessage id={"lower_consumption_limit"} />
                    </small>
                    <h4 className="mb-0">{getVehicleDetail?.LowerConsumptionLimit} <small className="h6">L/100km</small></h4>
                  </div>
                  <div className="timeline-item">
                    <small className="text-muted">
                      <FormattedMessage id={"higher_consumption_limit"} />
                    </small>
                    <h4 className="mb-0">{getVehicleDetail?.HigherConsumptionLimit} <small className="h6">L/100km</small></h4>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="p-3 bg-light row">
            <div className="col-4">
              <small className="text-muted">
                <FormattedMessage id={"registration_no"} />
              </small>
              <p>{getVehicleDetail?.RegistrationNo} </p>
            </div>
            <div className="col-4">
              <small className="text-muted">
                <FormattedMessage id={"registration_expiry_date"} />
              </small>
              <p>{moment(getVehicleDetail.RegistrationExpiryDate).format(DATE_FORMAT_BACKEND)}</p>
            </div>
            <div className="col-4">
              <small className="text-muted">
                <FormattedMessage id={"truck_allocation"} />
              </small>
              <p>{getVehicleDetail.TruckAllocation1}</p>
            </div>
            <div className="col-4">
              <small className="text-muted">
                <FormattedMessage id="allocation_period" />
              </small><br />
              {
                getVehicleDetail.AllocationPeriod >= 0 &&
                <span
                  className={`px-2 rounded fw-normal ${allocationPeriodColorSchemeVehicle(
                    AllocationPeriodEnum[getVehicleDetail.AllocationPeriod]
                  )}`}
                >
                  {" "}
                  {AllocationPeriodEnum[getVehicleDetail.AllocationPeriod]}{" "}
                </span>
              }
            </div>
            <div className="col-4">
              <small className="text-muted">
                <FormattedMessage id="allocation_per_fill" />
              </small><br />
              {getVehicleDetail?.AllocationPerFill}{" "}

            </div>
          </div>

          <Row className="mt-4">
            <Col xs={4}>
              <small className="text-break">   <FormattedMessage id={"cust_id_2"} />:{" "}</small>
              <p>{getVehicleDetail?.CustId2}</p>
            </Col>
            {/* <Col xs={4}>
              <small className="text-break">   <FormattedMessage id={"cust_id_4"} />:{" "}</small>
              <p>{getVehicleDetail?.CustId4}</p>
            </Col> */}
            <Col xs={4}>
              <small className="text-break">   <FormattedMessage id={"cust_id_5"} />:{" "}</small>
              <p>{getVehicleDetail?.CustId5}</p>
            </Col>
            <Col xs={4}>
              <small className="text-break">   <FormattedMessage id={"cust_id_6"} />:{" "}</small>
              <p>{getVehicleDetail?.CustId6}</p>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};

export default observer(VehicleDetails);
