import React, { useState } from 'react';

const EditSettingPopupHOC = (Component: any) =>
function Comp(props:any){
    const [showEditSettingPopup,setShowEditSettingPopup] = useState<boolean>(false);

    const editSettingToggleHandler = () => {
        setShowEditSettingPopup(!showEditSettingPopup);
    }
	
	return <Component showEditSettingPopup={showEditSettingPopup} editSettingToggleHandler={editSettingToggleHandler} {...props} />;
};

export default EditSettingPopupHOC;