/*    
<summary>
   This class component is all about Managing currency data functionality.
   Developer:Aashish Singh, Created Date:25-Oct-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from 'mobx';
import { ICommonState } from '../../models/state/ICommonState';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import { ICountryCurrencyOption, IObservableInitialState } from '../../models/ICommon';
import * as baseService from '../service/base-service';
import { ICurrencyVM } from '../../models/response/ICurrencyResponse';
import UrlConstants from '../../constants/url.constant';
import { formatMessage } from '../../translations/formatMessage';
import { toast } from 'react-toastify';
import { ICurrencyState } from '../../models/state/ICurrencyState';
import { IOption } from '../initialState/initial-filter-states';
import { demoCurrecy } from './data';

export class CurrencyStore implements ICurrencyState, ICommonState {
    inProgress = false;
    error = '';
    
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    
    allCountryCurrencies: ICurrencyVM[] = [];
    countryCurrencyState:IObservableInitialState ={...this.initialStateValue}

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            allCountryCurrencies: observable,
            countryCurrencyState: observable,
            GetAllCountryCurrencyService: action,
            reset: action,
            resetStore: action,
            resetCountryCurrencyState: action,
            allCountryCurrencyOptions: computed,
        });
    }
    
    /*
    This function is used to Get All Country Currencies data from API.  
    */
    GetAllCountryCurrencyService = () => {
        this.countryCurrencyState.inProgress = true;
        const url = UrlConstants.CurrencyList;
        return baseService.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<ICurrencyVM[]>>) => {
                this.allCountryCurrencies = response.data.Data;
            })
            .catch((err: string) => {
                // this.allCountryCurrencies = demoCurrecy;
                // this.countryCurrencyState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.countryCurrencyState.inProgress = false; }));
    }

    /*
    This function is used to map allCountryCurrencies to allCountryCurrencyOptions List suitable for Grid Component.  
    */
    get allCountryCurrencyOptions(): IOption[] {
        const options: any[] = [];
        if (this.allCountryCurrencies && this.allCountryCurrencies.length > 0)
            this.allCountryCurrencies.map((currencyDetails) => {
                let tempPlaceholder = currencyDetails.CurrencyName +" (" + currencyDetails.Symbol +")";
                options.push({
                    Id: currencyDetails.Id,
                    Name: tempPlaceholder,
                })
            })
        else options.push({
            Id:2, Name:"Dirham (د.إ)"
        });

        return options;
    }

    /**
     * This function is used to reset all countryCurrencyState observables to their initial values.
     * @returns
     */
    resetCountryCurrencyState = () => {
        this.countryCurrencyState = {...this.initialStateValue};
    }

    /*
    This function is used to reset all observables to their initial values.  
    */
    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
    }
}

export default new CurrencyStore();
