/*    
<summary>
   This class component is all about Managing dashboard data functionality.
   Developer:Aashish Singh, Created Date:04-April-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { toast } from "react-toastify";
import { formatMessage } from "../../translations/formatMessage";
import { DATE_TIME_FORMAT_FOR_XAXIS } from "../../constants/common-constant";
import { IObservableInitialState } from "../../models/ICommon";
import { IGetAllMonitoringData, IGetClient, IGetClientInfo, IGetClientInfoState, IGetClientState, ISuperAdminDashboardState } from "../../models/state/ISuperAdminDashboardState";
import moment from "moment";

export class SuperAdminDashboardStore implements ISuperAdminDashboardState {

    clientSummaryDetail: IGetClientState = {
        state:{
            ClientCount: 0,
            RecentClientList: []
        },
        success: false,
        error: '',
        inProgress: false
    }

    clientInfoSummaryDetail: IGetClientInfoState = {
        state:{
            TotalVehicles: 0,
            TotalDrivers: 0,
            TotalCustomer: 0,
            TotalTransactions: 0,
            TotalTanks: 0
        },
        success: false,
        error: '',
        inProgress: false
    }

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    monitoringData: IGetAllMonitoringData | undefined = undefined;
    monitoringDataState ={...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            monitoringData:observable,
            monitoringDataState:observable,
            GetAllMonitoringDataService:action,
            getMonitoringData:computed,
            clientSummaryDetail: observable,
            GetClientSummaryDetail:action,
            getClientData:computed,
            clientInfoSummaryDetail: observable,
            GetClientInfoSummaryDetail:action,
            getClientInfoData:computed,
            resetDashboardDataSuccessState:action,
            getDashboardDataSuccessState:computed,
        });
    }

    GetAllMonitoringDataService = () => {
        this.monitoringDataState.inProgress = true;
        const url = UrlConstants.GetAllMonitoringData;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetAllMonitoringData>>) => {
                this.monitoringData = response.data.Data;
                this.monitoringDataState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.monitoringDataState.inProgress = false; }));
    }

    /**
    * This function provides coumputed monitoringData values suitable for plotting line chart. 
    * @returns chart points.
    */
    get getMonitoringData(): {chartPoints:any, maxValue:number} {
        let chartPoints:any=[
            {KPIName:"cpu_usage", dataPoints:[]},
            {KPIName:"memory_usage", dataPoints:[]},
            {KPIName:"disk_free_space", dataPoints:[]},
        ]
        let maxValue: number =0;
        if(this.monitoringData){
            let usedMemory= this.monitoringData.TotalMemory - this.monitoringData.DiskFreeSpace;
            chartPoints[2].dataPoints.push({label:"Disk Free Space", name:"Disk Free Space", y: this.monitoringData.DiskFreeSpace/(1024*1024*1024)});
            chartPoints[2].dataPoints.push({label:"Used Disk Space", name:"Used Disk Space", y: usedMemory/(1024*1024*1024)});
        }
        if (this.monitoringData && this.monitoringData?.UsageArrayList && this.monitoringData.UsageArrayList?.length > 0){
            this.monitoringData.UsageArrayList.map((stat:any,index:number) => {
                maxValue = stat.MemoryUsage > maxValue ? stat.MemoryUsage : maxValue;
                let memoryUsageInMB = parseFloat(stat.MemoryUsage)/(1024*1024*1024);
                chartPoints[0].dataPoints.push({label: moment(stat.MonitoringTime).format(DATE_TIME_FORMAT_FOR_XAXIS), y:parseFloat(stat.CpuUsage)/100});
                chartPoints[1].dataPoints.push({label: moment(stat.MonitoringTime).format(DATE_TIME_FORMAT_FOR_XAXIS), y:memoryUsageInMB});
            });
        }
        return {chartPoints, maxValue};
    }
    
    GetClientSummaryDetail = () => {
        this.clientSummaryDetail.inProgress = true;
        const url = UrlConstants.GetClientSummary;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetClient>>) => {
                this.clientSummaryDetail.state = response.data.Data;
                this.clientSummaryDetail.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.clientSummaryDetail.inProgress = false; }));
    }

    get getClientData(){
        return this.clientSummaryDetail.state; 
    }

    GetClientInfoSummaryDetail = (clientId:string) => {
        this.clientInfoSummaryDetail.inProgress = true;
        const url = `${UrlConstants.GetClientDetailInformation}?clientId=${clientId}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IGetClientInfo>>) => {
                this.clientInfoSummaryDetail.state = response.data.Data;
                this.clientInfoSummaryDetail.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.clientInfoSummaryDetail.inProgress = false; }));
    }

    get getClientInfoData(){
        return this.clientInfoSummaryDetail.state; 
    }

    resetDashboardDataSuccessState = ()=>{  }

    get getDashboardDataSuccessState():boolean{
        let success = this.clientSummaryDetail.success;
        return success;
    }

}

export default new SuperAdminDashboardStore();