/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import { IOption } from "../../../models/ICommon";
import { IAddEditRole } from "../../../models/response/IRoleResponse";
import { editTransactionCustomerIdsValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { ITransaction, IUpdateTransactions } from "../../../models/ITransaction";
import transactionsStore from "../../../core/stores/transactions-store";

interface IProps {
  id: number;
  submitHandler: (data: IUpdateTransactions) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: ITransaction;
}

const UpdateCustomerIdsInTransactionForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal " show={true} centered>
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            <FormattedMessage id="update_transaction" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={editTransactionCustomerIdsValidateSchema}
          onSubmit={async (values: ITransaction) => {
            // submitHandler({ ...values });
            submitHandler({
              CustomerId1: values.CustomerId1 ? values.CustomerId1.trim() : values.CustomerId1,
              CustomerId2: values.CustomerId2 ? values.CustomerId2.trim() : values.CustomerId2,
              CustomerId3: values.CustomerId3 ? values.CustomerId3.trim() : values.CustomerId3,
              CustomerId4: values.CustomerId4 ? values.CustomerId4.trim() : values.CustomerId4,
              CustomerId5: values.CustomerId5 ? values.CustomerId5.trim() : values.CustomerId5,
              CustomerId6: values.CustomerId6 ? values.CustomerId6.trim() : values.CustomerId6
            });
          }}
        >
          {({ values }) => (
            <Form className="user-form customer-modal p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <div className="TicketNumber d-flex justify-content-between align-items-center mb-3">
                  <small className="h6 m-0"><FormattedMessage id="transactions_label_ticketnumber" /></small>
                  {/* <p>12345678</p> */}
                  <p>{initialValues?.TicketNumber}</p>
                </div><hr />
                <Row className="position-relative">
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_1"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId1"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_2"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId2"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_3"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId3"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_4"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId4"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_5"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId5"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="mb-3  form-group">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_customer_id_6"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="CustomerId6"
                        type="text"
                        validcharacter={['@', '#', '$', '-', '_', '.', " "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text="button_edit"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UpdateCustomerIdsInTransactionForm;
