export enum ColumnType {
  Sorting,
  Icons,
  Hidden,
  TextHeader,
  DeviceTextHeader,
  Detail,
  FormattedCellValue,
  ActionsFormatter,
  FormattedDateTimeWithSorting,
  LineProgressBar,
  ColoredIcons,
  Action,
  CheckboxGroup,
  TimePicker,
  SearchSelect,
  CheckBoxSelect,
  RadioGroupFormatter,
  NumberInput,
  TextInput,
  TwoSetOfIcons,
  SelectAllCheckbox,
  CheckboxInput,
  ClickFormatterWithSorting,
  JsxElement,
  CustomDivAlarmFormatter
}