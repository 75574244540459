import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { withSize } from "react-sizeme";
import allocatedAmount from "../../assets/Images/allocatedAmount.png";
import allocatedLimit from "../../assets/Images/allocatedLimit.png";
import blackRectangle from "../../assets/Images/blackRectangle.png";
import consumedVolume from "../../assets/Images/consumedVolume.png";
import Drivers from "../../assets/Images/drivers.svg";
import Filter from "../../assets/Images/filterdashboard.svg";
import greenRecatngle from "../../assets/Images/greenRecatngle.png";
import inflow from "../../assets/Images/inflow.png";
import NoDataAlarm from "../../assets/Images/no-alarm.png";
import NoData from "../../assets/Images/no_record.png";
import NoChart from "../../assets/Images/noChart.png";
import oragneRecatngle from "../../assets/Images/oragneRecatngle.png";
import redBox from "../../assets/Images/redBox.png";
import RemainingVolume from "../../assets/Images/RemainingVolume.png";
import transaction from "../../assets/Images/transaction.png";
import unitPrice from "../../assets/Images/unitPrice.png";
import PriceLitre from "../../assets/Images/unitPriceLitre.png";
import vehicles from "../../assets/Images/vehicles.svg";
import yellowDots from "../../assets/Images/yellowDots.png";
import yellowRectangle from "../../assets/Images/yellowRectangle.png";
import { DashboardFilterCase, DATE_TIME_FORMAT_TO_BACKEND } from "../../constants/common-constant";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { ModuleName } from "../../constants/moduleName.constant";
import { useStore } from "../../contexts/StoreProvider";
import { getAmountDecimal } from "../../helpers/localStorages.helper";
import { IOption } from "../../models/ICommon";
import Chart from "../../shared-components/Charts/Chart";
import Footer from "../../shared-components/Footer/Footer";
import { getColumns } from "../../shared-components/Grid/Grid";
import SubHeader from "../../shared-components/Header/SubHeader";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import FilterPopupHOC from "../../shared-components/Popup/Hoc/FilterPopupHoc";
import { formatMessage } from "../../translations/formatMessage";
import "./customer-dashboard.scss";
import CustomerDashboardFilterForm from "./form/customer-dashboard-filter-form";
import { initialCustomerDashboardFilter } from "../../core/initialState/initial-filter-states";




interface IProps {
    size: {
        height: number;
    };
    showFilterPopup: boolean;
    filterToggleHandler: () => void;
}


const CustomerDashboard = (props: IProps) => {
    let t: NodeJS.Timeout;
    const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
    const volumePercentage = 60;
    const navigate = useNavigate();
    const { customerDashboardStore, preferencesStore } = useStore();
    const { showFilterPopup, filterToggleHandler } = props;
    const [isLocalFilterChanged, setIsLocalFilterChanged] = useState<boolean>(false);
    const [isGlobalFilterChanged, setIsGlobalFilterChanged] = useState<boolean>(false);
    const {
        GetFuelStatusService,
        getFuelStatusDetail,
        dailyFuelUsageDetail,
        dashboardMetricsDetail,
        fuelStatusDetailState,
        dailyFuelUsageDetailState,
        dashboardMetricsDetailState,
        VolumeConsumedDetailState,
        VolumeDeliveredDetailState,
        getDashboardDataSuccessState,
        resetDashboardDataSuccessState,
        GetDailyFuelUsageService,
        GetTankLevelService,
        getDailyFuelUsageChartData,
        filterCase,
        filterSetting,
        setFilterCase,
        setFilterSetting,
        setFilterDateSetting,
        getFilterSettingInfo,
        dashboardRecentAlarmsState,
        truckStatusDetailState,
        tankLevelDetail,
        tankLevelDetailState,
        GetRecentAlarmsService,
        GetDashboardMetricsService,
        getDashboardRecentAlarmsDetail,
        getDashboardMetricsDetail,
        getDashboardPricingDetail,
        GetPricingService,
        getTankLevelDetail
    } = customerDashboardStore;
    const { getTruckCustomerOptions, GetTruckSummaryService, getDepartmentCustomerOptions, getVehicleCustomerOptions, GetVehicleSummaryService, GetDepartmentSummaryService } = preferencesStore;
    // const [filterCase, setFilterCase] = useState<string>("");
    // const defaultFilterValue: { TruckIds: string | string[] } = { TruckIds: availableTruckOptions.length > 0 ? availableTruckOptions[0].Id : "" };
    const [dateFilter, setDateFilter] = useState<{ startDate: Date, endDate: Date }>({ startDate: getFilterSettingInfo.StartDate, endDate: getFilterSettingInfo.EndDate });
    // const [initialFuelStatusFilterValue, setInitialFuelStatusFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);
    // const [initialDailyFuelUsageFilterValue, setInitialDailyFuelUsageFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);
    // const [initialTankLevelFilterValue, setInitialTankLevelFilterValue] = useState<{ TruckIds: string | string[] }>(defaultFilterValue);


    const defaultFilterValue = {
        FuelStatus: { CustomerId2: "All", CustomerId3: 'All' },
        DailyFuelUsage: { CustomerId2: 'All', CustomerId3: 'All' },
        TankLevel: { TruckIds: '' },
    };

    const Columns = getColumns([
        {
            columnType: ColumnType.Hidden,
            accessorKey: 'Id',
        },
        {
            columnType: ColumnType.TextHeader,
            accessorKey: 'Name',
            displayName: 'label_all'
        },
    ])

    const getXAxisLabelInterval = (range: number) => {
        let xAxisInterval: number = 2;
        if (!isNaN(range)) {
            let interval = Math.trunc(range / 2);
            xAxisInterval = interval > 0 ? interval : 1;
        }
        return xAxisInterval;
    };

    const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
    const truckCount = getTruckCustomerOptions.length;
    const vehicleCount = getVehicleCustomerOptions.length;
    const departmentCount = getDepartmentCustomerOptions.length;

    const dateFilterHandler = (values: { startDate: Date; endDate: Date }) => {
        setFilterDateSetting(values.startDate, values.endDate);
        setDateFilter({ ...values });
    }

    const callDashboardDataApi = () => {
        setAutoRefresh(false);
        GetFuelStatusService(dateFilter.startDate, dateFilter.endDate);
        GetDashboardMetricsService();
        GetRecentAlarmsService();
        GetPricingService();
        GetDailyFuelUsageService(dateFilter.startDate, dateFilter.endDate);
        GetTankLevelService(dateFilter.startDate, dateFilter.endDate);
    }

    useEffect(() => {
        GetTruckSummaryService();
        GetVehicleSummaryService();
        GetDepartmentSummaryService();
    }, [GetDepartmentSummaryService, GetTruckSummaryService, GetVehicleSummaryService])

    useEffect(() => {
        GetDashboardMetricsService();
        GetRecentAlarmsService();
        GetPricingService();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let tempFilterDetails = localStorage.getItem("secure_fuel_customer_dashboard_filter") ?? { ...initialCustomerDashboardFilter };
        let tempFilter = JSON.parse(tempFilterDetails);
        if (truckCount > 0) {
            console.log(tempFilterDetails, tempFilter)
            let tempTruckValue: any = getTruckCustomerOptions.length > 1 ? getTruckCustomerOptions[1].Name : "";
            if (tempFilterDetails === null && tempFilter.TankLevel.TruckIds) {
                setFilterSetting(DashboardFilterCase.TankLevel, { TruckIds: tempTruckValue }, true);
            }
            else {
                setFilterSetting(DashboardFilterCase.TankLevel, { TruckIds: tempFilter.TankLevel.TruckIds }, false, true);
            }
            GetTankLevelService(dateFilter.startDate, dateFilter.endDate);
        }
        if (vehicleCount > 0 && departmentCount > 0) {
            let tempVehicleValue: any = getVehicleCustomerOptions.length > 0 ? getVehicleCustomerOptions[0].Name : "All";
            let tempDepartmentValue: any = getDepartmentCustomerOptions.length > 0 ? getDepartmentCustomerOptions[0].Name : "All";
            if (tempFilterDetails === null) {
                setFilterSetting(DashboardFilterCase.FuelStatus, { CustomerId2: tempVehicleValue, CustomerId3: tempDepartmentValue }, true);
                setFilterSetting(DashboardFilterCase.DailyFuelUsage, { CustomerId2: tempVehicleValue, CustomerId3: tempDepartmentValue }, true);
            }
            else {
                setFilterSetting(DashboardFilterCase.FuelStatus, { CustomerId2: tempFilter.FuelStatus.CustomerId2, CustomerId3: tempFilter.FuelStatus.CustomerId3 }, false, true);
                setFilterSetting(DashboardFilterCase.DailyFuelUsage, { CustomerId2: tempFilter.DailyFuelUsage.CustomerId2, CustomerId3: tempFilter.DailyFuelUsage.CustomerId3 }, false, true);
            }
            GetFuelStatusService(dateFilter.startDate, dateFilter.endDate);
            GetDailyFuelUsageService(dateFilter.startDate, dateFilter.endDate);

        }
    }, [truckCount, vehicleCount, departmentCount, getTruckCustomerOptions, GetTankLevelService, dateFilter.startDate, dateFilter.endDate, setFilterSetting, getVehicleCustomerOptions, getDepartmentCustomerOptions]);

    useEffect(() => {
        if (isGlobalFilterChanged) {
            resetDashboardDataSuccessState();
            callDashboardDataApi();
        }
        setIsGlobalFilterChanged(() => false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGlobalFilterChanged]);


    useEffect(() => {
        if (isLocalFilterChanged) {
            resetDashboardDataSuccessState();
            callDashboardDataApi();
            setIsLocalFilterChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocalFilterChanged]);

    useEffect(() => {
        if (getDashboardDataSuccessState) {
            setAutoRefresh(true);
            resetDashboardDataSuccessState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDashboardDataSuccessState]);

    /**
     * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
     * dependency "autoRefresh" of the useEffect changed.
     */
    useEffect(() => {
        if (autoRefresh) {
            t = setInterval(() =>
                callDashboardDataApi(), 30000);
        }
        else clearInterval(t);
        return () => {
            clearInterval(t);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoRefresh]);


    const filterHandler = (values: any) => {
        if (values) {
            setIsLocalFilterChanged(true);
            setInitialValuesByCase(values, filterCase);
        }
        filterToggleHandler();
    };

    let decimalValue = getAmountDecimal();

    const getInitialValuesByCase = (fCase: string, objectKey: string) => {
        switch (fCase) {
            case "FuelStatus":
                return getFilterSettingInfo.FuelStatus[objectKey];
            case "DailyFuelUsage":
                return getFilterSettingInfo.DailyFuelUsage[objectKey];
            case "TankLevel":
                return getFilterSettingInfo.TankLevel[objectKey];
        }
        return defaultFilterValue;
    }

    const callServiceByFilterCase = (fCase: string) => {
        switch (fCase) {
            case "FuelStatus":
                return GetFuelStatusService(dateFilter.startDate, dateFilter.endDate);
            case "DailyFuelUsage":
                return GetDailyFuelUsageService(dateFilter.startDate, dateFilter.endDate);
            case "TankLevel":
                return GetTankLevelService(dateFilter.startDate, dateFilter.endDate);
        }
    }

    const getTitleByCase = (fCase: string) => {
        switch (fCase) {
            case "FuelStatus":
                return "fuel_status";
            case "DailyFuelUsage":
                return "daily_fuel_usage";
            case "TankLevel":
                return "tank_level";
        }
        return "filter_title";
    }
    const setInitialValuesByCase = (values: any, fCase: string) => {
        switch (fCase) {
            case "FuelStatus":
                setFilterSetting(DashboardFilterCase.FuelStatus, values);
                return;
            case "DailyFuelUsage":
                setFilterSetting(DashboardFilterCase.DailyFuelUsage, values);
                return;
            case "TankLevel":
                setFilterSetting(DashboardFilterCase.TankLevel, values);
                return;
        }
    }
    const openFilterHandler = (fCase: string) => {
        setFilterCase(fCase);
        filterToggleHandler();
    }

    return (
        <>
            {showFilterPopup && (
                <CustomerDashboardFilterForm
                    title={formatMessage(getTitleByCase(filterCase))}
                    filterCase={filterCase}
                    initialValues={{ TruckIds: getInitialValuesByCase(filterCase, "TruckIds"), CustomerId2: getInitialValuesByCase(filterCase, "CustomerId2"), CustomerId3: getInitialValuesByCase(filterCase, "CustomerId3") }}
                    submitHandler={filterHandler}
                    onModalClosed={filterToggleHandler}
                    actionInProgress={false}
                />
            )}
            <div className={`main-content`}>
                <SubHeader
                    moduleNameString={"dashboard_title"}
                    moduleName={ModuleName.Dashboard}
                    setIsFullScreen={setIsFullScreen}
                    isAdd={false}
                    isFullView={false}
                    isDownload={false}
                    isRefresh={false}
                    isFilter={false}
                    isDateFilter={true}
                    isGlobalFilterChanged={isGlobalFilterChanged}
                    setIsGlobalFilterChanged={setIsGlobalFilterChanged}
                    dateFilterHandler={dateFilterHandler}
                    dateFilter={dateFilter}
                />
            </div>
            <div className={`${isFullScreen && "dashboard-full-screen"}`}>
                {isFullScreen && (
                    <div className={"circle-icon blue miniMizeIcon"}>
                        <svg
                            onClick={() => {
                                setIsFullScreen((isFullScreen) => !isFullScreen);
                            }}
                            height="22"
                            fill="#fff"
                            width="22"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path d="m21 10c0-.69033-.5596-1.24997-1.25-1.24997h-2.7322l3.9822-3.98224c.4882-.48815.4882-1.27961 0-1.76776-.4882-.48816-1.2796-.48816-1.7678 0l-3.9822 3.98223v-2.73223c0-.69036-.5596-1.25-1.25-1.25-.6903 0-1.25.55964-1.25 1.25v5.74997c0 .6904.5597 1.25 1.25 1.25h5.75c.6904 0 1.25-.5596 1.25-1.25z" />
                                <path d="m3 14c0 .6904.55964 1.25 1.25 1.25h2.73222l-3.98222 3.9823c-.48816.4881-.48816 1.2796 0 1.7677.48815.4882 1.27961.4882 1.76777 0l3.98221-3.9822v2.7322c0 .6904.55965 1.25 1.25 1.25.69032 0 1.25002-.5596 1.25002-1.25v-5.75c0-.6903-.5597-1.25-1.25002-1.25h-5.74998c-.69036 0-1.25.5597-1.25 1.25z" />
                            </g>
                        </svg>
                    </div>
                )}
                <div className="dashboard py-2 px-5">
                    <div className="container">
                        {/* customer Status Card Start */}
                        <Row>
                            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 mt-5">
                                <Card className="shadow-sm rounded h-100 fuel-header">
                                    <Card.Header>
                                        <Card.Title className="m-0">
                                            <div className="d-flex justify-content-between">
                                                <span className="fw-bolder fs-5">
                                                    <FormattedMessage id="Fuel Status" />


                                                    <small className="text-white ms-3"><FormattedMessage id="Dept. Id " /></small>
                                                    {
                                                        getFilterSettingInfo?.FuelStatus?.CustomerId2 &&
                                                        <small className="text-white client-filter ms-2">{getFilterSettingInfo?.FuelStatus?.CustomerId2}</small>
                                                    }



                                                    <small className="text-white ms-1">  <FormattedMessage id="Vehicle Reg. No. " /></small>
                                                    {
                                                        getFilterSettingInfo?.FuelStatus?.CustomerId3 &&
                                                        <small className="text-white client-filter ms-2">{getFilterSettingInfo?.FuelStatus?.CustomerId3}</small>
                                                    }



                                                </span>
                                                <img title="Filter"
                                                    src={Filter}
                                                    onClick={() => { openFilterHandler("FuelStatus") }}
                                                    alt="blackRectangle"
                                                    className="filter"
                                                />
                                            </div>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="py-5 d-flex">
                                        {fuelStatusDetailState.inProgress && <SpinnerLoader />}
                                        <Card.Text className="w-100">
                                            <Row className="mt-2 justify-content-between">
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-danger">
                                                        <img
                                                            src={transaction}
                                                            alt="transaction"
                                                            className=""
                                                        />

                                                        <p className="text-white m-0">
                                                            <FormattedMessage id="total_transaction" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getFuelStatusDetail?.TotalTransaction}
                                                        </p>
                                                        <img src={redBox} alt="redBox" className="" />
                                                    </div>
                                                </Col>

                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-light">
                                                        <img
                                                            src={inflow}
                                                            alt="inflow"
                                                            className=""
                                                        />

                                                        <p className="text-white m-0">
                                                            <FormattedMessage id="Total Fuel Consumed" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getFuelStatusDetail?.TotalFuelConsumed} L
                                                        </p>
                                                        <img
                                                            src={yellowDots}
                                                            alt="yellowDots"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                                {/* <Col className="position-relative">
                                                    <div className="circle bg-gradient-info">
                                                        <img
                                                            src={allocatedLimit}
                                                            alt="allocatedLimit"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Allocated Limit" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getFuelStatusDetail?.AllocatedLimit} L
                                                        </p>
                                                        <img
                                                            src={oragneRecatngle}
                                                            alt="oragneRecatngle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col> */}

                                                {/* <Col className="position-relative">
                                                    <div className="circle bg-gradient-warning">
                                                        <img
                                                            src={allocatedAmount}
                                                            alt="allocatedAmount"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Allocated Amount" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {Number(getFuelStatusDetail?.AllocatedAmount).toFixed(Number(decimalValue))}
                                                        </p>
                                                        <img
                                                            src={greenRecatngle}
                                                            alt="greenRecatngle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col> */}


                                                {/* <Col className="position-relative" lg={7} md={6} sm={6}>
                                                    <div className="circle bg-gradient-blue">
                                                        <img
                                                            src={RemainingVolume}
                                                            alt="RemainingVolume"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Remaining Volume" />{" "}
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0  text-white">
                                                            {getFuelStatusDetail.RemainingVolume} L
                                                        </p>
                                                        <img
                                                            src={blackRectangle}
                                                            alt="blackRectangle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col> */}
                                                {/* <Col className="position-relative">
                           <div className="circle bg-gradient-success">
                              <img src={inflow} alt="inflow" className="" />

                              <p className="m-0 text-white">
                                <FormattedMessage id="total_inflow" />{" "}
                              </p>
                              <p className="fs-3 fw-bolder m-0  text-white">
                                {getFuelStatusDetail.TotalFuelConsumed} L
                              </p>
                              <img
                                src={blackRectangle}
                                alt="blackRectangle"
                                className=""
                              />
                            </div> 
                          </Col> */}
                                            </Row>
                                            <Row className="mt-2 justify-content-between">
                                            </Row>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* Tank Level Card Start */}

                            <div className="Tank-background-brown col-lg-5 col-md-12 col-sm-12 col-xs-12 mt-5">
                                <Card className="shadow-sm rounded h-100 fuel-header">
                                    <Card.Header>
                                        <Card.Title className="m-0">
                                            <div className="d-flex justify-content-between">
                                                <span className="fw-bolder fs-5">
                                                    <FormattedMessage id="tank_level" />
                                                    {
                                                        getFilterSettingInfo?.TankLevel?.TruckIds &&
                                                        <small className="text-white client-filter ms-2"> {getFilterSettingInfo?.TankLevel?.TruckIds}</small>
                                                    }
                                                </span>
                                                <img title="Filter"
                                                    src={Filter}
                                                    onClick={() => { openFilterHandler("TankLevel") }}
                                                    alt="blackRectangle"
                                                    className="filter"
                                                />
                                            </div>
                                        </Card.Title>
                                    </Card.Header>
                                    {
                                        (truckCount > 0) ?
                                            <Card.Body className="py-5 d-flex">
                                                {tankLevelDetailState.inProgress && <SpinnerLoader />}
                                                <Card.Text>

                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-sm-6">
                                                            <div className="vertical_tank_progress">
                                                                <div className="progress progress-bar-vertical mx-auto">
                                                                    <div
                                                                        role="progressbar"
                                                                        className="progress-bar bg-primary"
                                                                        style={{
                                                                            height: `${volumePercentage}%`,
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                                <ul className="term-list">
                                                                    <li className="list-group-item"></li>
                                                                    <li className="list-group-item"></li>
                                                                    <li className="list-group-item"></li>
                                                                    <li className="list-group-item"></li>
                                                                    <li className="list-group-item"></li>
                                                                    <li className="list-group-item"></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {
                                                            getTankLevelDetail.Compartment === 2 &&
                                                            <div className="col-sm-6">
                                                                <div className="vertical_tank_progress">
                                                                    <div className="progress progress-bar-vertical mx-auto">
                                                                        <div
                                                                            role="progressbar"
                                                                            className="progress-bar bg-primary"
                                                                            style={{
                                                                                height: `${volumePercentage}%`,
                                                                            }}
                                                                        ></div>
                                                                    </div>
                                                                    <ul className="term-list">
                                                                        <li className="list-group-item"></li>
                                                                        <li className="list-group-item"></li>
                                                                        <li className="list-group-item"></li>
                                                                        <li className="list-group-item"></li>
                                                                        <li className="list-group-item"></li>
                                                                        <li className="list-group-item"></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="col-sm-6 p-0">
                                                            <div className="row">
                                                                <p className="mt-3 fs-6 fw-semi-bold">
                                                                    Compartment 1
                                                                </p>
                                                                <div className="col-sm-12">
                                                                    <div className="filledCircle">
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <div className="">
                                                                                <span className="p-1 d-block bg-primary rounded-circle"></span>
                                                                            </div>
                                                                            <div className="ms-2">
                                                                                <p className="mb-0">
                                                                                    Filled Fuel{" "}
                                                                                    <small className="text-muted"> {getTankLevelDetail.Fuel1} %</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="filledCircle emptyCircle">
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <div className="">
                                                                                <span className="p-1 d-block bg-primary rounded-circle"></span>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-2">
                                                                                <p className="mb-0">
                                                                                    Empty Fuel{" "}
                                                                                    <small className="text-muted"> {100 - getTankLevelDetail.Fuel1} %</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            getTankLevelDetail.Compartment === 2 &&
                                                            <div className="col-sm-6 p-0">
                                                                <div className="row">
                                                                    <p className="mt-3   fs-6 fw-semi-bold">
                                                                        Compartment 2
                                                                    </p>
                                                                    <div className="col-sm-12">
                                                                        <div className="filledCircle">
                                                                            <div className="d-flex align-items-center mb-2">
                                                                                <div className="">
                                                                                    <span className="p-1 d-block bg-primary rounded-circle"></span>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-2">
                                                                                    <p className="mb-0">
                                                                                        Filled Fuel
                                                                                        <small className="text-muted"> {getTankLevelDetail.Fuel2} %</small>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="filledCircle emptyCircle">
                                                                            <div className="d-flex align-items-center mb-2">
                                                                                <div className="">
                                                                                    <span className="p-1 d-block bg-primary rounded-circle"></span>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-2">
                                                                                    <p className="mb-0">
                                                                                        Empty Fuel
                                                                                        <small className="text-muted"> {100 - getTankLevelDetail.Fuel2} %</small>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                </Card.Text>
                                            </Card.Body>
                                            :
                                            <Card.Body>
                                                <Card.Text>
                                                    <div className="no_data_table no-tanks">
                                                        <div className="no_data w-100 text-center">
                                                            <img src={NoData} alt="NOData" className="NOData" />
                                                            <h1 className="fs-4">
                                                                <FormattedMessage id="message_no_datafound" />
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                    }
                                </Card>
                            </div>
                            {/* Tank Level Card End */}
                        </Row>
                        {/* customer Status Card End */}

                        {/* Pricing Card Start */}
                        <Row>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                                <Card className="shadow-sm rounded h-100 fuel-header">
                                    <Card.Header>
                                        <Card.Title className="m-0">
                                            <div className="d-flex justify-content-between">
                                                <span className="fw-bolder fs-5">
                                                    <FormattedMessage id="Pricing" />
                                                </span>
                                            </div>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="py-5 d-flex">
                                        {fuelStatusDetailState.inProgress && <SpinnerLoader />}
                                        <Card.Text className="w-100">
                                            <Row className="mt-2">

                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-danger">
                                                        <img
                                                            src={unitPrice}
                                                            alt="unitPrice"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Unit Price (UKG)" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getDashboardPricingDetail?.PricePerGallon}
                                                        </p>
                                                        <img src={redBox} alt="redBox" className="" />
                                                    </div>
                                                </Col>
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-light">
                                                        <img
                                                            src={PriceLitre}
                                                            alt="PriceLitre"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Unit Price (L)" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getDashboardPricingDetail?.PricePerL}
                                                        </p>
                                                        <img
                                                            src={yellowRectangle}
                                                            alt="yellowRectangle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-info">
                                                        <img
                                                            src={allocatedLimit}
                                                            alt="allocatedLimit"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Amount Limit" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getDashboardPricingDetail?.MaxAmountLimit}
                                                        </p>
                                                        <img
                                                            src={oragneRecatngle}
                                                            alt="oragneRecatngle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5">
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-warning">
                                                        <img
                                                            src={allocatedAmount}
                                                            alt="allocatedAmount"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Volume Limit" />
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0 text-white">
                                                            {getDashboardPricingDetail?.MaxVolumeLimit} L
                                                        </p>
                                                        <img
                                                            src={greenRecatngle}
                                                            alt="greenRecatngle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-blue">
                                                        <img
                                                            src={RemainingVolume}
                                                            alt="RemainingVolume"
                                                            className=""
                                                        />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Remaining Volume" />{" "}
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0  text-white">
                                                            {getDashboardPricingDetail?.RemainingVolume} L
                                                        </p>
                                                        <img
                                                            src={blackRectangle}
                                                            alt="blackRectangle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="position-relative">
                                                    <div className="circle bg-gradient-success">
                                                        <img src={consumedVolume} alt="consumedVolume" className="" />

                                                        <p className="m-0 text-white">
                                                            <FormattedMessage id="Threshold Volume Limit" />{" "}
                                                        </p>
                                                        <p className="fs-3 fw-bolder m-0  text-white">
                                                            {getDashboardPricingDetail?.ThresholdVolumeLimit} L
                                                        </p>
                                                        <img
                                                            src={blackRectangle}
                                                            alt="blackRectangle"
                                                            className=""
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Row>
                        {/* Pricing Status Card End */}

                        {/* Tank Level and Fuel Status Card Start */}
                        <Row className="Fuel-background-blue">


                            {/* Daily Fuel Usage Card Start */}
                            <div className="col-lg-7 col-md-12 mt-5">
                                <Card className="shadow-sm rounded h-100 fuel-header">
                                    <Card.Header>
                                        <Card.Title className="m-0">
                                            <div className="d-flex justify-content-between">
                                                <span className="fw-bolder fs-5">
                                                    {" "}
                                                    <FormattedMessage id="Daily Fuel Usage" />


                                                    <small className="text-white ms-3">    <FormattedMessage id="Dept. Id " /></small>
                                                    {
                                                        getFilterSettingInfo?.DailyFuelUsage?.CustomerId2 &&
                                                        <small className="text-white client-filter ms-2">{getFilterSettingInfo?.DailyFuelUsage?.CustomerId2}</small>
                                                    }


                                                    <small className="text-white ms-3">
                                                        <FormattedMessage id="Vehicle Reg. No. " /></small>
                                                    {
                                                        getFilterSettingInfo?.DailyFuelUsage?.CustomerId3 &&
                                                        <small className="text-white client-filter ms-2">{getFilterSettingInfo?.DailyFuelUsage?.CustomerId3}</small>
                                                    }


                                                </span>
                                                <img title="Filter"
                                                    src={Filter}
                                                    onClick={() => { openFilterHandler("DailyFuelUsage") }}
                                                    alt="blackRectangle"
                                                    className="filter"
                                                />
                                            </div>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            {dailyFuelUsageDetailState.inProgress && <SpinnerLoader />}

                                            {
                                                getDailyFuelUsageChartData.length > 0 ?
                                                    <Chart
                                                        data={[
                                                            {
                                                                type: "column",
                                                                color: "#AF9763",
                                                                yValueFormatString: "#0.###### L",
                                                                // indexLabelFontSize: 16,
                                                                dataPoints: [...getDailyFuelUsageChartData],
                                                            },
                                                        ]}
                                                        xAxisInterval={getXAxisLabelInterval(getDailyFuelUsageChartData.length)}
                                                    /> :
                                                    <>
                                                        <div className="no-data-chart text-center ">
                                                            <div className="no-chart-content">

                                                                <img
                                                                    src={NoChart}
                                                                    alt="noChart"
                                                                    className="noChart"
                                                                />
                                                                <p className="pt-1 fs-4">
                                                                    <FormattedMessage id="no_chart_data_available_for_the_selected_date_range" />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* Recent Alarms Card Start */}
                            <div className="col-lg-5 col-md-12 mt-5">
                                <Card className="shadow-sm rounded h-100 fuel-header">
                                    <Card.Header>
                                        <Card.Title className="m-0">
                                            <div className="d-flex justify-content-between">
                                                <span className="fw-bolder fs-5">Recent Alarms</span>
                                            </div>
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        {dashboardRecentAlarmsState.inProgress && <SpinnerLoader />}
                                        <Card.Text>
                                            <div className="list-group timeline-list">
                                                <div className="list-group-item border-0 bg-transparent">
                                                    {getDashboardRecentAlarmsDetail.length > 0 ? getDashboardRecentAlarmsDetail.map((alarm) => <div className="row d-flex align-items-center p-2 circle-repeat">
                                                        <div className="col-lg-2 col-sm-2 col-xs-2 timeline-width">
                                                            <div className="timeline-circle-wrap">
                                                                <div className="timeline-circle border-warning">
                                                                    <div className="timeline-circle blue">
                                                                        <span className="material-symbols-outlined text-white">
                                                                            autostop
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10 col-sm-10 col-xs-10 timeline-width">
                                                            <small>{moment(alarm.AlarmTimestamp).format(DATE_TIME_FORMAT_TO_BACKEND)}</small>

                                                            <p className="mb-0">Alarm Updated</p>
                                                            <small className="small text-muted mb-0">
                                                                {alarm.AlarmDetail}
                                                            </small>
                                                        </div>
                                                    </div>) : <div className="No-data-alarm text-center py-2">
                                                        <img src={NoDataAlarm} alt="NOData" className="NoAlarm" />
                                                        <p className="fs-3 mt-3 pt-2 ">     <FormattedMessage id="message_no_datafound" /></p>
                                                    </div>}
                                                </div>
                                            </div>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                            {/* Recent Alarms Card End */}
                            {/* Daily Fuel Usage Card End */}
                        </Row>
                        {/* Tank Level and Fuel Status Card End */}

                        {/* Toatal Vehicles and Drivers Start */}
                        <div className="vehicles row">
                            <div className="col-lg-4 mt-5">
                                <div className="rounded h-100 bg-vehicles-pink  py-3">
                                    {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                                    <div className="text-center">
                                        <p className="fs-3 fw-bolder mb-0 mt-2">{getDashboardMetricsDetail.Vehicles}</p>
                                        <p className="fs-6"><FormattedMessage id="total_vehicles" /></p>
                                        <img src={vehicles} alt="vehicles" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-5">
                                <div className="rounded h-100 bg-drivers-blue text-center py-3">
                                    {/* {dashboardMetricsDetailState.inProgress && <SpinnerLoader />} */}
                                    <div className="text-end"></div>
                                    <div className="text-center">
                                        <p className="fs-3 fw-bolder mb-0 mt-2">{getDashboardMetricsDetail.Drivers}</p>
                                        <p className="fs-6"><FormattedMessage id="total_drivers" /></p>
                                        <img src={Drivers} alt="Drivers" />
                                    </div>
                                </div>
                            </div>
                            {/* Toatal Vehicles and Drivers End */}

                        </div>

                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default withSize({ monitorHeight: true })(
    FilterPopupHOC(observer(CustomerDashboard))
);
