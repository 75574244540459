/*    
<summary>
   This file is used to provide initial values or state to the driver Data observables.
   Developer:Ashish Singh, Created Date:04-March-2024
</summary>
*/
import { IAddEditVehicle } from "../../models/response/IVehicleResponse";

export const addEditVehicleState: IAddEditVehicle = {
    RFID: "",
    Description: "",
    RegistrationNo: "",
    RegistrationExpiryDate: "",
    TruckAllocation: "",
    TruckAllocation1: "",
    FuelDeliveryEnabled: false,
    AllocationPerFill: "",
    AllocationPeriod: -1,
    AllocationVolume: "",
    RemainingVolume: "",
    LowerConsumptionLimit: "",
    HigherConsumptionLimit: "",
    CustId2: "",
    CustId4: "",
    CustId5: "",
    CustId6: "",
    BelongToCustomer: false,
    CustomerId: -1,
}