/*    
<summary>
   This file is used to provide initial values or state to the Supplier Module observables.
    Developer:Aashish Singh, Created Date:20-March-2024
</summary>
*/

import { IGetSupplierList } from "../../models/response/ISupplierResponse";

export const getSupplierListState: IGetSupplierList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    SupplierList: [],
    TotalFilteredSuppliers: 0
}