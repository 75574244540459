import React from "react";
import { FormattedMessage } from "react-intl";

interface IProps {
 footerDetails?: string
}

const Footer = (props: IProps) => {
    const {footerDetails}= props;
    return (
        <footer className="text-center">
            <small><FormattedMessage id="Copyright © Rapid ConnectX | All Rights Reserved"/></small>
        </footer>
    );
};

export default Footer;
