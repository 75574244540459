/*    
<summary>
  This functional component "AddUpdateVehicle" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import { compartmentTypes } from '../../../constants/common-constant';
import AddUpdateTruckForm from '../forms/add-edit-truck-forms';
import { IAddEditTruck } from '../../../models/response/ITruckResponse';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateTruck = (props: IProps) => {

  const { truckStore, customerStore } = useStore();
  const {  GetTruckByIdService,  resetAddUpdateTruckState, resetGetTruckDetail,   truckDetailState, getTruckDetail, addUpdateTruckState, AddTruckService, UpdateTruckService} = truckStore;
  // const {allAvailableCustomerOptions} = customerStore;
  const initialValues: IAddEditTruck = getTruckDetail;
  const{id, modalClosed}=props


  const onModalClosed = useCallback(() => {
    resetGetTruckDetail();
    resetAddUpdateTruckState();
     modalClosed();
  },[ resetGetTruckDetail, resetAddUpdateTruckState,modalClosed])
 
  useEffect(() => {
    if (id > 0) {
      GetTruckByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateTruckState.success) {
      if ( id === -1)
        toast.success(formatMessage("added_success"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateTruckState.success]);

  useEffect(() => {
    if (addUpdateTruckState.error) {
        toast.error(formatMessage(addUpdateTruckState.error));
        resetAddUpdateTruckState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateTruckState.error]);

  const onSubmitForm = (values: IAddEditTruck) => {
    if(id>0)
        UpdateTruckService(id, values);
    else
        AddTruckService( id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateTruckForm
        initialValues={initialValues}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={ truckDetailState.inProgress}
        addUpdateInProgress={addUpdateTruckState.inProgress}
        compartmentTypes={compartmentTypes}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateTruck);
