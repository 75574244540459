import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  DATE_FORMAT_BACKEND,
  floatRegex
} from "../../../constants/common-constant";
import { IUser } from "../../../models/IUser";
import {
  IAddCustomerRate
} from "../../../models/response/ICustomerResponse";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikDateFieldMUI from "../../../shared-components/FormikFormHandlers/FormikFormDatePickerMUI";
import { addCustomerRateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/formik-form-input-for-regex-check";
import Label from "../../../shared-components/label/label";
import { observer } from "mobx-react";
import { addCustomerState } from "../../../core/initialState/add-edit-customer-state";
import { getAmountDecimal } from "../../../helpers/localStorages.helper";

interface IProps {
  submitHandler: (data: IAddCustomerRate) => void;
  addUpdateInProgress: boolean;
  actionSuccess: boolean;
}

const AddUpdateCustomerInvoiceForm: React.FC<IProps> = (props: IProps) => {
  const {
    submitHandler,
    addUpdateInProgress,
    actionSuccess
  } = props;
  let decimalValue: number = getAmountDecimal();
  let resetPresForm = () => { };
  const [initialValues, setInitialValues] = useState<IAddCustomerRate>({ ...addCustomerState })
  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  /**
   * This functional component stores the reset form function in the resetPresentForm variable
   */
  const ResettingForm = () => {
    const { resetForm } = useFormikContext();
    resetPresForm = resetForm; // Store the value of resetForm in this variable
    return null;
  };

  useEffect(() => {
    if (actionSuccess) {
      setInitialValues({ ...addCustomerState });
      resetPresForm();
    }
  }, [actionSuccess])

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize
        validationSchema={addCustomerRateSchema(decimalValue)}
        onSubmit={async (values: any) => {
          submitHandler({
            ...values, EffectiveDate:
              new Date(values.EffectiveDate?.$d).toString() !== "Invalid Date"
                ? moment(values.EffectiveDate.$d).format(DATE_FORMAT_BACKEND)
                : values.EffectiveDate
          });
        }}
      >
        {({ values }) => (
          <>
            <Form className="customer-form p-3 user-form pb-0" noValidate>
              <FormObserver />
              <Modal.Body className="pb-0">
                <Row className="position-relative">
                  <Col xs={12} lg={3} md={3} xl="3">
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitprice"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="RateLtr"
                        placeholder="transactions_label_perunitprice"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={3} md={3} xl="3">
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_perunitgallon"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="RateUkg"
                        placeholder="transactions_label_perunitgallon"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  {/* <Col xs={6}>
                    <div className="form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="currency"
                      />
                      <Field
                        data-testid="testCurrencyName"
                        className="form-control"
                        name="CurrencyName"
                        type="text"
                        placeholder="currency"
                        invalidcharacter={[" "]}
                        as={FormikFormInput}
                      />
                    </div>
                  </Col> */}
                  <Col xs={12} lg={2} md={3} xl="2">
                    <div className="form-group vat-error">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_vat_percent"
                      />
                      <Field
                        className="form-control"
                        aria-label="Default select example"
                        name="Vat"
                        placeholder="transactions_label_vat"
                        regex={floatRegex}
                        as={FormikFormInputForRegexCheck}
                      />
                    </div>
                  </Col>
                  <Col xs={12} lg={4} md={3} xl="4">
                    <div className="form-group date-picker-error">
                      <Label
                        className="form-label"
                        required={true}
                        label="label_effectivedate"
                      />
                      <Field name="EffectiveDate"
                        as={FormikDateFieldMUI}
                      // min={id > 0 ? initialValues.StartDate : moment(new Date()).format(DATE_FORMAT_BACKEND)}
                      />
                      {/* <Field
                          data-testid="testEmail"
                          className="form-control"
                          name="StartDate"
                          // type="text"
                          placeholder="start_date"
                          minDate={new Date().setDate(new Date().getDate())}
                          as={FormikFormDatePickerMUI}
                        /> */}
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  pt-0 border-0 mt-1">
                <div className="">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text="add"
                  />
                </div>
              </div>
            </Form>
            <ResettingForm />
          </>
        )}
      </Formik>

    </>
  );
};

export default observer(AddUpdateCustomerInvoiceForm);
