/*    
<summary>
  This functional component "AddUpdateVehicle" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateVehicleForm from '../forms/add-edit-vehicle-form';
import { IAddEditVehicle } from '../../../models/response/IVehicleResponse';
import { allocationPeriodTypes, customerIdList } from '../../../constants/common-constant';
import { getClientLimitSetting } from '../../../helpers/localStorages.helper';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateVehicle = (props: IProps) => {

  const { vehicleStore, customerStore } = useStore();
  const { GetVehicleByIdService, resetAddUpdateVehicleState, resetGetVehicleDetail, vehicleDetailState, getVehicleDetail, addUpdateVehicleState, AddVehicleService, UpdateVehicleService} = vehicleStore;
  const {allAvailableCustomerOptions} = customerStore;
  const initialValues: IAddEditVehicle = getVehicleDetail;
  const{id, modalClosed}=props
  let clientSetting = getClientLimitSetting();


  const onModalClosed = useCallback(() => {
    resetGetVehicleDetail();
    resetAddUpdateVehicleState();
     modalClosed();
  },[resetGetVehicleDetail,resetAddUpdateVehicleState,modalClosed])
 
  useEffect(() => {
    if (id > 0) {
      GetVehicleByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateVehicleState.success) {
      if ( id === -1)
        toast.success(formatMessage("added_success"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateVehicleState.success]);

  // useEffect(() => {
  //   if (vehicleDetailState.error) {
  //     toast.error(formatMessage(vehicleDetailState.error));
  //     resetGetVehicleDetail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [vehicleDetailState.error]);

  useEffect(() => {
    if (addUpdateVehicleState.error) {
        toast.error(formatMessage(addUpdateVehicleState.error));
        resetAddUpdateVehicleState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateVehicleState.error]);

  const onSubmitForm = (values: IAddEditVehicle) => {
    if(id>0)
        UpdateVehicleService(id, values);
    else
        AddVehicleService( id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateVehicleForm
        initialValues={initialValues}
        id={id}
        isLimitSettingEnabled = {clientSetting.EnableLimitSettings}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={vehicleDetailState.inProgress}
        addUpdateInProgress={addUpdateVehicleState.inProgress}
        allocationPeriodTypes={allocationPeriodTypes}
        customerIdList={allAvailableCustomerOptions}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateVehicle);
