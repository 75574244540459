import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../contexts/StoreProvider';
import { formatMessage } from '../../translations/formatMessage';
import { IAddUser, IUser } from '../../models/IUser';
import { ErrorMessage } from '../../constants/error.constant';
import AddUserForm from './Components/AddUserForm';
import { IOption } from '../../models/ICommon';
import { getUserType } from '../../helpers/localStorages.helper';
import UserType from '../../constants/userType.constant';
import { ModuleName } from '../../constants/moduleName.constant';

interface IProps {
  id: number;
  modalClosed: () => void;
  from: string;
}

const AddUser = (props: IProps) => {

  const { userStore, roleStore, customerStore } = useStore();
  const { getUserById, resetAddNewUser, resetGetUserDetail, userDetailError, userDetailInProgress, addNewUserInProgress, getUserDetail, addNewUserSuccess, addNewUserError,
    AddNewUserService, UpdateUserProfileService, UpdateUserService } = userStore;
  const { allAvailableRoleOptions } = roleStore;
  const { allAvailableCustomerOptions } = customerStore;
  const initialValues: IAddUser = getUserDetail;
  const { id, modalClosed } = props
  const userType = getUserType();

  const userTypeOptions: IOption[] = [
    { Id: -1, Name: "please_select", disabled: false },
    { Id: 0, Name: "customer_user", disabled: false },
  ];

  /**Dynamically adding user types in userTypeOptions on the basis of "userType" */
  if ((userType == UserType.SuperAdmin || userType == UserType.CleintAdmin)) {
    userTypeOptions.push({ Id: 1, Name: "customer_admin" });
    userTypeOptions.push({ Id: 2, Name: "client_user" });
    userTypeOptions.push({ Id: 3, Name: "client_admin" });
  }
  if (userType == UserType.CustomerAdmin) {
    userTypeOptions.push({ Id: 1, Name: "customer_admin" });
  }
  // if (props.from !== ModuleName.User) {
  //   userTypeOptions.push({ Id: 1, Name: "customer_admin" });
  //   userTypeOptions.push({ Id: 3, Name: "client_admin" });
  //   userTypeOptions.push({ Id: 4, Name: "super_admin" });
  // }
  // if ((userType == UserType.SuperAdmin)) userTypeOptions.push({ Id: 2, Name: "client_admin" });
  // if ((userType == UserType.SuperAdmin)) userTypeOptions.push({ Id: 3, Name: "customer_admin" });

  const onModalClosed = useCallback(() => {
    resetGetUserDetail();
    resetAddNewUser();
    modalClosed();
  }, [resetGetUserDetail, resetAddNewUser, modalClosed])

  useEffect(() => {
    if (id > 0) {
      getUserById(id);
    }
  }, [getUserById, id]);

  useEffect(() => {
    if (addNewUserSuccess) {
      if (id === -1)
        toast.success(formatMessage("added_success_otp_sent"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addNewUserSuccess, id, onModalClosed]);

  useEffect(() => {
    if (userDetailError) {
      toast.error(formatMessage(userDetailError));
      resetGetUserDetail();
    }
  }, [userDetailError, resetGetUserDetail]);

  useEffect(() => {
    if (addNewUserError) {
      toast.error(formatMessage(addNewUserError));
      // if(addNewUserError === ErrorMessage.NameAlreadyExists) 
      //   toast.error(formatMessage("user_message_usernamealreadyexists"));
      // else if(addNewUserError === ErrorMessage.EmailAlreadyExists) 
      //   toast.error(formatMessage( "message_emailalreadyexists"));
      // else if ( id === -1) 
      //   toast.error(formatMessage( "user_message_add_failed"));
      // else
      //   toast.error(formatMessage( "user_message_update_failed"));
      resetAddNewUser();
    }
  }, [addNewUserError, resetAddNewUser, id]);

  const onSubmitForm = (values: IAddUser) => {
    if (props.from == "User") {
      if (id === -1)
        AddNewUserService(values);
      else
        UpdateUserService(id, values)
    }
    else
      UpdateUserProfileService(id, values);
  }

  useEffect(
    () => {

    }, [allAvailableRoleOptions, allAvailableCustomerOptions]
  )

  return (
    <React.Fragment>
      <AddUserForm
        initialValues={initialValues}
        // initialValues={(userType == UserType.CustomerAdmin ? {...initialValues, UserType: 1, CustomerId: getCustomerId()} : initialValues)}
        id={id}
        from={props.from}
        userType={userType!}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getUserLoading={userDetailInProgress}
        addUserLoading={addNewUserInProgress}
        userTypeOptions={userTypeOptions}
        roleList={allAvailableRoleOptions}
        customerList={allAvailableCustomerOptions}
      />
    </React.Fragment>
  );
};

export default observer(AddUser);
