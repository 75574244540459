/*    
<summary>
   This functional common component provides modal popup that shows stop Alert confirmation message. 
   Developer:Aashish Singh, Created Date: 02-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import LoaderButton from "../Button/LoaderButton";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
import { formatMessage } from "../../translations/formatMessage";

interface Props {
  modalSubmit: () => void;
  modalClosed: () => void;
  isLoading: boolean;
  title: string;
  message: string;
  dialogClassName: string;
}

const ConfirmPopup: React.FC<Props> = (props) => {

  const [isConfirmButtonActive, setConfirmButtonActive] =
    useState<boolean>(true);

  const handleComfirmText = (event: any) => {
    if (event.target.value == "delete") {
      setConfirmButtonActive(false);
    } else {
      setConfirmButtonActive(true);
    }
  };

  return (
    <Modal
      className="add-customer-modal add-user-modal delete-popup"
      dialogClassName={props.dialogClassName}
      show={true}
      centered
    >
      <Modal.Header className="border-0">
        <Modal.Title className="fs-5 ps-3">
          <FormattedMessage id={props.title} />
        </Modal.Title>
        <div onClick={props.modalClosed} className="icons8-close">
          <span className="material-symbols-outlined fs-5 text-dark">
            close
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <div className="bootbox-body text-center p-3">
          <p className="fs-6 mb-3 alert"> <span className="material-symbols-outlined">
            error
          </span>{formatMessage(props.message)}{" "}</p>

          <div className="text-center">
            <input
              type="text"
              className="form-control text-center mx-auto w-50 mt-2"
              placeholder="delete"
              onChange={handleComfirmText}
            />
          </div>
          <p className="mt-3 small m-0">
            <FormattedMessage id={"delete_confirmation"} />
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 m-auto pb-4">
        <LoaderButton
          type="submit"
          disabled={isConfirmButtonActive || props.isLoading}
          onClick={props.modalSubmit}
          isLoading={props.isLoading}
          text="button_confirm"
        />
        <Button
          variant="secondary"
          onClick={props.modalClosed}
          disabled={props.isLoading}
          className="btnOkSize btn-secondary"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmPopup;
