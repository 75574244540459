import React from "react";
import ILogin from "../../../models/ILogin";
import { Field, Form, Formik } from "formik";
import { loginState } from "../../../core/initialState/login.state";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormPasswordField from "../../../shared-components/FormikFormHandlers/FormikFormPasswordField";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import RoutesConstants from "../../../constants/routes.constant";
import { LoginValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
interface IProps {
  loginHandler: (data: ILogin) => void;
  isLoading: boolean;
}

const LoginForm = (props: IProps) => {
  return (
    <>
      <Formik
        initialValues={loginState}
        validationSchema={LoginValidateSchema}
        onSubmit={async (values) => {
          props.loginHandler(values);
        }}
      >
        {({ values }) => (
          <Form noValidate className="w-100 p-4">
            <p className="fs-1 mb-0 fw-bold lh-sm"> <FormattedMessage id="login" /></p>
            <p className="fs-4 mb-4">Welcome to Secure Fuel Fill</p>
            <div className="form-group mb-4">
              <Field
                placeholder="E-mail"
                name="Email"
                invalidcharacter={[" "]}
                validcharacter={[
                  "-",
                  "_",
                  "[",
                  "]",
                  "(",
                  ")",
                  ":",
                  "#",
                  "@",
                  ".",
                ]}
                type="text"
                tolowercase={+true}
                as={FormikFormInput}
              />

              <div className="iconInputBox">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  height="16"
                  width="16"
                  viewBox="0 0 512 512"
                >
                  <g>
                    <g>
                      <path
                        d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
    C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
    c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
    h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
    c59.551,0,108,48.448,108,108S315.551,256,256,256z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
            </div>{" "}
            <div className="form-group">
              <Field
                placeholder="Password"
                dataTestId="testPassword"
                name="Password"
                type="password"
                autoComplete="new-password"
                validcharacter={[
                  "?",
                  ">",
                  "<",
                  "'",
                  ":",
                  ";",
                  ",",
                  "+",
                  "-",
                  "(",
                  ")",
                  "*",
                  "&",
                  "^",
                  "%",
                  "$",
                  "#",
                  "{",
                  "[",
                  "]",
                  "}",
                  "|",
                  "/",
                  "=",
                  "_",
                  ".",
                ]}
                as={FormikFormPasswordField}
              />
            </div>
            {/* <div className="form-group forgot-password">
              <NavLink to={RoutesConstants.ForgotPassword} state={{ Email: values.Email }} className="mb-0 d-flex justify-content-end text-danger text-decoration-none" aria-current="page">
                <FormattedMessage id="Forgot Password?" />
              </NavLink>
            </div>
            <div className="form-group verify-email">
              <NavLink to={RoutesConstants.VerifyEmail} state={{ Email: values.Email }} className="mb-0 d-flex justify-content-end text-danger text-decoration-none" aria-current="page">
                <FormattedMessage id="Verify Email" />
              </NavLink>
            </div> */}
            <div className="form-group">
              <div className="d-grid">
                <LoaderButton
                  type="submit"
                  className="shadow-sm mt-4 btn btn-primary btn-lg text-uppercase glow-on-hover fw-bolder"
                  isLoading={props.isLoading}
                  disabled={props.isLoading}
                  text="Sign In"
                />
              </div>
              <div className="align-items-center justify-content-center flex-wrap d-flex">
                <div className="forgetPas">
                  <p>
                    <NavLink
                      to={RoutesConstants.ForgotPassword}
                      state={{ Email: values.Email }}
                      className="mb-0 d-flex justify-content-end text-danger text-decoration-none"
                      aria-current="page"
                    >
                      <FormattedMessage id="Forgot Password?" />
                    </NavLink>
                  </p>
                </div>
                <div className="mx-4 forgetPas">|</div>
                <div className="forgetPas">
                  <p>
                    <NavLink
                      to={RoutesConstants.VerifyEmail}
                      state={{ Email: values.Email }}
                      className="mb-0 d-flex justify-content-end text-danger text-decoration-none"
                      aria-current="page"
                    >
                      <FormattedMessage id="Verify Email" />
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="account-footer mt-4 text-center fw-semi-bold"><p className="fs-6">Need to activate your pre-setup account? <a>Click here</a></p></div> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
