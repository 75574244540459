
/**
 * This interface maps the DashboardData properties.
 */
export type lineChartOptions = {
    title: {
        text: string;
        fontSize: number;
        fontFamily: string;
    };
    colorSet?:any;
    axisX?: any,
    axisY?: any,
    toolTip?: any,
    data: DataObj[],
    height: number,
    subtitles?:any,
    //width: number,
    zoomEnabled?: boolean,
    interactivityEnabled?: boolean,
    animationEnabled?: boolean,
    exportEnabled?: boolean,
    exportFileName?: string,
    culture?: string
    theme?: string
    backgroundColor?: string
}

export type Map<T> = {
    [id: number]: T;
}

export type DataPoint = { x?: any, y?: any, label:string, name?:string  };

export type DataObj = {
    type: string;
    id: string;
    showInLegend?: boolean;
    toolTipContent?: string;
    name?: string;
    color?: string;
    xValueFormatString?: string;
    yValueFormatString?: string;
    startAngle?: number;
    indexLabel?: string;
    indexLabelFontSize?: number;
    markerSize?: number;
    fillOpacity?: number;
    dataPoints: Array<DataPoint>;
}

export const initOptions: lineChartOptions = {
    title: {
        text: "",
        fontSize: 15,
        fontFamily: "sans-serif"
    },
    toolTip:{   
        content: "{label} <br/>{y}"  
    },
    axisX: {
        title: "",
        // valueFormatString: "DD-MMM",
        interval: 4,
        intervalType: "hours",
        labelFontSize: 13,
        labelFontFamily: "sans-serif",
        labelFontColor: "#000",
        titleFontSize: 13,
        titleFontFamily: "sans-serif",
        lineThickness: 2,
        lineColor: "#eee",
        tickColor: "#eee",
        tickThickness : 1,
        gridThickness: 0,
    },
    axisY: {
        title: "Data",
        titleFontColor: "#000",
        labelFontSize: 13,
        labelFontFamily: "sans-serif",
        labelFontColor: "#000",
        titleFontSize: 13,
        titleFontFamily: "sans-serif",
        gridColor: "#eee",
        gridThickness: 0,
        lineThickness: 2,
        lineColor: "#eee",
        tickColor: "#eee",
        tickThickness : 1,
        minimum:0,
    },
    height: 280,
    //width: 800,
    data: [
        {
            // xValueFormatString:"YYYY-MM-DD HH:mm:ss",
            type: "splineArea",
            id: "0_0",
            fillOpacity:0.2,
            markerSize: 5,
            dataPoints: [],
        },
        // {
        //     yValueFormatString:"#0.###### GB",
        //     type: "splineArea",
		//     color: "#1fe8b2",
        //     id: "0_0",
        //     markerSize: 5,
        //     dataPoints: [],
        // },
    ],
    theme: "light2",
    backgroundColor: "transparent",
    interactivityEnabled: true,
    animationEnabled: true,
    exportEnabled: false,
    zoomEnabled: true,
    culture: "en"
};

export const pieChartConfig: lineChartOptions = {
    title: {
        text: "",
        fontSize: 16,
        fontFamily: "oswald",
   
    },
    axisX:{
        title: "Red Color labels",
        labelFontColor: "red"
      },
    colorSet: "doughnut",
    toolTip: {
        shared: true,
    },
    height: 280,
    subtitles: [{
        text: "100% Free",
        verticalAlign: "center",
        fontSize: 22,
        dockInsidePlotArea: true
    }],
    data: [{
		type: "doughnut",
        id: "0_0",
		startAngle: 240,
		yValueFormatString: "##0.##\" GB\"",
		indexLabel: "{name} {y}",
        indexLabelFontSize: 13,
        toolTipContent: "{name}: <strong>{y}</strong>",
        showInLegend: true,
		dataPoints: [
            {label:"Disk Free Space", y: 100, name: "Disk Free Space"},
			{label:"Used Disk Space", y: 0, name: "Memory Used"},
		]
	}],
    zoomEnabled: true,
    interactivityEnabled: true,
    animationEnabled: true,
    exportEnabled: false,
    culture: "en",
    backgroundColor: "transparent",
};
