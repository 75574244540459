import React from "react";
import { injectIntl } from "react-intl";
import CanvasJSReact from "../../assets/canvasjs.react";
import "./line-chart-styles.scss";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet("doughnut",
                [
                "#f39810",
                "#c6c6c9",              
                ]);

CanvasJS.addCultureInfo("jp", {
  saveJPGText: "画像保存（JPEG) ",
  savePNGText: "画像保存（PNG）",
  printText: "印刷",
  menuText:"オプション",
  resetText: "リセット",
  panText: "スクロール"
});

const LineChart = (props: any) => {
  return (
    <div className="chart-container">
      <div key={props.index}><CanvasJSChart options={props.options}
      /* onRef = {ref => this.chart = ref} */
      /></div>
    </div>
  );
};

export default injectIntl(LineChart);