/*    
<summary>
   This class component is all about Managing role data functionality.
   Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { IAddEditRole, IRole, IRoleList, IGetRoleList } from "../../models/response/IRoleResponse";
import { demoRole } from "./data";
import moment from "moment";
import { DATE_TIME_FORMAT_FILENAME } from "../../constants/common-constant";
import { getClientId } from "../../helpers/localStorages.helper";
import { getRoleListState } from "../initialState/get-role-list-state";
import { addEditRoleState as initialState } from "../initialState/add-edit-role-state";
import { permissionType } from "../../constants/enums/permissionTypeEnum";
import { formatMessage } from "../../translations/formatMessage";
import { IRoleState } from "../../models/state/IRoleState";
import { ICommonState } from "../../models/state/ICommonState";

export class RoleStore implements IRoleState,ICommonState {
    inProgress = false;
    error = '';
    roleList: IGetRoleList = getRoleListState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }
    RolesData: Array<IRole> = [];

    addUpdateRoleState : IObservableInitialState = {...this.initialStateValue};
    DeleteRoleState : IObservableInitialState = {...this.initialStateValue};
    
    roleDetail: IRole | undefined = undefined;
    roleDetailState : IObservableInitialState = {...this.initialStateValue};

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            roleList: observable,
            addUpdateRoleState: observable,
            DeleteRoleState: observable,
            roleDetail: observable,
            roleDetailState: observable,
            RolesData: observable,
            GetAllRolesService: action,
            GetRoleListService: action,
            GetRoleByIdService: action,
            AddRoleService: action,
            UpdateRoleService: action,
            DeleteRoleService: action,
            reset: action,
            resetStore: action,
            resetGetRoleDetail: action,
            resetAddUpdateRoleState: action,
            resetDeleteRoleState: action,
            getRoleDetail: computed,
            getRoleList: computed,
            allAvailableRoleOptions: computed,
        });
    }

    GetAllRolesService = () => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetAllRole}?clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<any>) => {
                this.RolesData = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get allAvailableRoleOptions(): IOption[] {
        const options: IOption[] = [
            {
                Id: -1,
                Name: "please_select",
            }
        ];
        if (this.RolesData && this.RolesData?.length > 0)
            this.RolesData.map((role) => {
                options.push({
                    Id: role.Id,
                    Name: role.RoleName,
                })
            })
        return options;
    }

    GetRoleListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, searchText: string) => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        // const url = UrlConstants.User + '?pageNo=' + currentPage + '&pageSize=' + pagerSize + '&orderBy=' + orderBy + '&column=' + column + '&searchText=' + searchText;
        // const url = UrlConstants.GetRoleList;
        const url = `${UrlConstants.GetRoleList}?clientId=${clientIdFromLocalStorage}`
        // this.roleList = demoRole;
        // this.inProgress = false;
        return services.get(url)
            .then((response:IApiResponse<IApiSuccessResponse<any>>) => {
                this.roleList = {...getRoleListState, Roles: response.data.Data};
            })
            .catch((err:string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getRoleList(): IRoleList[] {
        if (this.roleList?.Roles && this.roleList.Roles?.length > 0)
        {
            return this.roleList.Roles.map((role:IRole) => {
                let IsDelete:boolean = true;
                let IsEdit:boolean = true;
                return {
                    ...role,
                    DriverManagementPermission : formatMessage(role.DriverManagementPermission),
                    VehicleManagementPermission : formatMessage(role.VehicleManagementPermission),
                    TankManagementPermission : formatMessage(role.TankManagementPermission),
                    TicketManagementPermission : formatMessage(role.TicketManagementPermission),
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                }
            })
        }
        return [];
    }

    GetRoleByIdService = (id: number) => {
        this.roleDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetRoleById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        // const tempDetail = demoRole.Roles.filter((d:IRole)=>d.Id === id);
        // this.roleDetail = tempDetail.length > 0 ? {...tempDetail[0]} : undefined;
        // this.roleDetailState.inProgress = false;
        return services.get(url)
            .then((response:IApiResponse<IApiSuccessResponse<any>>) => {
                this.roleDetail = response.data.Data;
            })
            .catch((err:string) => {
                this.roleDetailState.error = err;
            }).finally(action(() => { this.roleDetailState.inProgress = false; }));
    }

    get getRoleDetail(): IAddEditRole {
        if(this.roleDetail) {
            const temp = Object.keys(permissionType).indexOf(this.roleDetail.DriverManagementPermission);
            return {
                ...this.roleDetail,
                DriverManagementPermission : Object.keys(permissionType).indexOf(this.roleDetail.DriverManagementPermission),
                VehicleManagementPermission : Object.keys(permissionType).indexOf(this.roleDetail.VehicleManagementPermission),
                TankManagementPermission : Object.keys(permissionType).indexOf(this.roleDetail.TankManagementPermission),
                TicketManagementPermission : Object.keys(permissionType).indexOf(this.roleDetail.TicketManagementPermission),
                };
        }
        return initialState;
    } 

    resetGetRoleDetail = () => {
        this.roleDetail = undefined;
        this.roleDetailState = {...this.initialStateValue};
    }

    AddRoleService = (id: number, data: IAddEditRole) => {
        this.addUpdateRoleState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let requestPayload: any ={
            ...data, ClientId: clientIdFromLocalStorage
        }
        let  url = `${UrlConstants.AddRole}?clientId=${clientIdFromLocalStorage}`;
        const clientId = getClientId();
        return services.post(url, requestPayload)
            .then(() => {
                this.addUpdateRoleState.success = true;
            })
            .catch((err:string) => {
                this.addUpdateRoleState.error = err;
            })
            .finally(action(() => { this.addUpdateRoleState.inProgress = false; }));
    }

    UpdateRoleService = (id: number, data: IAddEditRole) => {
        this.addUpdateRoleState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateRole}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.put(url, {...data})
            .then(() => {
                this.addUpdateRoleState.success = true;
            })
            .catch((err:string) => {
                this.addUpdateRoleState.error = err;
            })
            .finally(action(() => { this.addUpdateRoleState.inProgress = false; }));
    }

    resetAddUpdateRoleState = () =>{
        this.addUpdateRoleState = {...this.initialStateValue}
    }

    DeleteRoleService = (id: number) => {
        this.DeleteRoleState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteRole}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.DeleteRoleState.success = true;
            })
            .catch((err:string) => {
                this.DeleteRoleState.error = err;
            })
            .finally(action(() => { this.DeleteRoleState.inProgress = false; }));
    }

    resetDeleteRoleState = () =>{
        this.DeleteRoleState = {...this.initialStateValue}
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }
    
    resetError = () =>{
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.roleList = getRoleListState;

        this.roleDetail = undefined;
        this.roleDetailState ={...this.initialStateValue }

        this.addUpdateRoleState ={...this.initialStateValue }
        
        this.DeleteRoleState ={...this.initialStateValue }
    }
}

export default new RoleStore();