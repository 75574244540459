import React, { useCallback, useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination';

interface IProps {
    totalPages: number;
    pageLimit: number;
    onPageChanged: (currentPage: number) => void;
    disablePagination: boolean;
}

const ApplicationPagger = (props: IProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [inputCurrentPage, setInputCurrentPage] = useState<number|string>(1);

    const { pageLimit, onPageChanged, disablePagination } = props;
    let { totalPages } = props;

    if (totalPages === 0) {
        totalPages = 1;
    }
    const gotoPage = useCallback((page: number) => {
        const changedPage = Math.max(1, Math.min(page, totalPages));
        setCurrentPage(changedPage);
    }, [totalPages])

    useEffect(() => {
        onPageChanged(1);
        setCurrentPage(1);
        setInputCurrentPage(1);
    }, [pageLimit, onPageChanged])

    useEffect(() => {
        if (currentPage) {
            onPageChanged(currentPage);
            setInputCurrentPage(currentPage)
        }
    }, [currentPage, onPageChanged])

    useEffect(() => {
        gotoPage(1);
        setInputCurrentPage(1);
    }, [gotoPage]);

    const handleCurrentPage = () => {
        if(typeof inputCurrentPage === 'number') gotoPage(inputCurrentPage);
    }

    const handleMoveFirst = (e: React.MouseEvent) => {
        e.preventDefault();
        gotoPage(1);
    }

    const handleMoveLast = (e: React.MouseEvent) => {
        e.preventDefault();
        gotoPage(totalPages);
    }

    const handleMoveNext = (e: React.MouseEvent) => {
        e.preventDefault();
        gotoPage(currentPage + 1);
    }

    const handleMovePrev = (e: React.MouseEvent) => {
        e.preventDefault();
        gotoPage(currentPage - 1);
    }

    const onInputPageChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === '') setInputCurrentPage('');
        const numRegex = /^[0-9\b]+$/;
        if (numRegex.test(e.target.value)) {
            gotoPage(Number(e.target.value))
        }
    }

    const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "")
            setInputCurrentPage(currentPage);
    }

    return (
            <Pagination className='mb-0'>

                <Pagination.First as="span" onClick={handleMoveFirst} disabled={disablePagination} />
                <Pagination.Prev as="span" onClick={handleMovePrev} disabled={disablePagination} />
                <div className='customPages'>
                    <span className='currentPage'>
                        <input type="text" name="currentPage" value={inputCurrentPage} className='pageInput bg-transparent' onChange={onInputPageChanged} onBlur={inputChanged} />
                        <span className={(parseInt(inputCurrentPage.toString()) <= totalPages && parseInt(inputCurrentPage.toString()) > 0) ? "searchIcon" : "searchIcon disabled-class"} onClick={handleCurrentPage}>
                </span>
                    </span>
                    <small className='total-pages'> / {totalPages}</small>
                </div>
                <Pagination.Next as="span" data-test-id="nextPage" onClick={handleMoveNext} disabled={disablePagination} />
                <Pagination.Last as="span" onClick={handleMoveLast} disabled={disablePagination} />
           
            </Pagination>
    )
}

export default ApplicationPagger;

// <Pagination.Item key={1} data-page={1}>
//     {1}
// </Pagination.Item>
// <Pagination.Item key={2} data-page={2}>
//     {2}
// </Pagination.Item>