import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import RoutesConstants from '../constants/routes.constant';
import { useStore } from './StoreProvider';

interface IProps {
  children: React.ReactNode
}

const AuthProvider = (props: IProps) => {
  const navigate = useNavigate();
  const { languageStore, authStore } = useStore();
  const { isAuthenticated } = authStore;
  const getToken: any = localStorage.getItem("secure_fuel_token");

  useEffect(() => {
    if (isAuthenticated || getToken) {
      languageStore.updateLanguage();
    }
    else if(!isAuthenticated || !getToken){
      navigate(RoutesConstants.Login);
    }
  }, [getToken, isAuthenticated, navigate, languageStore]);

  return (
    <React.Fragment>
      {isAuthenticated || getToken ? props.children : <Navigate to={RoutesConstants.Login} />}
    </React.Fragment>
  )
}

export default observer(AuthProvider);