/*    
<summary>
  This functional component "AddUpdateClient" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateClientForm from '../forms/add-edit-client-form';
import { IAddEditClient } from '../../../models/response/IClientResponse';

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateClient = (props: IProps) => {

  const { clientStore } = useStore();
  const { GetClientByIdService, resetAddUpdateClientState, resetGetClientDetail, clientDetailState, getClientDetail, addUpdateClientState, AddClientService, UpdateClientService} = clientStore;
  const initialValues: IAddEditClient = getClientDetail;
  const{id, modalClosed}=props

  const onModalClosed = useCallback(() => {
    resetGetClientDetail();
    resetAddUpdateClientState();
    modalClosed();
  },[resetGetClientDetail,resetAddUpdateClientState,modalClosed])
 
  useEffect(() => {
    if (id > 0) {
      GetClientByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateClientState.success) {
      if ( id === -1)
        toast.success(formatMessage("added_success_otp_sent"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateClientState.success]);

  // useEffect(() => {
  //   if (clientDetailState.error) {
  //     toast.error(formatMessage(clientDetailState.error));
  //     // resetGetClientDetail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clientDetailState.error]);

  useEffect(() => {
    if (addUpdateClientState.error) {
        toast.error(formatMessage(addUpdateClientState.error));
        resetAddUpdateClientState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateClientState.error]);

  const onSubmitForm = (values: IAddEditClient) => {
    if(id>0)
        UpdateClientService(id, values);
    else
        AddClientService( id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateClientForm
        initialValues={initialValues}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={clientDetailState.inProgress}
        addUpdateInProgress={addUpdateClientState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateClient);
