/*    
<summary>
   This file is used to provide initial values or state to the Truck Module observables.
    Developer:Aashish Singh, Created Date:04-April-2024
</summary>
*/

import { IGetTruckList } from "../../models/response/ITruckResponse";

export const getTruckListState: IGetTruckList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Trucks: [],
    TotalTrucks: 0
}