import { Tooltip } from "react-tooltip";
import { getMonthName } from "../constants/common-constant";
import { formatMessage } from "../translations/formatMessage";


export const formatDateToText = (date: Date) => {
    const day = date.getDate();
    const monthName = getMonthName(date.getMonth());
    const year = date.getFullYear();
    const superScriptText = (day%10 === 1 ? "st" : (day%10 == 2 ? "nd" : (day%10 == 3 ? "rd" : "th"))); 
    return(
        <>
          {day}<sup>{superScriptText}</sup> {monthName}, {year}
        </>
    )
  }

interface IInfoTooltip{
  Info:string;
}
export const InfoTooltip = (props: IInfoTooltip) => {
    return(
      <>
        <div className="d-inline tooltip-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle ml-2"
            viewBox="0 0 16 16"
            data-tooltip-id="my-tooltip"
            data-tooltip-html={formatMessage(props.Info)}
            data-tooltip-variant="dark"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        <Tooltip id="my-tooltip" place="top" />
        </div>
      </>
    )
  }
