import React, { useState } from 'react';

const ViewDetailsPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showViewDetailsPopup,setShowViewDetailsPopup] = useState<boolean>(false);

    const viewDetailsToggleHandler = () => {
        setShowViewDetailsPopup(!showViewDetailsPopup);
    }
	
	return <Component showViewDetailsPopup={showViewDetailsPopup} viewDetailsToggleHandler={viewDetailsToggleHandler} {...props} />;
};

export default ViewDetailsPopupHOC;