import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/StoreProvider";
// import UserType from "../../constants/userType.constant";
// import { getUserType } from "../../helpers/localStorages.helper";
import MainTabsView from "../../modules/TabControl/TabsView";
import English from "../../assets/Images/english.svg";
import Arab from "../../assets/Images/united-arab-emirates.svg";
import { Col, Dropdown, Form, ListGroup, Nav, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import SecureFuelLogo from "../../assets/Images/logo1.png";
import "./Header.scss";
import DateRangeFilter from "../../modules/Filter/Filter";
import { UserTypeSuperAdmin } from "../../constants/common-constant";
import LogoutPopup from "../../modules/Auth/LogoutPopup/LogoutPopup";
import ProfilePage from "../Profile/Profile";
import ChangePasswordForm from "../change-password/change-password";
import {
  getProfileImage,
  getUserId,
  getUserName,
  getUserType,
  getVersion,
} from "../../helpers/localStorages.helper";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { addEditChangePasswordState } from "../../core/initialState/add-edit-change-password-state";
import { IUpdatePassword } from "../../models/form/IChangePassword";
import { observer } from "mobx-react";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import AddUser from "../../modules/User/AddUser";
import AboutSupportPopupHOC from "./hoc/about-support-popup-hoc";
import AboutSupport from "./component/about-support";
import UserType from "../../constants/userType.constant";
import Avatar from "../../assets/Images/defaultProfile.png";
import Avatar2 from "../../assets/Images/defaultImages2.png";
import { getUserTypeStyleClass } from "../../constants/common-constant";

interface IProps {
  headerText: string;
  filterButton?: React.JSX.Element;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showAboutSupportPopup: boolean;
  aboutSupportToggleHandler: () => void;
}

const Header = (props: IProps) => {
  const { userStore } = useStore();
  const {
    changePasswordState,
    reseChangePasswordState,
    UpdateLoggedInUserPasswordService,
    resetGetUserDetail,
    updatUserProfileState,
    resetUpdateUserProfileState,
    getProfileImageFromStore,
    getProfileUserNameFromStore,
  } = userStore;
  const {
    showEditPopup,
    editToggleHandler,
    showAboutSupportPopup,
    aboutSupportToggleHandler,
  } = props;
  const [showPopup, setShowPopup] = useState(false);
  const openLogoutPopup = () => {
    setShowPopup((showPopup) => !showPopup);
  };

  const [showProfilePopup, setProfilePopup] = useState(false);
  const openProfilePopup = () => {
    setProfilePopup((showProfilePopup) => !showProfilePopup);
  };

  const [showChangePasswordPopup, setChangePasswordPopup] = useState(false);
  const openChangePasswordPopup = () => {
    setChangePasswordPopup(
      (showChangePasswordPopup) => !showChangePasswordPopup
    );
  };

  const userType = getUserType() != null ? getUserType() : "";
  const profileImageIcon =
    getProfileImage() === "" || getProfileImage() == null
      ? Avatar
      : getProfileImage() ?? Avatar;
  const profileImageMenuIcon =
    getProfileImage() === "" || getProfileImage() == null
      ? Avatar2
      : getProfileImage() ?? Avatar2;
  const userIdFromLocalStorage: number =
    getUserId() != null ? parseInt(getUserId()!) : -1;

  const changePasswordHandler = (data: IUpdatePassword) => {
    UpdateLoggedInUserPasswordService(data);
  };

  const openEditProfilePopupHandler = () => {
    setProfilePopup(false);
    editToggleHandler();
  };

  const closeEditPopupHandler = () => {
    resetGetUserDetail();
    // setProfilePopup(false);
    editToggleHandler();
  };

  useEffect(() => {
    if (changePasswordState.success) {
      toast.success(formatMessage("updated_success"));
      reseChangePasswordState();
      setChangePasswordPopup(false);
    }
  }, [changePasswordState.success]);

  useEffect(() => {
    if (updatUserProfileState.success) {
      toast.success(formatMessage("updated_success"));
      // resetGetUserDetail();
      resetUpdateUserProfileState();
      closeEditPopupHandler();
      // openEditProfilePopupHandler();
    }
  }, [updatUserProfileState.success]);

  return (
    <React.Fragment>
      {showPopup && <LogoutPopup modalClosed={openLogoutPopup} />}

      {showAboutSupportPopup && (
        <AboutSupport modalClosed={aboutSupportToggleHandler} />
      )}

      {showProfilePopup && (
        <ProfilePage
          id={userIdFromLocalStorage}
          modalClosed={openProfilePopup}
          openEditProfilePopupHandler={openEditProfilePopupHandler}
        />
      )}

      {showEditPopup && (
        <AddUser
          id={userIdFromLocalStorage}
          from="Header"
          modalClosed={closeEditPopupHandler}
        />
      )}

      {showChangePasswordPopup && (
        <ChangePasswordForm
          initialValues={addEditChangePasswordState}
          submitHandler={changePasswordHandler}
          onModalClosed={openChangePasswordPopup}
          actionInProgress={changePasswordState.inProgress}
        />
      )}

      <section>
        <div className="header fixed-top">
          <Container fluid className="p-0">
            <Navbar className="ps-3 p-0">
              <Navbar.Brand href="./dashboard" className="p-0 logo text-white">
                <img src={SecureFuelLogo} alt="logo" />
                {/* <span className="logo_text">  Secure Fuel Fill </span> */}
              </Navbar.Brand>
              <Navbar />
              <Navbar.Collapse className="justify-content-end d-flex flex-row align-items-center">
                <div className="welcome-area text-white pe-2 pt-1">
                  <p className="mb-0">Welcome</p>
                  <a
                    className="welcome-text fw-semi-bold"
                    title={getProfileUserNameFromStore}
                  >
                    {getProfileUserNameFromStore}
                  </a>
                </div>
                <Navbar.Text className="text-white p-0 profile">
                  <Dropdown className="border-0 p-0">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className="shadow-none profile-dropdown py-1 p-0 m-0"
                    >
                      {/* <svg
                      version="1.1"
                      x="0px"
                      y="0px"
                      fill="white"
                      width="24"
                      height="24"
                      viewBox="0 0 45.532 45.532"
                    >
                      <g>
                        <path
                          d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
		S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
		c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
		c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
		c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg> */}
                      <small>
                        {" "}
                        <div className="avatar" title="Profile">
                          <img
                            src={profileImageIcon}
                            // src={getProfileImageFromStore ? getProfileImageFromStore : profileImageIcon}
                            alt="Avatar"
                            className="h-30px w-30px rounded"
                          />
                        </div>
                      </small>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-end profile-area border-0 p-0">
                      <div className="d-flex align-item-center profile-header">
                        <div className="d-flex align-items-start">
                          <div className="avatar avatar-50px me-3">
                            <img
                              src={profileImageMenuIcon}
                              alt="Avatar"
                              className="h-50px w-50px rounded"
                            />
                          </div>

                          <div className="w-100">
                            <span className="fs-5 fw-semi-bold mt-0 profile-name">
                              {getProfileUserNameFromStore}
                            </span>
                            <br />
                            <span
                              className={`badge badge-pill badge-primary ${getUserTypeStyleClass(
                                userType ?? ""
                              )}`}
                            >
                              {formatMessage(userType!)}{" "}
                            </span>
                            <br />
                            {/* <small className="version w-100 fw-semi-bold"> SecureFuelFill</small><br/>
                            <small className="version w-100">   Version: {getVersion()}</small> */}
                          </div>
                        </div>
                      </div>
                      {userType != UserType.SuperAdmin && (
                        <Dropdown.Item
                          onClick={openProfilePopup}
                          className="d-flex align-items-center"
                        >
                          <svg
                            fill="none"
                            height="18"
                            viewBox="0 0 24 24"
                            width="18"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="rgb(0,0,0)">
                              <path d="m12 12.75c-3.17 0-5.75-2.58-5.75-5.75s2.58-5.75 5.75-5.75 5.75 2.58 5.75 5.75-2.58 5.75-5.75 5.75zm0-10c-2.34 0-4.25 1.91-4.25 4.25s1.91 4.25 4.25 4.25 4.25-1.91 4.25-4.25-1.91-4.25-4.25-4.25z" />
                              <path d="m20.5901 22.75c-.41 0-.75-.34-.75-.75 0-3.45-3.5199-6.25-7.8399-6.25-4.32005 0-7.84004 2.8-7.84004 6.25 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-4.27 4.18999-7.75 9.34004-7.75 5.15 0 9.3399 3.48 9.3399 7.75 0 .41-.34.75-.75.75z" />
                            </g>
                          </svg>
                          <span className="ps-3"> Profile</span>
                        </Dropdown.Item>
                      )}
                      {/* <Dropdown.Item
                      onClick={() => {}}
                      className="language d-flex justify-content-between mt-3"
                    >
                      Language{" "}
                      <small>
                        English
                        <img src={English} alt="english" className="ms-2" />
                      </small>
                    </Dropdown.Item> */}

                      <Dropdown.Item
                        className="d-flex align-items-center"
                        onClick={aboutSupportToggleHandler}
                      >
                        {" "}
                        <svg
                          version="1.1"
                          height={18}
                          width={18}
                          x="0px"
                          y="0px"
                          viewBox="0 0 422.139 422.139"
                        >
                          <g>
                            <g>
                              <path
                                d="M363.631,174.498h-1.045v-25.6C362.586,66.664,295.923,0,213.688,0S64.79,66.664,64.79,148.898v25.6h-6.269
			c-22.988,0-40.751,20.375-40.751,43.886v65.306c-0.579,22.787,17.425,41.729,40.212,42.308c0.18,0.005,0.359,0.008,0.539,0.01
			h38.661c5.476-0.257,9.707-4.906,9.449-10.382c-0.009-0.197-0.024-0.394-0.045-0.59v-128c0-6.269-3.657-12.539-9.404-12.539
			H85.688v-25.6c0-70.692,57.308-128,128-128s128,57.308,128,128v25.6h-11.494c-5.747,0-9.404,6.269-9.404,12.539v128
			c-0.583,5.451,3.363,10.343,8.814,10.926c0.196,0.021,0.393,0.036,0.59,0.045h12.016l-1.045,1.567
			c-15.677,20.835-40.277,33.038-66.351,32.914c-5.708-27.989-33.026-46.052-61.015-40.343
			c-23.935,4.881-41.192,25.843-41.385,50.27c0.286,28.65,23.594,51.724,52.245,51.722c14.183-0.23,27.702-6.05,37.616-16.196
			c6.689-6.85,11.072-15.617,12.539-25.078c32.652,0.124,63.445-15.176,83.069-41.273l9.927-14.629
			c22.465-1.567,36.571-15.673,36.571-36.049v-65.306C404.382,201.143,387.664,174.498,363.631,174.498z M85.688,305.11H58.521
			c-11.25-0.274-20.148-9.615-19.874-20.865c0.005-0.185,0.012-0.37,0.021-0.556v-65.306c0-12.016,8.359-22.988,19.853-22.988
			h27.167V305.11z M247.125,391.314c-5.79,6.278-13.925,9.873-22.465,9.927c-16.998-0.27-30.792-13.834-31.347-30.825
			c-0.007-17.024,13.788-30.83,30.812-30.837c17.024-0.007,30.83,13.788,30.837,30.812c0,0.008,0,0.017,0,0.025
			C255.397,378.173,252.553,385.756,247.125,391.314z M383.484,288.914c0,14.106-13.584,16.196-19.853,16.196h-21.943V195.396
			h21.943c11.494,0,19.853,16.196,19.853,28.212V288.914z"
                              />
                            </g>
                          </g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                          <g></g>
                        </svg>
                        <span className="ps-3">
                          {" "}
                          <FormattedMessage id="about_support_title" />
                        </span>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={openChangePasswordPopup}
                        className="p-3   d-flex flex-row align-items-center"
                      >
                        {" "}
                        <svg
                          id="Layer_1"
                          height="18"
                          viewBox="0 0 128 128"
                          width="18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="rgb(0,0,0)">
                            <path d="m107.683 55.056c-3.133-3.132-7.298-4.856-11.727-4.856h-1.2v-16.484c0-16.838-13.698-30.536-30.535-30.536h-1.129c-16.837 0-30.536 13.698-30.536 30.536v16.483h-1.2c-4.429 0-8.594 1.725-11.727 4.857-3.132 3.133-4.857 7.298-4.857 11.728v41.452c0 4.43 1.725 8.595 4.857 11.726 3.132 3.133 7.297 4.858 11.727 4.858h64.6c4.43 0 8.595-1.725 11.728-4.858 3.132-3.132 4.857-7.297 4.857-11.726v-41.452c0-4.429-1.725-8.594-4.858-11.728zm-67.127-21.34c0-12.426 10.109-22.536 22.536-22.536h1.129c12.426 0 22.535 10.11 22.535 22.536v16.483h-46.2zm63.985 74.52c0 2.259-.917 4.471-2.514 6.069-1.622 1.622-3.778 2.515-6.071 2.515h-64.6c-2.293 0-4.448-.893-6.071-2.516-1.621-1.62-2.514-3.775-2.514-6.068v-41.452c0-2.293.893-4.45 2.514-6.071 1.622-1.621 3.777-2.514 6.07-2.514h64.6c2.293 0 4.449.893 6.07 2.513 1.622 1.623 2.515 3.779 2.515 6.072v41.452z" />
                            <path d="m63.656 76.464c-2.209 0-4 1.791-4 4v14.092c0 2.209 1.791 4 4 4s4-1.791 4-4v-14.092c0-2.209-1.791-4-4-4z" />
                          </g>
                        </svg>
                        <span className="ps-3"> Change Password</span>
                      </Dropdown.Item>

                      <Dropdown.Item
                        // onClick={logout}
                        onClick={openLogoutPopup}
                        className="p-3   d-flex flex-row align-items-center"
                      >
                        {" "}
                        <svg viewBox="0 0 24 24" height={20} width={20}>
                          <g>
                            <path d="m12 3.25c.4142 0 .75.33579.75.75s-.3358.75-.75.75c-4.00406 0-7.25 3.24594-7.25 7.25 0 4.0041 3.24594 7.25 7.25 7.25.4142 0 .75.3358.75.75s-.3358.75-.75.75c-4.83249 0-8.75-3.9175-8.75-8.75 0-4.83249 3.91751-8.75 8.75-8.75z" />
                            <path d="m16.4697 9.53033c-.2929-.29289-.2929-.76777 0-1.06066s.7677-.29289 1.0606 0l3 3.00003c.2929.2929.2929.7677 0 1.0606l-3 3c-.2929.2929-.7677.2929-1.0606 0s-.2929-.7677 0-1.0606l1.7196-1.7197h-8.1893c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h8.1893z" />
                          </g>
                        </svg>{" "}
                        <span className="ps-3"> Logout</span>
                        {/* <svg fill="none" height={18}  width={18} viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="m5.63604 5.63616c2.94562-2.94563 7.42276-3.42155 10.86436-1.43234.4782.27638.6417.88805.3654 1.36621-.2764.47816-.8881.64173-1.3662.36536-2.6774-1.54748-6.16007-1.1743-8.44935 1.11498-2.73367 2.73367-2.73367 7.16583 0 9.89953 2.28928 2.2892 5.77195 2.6624 8.44935 1.1149.4781-.2763 1.0898-.1128 1.3662.3654.2763.4782.1128 1.0898-.3654 1.3662-3.4416 1.9892-7.91874 1.5133-10.86436-1.4323-3.51472-3.5147-3.51472-9.21323 0-12.72794zm12.65686 2.65685c.3905-.39053 1.0237-.39053 1.4142 0l1.8912 1.89119c.1785.1785.3523.3522.4875.5114.1484.1749.308.3968.4021.6865.1305.4017.1305.8344 0 1.236-.0941.2898-.2537.5117-.4021.6865-.1352.1592-.309.333-.4875.5114l-1.8912 1.8912c-.3905.3905-1.0237.3905-1.4142 0s-.3905-1.0237 0-1.4142l1.2929-1.2929h-7.5858c-.5523 0-1-.4477-1-1s.4477-1 1-1h7.5858l-1.2929-1.29288c-.3905-.39052-.3905-1.02369 0-1.41421z" fill="rgb(0,0,0)" fill-rule="evenodd"/></svg> 
                        <span className="ps-2">  <FormattedMessage id="Log Out" /></span>  */}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Navbar.Text>

                {/* <Navbar.Text className="text-white p-0">
                <button type="button" className="btn btn-dark">
                  <svg
                    clipRule="evenodd"
                    fillRule="evenodd"
                    fill="white"
                    height="24"
                    strokeLinejoin="round"
                    strokeMiterlimit="2"
                    viewBox="0 0 512 512"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m307.66 465.229c0-17.696-14.346-32.042-32.042-32.042h-39.236c-8.498 0-16.648 3.376-22.657 9.385s-9.385 14.159-9.385 22.657c0 17.696 14.346 32.042 32.042 32.042h39.236c17.696 0 32.042-14.346 32.042-32.042zm-240.299-82.623c5.352.409 11.827.644 18.331.263 3.465 17.572 12.073 33.87 24.922 46.72 17.058 17.057 40.193 26.64 64.316 26.64h12.226c-.539 2.95-.816 5.961-.816 9 0 3.073.277 6.082.808 9h-12.218c-28.897 0-56.611-11.479-77.044-31.912-16.301-16.302-26.904-37.237-30.525-59.711zm-9.156-19.153c-14.211-2.396-27.435-9.152-37.758-19.476-13.092-13.092-20.447-30.849-20.447-49.364v-42.543c0-18.515 7.355-36.272 20.447-49.364s30.849-20.448 49.364-20.448h4.647c7.428-93.756 85.87-167.529 181.542-167.529s174.114 73.773 181.542 167.529h4.647c18.515 0 36.272 7.356 49.364 20.448s20.447 30.849 20.447 49.364v42.543c0 18.515-7.355 36.272-20.447 49.364-13.092 13.093-30.849 20.448-49.364 20.448h-20.601c-8.544 0-15.47-6.927-15.47-15.47v-152.108c0-82.908-67.21-150.118-150.118-150.118s-150.118 67.21-150.118 150.118v152.108c0 6.032-3.453 11.259-8.491 13.81-13.868 4.812-35.375 1.331-39.186.688z" />
                  </svg>
                  <span className="ms-1"> Help and Support</span>
                </button>
              </Navbar.Text> */}
              </Navbar.Collapse>
            </Navbar>
            <MainTabsView />
          </Container>
        </div>

        <div className="FuelBanner-background position-relative"></div>
      </section>
    </React.Fragment>
  );
};

export default AboutSupportPopupHOC(EditPopupHOC(observer(Header)));
