import { action, computed, makeObservable, observable } from "mobx";

export class LanguageStore {
    language = 'en';

    constructor() {
        makeObservable(this, {
            language: observable,
            getLanguage: computed,
            changeLanguage: action,
            updateLanguage:action
        })
    }
    
    get getLanguage() {
        return this.language;
    }

    changeLanguage(language: string) {
        this.language = language;
        localStorage.setItem('secure_fuel_language',language);
    }

    updateLanguage() {
        let language = localStorage.getItem('secure_fuel_language');
        if(language)
            this.language = language;
    }
}

export default new LanguageStore();