/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../models/IUser";
import Label from "../label/label";
import LoaderButton from "../Button/LoaderButton";
import FormikFormPasswordField from "../FormikFormHandlers/FormikFormPasswordField";
import { IUpdatePassword } from "../../models/form/IChangePassword";
import { updateLoggedInUserPasswordSchema } from "../FormikFormHandlers/FormikValidationSchema";



interface IProps {
  submitHandler: (data: IUpdatePassword) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: any;
}


const ChangePasswordForm = (props: IProps) => {
  const {
    submitHandler,
    onModalClosed,
    initialValues,
    actionInProgress,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal modal-password" show={true} centered>
        <Modal.Header>
          <Modal.Title className="fs-5">
            <FormattedMessage id="change_password_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={updateLoggedInUserPasswordSchema}
          onSubmit={async (values) => { props.submitHandler(values) }} >
          {({ values }) => (
            <Form className="user-form py-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col xs={12}>
                    <div className="m-2 position-relative">
                      <Label
                        className="form-label"
                        required={true}
                        label="old_password"
                      />
                      <Field
                        className="form-control"
                        tested="testPassword"
                        name="OldPassword"
                        autoComplete="new-password"
                        validcharacter={[
                          "?",
                          ">",
                          "<",
                          "'",
                          ":",
                          ";",
                          ",",
                          "+",
                          "-",
                          "(",
                          ")",
                          "*",
                          "&",
                          "^",
                          "%",
                          "$",
                          "#",
                          "{",
                          "[",
                          "]",
                          "}",
                          "|",
                          "/",
                          "=",
                          "_",
                          ".",
                        ]}
                        as={FormikFormPasswordField}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="m-2 position-relative">
                      <Label
                        className="form-label"
                        required={true}
                        label="new_password"
                      />
                      <Field
                        className="form-control "
                        tested="testConfirmPassword"
                        name="NewPassword"
                        autoComplete="new-password"
                        validcharacter={[
                          "?",
                          ">",
                          "<",
                          "'",
                          ":",
                          ";",
                          ",",
                          "+",
                          "-",
                          "(",
                          ")",
                          "*",
                          "&",
                          "^",
                          "%",
                          "$",
                          "#",
                          "{",
                          "[",
                          "]",
                          "}",
                          "|",
                          "/",
                          "=",
                          "_",
                          ".",
                        ]}
                        as={FormikFormPasswordField}
                      />
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="m-2 position-relative">
                      <Label
                        className="form-label"
                        required={true}
                        label="confirm_password"
                      />
                      <Field
                        className="form-control "
                        tested="testConfirmPassword"
                        name="ConfirmPassword"
                        autoComplete="new-password"
                        validcharacter={[
                          "?",
                          ">",
                          "<",
                          "'",
                          ":",
                          ";",
                          ",",
                          "+",
                          "-",
                          "(",
                          ")",
                          "*",
                          "&",
                          "^",
                          "%",
                          "$",
                          "#",
                          "{",
                          "[",
                          "]",
                          "}",
                          "|",
                          "/",
                          "=",
                          "_",
                          ".",
                        ]}
                        as={FormikFormPasswordField}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>

              <div className="modal-footer mb-2 border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="save"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ChangePasswordForm;
