/*    
<summary>
   This file is used to provide initial values or state to the driver Data observables.
   Developer:Ashish Singh, Created Date:04-March-2024
</summary>
*/
import { IAddEditDriver } from "../../models/response/IDriverResponse";

export const addEditDriverState: IAddEditDriver = {
    RFID: "",
    DriverName: "",
    DLNo: "",
    DLExpiryDate: "",
    TruckAllocation: "",
    TruckAllocation1: "",
    FuelDeliveryEnabled: false,
    AllocationPerFill: "",
    AllocationPeriod: -1,
    AllocationVolume: "",
    RemainingVolume: "",
    BelongToCustomer: false,
    CustomerId: -1,
    EmployeeId: "",
}