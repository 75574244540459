import { IAddUser } from "../../models/IUser";

export const userState: IAddUser = {
    UserName: "",
    Email: "",
    PhoneNo: "",
    WhatsAppNo: "",
    Address: "",
    City: "",
    State: "",
    Country: "",
    // UserType: RoleEnum,
    // RolePermissions: Array<IRoleUserGroup>,
    // CreationDate: "",
    // ModificationDate: "",
    // Password: "",
    // ConfirmPassword: "",
    ProfileImage: "",
    UserType: -1,
    RoleId: -1,
    CustomerId: -1,
    // ContactPerson: "",
    IsActive: true,
    // ClientId: ""
}