/*    
<summary>
   This file is used to provide initial values or state to the truck Data observables.
   Developer:Ashish Singh, Created Date:04-April-2024
</summary>
*/
import { IAddEditTruck } from "../../models/response/ITruckResponse";

export const addEditTruckState: IAddEditTruck = {
    Name: "",
    DeviceId:"",
    ClientId:"",
    TruckId:"",
    Compartment:-1,
}