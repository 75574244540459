/*    
<summary>
   This class component is all about Managing alarm data functionality.
   Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/

import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState } from "../../models/ICommon";
import { demoAlarm } from "./data";
import moment from "moment";
import { DATE_FORMAT_BACKEND, DATE_TIME_FORMAT, emptyValue } from "../../constants/common-constant";
import { getAlarmListState } from "../initialState/get-alarm-list-state";
import { IAlarm, IAlarmList, IGetAlarmList } from "../../models/response/IAlarmResponse";
import { IAlarmState } from "../../models/state/IAlarmState";
import { AlarmTypeEnum } from "../../constants/enums/alarm-type-enum";
import { formatMessage } from "../../translations/formatMessage";
import { IAlarmFilter } from "../../models/IFilter";
import { alarmInitialFilterState } from "../initialState/initial-filter-states";
import { getClientId } from "../../helpers/localStorages.helper";


export class AlarmStore implements IAlarmState {
    inProgress = false;
    error = '';
    alarmList: IGetAlarmList = getAlarmListState;

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    alarmDetail: IAlarm | undefined = undefined;
    alarmDetailState: IObservableInitialState = { ...this.initialStateValue };

    filter: IAlarmFilter = {
        ...alarmInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            alarmList: observable,
            alarmDetail: observable,
            alarmDetailState: observable,
            filter: observable,
            GetAlarmListService: action,
            GetAlarmByIdService: action,
            reset: action,
            resetStore: action,
            resetGetAlarmDetail: action,
            setFilterDetail: action,
            getAlarmDetail: computed,
            getAlarmList: computed,
        });
    }

    GetAlarmListService = (currentPage: number, pagerSize: number, orderBy: string, column: string, searchText: string, filter: any) => {
        let startDateData = moment(filter.StartDate).format("YYYY-MM-DD");
        let timeStamp = moment(filter.AlarmTimestamp).format("YYYY-MM-DD");
        let endDateData = moment(filter.EndDate).format("YYYY-MM-DD");
        const alarmTimestamp = timeStamp + " 00:00:00";
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        this.inProgress = true;
        const url = UrlConstants.GetAllAlarm + '?clientId=' + clientIdFromLocalStorage + '&pageNo=' + currentPage + '&pageSize=' + pagerSize + '&orderBy=' + orderBy + '&column=' + column;
        // this.alarmList = demoAlarm;
        // this.inProgress = false;
        return services.post(url, { StartDate: tempStartDate , EndDate: tempEndDate ,AlarmType: filter.AlarmType == -1 ? null : filter.AlarmType })
            .then((response: IApiResponse<IApiSuccessResponse<IGetAlarmList>>) => {
                this.alarmList = response.data.Data;
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    get getAlarmList(): IAlarmList[] {
        if (this.alarmList?.Alarms && this.alarmList.Alarms?.length > 0) {
            return this.alarmList.Alarms.map((alarm: IAlarm) => {
                return {
                    ...alarm,
                    AlarmTimestamp: moment(alarm.AlarmTimestamp).format(DATE_TIME_FORMAT),
                    AlarmType: AlarmTypeEnum[alarm.AlarmType] ? formatMessage(AlarmTypeEnum[alarm.AlarmType]) : emptyValue,
                    AlarmDetail: alarm.AlarmDetail ? alarm.AlarmDetail : emptyValue,
                    ActionTaken: alarm.ActionTaken ? alarm.ActionTaken : emptyValue,
                    RFID: alarm.RFID ? alarm.RFID : emptyValue,
                }
            })
        }
        return [];
    }

    GetAlarmByIdService = (id: number) => {
        this.alarmDetailState.inProgress = true;
        const url = `${UrlConstants.GetAlarmById}/${id}`;
        const tempDetail = demoAlarm.Alarms.filter((d: IAlarm) => d.Id === id);
        return services.get(url)
            .then((response: IApiResponse<IAlarm>) => {
                this.alarmDetail = response.data;
            })
            .catch((err: string) => {
                this.alarmDetailState.error = err;
            }).finally(action(() => { this.alarmDetailState.inProgress = false; }));
    }

    get getAlarmDetail(): IAlarm | undefined {
        if (this.alarmDetail) {
            return {
                ...this.alarmDetail,
                AlarmTimestamp: moment(this.alarmDetail.AlarmTimestamp).format(DATE_FORMAT_BACKEND)
            };
        }
        return undefined;
    }

    setFilterDetail = (filter: IAlarmFilter) => {
        this.filter = { ...filter };
    }

    resetGetAlarmDetail = () => {
        this.alarmDetail = undefined;
        this.alarmDetailState = { ...this.initialStateValue };
    }

    reset = () => {
        this.error = '';
        this.inProgress = false;
    }

    resetError = () => {
        this.error = '';
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.alarmList = getAlarmListState;
        this.alarmDetail = undefined;
        this.alarmDetailState = { ...this.initialStateValue }
    }
}

export default new AlarmStore();