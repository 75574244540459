import { IntlProvider } from 'react-intl';
import translations from './translation';
import moment from 'moment';
import languageStore from '../core/stores/language-store';

let intl: any;

export function formatMessage(key: string, value?: object): string {
  try {
    let locale: any = languageStore.getLanguage;
    let messages = translations[locale].messages;
    let intlProvider = new IntlProvider({ locale, messages });
    moment.locale(locale);
    intl = intlProvider.state.intl;
    let label: {
      [key: string]: any;
    } = {};
    label[key] = intl.formatMessage(
      {
        id: key
      },
      value
    );
    return label[key];
  } catch {
    return key;
  }
}
