/*    
<summary>
   This is the Supplier Component which shows & manages the Suppliers Data.
   Developer: Aashish Singh, Created Date:20-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import NoData from "../../assets/Images/no_record.png";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { ModuleName } from "../../constants/moduleName.constant";
import {
  initialPageLimit,
  PageLimitOptions
} from "../../constants/pageLimit.constant";
import UrlConstants from "../../constants/url.constant";
import UserType from "../../constants/userType.constant";
import { useStore } from "../../contexts/StoreProvider";
import { supplierInitialFilterState } from "../../core/initialState/initial-filter-states";
import { getClientId, getUserType } from "../../helpers/localStorages.helper";
import useWindowDimensions, { getTableMaxHeight } from "../../helpers/windowResize.helper";
import { IActionFormatter } from "../../models/IFormatter";
import IPageInfo from "../../models/IPageInfo";
import { ISorting } from "../../models/ISorting";
import {
  IGetSupplier,
  ISupplier
} from "../../models/response/ISupplierResponse";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import Footer from "../../shared-components/Footer/Footer";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SubHeader from "../../shared-components/Header/SubHeader";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import DeleteConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import ConfirmPopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import { formatMessage } from "../../translations/formatMessage";
import AddUpdateSupplier from "./components/add-edit-supplier";
import "./supplier.scss";
import SupplierFilterForm from "./forms/supplier-filter-form";
import Pagination from "../../shared-components/Grid/Pagination";


interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showConfirmPopup: boolean;
  confirmToggleHandler: () => void;
  showEditSettingPopup: boolean;
  editSettingToggleHandler: () => void;
  showAddInvoiceDetailsPopup: boolean;
  addInvoiceDetailsToggleHandler: () => void;
  showPaymentDetailsPopup: boolean;
  paymentDetailsToggleHandler: () => void;
}

const Supplier: React.FC<IProps> = (props: IProps) => {
  const { supplierStore, preferencesStore } = useStore();
  const {
    tabKey,
    setTabKey,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
    showConfirmPopup,
    confirmToggleHandler,
    showEditSettingPopup,
    editSettingToggleHandler,
    showAddInvoiceDetailsPopup,
    addInvoiceDetailsToggleHandler,
    showPaymentDetailsPopup,
    paymentDetailsToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    supplierList,
    GetSupplierListService,
    getSupplierList,
    reset,
    resetError,
    DeleteSupplierService,
    DeleteSupplierState,
    resetDeleteSupplierState,
    addUpdateSupplierState,
    downloadSupplierCSV,
    resetGetSupplierDetail,
    filter,
    setFilterDetail,
    totalSupplier,
  } = supplierStore;
  const { selectedClientId, setSelectedClientId, CSVFileDownloadPostService, allAvailableClientOptions, resetClientListState, clientList, setAmountDecimal } =
    preferencesStore;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedSupplierName, setSelectedSupplierName] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<ISupplier | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const supplierListData: Array<ISupplier> = getSupplierList;
  const [pageLimit, setPageLimit] = useState<number>(initialPageLimit);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "supplierName",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChanged = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageLimit(parseInt(evt.target.value));
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  /**
   * This function provides filtering functionality of data on the basis of client id
   */
  const clientSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = parseInt(e.target.value);
    if (value < 0) toast.error(formatMessage("please_select_any_client_admin"));
    else {
      resetFilterHandler();
      setSelectedClientId(value);
      setAmountDecimal(value)
      setPageInfo({ ...pageInfo, currentPage: 1 });
    }
  };

  /**
   * This function calls the "GetSupplierListService" store funtion that get supplier list from API
   */
  const callGetSupplierList = useCallback(() => {
    setDisablePagination(true);
    if (getUserType() === UserType.SuperAdmin) {
      if (selectedClientId > 0 && clientList.length > 0) {
        GetSupplierListService(
          pageInfo.currentPage,
          pageLimit,
          sorting.orderBy,
          sorting.column,
          selectedClientId,
          _.omit(filter, ["isFilterActive"])
        );
      }
    }
    else {
      GetSupplierListService(
        pageInfo.currentPage,
        pageLimit,
        sorting.orderBy,
        sorting.column,
        selectedClientId,
        _.omit(filter, ["isFilterActive"])
      );
    }
    setIsApiCalled(true);
  }, [
    GetSupplierListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    searchText,
    selectedClientId,
    filter,
    clientList.length
  ]);

  useEffect(() => {
    return () => {
      resetFilterHandler();
      resetClientListState();
    };
  }, []);

  // useEffect(() => {
  //   if (selectedClientId > 0 && filter.isFilterActive) {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetSupplierList();
  //       else return;
  //     } else callGetSupplierList();
  //   }
  //   else {
  //     if (getUserType() == UserType.SuperAdmin) {
  //       if (selectedClientId != -1) callGetSupplierList();
  //       else return;
  //     } else callGetSupplierList();
  //   }
  // }, [filter, selectedClientId, callGetSupplierList]);

  useEffect(() => {
    if (selectedClientId > 0) { callGetSupplierList(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedClientId, callGetSupplierList]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: supplierList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getSupplierList, supplierList.TotalPages]);

  useEffect(() => {
    if (addUpdateSupplierState.success) {
      callGetSupplierList();
    }
  }, [addUpdateSupplierState.success, callGetSupplierList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteSupplierState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteSupplierState();
      confirmToggleHandler();
      callGetSupplierList();
    }
  }, [DeleteSupplierState.success, reset, callGetSupplierList]);

  useEffect(() => {
    if (DeleteSupplierState.error) {
      toast.error(formatMessage(DeleteSupplierState.error));
      resetDeleteSupplierState();
    }
  }, [DeleteSupplierState.error, resetDeleteSupplierState]);

  const filterSubmitHandler = (values: any) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    // setFilterData({...values, isFilterActive: true});
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    // closePanelHandler();
    // setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...supplierInitialFilterState });
    // setFilterData({
    //   ...clientInitialFilterState
    // });
  };

  /**
   * This function shows the add supplier pop up from where we can add new supplier and update existing supplier. we use id to identify
   * that we are adding or updating supplier.
   * "Id == -1" : we are adding new supplier
   * "Id != -1" : means we are updating existing supplier
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  const openUpdateSettingHandler = () => {
    closePanelHandler();
    setChangeDetails(false);
    editSettingToggleHandler();
  };

  const closeSettingHandler = () => {
    editSettingToggleHandler();
  };

  const closeInvoiceHandler = () => {
    addInvoiceDetailsToggleHandler();
    callGetSupplierList();
  };
  const closePaymentHandler = () => {
    paymentDetailsToggleHandler();
    // callGetSupplierList();
  };

  const openFilterHandler = () => {
    filterToggleHandler();
  };

  /**
   * This function shows the delete supplier confirmation popup component and sets the seleted supplier Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    confirmToggleHandler();
  };
  const addInvoiceClickHandler = (row: { Id: number; Name: string }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setSelectedSupplierName(row.Name);
    setChangeDetails(false);
    addInvoiceDetailsToggleHandler();
  };
  const paymentClickHandler = (row: { Id: number; Name: string }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setSelectedSupplierName(row.Name);
    setChangeDetails(false);
    paymentDetailsToggleHandler();
  };

  /**
   * This function deletes the supplier by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteSupplierService(selectedId);
  };

  /**
   * This function shows the detail supplier panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const exportFilteredLogsCSV = () => {
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.SupplierCSVDownload}?clientId=${clientIdFromLocalStorage}`;
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    tempFilterDetails = {
      ...tempFilterDetails
    };
    CSVFileDownloadPostService(url, "Supplier", tempFilterDetails);
  };

  const rowEvents = (row: any) => {
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelHandler = () => {
    setSelectedRow(undefined);
    setOpenPanel(false);
    resetGetSupplierDetail();
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="14"
          height="14"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      ),
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
      className: "btn-danger",
    },
    // {
    //   handler: rowEvents,
    //   icon: (
    //     <svg
    //       enableBackground="new 0 0 519.643 519.643"
    //       width="14"
    //       height="14"
    //       viewBox="0 0 519.643 519.643"
    //     >
    //       <circle cx="259.823" cy="259.866" r="80" />
    //       <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
    //     </svg>
    //   ),
    //   key: "",
    //   title: "Label_ShowDetails",
    //   className: "btn-secondary",
    // },
  ];

  const columns = getColumns<IGetSupplier, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "SupplierName",
        sortingName: "supplierName",
        displayName: "supplier_name",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: addIcons,
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "PhoneNo",
        sortingName: "PhoneNo",
        displayName: "phone_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonName",
        sortingName: "ContactPersonName",
        displayName: "table_label_contact_person_name",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonPhoneNo",
        sortingName: "ContactPersonPhoneNo",
        displayName: "table_lable_contact_person_phone_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "ContactPersonEmail",
        sortingName: "ContactPersonEmail",
        displayName: "table_lable_contact_person_email",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Address",
        sortingName: "address",
        displayName: "user_label_address",
      },
    ],
    sorting,
    handleSortingChange
  );

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateSupplier id={selectedId} modalClosed={addToggleHandler} />
      )}
      {showFilterPopup && (
        <SupplierFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}

      {showConfirmPopup && (
        <DeleteConfirmPopup
          title="delete_supplier"
          modalSubmit={deleteHandler}
          modalClosed={confirmToggleHandler}
          isLoading={DeleteSupplierState.inProgress}
          message="delete_supplier_confirm"
          dialogClassName="delete_popup"
        />
      )}

      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"suppliers"}
          moduleName={ModuleName.Supplier}
          exportCSVHandler={exportFilteredLogsCSV}
          filterHandler={openFilterHandler}
          addHandler={openAddUpdateHandler}
          isAdd={
            getUserType() == UserType.SuperAdmin
              ? selectedClientId > 0
                ? true
                : false
              : true
          }
          addButtonTitle="add_supplier"
          showClientSelectHandler={true}
          clientSelectHandler={clientSelectHandler}
          isAddSetting={false}
          isFullView={false}
          isDownload={true} //true
          isRefresh={false}
          isFilter={true} //true
          isDateFilter={false}
          isFilterActive={filter.isFilterActive}
          resetFilterHandler={resetFilterHandler}
        />
      </div>
      {inProgress && <SpinnerLoader />}
      <Container fluid className="p-0">
        {supplierListData.length !== 0 ? (
          <div className="supplierWrapper background-image">
            <div className="px-3 pt-2">
              <div
                className={`${supplierListData.length > 0 ? "blank_height" : ""
                  }`}
              >
                <div className="Total-users d-flex justify-content-end mb-2">
                  <FormattedMessage id="Total Suppliers" /> :{" "}
                  <b className="ps-1"> {supplierList.TotalFilteredSuppliers}</b>
                </div>
                <Row>
                  <div className="Fuel-table">
                    <div
                      className="table-responsive"
                      style={getTableMaxHeight(height, width)}
                      data-testid="table"
                    >
                      {supplierListData && (
                        <Grid
                          data={supplierListData}
                          columns={columns}
                          isInProgress={inProgress}
                          hiddenColumns={['Id']}
                        />
                      )}
                      {/* <small className="userLogin position-relative">
                   
                   <span className="status online"></span>
                 </small> */}

                    </div>
                  </div>
                </Row>
                <Container fluid>
                  <Row className="tableFooter py-1">
                    <Col xs="2" className="d-flex">
                      <b className="text-white text-hidden-m mt-1 pt-1">
                        <FormattedMessage id="show" />:{" "}
                      </b>

                      <select
                        data-testid="pageSelect"
                        className="ms-2 from-control"
                        onChange={onPageLimitChanged}
                        value={pageLimit}
                      >
                        {PageLimitOptions.map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col className="d-flex justify-content-end text-white" xs="10">
                      <Pagination
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                      />
                    </Col>



                  </Row>
                </Container>
              </div>
            </div>
          </div>
        ) : (
          <div className="no_data_table background-image ">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default
  ConfirmPopupHOC(
    FilterPopupHOC(
      EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Supplier))))
    )
  );
