/*    
<summary>
   This file is used to provide initial values or state to the Driver Module observables.
    Developer:Aashish Singh, Created Date:04-March-2024
</summary>
*/
import { IGetDriverList } from "../../models/response/IDriverResponse";

export const getDriverListState: IGetDriverList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Drivers: [],
    TotalFilteredDrivers: 0
}