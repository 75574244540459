/*    
<summary>
  This functional component shows the driver details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { Col, Row } from "react-bootstrap";
import { emptyValue, getCustomerLabelPrefix } from "../../../constants/common-constant";
import { ITransaction } from "../../../models/ITransaction";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import fuelConsumed from "../../../assets/Images/fuelConsumed.png";
import tankIcon from "../../../assets/Images/fuel-container.png";
import DeviceId from "../../../assets/Images/deviceId.png";
import { TransactionsEnum } from "../../../constants/enums/productIdTransactionsEnum";
import { getAmountDecimal } from "../../../helpers/localStorages.helper";
interface IProps {
  selectedData: ITransaction | undefined;
  productId: string;
}

const TransactionDetails = (props: IProps) => {
  const { transactionsStore, customerStore } = useStore();
  const { productId } = props;
  const { getTransactionByIdService, getTransactionDetail, transactionDetailState } = transactionsStore;
  const { getSettingDetail } = customerStore;
  let decimalValue = getAmountDecimal();

  /**
   * This function calls the "GetDriverByIdService" store funtion that get group data from API
   */
  const callGetTranscationById = () => {
    if (props.selectedData) getTransactionByIdService(props?.selectedData.Id);
  };

  const trsanformProductId = (productId: string) => {
    const productIdArray = productId.split("_")
    return productIdArray.length > 0 ? productIdArray[0] : emptyValue;
  };

  useEffect(() => {
    if (props?.selectedData && props?.selectedData?.Id > 0)
      callGetTranscationById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.selectedData?.Id]);

  return (
    <React.Fragment>
      {transactionDetailState.inProgress && <SpinnerLoader />}
      {getTransactionDetail && (
        <>

          {/* <FormattedMessage id={"driver_name"} />:{" "} */}
          {/* {getTransactionDetail?.DriverName} */}
          <Row className="my-3 pb-3 mb-0">
            <Col xs={12}>
              <div className="TicketNumber d-flex justify-content-between align-items-center">
                <small className="h6"><FormattedMessage id="transactions_label_ticketnumber" /></small>
                <p>{getTransactionDetail?.TicketNumber}</p>
              </div>
            </Col>

            <Col xs={12} className="mt-3 pt-4">
              <div className="timeline horizontal">
                <div className="timeline-item">
                  <h4 className="mb-0">  <small className="text-muted h6">
                    <FormattedMessage id="transactions_label_starttime" />
                  </small>
                  </h4>
                  <h4 className="mb-0"><small className="h6">  {getTransactionDetail.TsStart ? getTransactionDetail.TsStart : emptyValue}</small></h4>
                </div>
                <div className="timeline-item">
                  <h4 className="mb-0">  <small className="text-muted h6">
                    <FormattedMessage id="transactions_label_endtime" />
                  </small>
                  </h4>
                  <h4 className="mb-0"> <small className="h6">  {getTransactionDetail.TsFinish ? getTransactionDetail.TsFinish : emptyValue} </small></h4>
                </div>
              </div>
            </Col>
          </Row>

          <div className="row detailTransactionParent">
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="detailviewCircle">
                <img src={tankIcon} />
                <small>
                  <FormattedMessage id="transactions_label_truckid" />
                </small>
                <h6 className="text-break">{getTransactionDetail?.TruckId ? getTransactionDetail.TruckId : emptyValue}</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="detailviewCircle">
                <img src={DeviceId} />
                <small><FormattedMessage id="transactions_label_meterid" /></small>
                <h6 className="text-break">{getTransactionDetail?.DeviceId ? getTransactionDetail.DeviceId : emptyValue}</h6>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <div className="detailviewCircle">
                <img src={fuelConsumed} />
                <small><FormattedMessage id="transactions_label_productid" /></small>
                <h6 className="text-break">
                  {getTransactionDetail?.ProductId ? trsanformProductId(getTransactionDetail.ProductId) : emptyValue}
                </h6>
              </div>
            </div>
          </div>

          <Row className="mt-4 customer bg-light p-3 mb-3">
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 1) : getSettingDetail.CustomerId1Title} /></p>
              <p className="text-break fw-semi-bold">  {getTransactionDetail?.CustomerId1 ? getTransactionDetail.CustomerId1 : emptyValue}</p>
            </Col>
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 2) : getSettingDetail.CustomerId2Title} /></p>
              <p className="text-break fw-semi-bold">  {getTransactionDetail?.CustomerId2 ? getTransactionDetail.CustomerId2 : emptyValue}</p>
            </Col>
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 3) : getSettingDetail.CustomerId3Title} /></p>
              <p className="text-break fw-semi-bold"> {getTransactionDetail?.CustomerId3 ? getTransactionDetail.CustomerId3 : emptyValue}</p>
            </Col>
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 4) : getSettingDetail.CustomerId4Title} /></p>
              <p className="text-break fw-semi-bold">  {getTransactionDetail?.CustomerId4 ? getTransactionDetail.CustomerId4 : emptyValue}</p>
            </Col>
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 5) : getSettingDetail.CustomerId5Title} /></p>
              <p className="text-break fw-semi-bold">  {getTransactionDetail?.CustomerId5 ? getTransactionDetail.CustomerId5 : emptyValue}</p>
            </Col>
            <Col xs={4}>
              <p className="text-break m-0">  <FormattedMessage id={productId !== TransactionsEnum.Deliveries ? getCustomerLabelPrefix(productId, 6) : getSettingDetail.CustomerId6Title} /></p>
              <p className="text-break fw-semi-bold">  {getTransactionDetail?.CustomerId6 ? getTransactionDetail.CustomerId6 : emptyValue}</p>
            </Col>
          </Row>

          <table className="table table-striped detail-Table">
            <thead>
              <tr>
                <td>
                  <FormattedMessage id="description" />
                </td>
                <td className="text-end">
                  <FormattedMessage id="value" />
                </td>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>
                  <FormattedMessage id="transactions_label_order_uuid" />
                </td>
                <td className="text-end">{getTransactionDetail?.OrderUuid !== null ? getTransactionDetail.OrderUuid : emptyValue}</td>
              </tr> */}
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_grossdelivered" />
                  {getTransactionDetail.Unit && <>
                    {" "} ({getTransactionDetail.Unit})
                  </>
                  }
                </td>
                <td className="text-end">{getTransactionDetail?.GrossDelivered !== null ? getTransactionDetail.GrossDelivered : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_endgross" />
                  {getTransactionDetail.Unit && <>
                    {" "} ({getTransactionDetail.Unit})
                  </>
                  }
                </td>
                <td className="text-end">{getTransactionDetail?.EndGross !== null ? getTransactionDetail.EndGross : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_flowaverage" />
                </td>
                <td className="text-end">{getTransactionDetail?.FlowAvg !== null ? getTransactionDetail.FlowAvg : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_flowmax" />
                </td>
                <td className="text-end">{getTransactionDetail?.FlowMax !== null ? getTransactionDetail.FlowMax : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="meter_id" />
                </td>
                <td className="text-end">{getTransactionDetail?.MeterId !== null ? getTransactionDetail.MeterId : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="net_delivered" />
                  {getTransactionDetail.Unit && <>
                    {" "} ({getTransactionDetail.Unit})
                  </>
                  }
                </td>
                <td className="text-end">{getTransactionDetail?.NetDelivered !== null ? getTransactionDetail.NetDelivered : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_vehicleRfid" />
                </td>
                <td className="text-end"> {getTransactionDetail?.VehicleRfid ? getTransactionDetail.VehicleRfid : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_driverRfid" />
                </td>
                <td className="text-end"> {getTransactionDetail?.DriverRfid ? getTransactionDetail.DriverRfid : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_operatorRfid" />
                </td>
                <td className="text-end"> {getTransactionDetail?.OperatorRfid ? getTransactionDetail.OperatorRfid : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_gentimestamp" />
                </td>
                <td className="text-end">{getTransactionDetail?.TsGen ? getTransactionDetail.TsGen : emptyValue}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="transactions_label_sendtimestamp" />
                </td>
                <td className="text-end">{getTransactionDetail?.TsSend ? getTransactionDetail.TsSend : emptyValue}</td>
              </tr>
            </tbody>
          </table>

          <table className="table amount-table no-hover">
            <thead>
              <tr>
                <th scope="col" className="text-end">
                  <FormattedMessage id="unitprice" />
                  {getTransactionDetail.CurrencyName && <>
                    {" "}({getTransactionDetail.CurrencyName})
                  </>
                  }:
                </th>
                <td className="text-end"> {getTransactionDetail?.PricePerL !== null ? Number(getTransactionDetail.PricePerL).toFixed(Number(decimalValue)) : emptyValue}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-end">
                  <FormattedMessage id="transactions_label_vat" />
                  <>
                    {" "}({getTransactionDetail?.Vat !== null ? getTransactionDetail.Vat : emptyValue}<FormattedMessage id="percent" />)
                  </>
                  :
                  {" "}
                </th>
                <td className="text-end"> {getTransactionDetail?.VatAmount !== null ? Number(getTransactionDetail.VatAmount).toFixed(Number(decimalValue)) : emptyValue}</td>
              </tr>
              <tr>
                <th scope="row" className="text-end">
                  <FormattedMessage id="transactions_label_amountdue" />
                  {getTransactionDetail.CurrencyName && <>
                    {" "}({getTransactionDetail.CurrencyName})
                  </>
                  }
                  :
                </th>
                <td className="text-end">
                  {" "}
                  <span className="amount-due">{getTransactionDetail?.AmountDue !== null ? Number(getTransactionDetail.AmountDue).toFixed(Number(decimalValue)) : emptyValue}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </React.Fragment>
  );
};

export default observer(TransactionDetails);