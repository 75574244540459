import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Navbar, Container, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import "./TabsView.scss";
import { Link, NavLink } from "react-router-dom";
import RoutesConstants from "../../constants/routes.constant";
import { useStore } from "../../contexts/StoreProvider";
import UserType from "../../constants/userType.constant";
import { getUserType } from "../../helpers/localStorages.helper";
import { isRoutePermitted } from "../../helpers/routesFunctions.helper";
import { ModuleName } from "../../constants/moduleName.constant";

interface IProps { }

const MainTabsView = (props: IProps) => {
  const { authStore } = useStore();
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);

  const userType = getUserType() !== null ? getUserType() : "";

  const handleNavLinkClick = () => {
    setExpanded(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (navbarRef.current) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar
      ref={navbarRef}
      expand="lg"
      collapseOnSelect
      className="bg-body-tertiary pt-0"
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="menu-Responsive"
        >
          <span
            className="material-symbols-outlined text-white open-menu"
            onClick={() => setExpanded(!expanded)}
          >
            menu
          </span>
          <span className="material-symbols-outlined text-white close-menu">
            close
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="mobile-menu">
          <Nav className="m-auto nav-scroll">
            {isRoutePermitted(ModuleName.Dashboard) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Dashboard}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="dashboard_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.SuperAdminDashboard) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.SuperAdminDashboard}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="dashboard_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.CustomerDashboard) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.CustomerDashboard}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="dashboard_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.TicketManagement) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Transactions}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="transactions_title" />
                </NavLink>
              </Nav.Item>
            )}

            {isRoutePermitted(ModuleName.Clients) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Clients}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="client_title" />
                </NavLink>
              </Nav.Item>
            )}

            {isRoutePermitted(ModuleName.Reports) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Reports}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="reports_title" />
                </NavLink>
              </Nav.Item>
            )}

            {isRoutePermitted(ModuleName.Customer) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Customer}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="customer_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.Supplier) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Supplier}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="Supplier_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.VehicleManagement) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Vehicles}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="vehicle_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.DriverManagement) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Drivers}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="drivers_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.TankManagement) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.Trucks}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="trucks_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.User) && (
              <Nav.Item className="d-lg-none">
                <NavLink to={RoutesConstants.User} className="text-white text-decoration-none px-3" onClick={handleNavLinkClick}>
                  <FormattedMessage id="user_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(ModuleName.Roles) && (
              <Nav.Item className="d-lg-none">
                <NavLink to={RoutesConstants.Roles} className="text-white text-decoration-none px-3" onClick={handleNavLinkClick}>
                  <FormattedMessage id="role_title" />
                </NavLink>
              </Nav.Item>
            )}
            {isRoutePermitted(
              userType == UserType.ClientUser
                ? ModuleName.TicketManagement
                : ModuleName.Alarms
            ) && (
                <Nav.Item>
                  <NavLink
                    to={RoutesConstants.Alarms}
                    className="text-white text-decoration-none px-3"
                    onClick={handleNavLinkClick}
                  >
                    <FormattedMessage id="alarms_title" />
                  </NavLink>
                </Nav.Item>
              )}

            {(userType === UserType.CustomerAdmin) ?
              <>
                {isRoutePermitted(ModuleName.User) && (
                  <Nav.Item>
                    <NavLink to={RoutesConstants.User} className="text-white text-decoration-none px-3" onClick={handleNavLinkClick}>
                      <FormattedMessage id="user_title" />
                    </NavLink>
                  </Nav.Item>
                )}
                {isRoutePermitted(ModuleName.Roles) && (
                  <Nav.Item>
                    <NavLink to={RoutesConstants.Roles} className="text-white text-decoration-none px-3" onClick={handleNavLinkClick}>
                      <FormattedMessage id="role_title" />
                    </NavLink>
                  </Nav.Item>
                )}
              </> : <Dropdown className="submenu ">
                <Dropdown.Toggle>
                  Users
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  {isRoutePermitted(ModuleName.User) && (
                    <NavDropdown.Item
                      as={NavLink}
                      to={RoutesConstants.User}
                      onClick={handleNavLinkClick}
                      className="icon-bottom"
                    >
                      <FormattedMessage id="user_title" />
                    </NavDropdown.Item>
                  )}


                  {isRoutePermitted(ModuleName.Roles) && (
                    <NavDropdown.Item
                      as={NavLink}
                      to={RoutesConstants.Roles}
                      onClick={handleNavLinkClick}
                    >
                      <FormattedMessage id="role_title" />
                    </NavDropdown.Item>
                  )}

                </Dropdown.Menu>
              </Dropdown>}




            {isRoutePermitted(ModuleName.LiveTracking) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.LiveTracking}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="livetracking_title" />
                </NavLink>
              </Nav.Item>
            )}

            {isRoutePermitted(ModuleName.AddTransaction) && (
              <Nav.Item>
                <NavLink
                  to={RoutesConstants.AddTransaction}
                  className="text-white text-decoration-none px-3"
                  onClick={handleNavLinkClick}
                >
                  <FormattedMessage id="add_transactions_title" />
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainTabsView;