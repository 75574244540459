import axios, { AxiosResponse } from 'axios';
import config from '../../helpers/config.helper';
import RoutesConstants from '../../constants/routes.constant';
import { getRefreshToken, getToken, getUserName } from '../../helpers/localStorages.helper';
import { AuthStore } from '../stores/auth-store';
import UrlConstants from '../../constants/url.constant';
import { ErrorMessage } from '../../constants/error.constant';

const appConfig = config();
export const baseURL = appConfig.API_URL;
export const baseAPI = axios.create({ baseURL });

const requestHandler = (request: any) => {
    request.headers['RequestType'] = 'Web';
    // request.headers['Access-Control-Allow-Origin']= '*';
    // request.headers['Access-Control-Allow-Methods']= 'GET, POST, PUT, DELETE';
    // request.headers['Access-Control-Allow-Headers']= 'Content-Type, Authorization';

    //don't send authorization in headers for these 3 requests
    if (request.url === `${UrlConstants.RefreshToken}` || request.url === `${UrlConstants.Authenticate}`)
        return request;

    const token = getToken();
    const userName = getUserName();
    request.headers['authorization'] = 'Bearer ' + token;
    request.headers['X-Tenant-ID'] = userName;
    return request;
};
const refreshTokenHandler = async (originalRequest: any) => {
    const authStore = new AuthStore();
    if (!originalRequest._retry) {
        originalRequest._retry = true;
        try {
            const rs: any = await baseAPI.post(UrlConstants.RefreshToken, {
                // AccessToken: getToken(),
                RefreshToken: getRefreshToken()
            });
            authStore.updateToken(rs.data.Data);
            return baseAPI(originalRequest);
        } catch (_error) {
            return Promise.reject(_error);
        }
    }
}
const errorHandler = async (error: any) => {
    let message: string = ErrorMessage.ErrorOccured;
    let errors: string[] = [];
    const originalRequest: any = error.config;
    if (error.response) {
        if (error.response.status === 401) {
            const err = error.response.data?.ErrorMessage ? error.response.data?.ErrorMessage : error.response.data;
            switch (err) {
                case ErrorMessage.TokenExpired:
                    await refreshTokenHandler(originalRequest);
                    // message = ErrorMessage.TokenExpired;
                    // // message = ErrorMessage.Unauthorized;
                    // setTimeout(()=>{
                    //     const lang:any = localStorage.getItem("language");
                    //     localStorage.clear();
                    //     localStorage.setItem("language", lang === "jp" ? "jp" : "en");
                    //     window.location.href = RoutesConstants.Login}
                    //     , 3000);
                    // return Promise.reject(message);
                    return ;
                case ErrorMessage.RefreshTokenExpired:
                case ErrorMessage.RefreshTokenNotInDatabase:
                    message = ErrorMessage.RefreshTokenExpired;
                    // message = ErrorMessage.Unauthorized;
                    setTimeout(()=>{
                        const lang:any = localStorage.getItem("language");
                        localStorage.clear();
                        localStorage.setItem("language", lang === "jp" ? "jp" : "en");
                        window.location.href = RoutesConstants.Login}
                        , 3000);
                    return Promise.reject(err);
                case ErrorMessage.InvalidUsernameOrPassword:
                    message = error.response.data.ErrorMessage;
                    break;
                case ErrorMessage.Unauthorized:
                    message = error.response.data.ErrorMessage;
                    break;
                default:
                    // localStorage.clear();
                    // window.location.href = RoutesConstants.Login
                    return Promise.reject(message);
            }
        } else if (error.response.status === 403) {
            message = ErrorMessage.Forbidden;
        } 
        else if (error.response.data && error.response.data?.ErrorMessage) {
            message = error.response.data.ErrorMessage
        }
    }
    return Promise.reject(message);
};
const successHandler = (response: AxiosResponse): AxiosResponse => {
    return response;
};

baseAPI.interceptors.request.use(request => {
    return requestHandler(request)
});

baseAPI.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);