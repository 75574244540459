import React, { useState } from 'react';

const DeletePopupHOC = (Component: any) => 
function Comp(props: any){
    const [showDeletePopup,setShowDeletePopup] = useState<boolean>(false);

    const deleteToggleHandler = () => {
        setShowDeletePopup(!showDeletePopup);
    }
	
	return <Component showDeletePopup={showDeletePopup} deleteToggleHandler={deleteToggleHandler} {...props} />;
};

export default DeletePopupHOC;