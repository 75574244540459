import React from "react";
import TopNavBar from "../NavBar/TopNavBar";
import { useStore } from "../../contexts/StoreProvider";
import { formatMessage } from "../../translations/formatMessage";
import Header from "../Header/Header";

const Layout: React.FC<any> = (props) => {
  const { authStore } = useStore();

  return (
    <div id="FuelSecure">
      <Header
        headerText={formatMessage("application_title")}
      />
      {props.children}
    </div>
  );
};

export default Layout;
