/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate protocols in the overall application.
   Developer: Aashish Singh, Created Date:04-March-2024
</summary>
*/
export enum AllocationPeriodEnum {
    Daily,
    Weekly,
    Monthly
  }