import { IAddTransactions, ITransaction } from "../../models/ITransaction";

export const transactionState: ITransaction = {
    Id: 0,
    DeviceId: "",
    ClientId: "",
    TruckId: "",
    OrderUuid: "",
    Unit: "",
    TicketNumber: "",
    ProductId: "",
    TsStart: "",
    TsFinish: "",
    Lat: 0,
    Lon: 0,
    EndGross: 0,
    FlowAvg: 0,
    FlowMax: 0,
    GrossDelivered: 0,
    PricePerL: 0,
    Vat: 0,
    AmountDue: 0,
    TankId: 0,
    TsGen: "",
    TsSend: "",
    VehicleRfid: "",
    DriverRfid: "",
    OperatorRfid: "",
    CustomerId1: "",
    CustomerId2: "",
    CustomerId3: "",
    CustomerId4: "",
    CustomerId5: "",
    CustomerId6: "",
    CurrencyName: "",
    MeterId: 0,
    NetDelivered: 0,
    VatAmount: 0,
    InvName1: "",
    InvName2: "",
    InvUnit1: "",
    InvUnit2: "",
    InvValue1: 0,
    InvValue2: 0,
}

export const addTransactionInitialState: IAddTransactions = {
    DeviceId: "",
    ClientId: "",
    TruckId: "",
    OrderUuid: "",
    Unit: "liter_unit",
    TicketNumber: "",
    CustomerId1: "",
    CustomerId2: "",
    CustomerId3: "",
    CustomerId4: "",
    CustomerId5: "",
    CustomerId6: "",
    ProductId: "",
    TsStart: null,
    TsFinish: null,
    Lat: "",
    Lon: "",
    EndGross: "",
    FlowAvg: "",
    FlowMax: "",
    GrossDelivered: "",
    PricePerL: "",
    Vat: "",
    AmountDue: "",
    // Tank: {
    //     Value1: "",
    //     Type1: "",
    //     Value2: "",
    //     Type2: ""
    // },
    InvName1: "",
    InvName2: "",
    InvUnit1: "",
    InvUnit2: "",
    InvValue1: "",
    InvValue2: "",
    TsGen: null,
    TsSend: null,
    VehicleRfid: "",
    DriverRfid: "",
    OperatorRfid: "",
    MeterId: "",
    NetDelivered: "",
}

// {
//   "Id": 138,
//   "DeviceId": "PO20220302-2-2-001",
//   "ClientId": "testclient",
//   "TruckId": "MH_customer1",
//   "OrderUuid": "201F4DACCA7011EE90D6189BA514D9E7",
//   "TicketNumber": "147134",
//   "CustomerId1": "MH_truck2",
//   "CustomerId2": "20271DXBO",
//   "CustomerId3": "O20271",
//   "CustomerId4": "90832",
//   "CustomerId5": "",
//   "CustomerId6": "",
//   "ProductId": "DIESEL_IN",
//   "TsStart": 1710549012683,
//   "TsFinish": 1710550390683,
//   "Lat": 0.0,
//   "Lon": 0.0,
//   "EndGross": 78.03376537957385,
//   "FlowAvg": 52.568386544516336,
//   "FlowMax": 72.46419934291926,
//   "GrossDelivered": 78.03376537957385,
//   "PricePerL": 0.0,
//   "Vat": 0.0,
//   "AmountDue": 0.0,
//   "TankId": 138,
//   "TsGen": "2024-03-16T01:00:12.683994Z",
//   "TsSend": 1710549057683,
//   "VehicleRfid": "201F4DACCA7011EE90D6189BA514D9E7",
//   "DriverRfid": "201F4DACCA7011EE90D6189BA514D9E7",
//   "OperatorRfid": "201F4DACCA7011EE90D6189BA514D9E7"
// },