/*    
<summary>
   This file is used to provide initial values or state to the Alarm Module observables.
    Developer:Aashish Singh, Created Date:04-March-2024
</summary>
*/
import { IGetAlarmList } from "../../models/response/IAlarmResponse";

export const getAlarmListState: IGetAlarmList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Alarms: []
}