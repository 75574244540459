import React from 'react';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Body from './shared-components/Body/Body';
import './App.scss'
const App = () => {
  return (
    <div className="App" data-testid='AppDiv'> 
      <Body />
      <ToastContainer transition={Bounce} className="custom-toast"/>
    </div>
  );
}

export default App;

