/*    
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used for the pagination limit for corresponding modules.
   Developer: Aashish Singh, Created Date: 17-June-2024 
</summary>
*/
enum userPageLimitSettingEnum{
    TrasnactionLimitConfig = 'trasnactionLimitConfig',
    VehicleLimitConfig = 'vehicleLimitConfig',
    DriverLimitConfig = 'driverLimitConfig',
    TankLimitConfig = 'tankLimitConfig',
    AlarmLimitConfig = 'alarmLimitConfig',
    RoleLimitConfig = 'roleLimitConfig',
    ClientLimitConfig = 'clientLimitConfig',
    ReportLimitConfig = 'reportLimitConfig' ,
}

export default userPageLimitSettingEnum;