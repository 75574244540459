import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import { useStore } from "../../contexts/StoreProvider";
import { getBase64 } from "../../helpers/imagePicker.helper";
import imageCompression from "browser-image-compression";

interface IProps {
    children: React.ReactNode;
    className: string;
    name: string;
    "data-testid"?: string;
    intl: any;
    onChange: () => void;
    value: any;
    disabled: boolean;
    readOnly: boolean;
    label?: string;
}

const FormikFormImagePicker = (props: IProps) => {
    const [field, meta, helpers] = useField(props);
    const { languageStore } = useStore();
    const [focus, setFocus] = useState(false);
    const language = languageStore.getLanguage;
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);

    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 200,
        useWebWorker: true,
    }

    const handleCalendarClose = () => {
        if (calendarIsOpen) setCalendarIsOpen(false);
    };

    const onClickCalendarIcon = () => {
        setCalendarIsOpen(!calendarIsOpen);
    };

    const blurHandler = () => {
        if (!props.value)
            setFocus(false)
    }

    useEffect(() => {
        if (props.value)
            setFocus(true)
    }, [props.value]);

    return (
        <>
            <input id="imgupload" name="file" type="file" accept="image/*" onChange={async (event) => {
                try {
                    const compressedFile = await imageCompression(event.currentTarget.files![0], options);
                    getBase64(compressedFile)
                        .then(result => {
                            helpers.setValue(result);
                        })
                        .catch(err => {
                            helpers.setError("Error Uploading Image");
                        });
                }
                catch (error) {
                    helpers.setError("Error Uploading Image");
                }
            }} style={{ display: "none" }} />
            <label htmlFor='imgupload'>
                {field.value === "" && <div className="position-relative upload-size-image">
                    <div className="upload-icon-image">
                        <span className="material-symbols-outlined">
                            publish
                        </span>
                    </div>
                    <div className="user-profile-box">
                        <div className="upload-profile align-middle"><svg data-name="Layer 3" width="20" height="20" viewBox="0 0 32 32" fill="#AA000D">
                            <path d="M27.348,7H23.054l-.5-1.5A3.645,3.645,0,0,0,19.089,3H12.911A3.646,3.646,0,0,0,9.447,5.5L8.946,7H4.652A3.656,3.656,0,0,0,1,10.652v14.7A3.656,3.656,0,0,0,4.652,29h22.7A3.656,3.656,0,0,0,31,25.348v-14.7A3.656,3.656,0,0,0,27.348,7ZM29,25.348A1.654,1.654,0,0,1,27.348,27H4.652A1.654,1.654,0,0,1,3,25.348v-14.7A1.654,1.654,0,0,1,4.652,9H9.667a1,1,0,0,0,.948-.684l.729-2.187A1.65,1.65,0,0,1,12.911,5h6.178a1.649,1.649,0,0,1,1.567,1.13l.729,2.186A1,1,0,0,0,22.333,9h5.015A1.654,1.654,0,0,1,29,10.652Z" />
                            <path d="M16,10a7.5,7.5,0,1,0,7.5,7.5A7.508,7.508,0,0,0,16,10Zm0,13a5.5,5.5,0,1,1,5.5-5.5A5.506,5.506,0,0,1,16,23Z" />
                            <circle cx="26" cy="12" r="1" /></svg>
                        </div></div>
                </div>}
                {field.value !== "" && <div className="position-relative upload-size-image">
                    <div className="upload-icon-image">
                        <span className="material-symbols-outlined">
                            publish
                        </span>
                    </div>
                    <img
                        {...field} {...props}
                        className={"user-profile-box glow-on-hover"}
                        // style={{ borderRadius: "80px", width: "100px", height: "100px", backgroundPosition: "center center", backgroundRepeat: "no-repeat" }}

                        src={field.value}
                    // alt={"gym"}

                    />
                </div>}</label>
            <FormikFormErrorHandler intl={props.intl} meta={meta} />
        </>
    );
};

export default injectIntl(FormikFormImagePicker);
