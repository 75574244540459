import React, { ReactElement } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import '../../assets/css/style.css';
import "./Popup.css";
import LoaderButton from "../Button/LoaderButton";
import { formatMessage } from "../../translations/formatMessage";
import { FormattedMessage } from "react-intl";

interface Props {
  modalSubmit: () => void;
  modalClosed: () => void;
  isLoading: boolean;
  message: string;
  submitBtnText: string;
  cancelBtnText: string;
  modalTitle: string;
  icon: React.ReactElement;
  children?: ReactElement;
}

const Popup: React.FC<Props> = (props) => {
  return (
    <Modal
      show={true}
      centered
      dialogClassName="confirmation-modal modal-delete add-user-modal"
    >
      <Modal.Header>
        <Modal.Title className="fs-5 ps-3">
          <FormattedMessage id="Delete" />
        </Modal.Title>
        <div onClick={props.modalClosed} className="icons8-close">
          <span className="material-symbols-outlined fs-5 text-dark">
            close
          </span>
        </div>{" "}
      </Modal.Header>
      <Modal.Body className="text-center p-3 py-5">
        <svg
          height={80}
          width={80}
          fill="#AA000D"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm94.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" />
        </svg>

        <p className="h5 mt-3 pt-2">
          {formatMessage(props.message)}
          {props.children}{" "}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 m-auto pb-4 pt-0">
        <LoaderButton
          type="submit"
          onClick={props.modalSubmit}
          isLoading={props.isLoading}
          disabled={props.isLoading}
          text={props.submitBtnText}
        />
        <Button
          variant="grey-btn btn-secondary  btn-light ms-2"
          onClick={props.modalClosed}
        >
          {formatMessage(props.cancelBtnText)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
