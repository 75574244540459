import React, { useState } from 'react';

const EditPopupHOC = (Component: any) =>
function Comp(props:any){
    const [showEditPopup,setShowEditPopup] = useState<boolean>(false);

    const editToggleHandler = () => {
        setShowEditPopup(!showEditPopup);
    }
	
	return <Component showEditPopup={showEditPopup} editToggleHandler={editToggleHandler} {...props} />;
};

export default EditPopupHOC;