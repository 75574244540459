import React from 'react';
import BusyGif from '../../assets/Images/busy.gif'
import { Button } from 'react-bootstrap';
import { formatMessage } from '../../translations/formatMessage';

interface IProps{
    type: "submit" | "reset" | "button" | undefined;
    className?: string;
    isLoading: boolean;
    disabled?: boolean;
    text: string;
    onClick?: ()=>void;
}

const LoaderButton = (props:IProps) => {
    return (
        <Button type={props.type}
            disabled={props.disabled ? props.disabled : false}
            className={props.className??""}
            onClick={props.onClick}>
            {props.isLoading?
                <img style={{width:"15px",height:"15px", marginRight:"5px"}}src={BusyGif} alt="spinner-loader" />:<></>}
            {formatMessage(props.text)}
        </Button>
    )
};

export default LoaderButton;