import { AxiosResponse } from "axios";
import { baseURL } from "../core/interceptor/interceptor";
import { getToken } from "./localStorages.helper";
import { formatMessage } from "../translations/formatMessage";
import { toast } from "react-toastify";

export const downloadFile = (response: AxiosResponse) => {
  const contentDisposition = response.headers["content-disposition"];
  const pattern = /filename\*?=(?:UTF-8'')?([^;]+)/;
  const match = contentDisposition.match(pattern);
  let fileName = "file";
  if (match) fileName = match[1];
  const href = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const downloadPdfFile = (response: AxiosResponse, FileName: string, type: string) => {
  let fileName = FileName;
  const href = URL.createObjectURL(new Blob([response.data], { type: `application/${type}` }));
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  toast.success(formatMessage("downloaded_started_successfully"))
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

// export const downloadReportPdfFile = (response: AxiosResponse, reportName: string) => {
//   let fileName = reportName;
//   const href = URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
//   const link = document.createElement("a");
//   link.href = href;
//   link.download = fileName;
//   document.body.appendChild(link);
//   link.click();
//   toast.success(formatMessage("downloaded_started_successfully"))
//   document.body.removeChild(link);
//   URL.revokeObjectURL(href);
// };

export const downloadFileTest = (response: AxiosResponse, url: string) => {
  if (response.data !== null && response.data !== "") {
    const tenantName = window.location.pathname.split('/')[1];
    const token = getToken();
    const href = `${baseURL}/${tenantName}/${url}?authorization=${token}&fileId=${response.data}`;
    // const href = `http://localhost:7004/api/Files/Download?authorization=${token}&fileId=${response.data}`;
    // const href = `http://localhost:7008/api/FileDownLoad/2`;
    window.location.href = href;
  }
};