/*    
<summary>
  This functional component "AddUpdateCustomerSetting" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-June-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { ICustomerList, IPDFSetting } from '../../../models/response/ICustomerResponse';
import { formatMessage } from '../../../translations/formatMessage';
import AddUpdateCustomerSettingForm from '../forms/add-edit-customer-setting-form';

interface IProps {
  modalClosed: () => void;
}

const AddUpdateCustomerSetting = (props: IProps) => {

  const { customerStore } = useStore();
  const { GetPDFSettingByIdService, resetAddUpdateSettingState, resetGetSettingDetailState, settingDetailState, getSettingDetail, addUpdateSettingState, UpdatePDFSettingService} = customerStore;
  const initialValues: IPDFSetting = getSettingDetail;
  const{ modalClosed}=props

  const onModalClosed = useCallback(() => {
    resetGetSettingDetailState();
    resetAddUpdateSettingState();
     modalClosed();
  },[resetGetSettingDetailState,resetAddUpdateSettingState,modalClosed])
 
  useEffect(() => {
      GetPDFSettingByIdService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addUpdateSettingState.success) {
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateSettingState.success]);

  useEffect(() => {
    if (addUpdateSettingState.error) {
        toast.error(formatMessage(addUpdateSettingState.error));
        resetAddUpdateSettingState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateSettingState.error]);

  const onSubmitForm = (values: IPDFSetting) => {
    UpdatePDFSettingService(values);
  }

  return (
    <React.Fragment>
      <AddUpdateCustomerSettingForm
        initialValues={initialValues}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={settingDetailState.inProgress}
        addUpdateInProgress={addUpdateSettingState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateCustomerSetting);
