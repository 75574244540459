import React, { useState } from 'react';

const AddPopupHOC = (Component: any) => 
function Comp(props: any){
    const [showAddPopup,setShowAddPopup] = useState<boolean>(false);

    const addToggleHandler = () => {
        setShowAddPopup(!showAddPopup);
    }
	
	return <Component showAddPopup={showAddPopup} addToggleHandler={addToggleHandler} {...props} />;
};

export default AddPopupHOC;