import alarmStore, { AlarmStore } from './alarm-store';
import authStore, { AuthStore } from './auth-store';
import clientStore, { ClientStore } from './client-store';
import currencyStore, { CurrencyStore } from './currency-store';
import customerDashboardStore, { CustomerDashboardStore } from './customer-dashboard-store';
import customerStore, { CustomerStore } from './customer-store';
import dashboardStore, { DashboardStore } from './dashboard-store';
import driverStore, { DriverStore } from './driver-store';
import languageStore, { LanguageStore } from './language-store';
import paymentStore, { PaymentStore } from './payment-store';
import preferencesStore, { PreferencesStore } from './preferences-store';
import reportStore, { ReportStore } from './report-store';
import roleStore, { RoleStore } from './role-store';
import superAdminDashboardStore, { SuperAdminDashboardStore } from './superadmin-dashboard-store';
import supplierStore, { SupplierStore } from './supplier-store';
import transactionsStore, { TransactionsStore } from './transactions-store';
import truckStore, { TruckStore } from './truck-store';
import userStore, { UserStore } from './user-store';
import vehicleStore, { VehicleStore } from './vehicle-store';
import verifyEmailStore, { VerifyEmailStore } from './verify-email-store';

export type RootStore = {
    authStore: AuthStore;
    languageStore: LanguageStore;
    userStore: UserStore;
    transactionsStore: TransactionsStore;
    driverStore: DriverStore;
    alarmStore: AlarmStore;
    vehicleStore: VehicleStore;
    clientStore: ClientStore;
    customerStore: CustomerStore;
    supplierStore: SupplierStore;
    preferencesStore: PreferencesStore;
    roleStore: RoleStore;
    truckStore: TruckStore;
    dashboardStore: DashboardStore;
    superAdminDashboardStore: SuperAdminDashboardStore;
    verifyEmailStore: VerifyEmailStore;
    reportStore: ReportStore;
    currencyStore: CurrencyStore;
    paymentStore: PaymentStore;
    customerDashboardStore: CustomerDashboardStore;
}

const rootStore: RootStore = {
    authStore,
    languageStore,
    userStore,
    transactionsStore,
    driverStore,
    alarmStore,
    vehicleStore,
    clientStore,
    customerStore,
    preferencesStore,
    roleStore,
    truckStore,
    dashboardStore,
    superAdminDashboardStore,
    verifyEmailStore,
    reportStore,
    currencyStore,
    paymentStore,
    customerDashboardStore,
    supplierStore
};

export default rootStore;