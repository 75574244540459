import "./forget-password-form.scss";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../../contexts/StoreProvider";
import { Field, Form, Formik } from "formik";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import base64 from "base-64";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatMessage } from "../../../translations/formatMessage";
import { toast } from "react-toastify";
import RoutesConstants from "../../../constants/routes.constant";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import { forgetPasswordAuthSchema, forgetPasswordOTPSchema, UserChangePasswordValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import FormikFormOTPInput from "../../../shared-components/FormikFormHandlers/FormikFormOTPInput";
import { Button, NavLink } from "react-bootstrap";

const VerifyEmailForm = (props: any) => {
    const navigate = useNavigate();
    const { verifyEmailStore } = useStore();
    const {
        isUserVerifiedByOTPState,
        isUserVerifiedByOTP,
        VerifyUserByOTPService,
        oneTimePassword,
        UpdateUserPasswordByOTPService,
        isPasswordUpdatedState,
        resetIsUserVerifiedByOTPState,
        resetStore,
        ResendOTPService,
        isOtpResendState,
        resetIsResendOTP
    } = verifyEmailStore;
    const [showHidePasswordNew, setShowHidePasswordNew] = useState(false);
    const [showHidePasswordConfirm, setShowHidePasswordConfirm] = useState(false);
    const [email, setEmail] = useState(props.email ?? "");
    const [d1, setD1] = useState("");
    const [d2, setD2] = useState("");
    const [d3, setD3] = useState("");
    const [d4, setD4] = useState("");
    const [d5, setD5] = useState("");
    const [d6, setD6] = useState("");

    const initialValuesOTP = {
        Email: props.email ?? "",
    };

    const initialValuesNewPass = {
        Digit1: isUserVerifiedByOTP == true ? d1 : "",
        Digit2: isUserVerifiedByOTP == true ? d2 : "",
        Digit3: isUserVerifiedByOTP == true ? d3 : "",
        Digit4: isUserVerifiedByOTP == true ? d4 : "",
        Digit5: isUserVerifiedByOTP == true ? d5 : "",
        Digit6: isUserVerifiedByOTP == true ? d6 : "",
        NewPassword: "",
        ConfirmPassword: "",
    };

    // const submitEmailHandler = (values: any) => {
    //     VerifyUserPasswordResetService(values.Email);
    // };

    const submitOTPHandler = (values: any) => {
        let temp = { ...values };
        var otp = temp.Digit1 + temp.Digit2 + temp.Digit3 + temp.Digit4 + temp.Digit5 + temp.Digit6;
        setD1(temp.Digit1);
        setD2(temp.Digit2);
        setD3(temp.Digit3);
        setD4(temp.Digit4);
        setD5(temp.Digit5);
        setD6(temp.Digit6);
        VerifyUserByOTPService(temp.Email, otp);
        setEmail(() => { return temp.Email });
    };

    const submitNewPass = (values: any) => {
        let temp = { ...values };
        let data = {
            Email: email,
            NewPassword: temp.NewPassword,
            RepeatPassword: temp.ConfirmPassword,
        };
        UpdateUserPasswordByOTPService(data);
    };

    // useEffect(() => {
    //     if (isPasswordUpdatedState.error) {
    //         toast.error(formatMessage(isPasswordUpdatedState.error));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isPasswordUpdatedState.error]);

    useEffect(() => {
        if (isPasswordUpdatedState.success) {
            toast.success(formatMessage("password_update_success"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPasswordUpdatedState.success]);

    useEffect(() => {
        if (isOtpResendState.success) {
            resetIsResendOTP();
            toast.success(formatMessage("otp_sent_success"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOtpResendState.success]);

    useEffect(() => {
        if (isOtpResendState.error) {
            toast.error(formatMessage(isOtpResendState.error));
            resetIsResendOTP();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOtpResendState.error]);

    useEffect(() => {
        if (isUserVerifiedByOTPState.error) {
            toast.error(formatMessage(isUserVerifiedByOTPState.error));
            resetIsUserVerifiedByOTPState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserVerifiedByOTPState.error]);

    useEffect(() => {
        if (isUserVerifiedByOTPState.success) {
            toast.success(formatMessage("otp_verified_success"));
            resetIsUserVerifiedByOTPState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserVerifiedByOTPState.success]);

    useEffect(() => {
        if (isPasswordUpdatedState.success) {
            resetStore();
            navigate(RoutesConstants.Login);
            // // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
            // const timeoutId = setTimeout(() => {
            //     navigate(RoutesConstants.Login);
            // }, 2000);
            // // Cleanup function to clear the timeout if the component unmounts
            // return () => clearTimeout(timeoutId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPasswordUpdatedState.success]);

    const handleInput = (event: any, nextInput: any) => {
        const inputLength = event.target.value.length;

        if (inputLength === 1 && nextInput) {
            document.getElementById(nextInput)?.focus();
        }
    }

    /* Returning JSX */
    return (
        <React.Fragment>
            {(isOtpResendState.inProgress || isUserVerifiedByOTPState.inProgress || isPasswordUpdatedState.inProgress) && (
                <SpinnerLoader />
            )}

            {/* {isUserExists == false ? (
                <>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={forgetPasswordAuthSchema}
                        validateOnBlur={false}
                        onSubmit={async (values) => {
                            setEmail(values.Email);
                            submitEmailHandler(values);
                        }}
                    >
                        <Form className="login p-3">
                            <div className="display d-flex justify-content-between align-items-center">
                                <h1 className="forgetPassHeading login_heading fs-3 mb-0 fw-bold lh-sm">
                                    <FormattedMessage id={"Verify Email"} />
                                </h1>
                            </div>

                            <div className="form-group mb-1 mt-3 row">
                                <Label
                                    className="label-style col-sm-12 col-form-label"
                                    required={true}
                                    label="Email Id"
                                />
                                <div className="col-sm-12">
                                    <Field
                                        data-testid="testEmail"
                                        className="form-control"
                                        name="Email"
                                        type="text"
                                        disabled={isUserExists}
                                        invalidcharacter={[" "]}
                                        validcharacter={[
                                            "-",
                                            "_",
                                            "[",
                                            "]",
                                            "(",
                                            ")",
                                            ":",
                                            "#",
                                            "@",
                                            ".",
                                        ]}
                                        placeholder="Email Id"
                                        as={FormikFormInput}
                                    />
                                </div>
                            </div>

                            <LoaderButton
                                type="submit"
                                className="btn btn-primary btn-user mt-2"
                                isLoading={props.isLoading}
                                text="verify_email"
                            />
                            <p
                                className="mt-3 back_to_login"
                                onClick={() => {
                                    resetStore();
                                    navigate(RoutesConstants.Login);
                                }}
                            >
                                <FormattedMessage id={"back_to_login"} />
                            </p>
                        </Form>
                    </Formik>
                </>
            ) : (
                ""
            )} */}

            {isUserVerifiedByOTP == false ? (
                <Formik
                    initialValues={initialValuesOTP}
                    validationSchema={forgetPasswordOTPSchema}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        submitOTPHandler(values);
                    }}
                >
                    {({ values }) => (
                        <Form className="login p-3">
                            <div className="display d-flex justify-content-between align-items-center">
                                <h1 className="forgetPassHeading login_heading">
                                    <FormattedMessage id={"verify_email"} />
                                </h1>
                            </div>
                            <div className="form-group mb-1 mt-3 row">
                                <Label
                                    className="label-style col-sm-12 col-form-label"
                                    required={true}
                                    label="user_label_login_id"
                                />
                                <div className="col-sm-12 d-flex">
                                    <Field
                                        data-testid="testEmail"
                                        className="form-control"
                                        name="Email"
                                        type="text"
                                        // disabled={isUserExists}
                                        invalidcharacter={[" "]}
                                        validcharacter={[
                                            "-",
                                            "_",
                                            "[",
                                            "]",
                                            "(",
                                            ")",
                                            ":",
                                            "#",
                                            "@",
                                            ".",
                                        ]}
                                        placeholder="user_label_login_id"
                                        tolowercase={+true}
                                        as={FormikFormInput}
                                    />
                                    {/* {isUserExists && isUserExists == true ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="#00D85C"
                                        className="bi bi-check-circle-fill mx-2 my-1 mt-2"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                ) : (
                                    ""
                                )} */}
                                </div>
                            </div>
                            <div className="form-group mb-1 row">
                                <Label
                                    className="label-style col-sm-12 col-form-label"
                                    required={true}
                                    label="otp"
                                />
                                <div className="col-sm-12 d-flex">
                                    <Field
                                        testid="input1"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit1"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, 'input2')}
                                        as={FormikFormOTPInput}
                                    />
                                    <Field
                                        testid="input2"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit2"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, 'input3')}
                                        as={FormikFormOTPInput}
                                    />
                                    <Field
                                        testid="input3"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit3"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, 'input4')}
                                        as={FormikFormOTPInput}
                                    />
                                    <Field
                                        testid="input4"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit4"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, 'input5')}
                                        as={FormikFormOTPInput}
                                    />
                                    <Field
                                        testid="input5"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit5"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, 'input6')}
                                        as={FormikFormOTPInput}
                                    />
                                    <Field
                                        testid="input6"
                                        className="form-control otp mx-1 fw-bold"
                                        name="Digit6"
                                        type="number"
                                        onInput={(e: any) => handleInput(e, null)}
                                        as={FormikFormOTPInput}
                                    />
                                </div>
                                {values.Email && <NavLink onClick={() => { ResendOTPService(values.Email) }} className="mb-0 d-flex justify-content-end text-danger text-decoration-none" aria-current="page">
                                    <FormattedMessage id="Resend OTP" />
                                </NavLink>}
                            </div>
                            <LoaderButton
                                type="submit"
                                className="btn btn-primary btn-user mt-2"
                                isLoading={props.isLoading}
                                disabled={props.isLoading}
                                text="verify_otp"
                            />
                            <p
                                className="mt-3 back_to_login"
                                onClick={() => {
                                    resetStore();
                                    navigate(RoutesConstants.Login);
                                }}
                            >
                                <FormattedMessage id={"back_to_login"} />
                            </p>
                        </Form>
                    )}
                </Formik>
            ) : (
                ""
            )}

            {isUserVerifiedByOTP == true && isPasswordUpdatedState.success == false ? (
                <Formik
                    initialValues={initialValuesNewPass}
                    validationSchema={UserChangePasswordValidateSchema}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                        submitNewPass(values);
                    }}
                >
                    <Form className="login forgot-password p-3">
                        <div className="display d-flex justify-content-between align-items-center">
                            <h1 className="forgetPassHeading login_heading">
                                <FormattedMessage id={"Create Password"} />
                            </h1>
                        </div>
                        <div className="form-group row mt-1">
                            <Label
                                className="label-style col-sm-12 col-form-label position-relative"
                                required={true}
                                label="Enter New Password"
                            />

                            <div className="col-sm-12 position-relative">
                                <Field
                                    data-testid="testPassword"
                                    className="form-control"
                                    name="NewPassword"
                                    type={showHidePasswordNew ? "text" : "password"}
                                    placeholder="Enter New Password"
                                    invalidcharacter={[" "]}
                                    validcharacter={[
                                        "~",
                                        "`",
                                        "!",
                                        "@",
                                        "#",
                                        "$",
                                        "%",
                                        "^",
                                        "&",
                                        "*",
                                        "(",
                                        ")",
                                        "_",
                                        "-",
                                        "+",
                                        "=",
                                        "{",
                                        "[",
                                        "}",
                                        "]",
                                        "|",
                                        "\\",
                                        ":",
                                        ";",
                                        "'",
                                        '"',
                                        "<",
                                        ",",
                                        ">",
                                        ".",
                                        "?",
                                        "/",
                                    ]}
                                    as={FormikFormInput}
                                />
                                {showHidePasswordNew ? (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowHidePasswordNew(!showHidePasswordNew);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePassForget css-i6dzq1"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                ) : (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowHidePasswordNew(!showHidePasswordNew);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePassForget css-i6dzq1"
                                    >
                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                        <line x1="1" y1="1" x2="23" y2="23"></line>
                                    </svg>
                                )}
                            </div>
                        </div>
                        <div className="form-group row position-relative">
                            <Label
                                className="label-style  col-form-label pr-0"
                                required={true}
                                label="Confirm New Password"
                            />
                            <div className="col-sm-12 position-relative">
                                <Field
                                    data-testid="testPassword"
                                    className="form-control"
                                    name="ConfirmPassword"
                                    type={showHidePasswordConfirm ? "text" : "password"}
                                    placeholder="Confirm New Password"
                                    invalidcharacter={[" "]}
                                    validcharacter={[
                                        "~",
                                        "`",
                                        "!",
                                        "@",
                                        "#",
                                        "$",
                                        "%",
                                        "^",
                                        "&",
                                        "*",
                                        "(",
                                        ")",
                                        "_",
                                        "-",
                                        "+",
                                        "=",
                                        "{",
                                        "[",
                                        "}",
                                        "]",
                                        "|",
                                        "\\",
                                        ":",
                                        ";",
                                        "'",
                                        '"',
                                        "<",
                                        ",",
                                        ">",
                                        ".",
                                        "?",
                                        "/",
                                    ]}
                                    as={FormikFormInput}
                                />
                                {showHidePasswordConfirm ? (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowHidePasswordConfirm(!showHidePasswordConfirm);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePassForget css-i6dzq1"
                                    >
                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                ) : (
                                    <svg
                                        type="button"
                                        onClick={() => {
                                            setShowHidePasswordConfirm(!showHidePasswordConfirm);
                                        }}
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="showHidePassForget css-i6dzq1"
                                    >
                                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                        <line x1="1" y1="1" x2="23" y2="23"></line>
                                    </svg>
                                )}
                            </div>
                        </div>
                        <LoaderButton
                            type="submit"
                            className="btn btn-primary btn-user mt-4"
                            isLoading={props.isLoading}
                            disabled={props.isLoading}
                            text="submit_button"
                        />
                    </Form>
                </Formik>
            ) : (
                ""
            )}
        </React.Fragment>
    );
};

export default observer(VerifyEmailForm);
