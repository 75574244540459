import { useState } from 'react';

const AddInvoiceDetailsHoc = (Component: any) =>
    function Comp(props: any) {
        const [showAddInvoiceDetailsPopup, setShowAddInvoiceDetailsPopup] = useState<boolean>(false);

        const addInvoiceDetailsToggleHandler = () => {
            setShowAddInvoiceDetailsPopup(!showAddInvoiceDetailsPopup);
        }

        return <Component showAddInvoiceDetailsPopup={showAddInvoiceDetailsPopup} addInvoiceDetailsToggleHandler={addInvoiceDetailsToggleHandler} {...props} />;
    };

export default AddInvoiceDetailsHoc;