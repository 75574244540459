// import base64 from 'base-64';
import { action, computed, makeObservable, observable } from 'mobx';
import UrlConstants from '../../constants/url.constant';
import ILogin from '../../models/ILogin';
import * as services from '../service/base-service';
import IApiResponse, { IApiSuccessResponse } from '../../models/response/IApiResponse';
import IAuthResponse from '../../models/response/IAuthResponse';
import { IAuthState } from '../../models/state/IAuthState';
import { ICommonState } from '../../models/state/ICommonState';
import { IUserPermission } from '../../models/response/IUserPermissionResponse';
import RoutesConstants from '../../constants/routes.constant';
import { toast } from 'react-toastify';
import { formatMessage } from '../../translations/formatMessage';
import { initialCustomerDashboardFilter } from '../initialState/initial-filter-states';

export class AuthStore implements IAuthState, ICommonState {
    inProgress = false;
    error = '';
    tenantDetailInProgress = false;
    tenantDetailIsSuccess = false;
    tenantDetailError = '';
    isAuthenticated = false;
    token = '';
    userId = '';
    userType = '';
    userName = '';
    userPermission: IUserPermission = {};
    userPermissionInProgress = false;
    userPermissionError = '';
    version: string = "";

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            isAuthenticated: observable,
            userType: observable,
            userPermission: observable,
            userPermissionError: observable,
            userPermissionInProgress: observable,
            version: observable,
            reset: action,
            login: action,
            logout: action,
            updateToken: action,
            getVersionService: action,
            resetUserPermission: action,
            getAllUserPermission: computed
        });
    }

    get getAllUserPermission() {
        if (this.userPermission) {
            return { ...this.userPermission };
        }
        return {};
    }

    reset = () => {
        this.error = '';
        this.isAuthenticated = false;
    }

    get getUserType() {
        return localStorage.getItem('secure_fuel_userType');
    }

    login = (data: ILogin) => {
        this.inProgress = true;
        this.error = '';
        // const authData = { email: data.Email, password:data.Password };
        // this.isAuthenticated=true;
        // localStorage.setItem('token', "abcd");
        return services.post(UrlConstants.Authenticate, data)
            .then((response: IApiResponse<IApiSuccessResponse<IAuthResponse>>) => {
                const data = response.data.Data;
                const obj = {
                    authToken: data.Token,
                    userName: data.Username,
                    userId: data.Id,
                    userType: data.UserType,
                    Permissions: data.Permissions
                }
                this.userPermission = obj.Permissions
                let tempAmountDecimal = data.AmountDecimal !== 0 ? 3 : 2;
                localStorage.setItem('secure_fuel_token', obj.authToken);
                localStorage.setItem('secure_fuel_userName', obj.userName);
                localStorage.setItem('secure_fuel_userId', obj.userId);
                localStorage.setItem('secure_fuel_userType', obj.userType);
                localStorage.setItem('secure_fuel_refreshToken', data.RefreshToken);
                localStorage.setItem('secure_fuel_userPermissions', JSON.stringify(obj.Permissions))
                localStorage.setItem('secure_fuel_settings', JSON.stringify(data.Settings))
                localStorage.setItem('secure_fuel_clientId', data.ClientId)
                localStorage.setItem('secure_fuel_amount_decimal', tempAmountDecimal.toString())
                localStorage.setItem('secure_fuel_profileImage', data.ProfileImage ? data.ProfileImage : "")
                localStorage.setItem('secure_fuel_customerId', data.CustomerId ? String(data.CustomerId) : "-1")
                // if(obj.timezoneId) {
                //     localStorage.setItem('secure_fuel_timezoneId', obj.timezoneId.toString());
                // }
                if (obj.userType === "ROLE_CUSTOMER_ADMIN") {
                    localStorage.setItem("secure_fuel_customer_dashboard_filter", JSON.stringify({ ...initialCustomerDashboardFilter }));
                }
                this.userId = obj.userId;
                this.userType = obj.userType;
                this.isAuthenticated = true;
                this.getVersionService();
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => {
                this.inProgress = false;
            }));
    }

    // login = (data: ILogin) => {
    //     this.inProgress = true;
    //     this.error = '';
    //     this.isAuthenticated = true;
    //     const authData = {
    //         Username: data.username, Password: base64.encode(data.password), isTenant:true
    //     };
    //     // this.isAuthenticated=true;
    //     return services.post("http://13.113.229.4:8080/sumit/centra.identity/api/Identity/Authenticate", authData)
    //         .then((response: IApiResponse<IAuthResponse>) => {
    //             const obj = {
    //                 authToken: response.data.AuthToken,
    //                 userName: data.username,
    //                 userId: response.data.UserId,
    //                 userType: response.data.UserType,
    //                 timezoneId: response.data.TimezoneId,
    //                 userPermission: response.data.UserPermissions
    //             }
    //             this.userPermission = obj.userPermission
    //             localStorage.setItem('secure_fuel_token', obj.authToken);
    //             localStorage.setItem('secure_fuel_userName', obj.userName);
    //             localStorage.setItem('secure_fuel_userId', obj.userId);
    //             localStorage.setItem('secure_fuel_userType', obj.userType);
    //             localStorage.setItem('secure_fuel_refreshToken', response.data.RefreshToken);
    //             localStorage.setItem('secure_fuel_userPermission', JSON.stringify(obj.userPermission))
    //             if (obj.timezoneId) {
    //                 localStorage.setItem('secure_fuel_timezoneId', obj.timezoneId.toString());
    //             }
    //             this.userId = obj.userId;
    //             this.userType = obj.userType;
    //             this.isAuthenticated = true;

    //         })
    //         .catch((err: string) => {
    //             this.error = err;
    //         })
    //         .finally(action(() => {
    //             this.inProgress = false;
    //         }));
    // }

    logout = () => {
        return services.post(UrlConstants.SignOut, {})
            .then((response: IApiResponse<IAuthResponse>) => {
                localStorage.clear();
                window.location.href = RoutesConstants.Login;
                this.reset();
                this.resetUserPermission();
            })
            .catch((err: string) => {
                this.error = err;
            })
            .finally(action(() => {
                this.inProgress = false;
            }));

        // return Promise.resolve();
    }

    getVersionService = () => {
        this.inProgress = true;
        const url = UrlConstants.GetVerion;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<{ Version: string }>>) => {
                this.version = response.data.Data.Version;
                localStorage.setItem('secure_fuel_version', this.version);
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
            }).finally(action(() => { this.inProgress = false; }));
    }

    updateToken = (data: IAuthResponse) => {
        localStorage.setItem('secure_fuel_token', data.Token);
        localStorage.setItem('secure_fuel_refreshToken', data.RefreshToken);
        this.token = data.Token;
    }

    resetUserPermission = () => {
        this.userPermission = {};
        this.userPermissionError = '';
        this.userPermissionInProgress = false;
    }
}

export default new AuthStore();
