import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import { IGetTransactions, ITransaction } from "../../models/ITransaction";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import NoData from "../../assets/Images/no_record.png";
import _ from "lodash";
import Footer from "../../shared-components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { IReportInfo } from "../../models/response/IReportResponse";
import { Col, Container, Modal, Row } from "react-bootstrap";
import "./report-preview.scss";
import { TransactionReportTypeEnum } from "../../constants/enums/transaction-report-type-enum";
import { IPaymentViewReport } from "../../models/response/IPaymentResponse";
import Pagination from "../../shared-components/Grid/Pagination";
import {
  initialPageLimit,
  PageLimitOptions,
} from "../../constants/pageLimit.constant";
import IPageInfo from "../../models/IPageInfo";
import preferencesStore from "../../core/stores/preferences-store";
import { emptyValue } from "../../constants/common-constant";
import { getAmountDecimal } from "../../helpers/localStorages.helper";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";

interface IProps {
  data: IReportInfo;
  onModalClosed: () => void;
  actionInProgress: boolean;
  transactionReportType: TransactionReportTypeEnum;
}

const ReportPreview: React.FC<IProps> = (props: IProps) => {
  const navigate = useNavigate();
  const getToken: any = localStorage.getItem("secure_fuel_token");
  const { reportStore, languageStore, authStore } = useStore();
  const { isAuthenticated } = authStore;
  const { userPageLimitSetting, setUserPageLimitSetting } = preferencesStore;
  const {
    reportViewDataState, reportViewData, GetAllReportViewDataService,
    getAllReportViewData, getAllPaymentReportViewData, paymentReportViewData, } = reportStore;
  const { data, onModalClosed, transactionReportType } = props;

  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const allTransactionsData: Array<IGetTransactions> = getAllReportViewData;
  const allPaymentData: Array<IPaymentViewReport> = getAllPaymentReportViewData;
  const [sortingForTransactions, setSortingForTransactions] =
    useState<ISorting>({
      column: "ts_start",
      orderBy: "desc",
    });
  let decimalValue = getAmountDecimal();
  const handleSortingChange = (accessor: string) => {
    const sortOrder = sortingForTransactions.orderBy === "asc" ? "desc" : "asc";
    setSortingForTransactions({ column: accessor, orderBy: sortOrder });
  };

  const { height, width } = useWindowDimensions();

  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.driverLimitConfig);

  const onPageLimitChanged = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setPageInfo({ ...pageInfo, currentPage: 1 });
  };
  const [sorting, setSorting] = useState<ISorting>({
    column: "userName",
    orderBy: "asc",
  });

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  // const callGetAllTransactionsService = useCallback(() => {
  //   setDisablePagination(true);
  //   GetAllReportViewDataService(data);
  // }, [
  //   GetAllReportViewDataService,
  //   data
  // ]);

  const callGetAllTransactionsService = useCallback(() => {
    setDisablePagination(true);
    GetAllReportViewDataService(
      data,
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column
    );
  }, [
    GetAllReportViewDataService,
    data,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
  ]);

  useEffect(() => {
    if (data !== null) callGetAllTransactionsService();
  }, [callGetAllTransactionsService, data]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: paymentReportViewData?.TotalPages,
    }));
    setDisablePagination(false);
  }, [paymentReportViewData.TotalPages]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: reportViewData?.TotalPages,
    }));
    setDisablePagination(false);
  }, [reportViewData, reportViewData.TotalPages]);

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    // setFilterDetail({ ...reportViewDataFilterState });
  };


  const columns = getColumns<ITransaction, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TicketNumber",
        // sortingName: "ticket_number",
        displayName: "transactions_label_ticketnumber",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TsStart",
        // sortingName: "ts_start",
        displayName: "transactions_label_starttime",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TsFinish",
        // sortingName: "ts_finish",
        displayName: "transactions_label_endtime",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TruckId",
        // sortingName: "truck_id",
        displayName: "transactions_label_truckid",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "ProductId",
        // sortingName: "product_id",
        displayName: "transactions_label_productid",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "GrossDelivered",
        // sortingName: "gross_delivered",
        displayName: "transactions_label_grossdelivered",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "EndGross",
        // sortingName: "end_gross",
        displayName: "transactions_label_endgross",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "FlowAvg",
        // sortingName: "flow_avg",
        displayName: "transactions_label_flowaverage",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "FlowMax",
        // sortingName: "flow_max",
        displayName: "transactions_label_flowmax",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "PricePerL",
        // sortingName: "price_per_l",
        displayName: "unitprice",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "VatAmount",
        // sortingName: "vat",
        displayName: "transactions_label_vat",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "AmountDue",
        // sortingName: "amount_due",
        displayName: "transactions_label_amountdue",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "OperatorRfid",
        // sortingName: "operator_rfid",
        displayName: "transactions_label_operatorRfid",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TsGen",
        // sortingName: "TsGen",
        displayName: "transactions_label_gentimestamp",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "TsSend",
        // sortingName: "TsSend",
        displayName: "transactions_label_sendtimestamp",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId1",
        // sortingName: "customer_id_1",
        displayName: "transactions_label_customer_id_1",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId2",
        // sortingName: "customer_id_2",
        displayName: "transactions_label_customer_id_2",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId3",
        // sortingName: "customer_id_3",
        displayName: "transactions_label_customer_id_3",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId4",
        // sortingName: "customer_id_4",
        displayName: "transactions_label_customer_id_4",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId5",
        // sortingName: "customer_id_5",
        displayName: "transactions_label_customer_id_5",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerId6",
        // sortingName: "customer_id_6",
        displayName: "transactions_label_customer_id_6",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvName1",
        displayName: "transactions_label_inv_name1",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvValue1",
        displayName: "transactions_label_inv_value1",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvUnit1",
        displayName: "transactions_label_inv_unit1",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvName2",
        displayName: "transactions_label_inv_name2",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvValue2",
        displayName: "transactions_label_inv_value2",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "InvUnit2",
        displayName: "transactions_label_inv_unit2",
      },
    ],
    sortingForTransactions,
    handleSortingChange
  );

  const paymentColumns = getColumns<IPaymentViewReport, number>([
    {
      columnType: ColumnType.Hidden,
      accessorKey: "Id",
    },
    {
      columnType: ColumnType.TextHeader,
      accessorKey: "PaymentDate",
      displayName: "payment_date",
    },
    {
      columnType: ColumnType.TextHeader,
      accessorKey: "AmountCr",
      displayName: "payment_amount_credited",
    },
    {
      columnType: ColumnType.TextHeader,
      accessorKey: "ModeOfPayment",
      displayName: "payment_mode",
    },
    {
      columnType: ColumnType.TextHeader,
      accessorKey: "Description",
      displayName: "payment_description",
    },
  ]);

  return (
    <>
      <Modal
        className="report-preview-modal add-user-modal"
        show={true}
        centered
        size="xl"
      >
        <Modal.Header>
          <Modal.Title className="fs-5">
            <FormattedMessage id="report_view" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            {reportViewDataState.inProgress && <SpinnerLoader />}
            <Container fluid className="p-0">
              {allTransactionsData.length > 0 || allPaymentData.length > 0 ? (
                <div className="roleWrapper background-image ">
                  <div className="rounded shadow">

                    <div className="Fuel-table">
                      <div className="table-responsive"
                        style={getTableMaxHeight(height, width)}
                        data-testid="table"
                      >
                        <Grid
                          data={
                            transactionReportType ===
                              TransactionReportTypeEnum.PAYMENT_WISE
                              ? allPaymentData
                              : allTransactionsData
                          }
                          columns={
                            transactionReportType ===
                              TransactionReportTypeEnum.PAYMENT_WISE
                              ? paymentColumns
                              : columns
                          }
                          isInProgress={reportViewDataState.inProgress}
                          hiddenColumns={["Id"]}
                        />
                      </div>
                    </div>
                    <Container fluid>
                      <Row className="tableFooter py-1 mt-0">
                        <Col xs="2" className="d-flex">
                          <b className="text-white text-hidden-m mt-1 pt-1">
                            <FormattedMessage id="show" />:{" "}
                          </b>
                          <select
                            data-testid="pageSelect"
                            className="ms-2 from-control"
                            onChange={onPageLimitChanged}
                            value={pageLimit}
                          >
                            {PageLimitOptions.map((op) => (
                              <option key={op} value={op}>
                                {op}
                              </option>
                            ))}
                          </select>
                        </Col>

                        <Col className="d-flex justify-content-end" xs="10">

                          <Pagination
                            totalPages={pageInfo.totalPages}
                            pageLimit={pageLimit}
                            onPageChanged={onPageChanged}
                            disablePagination={disablePagination}
                          />
                        </Col>
                        <Col className="d-flex justify-content-center" xs="12">
                          <div className="total-report-review">
                            {transactionReportType ===
                              TransactionReportTypeEnum.PAYMENT_WISE ? (
                              <>
                                <b className="text-white mt-1 pt-1">
                                  <FormattedMessage id="Total Payments" />:{" "}
                                  {paymentReportViewData.TotalPayments !== null
                                    ? Number(paymentReportViewData.TotalPayments).toFixed(
                                      Number(decimalValue)
                                    )
                                    : emptyValue}
                                </b>
                              </>
                            ) : (
                              <>
                                <ul className="list-group list-group-horizontal">
                                  <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                    <FormattedMessage id="Total Transactions" />:{" "}
                                    {reportViewData.TotalTransactions}
                                  </li>
                                  <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                    <FormattedMessage id="Total Vat" />:{" "}
                                    {reportViewData.TotalVat !== null
                                      ? Number(reportViewData.TotalVat).toFixed(
                                        Number(decimalValue)
                                      )
                                      : emptyValue}
                                  </li>
                                  <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                    <FormattedMessage id="Total Amount Due" />:{" "}
                                    {reportViewData.TotalAmountDue !== null
                                      ? Number(reportViewData.TotalAmountDue).toFixed(
                                        Number(decimalValue)
                                      )
                                      : emptyValue}
                                  </li>
                                  <li className="list-group-item bg-transparent border-0 text-white d-flex">
                                    <FormattedMessage id="Total Volume Delivered" />:{" "}
                                    {reportViewData.TotalVolumeDelivered.toFixed(2)}
                                  </li>
                                </ul>

                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              ) : (
                <div className="no_data_height">
                  <div className="no_data w-100 text-capitalize text-center">
                    <img src={NoData} alt="NOData" className="NOData" />
                    <h1 className="text-capitalize fs-2">
                      <FormattedMessage id="message_no_datafound" />
                    </h1>
                  </div>
                </div>
              )}
            </Container>
          </React.Fragment>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default observer(ReportPreview);