const MAX_WIDTH = 320;
const MAX_HEIGHT = 180;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

export const getBase64 = (file: File | Blob) => {
  return new Promise(resolve => {
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

export const imageCompression64 = (fileUrl: string, filename: string) => {
  let compressedImage = "";
  const blobURL = window.URL.createObjectURL(dataURLtoFile(fileUrl, filename));
  const img = new Image();
  img.src = blobURL;
  img.onload = function (ev) {
    window.URL.revokeObjectURL(blobURL); // release memory
    // Use the img
  };
  const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
  const canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext("2d");
  ctx!.drawImage(img, 0, 0, newWidth, newHeight);
  canvas.toBlob(
    (blob) => {
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(blob!);

      // on reader load somthing...
      reader.onload = () => {
        compressedImage = reader.result as string
      };
    },
    MIME_TYPE,
    QUALITY
  );

  return compressedImage;
}

function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// function displayInfo(label:string, file:any) {
//   const p = document.createElement('p');
//   p.innerText = `${label} - ${readableBytes(file.size)}`;
// }

// function readableBytes(bytes:any) {
//   const i = Math.floor(Math.log(bytes) / Math.log(1024)),
//     sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
//   return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
// }
