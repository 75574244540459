import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { toast } from "react-toastify";
import { allOptionCustomerDashboard } from "../../constants/common-constant";
import UrlConstants from "../../constants/url.constant";
import { getAmountDecimal, getClientId, getCustomerId } from "../../helpers/localStorages.helper";
import { IDataPoint } from "../../models/IChartData";
import { IObservableInitialState } from "../../models/ICommon";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { ICustomerDashboardMetrics, ICustomerFuelStatusDetail, IDailyFuelUsage, IFuelStatusCustomer, IGetPricingDashboardMetrics, IPricingDashboardMetrics, IRecentAlarms, ITankLevelDetail, ITruckStatus, IVolumeConsumed, IVolumeDelivered } from "../../models/response/IDashboardResponse";
import { ICustomerDashboardState } from "../../models/state/ICustomerDashboardState";
import { formatMessage } from "../../translations/formatMessage";
import { customerDashboardGetPricingInitialState, customerDashboardMetricsInitialState, customerDashboardPricingInitialState, customerFuelStatusInitialDetail, dashboardRecentAlarmsInitialState, tankLevelInitialDetail } from "../initialState/add-edit-dashboard-state";
import { initialCustomerDashboardFilter } from "../initialState/initial-filter-states";
import * as services from '../service/base-service';

export class CustomerDashboardStore implements ICustomerDashboardState {
    inProgress = false;
    error = '';

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    fuelStatusDetail: IFuelStatusCustomer | undefined = undefined;
    fuelStatusDetailState: IObservableInitialState = { ...this.initialStateValue };

    dailyFuelUsageDetail: IDailyFuelUsage[] = [];
    dailyFuelUsageDetailState: IObservableInitialState = { ...this.initialStateValue };

    dashboardMetricsDetail: ICustomerDashboardMetrics = customerDashboardMetricsInitialState;
    dashboardMetricsDetailState: IObservableInitialState = { ...this.initialStateValue };

    dashboardPricingDetail: IPricingDashboardMetrics = customerDashboardPricingInitialState;
    dashboardPricingDetailState: IObservableInitialState = { ...this.initialStateValue };

    dashboardRecentAlarmsDetail: IRecentAlarms[] = dashboardRecentAlarmsInitialState;
    dashboardRecentAlarmsState: IObservableInitialState = { ...this.initialStateValue };

    tankLevelDetail: any = undefined;
    tankLevelDetailState: IObservableInitialState = { ...this.initialStateValue };

    VolumeDeliveredDetail: IVolumeDelivered | undefined = undefined;
    VolumeDeliveredDetailState: IObservableInitialState = { ...this.initialStateValue };

    VolumeConsumedDetail: IVolumeConsumed | undefined = undefined;
    VolumeConsumedDetailState: IObservableInitialState = { ...this.initialStateValue };
    date = new Date();

    filterSetting: any = {
        ...initialCustomerDashboardFilter
    };

    filterCase: string = "";

    truckStatusDetail: ITruckStatus[] = [];
    truckStatusDetailState: IObservableInitialState = { ...this.initialStateValue };

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            error: observable,
            fuelStatusDetail: observable,
            fuelStatusDetailState: observable,
            tankLevelDetail: observable,
            tankLevelDetailState: observable,
            dailyFuelUsageDetail: observable,
            dailyFuelUsageDetailState: observable,
            dashboardMetricsDetail: observable,
            dashboardMetricsDetailState: observable,
            VolumeDeliveredDetail: observable,
            VolumeDeliveredDetailState: observable,
            VolumeConsumedDetail: observable,
            VolumeConsumedDetailState: observable,
            dashboardRecentAlarmsState: observable,
            dashboardRecentAlarmsDetail: observable,
            filterSetting: observable,
            truckStatusDetail: observable,
            truckStatusDetailState: observable,
            filterCase: observable,
            dashboardPricingDetail: observable,
            dashboardPricingDetailState: observable,
            GetFuelStatusService: action,
            resetDashboardDataSuccessState: action,
            setFilterSetting: action,
            setFilterDateSetting: action,
            GetDailyFuelUsageService: action,
            setFilterCase: action,
            GetDashboardMetricsService: action,
            GetRecentAlarmsService: action,
            GetPricingService: action,
            GetTankLevelService: action,
            getFilterSettingInfo: computed,
            getFuelStatusDetail: computed,
            getDashboardDataSuccessState: computed,
            getDashboardMetricsDetail: computed,
            getDashboardPricingDetail: computed,
            getDailyFuelUsageChartData: computed,
            getTankLevelDetail: computed,
            getDashboardRecentAlarmsDetail: computed,
        });
    }


    GetFuelStatusService = (startDate: Date, endDate: Date) => {
        this.fuelStatusDetailState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const url = UrlConstants.GetFuelStatusForCustomer + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&customerIdLong=' + customerIdFromLocalStorage + '&customerId2=' + (this.filterSetting.FuelStatus.CustomerId2 === "All" ? "" : this.filterSetting.FuelStatus.CustomerId2) + '&customerId3=' + (this.filterSetting.FuelStatus.CustomerId3 === "All" ? "" : this.filterSetting.FuelStatus.CustomerId3);
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IFuelStatusCustomer>>) => {
                this.fuelStatusDetail = response.data.Data;
                this.fuelStatusDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.fuelStatusDetailState.inProgress = false; }));
    }

    get getFuelStatusDetail(): ICustomerFuelStatusDetail {
        if (this.fuelStatusDetail) {
            return {
                ...this.fuelStatusDetail,
                TotalFuelConsumed: (this.fuelStatusDetail.TotalFuelConsumed !== null && this.fuelStatusDetail.TotalFuelConsumed !== undefined) ? Number(this.fuelStatusDetail.TotalFuelConsumed).toFixed(2) : Number(customerFuelStatusInitialDetail.TotalFuelConsumed).toFixed(2),
            };
        }
        return customerFuelStatusInitialDetail;
    }

    GetDailyFuelUsageService = (startDate: Date, endDate: Date) => {
        this.dailyFuelUsageDetailState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const url = UrlConstants.GetDailyFuelUsageCustomer + '?startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&customerIdLong=' + customerIdFromLocalStorage + '&customerId2=' + (this.filterSetting.DailyFuelUsage.CustomerId2 === "All" ? "" : this.filterSetting.DailyFuelUsage.CustomerId2) + '&customerId3=' + (this.filterSetting.DailyFuelUsage.CustomerId3 === "All" ? "" : this.filterSetting.DailyFuelUsage.CustomerId3);
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<IDailyFuelUsage[]>>) => {
                this.dailyFuelUsageDetail = response.data.Data;
                this.dailyFuelUsageDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dailyFuelUsageDetailState.inProgress = false; }));
    }

    get getDailyFuelUsageChartData(): IDataPoint[] {
        if (this.dailyFuelUsageDetail && this.dailyFuelUsageDetail.length > 0) {
            return this.dailyFuelUsageDetail.map((item: IDailyFuelUsage) => {
                return { x: new Date(item.Day), y: item.Usage }
            })
        }
        return [];
    }

    GetDashboardMetricsService = () => {
        this.dashboardMetricsDetailState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetDashboardMetricsForCustomer}?customerIdLong=${customerIdFromLocalStorage}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<ICustomerDashboardMetrics>>) => {
                this.dashboardMetricsDetail = response.data.Data;
                this.dashboardMetricsDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dashboardMetricsDetailState.inProgress = false; }));
    }

    GetPricingService = () => {
        this.dashboardPricingDetailState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetPricingForCustomer}?id=${customerIdFromLocalStorage}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.dashboardPricingDetail = response.data.Data.CustomerAdmin;
                this.dashboardPricingDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dashboardPricingDetailState.inProgress = false; }));
    }

    GetRecentAlarmsService = () => {
        this.dashboardRecentAlarmsState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetRecentAlarmsForCustomer}?customerIdLong=${customerIdFromLocalStorage}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.dashboardRecentAlarmsDetail = response.data.Data;
                this.dashboardRecentAlarmsState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.dashboardRecentAlarmsState.inProgress = false; }));
    }

    GetTankLevelService = (startDate: Date, endDate: Date) => {
        this.tankLevelDetailState.inProgress = true;
        const customerIdFromLocalStorage = getCustomerId() != null ? getCustomerId() : -1;
        let startDateData = moment(startDate).format("YYYY-MM-DD");
        let endDateData = moment(endDate).format("YYYY-MM-DD");
        const tempStartDate = startDateData + " 00:00:00";
        const tempEndDate = endDateData + " 23:59:59";
        const url = UrlConstants.GetCompartmentFuelForCustomer + '?id=' + customerIdFromLocalStorage + '&startTime=' + tempStartDate + '&endTime=' + tempEndDate + '&truckIds=' + (this.filterSetting.TankLevel.TruckIds ? this.filterSetting.TankLevel.TruckIds : "");
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<ITankLevelDetail>>) => {
                this.tankLevelDetail = response.data.Data;
                this.tankLevelDetailState.success = true;
            })
            .catch((err: string) => {
                // this.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.tankLevelDetailState.inProgress = false; }));
    }

    get getTankLevelDetail(): ITankLevelDetail {
        if (this.tankLevelDetail) {
            return {
                ...this.tankLevelDetail,
                Compartment: this.tankLevelDetail.CompartmentType === 0 ? 1 : 2,
            };
        }
        return tankLevelInitialDetail;
    }

    get getDashboardRecentAlarmsDetail(): IRecentAlarms[] {
        if (this.dashboardRecentAlarmsDetail) {
            return this.dashboardRecentAlarmsDetail
        }
        else {
            return [];
        }
    }
    get getDashboardMetricsDetail(): ICustomerDashboardMetrics {
        if (this.dashboardMetricsDetail) {
            return this.dashboardMetricsDetail
        }
        else {
            return customerDashboardMetricsInitialState;
        }
    }

    get getDashboardPricingDetail(): IGetPricingDashboardMetrics {
        let decimalValue = getAmountDecimal();
        if (this.dashboardPricingDetail) {
            return {
                PricePerL: (this.dashboardPricingDetail.PricePerL !== null && this.dashboardPricingDetail.PricePerL !== undefined) ? Number(this.dashboardPricingDetail.PricePerL).toFixed(decimalValue) : Number(customerDashboardPricingInitialState.PricePerL).toFixed(decimalValue),
                PricePerGallon: (this.dashboardPricingDetail.PricePerGallon !== null && this.dashboardPricingDetail.PricePerGallon !== undefined) ? Number(this.dashboardPricingDetail.PricePerGallon).toFixed(decimalValue) : Number(customerDashboardPricingInitialState.PricePerGallon).toFixed(decimalValue),
                RemainingVolume: (this.dashboardPricingDetail.RemainingVolume !== null && this.dashboardPricingDetail.RemainingVolume !== undefined) ? Number(this.dashboardPricingDetail.RemainingVolume).toFixed(2) : Number(customerDashboardPricingInitialState.RemainingVolume).toFixed(2),
                ThresholdVolumeLimit: (this.dashboardPricingDetail.ThresholdVolumeLimit !== null && this.dashboardPricingDetail.ThresholdVolumeLimit !== undefined) ? Number(this.dashboardPricingDetail.ThresholdVolumeLimit).toFixed(2) : Number(customerDashboardPricingInitialState.ThresholdVolumeLimit).toFixed(2),
                MaxAmountLimit: (this.dashboardPricingDetail.MaxAmountLimit !== null && this.dashboardPricingDetail.MaxAmountLimit !== undefined) ? Number(this.dashboardPricingDetail.MaxAmountLimit).toFixed(decimalValue) : Number(customerDashboardPricingInitialState.MaxAmountLimit).toFixed(decimalValue),
                MaxVolumeLimit: (this.dashboardPricingDetail.MaxVolumeLimit !== null && this.dashboardPricingDetail.MaxVolumeLimit !== undefined) ? Number(this.dashboardPricingDetail.MaxVolumeLimit).toFixed(2) : Number(customerDashboardPricingInitialState.MaxVolumeLimit).toFixed(2),
            }
        }
        else {
            return customerDashboardGetPricingInitialState;
        }
    }

    resetDashboardDataSuccessState = () => {
        this.fuelStatusDetailState = { ...this.initialStateValue };
        this.dailyFuelUsageDetailState = { ...this.initialStateValue };
        this.dashboardMetricsDetailState = { ...this.initialStateValue };
        this.tankLevelDetailState = { ...this.initialStateValue };
        this.dashboardPricingDetailState = { ...this.initialStateValue };
    }

    get getDashboardDataSuccessState(): boolean {
        let success = this.fuelStatusDetailState.success && this.dailyFuelUsageDetailState.success && this.dashboardMetricsDetailState.success && this.tankLevelDetailState.success && this.dashboardPricingDetailState.success;
        return success;
    }

    setFilterSetting = (key: string, values: any, initial: boolean = false, setfromLocalStorage: boolean = false) => {
        if (setfromLocalStorage) {
            let tempFilterDetails = localStorage.getItem("secure_fuel_customer_dashboard_filter");
            if (tempFilterDetails != null)
                this.filterSetting = JSON.parse(tempFilterDetails);
        }
        else if (initial) {
            if (key === "FuelStatus" || key === "DailyFuelUsage") {
                this.filterSetting = {
                    ...this.filterSetting,
                    FuelStatus: allOptionCustomerDashboard,
                    DailyFuelUsage: allOptionCustomerDashboard,
                }
            }
            else if (key === "TankLevel") {
                console.log(values.TruckIds)
                this.filterSetting = {
                    ...this.filterSetting,
                    TankLevel: { TruckIds: values.TruckIds }
                }
            }

        }
        else {
            if (key === "FuelStatus") {
                this.filterSetting[key] = { CustomerId2: values.CustomerId2, CustomerId3: values.CustomerId3 };
            }
            else if (key === "DailyFuelUsage") {
                this.filterSetting[key] = { CustomerId2: values.CustomerId2, CustomerId3: values.CustomerId3 };
            }
            else if (key === "TankLevel") {
                this.filterSetting[key] = { TruckIds: values.TruckIds };
            }
        }
        localStorage.setItem("secure_fuel_customer_dashboard_filter", JSON.stringify(this.filterSetting));
    }

    setFilterDateSetting = (startDate: Date, endDate: Date) => {
        this.filterSetting.StartDate = startDate;
        this.filterSetting.EndDate = endDate;
        localStorage.setItem("secure_fuel_customer_dashboard_filter", JSON.stringify(this.filterSetting));
    }

    setFilterCase = (filtercase: string) => {
        this.filterCase = filtercase;
    }

    get getFilterSettingInfo(): any {
        let tempFilterDetails = localStorage.getItem("secure_fuel_customer_dashboard_filter");
        if (tempFilterDetails != null && JSON.stringify(this.filterSetting) !== JSON.stringify(tempFilterDetails)) {
            let tempFilterDetails = localStorage.getItem("secure_fuel_customer_dashboard_filter");
            if (tempFilterDetails != null) {
                const filterDetails = JSON.parse(tempFilterDetails);
                return filterDetails;
            }
            else
                return this.filterSetting;
        }
        else
            return this.filterSetting;
    }

}

export default new CustomerDashboardStore();