import { observer } from 'mobx-react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from '../../contexts/StoreProvider';
import { redirectToDefaultRoute } from '../../helpers/routesFunctions.helper';

const LoginLayout: React.FC<any> = (props) => {
    const { authStore } = useStore();
    const { isAuthenticated } = authStore;
    const getToken: any = localStorage.getItem("secure_fuel_token");
    return (
        <React.Fragment>
            {(!isAuthenticated &&!getToken)? props.children : <Navigate to={redirectToDefaultRoute()} />}
        </React.Fragment>
    );
};
export default observer(LoginLayout);
