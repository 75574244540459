/*    
<summary>
   This is the Driver Component which shows the Drivers Data.
   Developer: Aashish Singh, Created Date:04-mar-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter, IFormatter } from "../../models/IFormatter";
import Header from "../../shared-components/Header/Header";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Button, Container, Dropdown } from "react-bootstrap";
import DeletePopup from "../../shared-components/Popup/DeletePopup";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, ListGroup, Row } from "react-bootstrap";
import { IDriver, IDriverList } from "../../models/response/IDriverResponse";
import AddUpdateDriver from "./components/add-edit-driver";
import "./driver.scss";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import DriverDetails from "./components/driver-details";
import SlidingPanel from "react-sliding-side-panel";
import DriverFilterForm from "./forms/driver-filter-form";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import {
  isOperationPermitted,
  showIcons,
} from "../../helpers/routesFunctions.helper";
import { allocationPeriodColorSchemeDriver, emptyValue, notAvailable } from "../../constants/common-constant";
import { formatDateToText } from "../../helpers/common-helper-components";
import { getClientId, getTableView } from "../../helpers/localStorages.helper";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import NoData from "../../assets/Images/no_record.png";
import UrlConstants from "../../constants/url.constant";
import _ from "lodash";
import { driverInitialFilterState } from "../../core/initialState/initial-filter-states";
import userPageLimitSettingEnum from "../../constants/enums/user-pagination-setting-enum";


interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
}

const Driver: React.FC<IProps> = (props: IProps) => {
  const { driverStore, preferencesStore, customerStore } = useStore();
  const {
    tabKey,
    setTabKey,
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    driverList,
    GetDriverListService,
    getDriverList,
    reset,
    resetError,
    DeleteDriverService,
    DeleteDriverState,
    resetDeleteDriverState,
    addUpdateDriverState,
    resetGetDriverDetail,
    filter,
    setFilterDetail,
  } = driverStore;
  const { setUpdateTableView, CSVFileDownloadPostService, userPageLimitSetting, setUserPageLimitSetting } = preferencesStore;
  const { GetAllCustomerService } = customerStore;
  const [tableView, setTableView] = useState<boolean>(
    getTableView() ? (getTableView() == "false" ? false : true) : false
  );
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedRow, setSelectedRow] = useState<IDriverList | undefined>(undefined);
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const driverListData: Array<IDriverList> = getDriverList;
  const [pageLimit, setPageLimit] = useState<number>(userPageLimitSetting.driverLimitConfig);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "driver_name",
    orderBy: "asc",
  });
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let value = parseInt(evt.target.value);
    setPageLimit(value);
    setUserPageLimitSetting(userPageLimitSettingEnum.DriverLimitConfig, value);
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  const exportFilteredLogsCSV = () => {
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
    const url = `${UrlConstants.DriverCSVDownload}?clientId=${clientIdFromLocalStorage}`;
    tempFilterDetails = {
      ...tempFilterDetails,
      DLExpiryInDays:
        tempFilterDetails.DLExpiryInDays == -1
          ? null
          : tempFilterDetails.DLExpiryInDays,
    };
    CSVFileDownloadPostService(url, "Driver", tempFilterDetails);
  };

  /**
   * This function calls the "GetDriverListService" store funtion that get driver list from API
   */
  const callGetDriverList = useCallback(() => {
    setDisablePagination(true);
    GetDriverListService(
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column,
      _.omit(filter, ["isFilterActive"])
    );
    setIsApiCalled(true);
  }, [
    GetDriverListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    filter,
  ]);

  const [selectedCard, setSelectedCard] = useState<number>(-1);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (filter.isFilterActive) {
      callGetDriverList();
    } else callGetDriverList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, callGetDriverList]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: driverList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getDriverList, driverList.TotalPages]);

  useEffect(() => {
    if (addUpdateDriverState.success) {
      callGetDriverList();
    }
  }, [addUpdateDriverState.success, callGetDriverList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteDriverState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteDriverState();
      deleteToggleHandler();
      callGetDriverList();
    }
  }, [DeleteDriverState.success, reset, callGetDriverList]);

  useEffect(() => {
    if (DeleteDriverState.error) {
      toast.error(formatMessage(DeleteDriverState.error));
      resetDeleteDriverState();
    }
  }, [DeleteDriverState.error, resetDeleteDriverState]);

  /**
   * This function shows the add driver pop up from where we can add new driver and update existing driver. we use id to identify
   * that we are adding or updating driver.
   * "Id == -1" : we are adding new driver
   * "Id != -1" : means we are updating existing driver
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    GetAllCustomerService();
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  const openFilterHandler = () => {
    closePanelHandler();
    filterToggleHandler();
  };

  const filterSubmitHandler = (values: any) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...driverInitialFilterState });
  };

  /**
   * This function shows the delete driver confirmation popup component and sets the seleted driver Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    deleteToggleHandler();
  };

  /**
   * This function deletes the driver by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteDriverService(selectedId);
  };

  /**
   * This function shows the detail driver panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const rowEvents = (row: any) => {
    setSelectedCard(row.DriverId)
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const closePanelHandler = () => {
    setSelectedCard(-1);
    setSelectedRow(undefined);
    setOpenPanel(false);
    resetGetDriverDetail();
  };

  const tableViewToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tableView =
      localStorage.getItem("secure_fuel_tableView") == "false"
        ? "true" : "false";
    setUpdateTableView(tableView);
    if (tableView == "false") {
      setTableView(false);
    } else {
      setTableView(true);
    }
  };

  const noDataView = () => {
    return (
      <>
        {driverListData.length == 0 && (
          <div className="no_data_height">
            <div className="no_data w-100 text-center">

            </div>
          </div>
        )}
      </>
    );
  };

  const addIcons: Array<IActionFormatter<string>> = [
    {
      handler: rowEvents,
      icon: (
        <svg
          enableBackground="new 0 0 519.643 519.643"
          width="14"
          height="14"
          viewBox="0 0 519.643 519.643"
        >
          <circle cx="259.823" cy="259.866" r="80" />
          <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
        </svg>
      ),
      key: "",
      title: "label_showdetails",
      className: "btn-secondary",
    },
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="14"
          height="14"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
              />
            </g>
          </g>
        </svg>
      ),
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
      className: "btn-danger",
    },

  ];

  const columns = getColumns<IDriver, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "DriverId",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "DriverName",
        sortingName: "driver_name",
        displayName: "driver_name",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: showIcons(addIcons, ModuleName.DriverManagement),
        displayName: "label_action",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RFID",
        sortingName: "rfid",
        displayName: "rfid",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "DLNo",
        sortingName: "dl_no",
        displayName: "dl_no",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "DLExpiryDate",
        sortingName: "dl_expiry_date",
        displayName: "dl_expiry_date",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "TruckAllocation1",
        sortingName: "truck_allocation1",
        displayName: "truck_allocation",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "FuelDeliveryEnabled",
        sortingName: "fuel_delivery_enabled",
        displayName: "fuel_delivery_enabled",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationPerFill",
        sortingName: "allocation_per_fill",
        displayName: "allocation_per_fill",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationPeriod",
        sortingName: "allocation_period",
        displayName: "allocation_period",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "AllocationVolume",
        sortingName: "allocation_volume",
        displayName: "allocation_volume",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "RemainingVolume",
        sortingName: "remaining_volume",
        displayName: "remaining_volume",
      },
      // {
      //   columnType: ColumnType.TextHeader,
      //   accessorKey: "BelongToCustomer",
      //   // sortingName: "belong_to_customer",
      //   displayName: "belongs_to_customer",
      // },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "CustomerName",
        // sortingName: "customer_id",
        displayName: "customer_name",
      },
    ],
    sorting,
    handleSortingChange
  );

  const switchButton = () => {
    return (
      <div className="form-check tableAccordian-switch p-0 m-0">
        <input
          className="ml-1 form-check-input"
          type="checkbox"
          role="switch"
          data-testid="SwitchConfigurationView"
          checked={tableView}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            closePanelHandler();
            tableViewToggleHandler(e);
          }}
          id="flexSwitchCheckDefault"
        />
        <label htmlFor="flexSwitchCheckDefault">
          <svg width="17" height="17" version="1.1" viewBox="0 0 32 32">
            <path
              fill="currentColor"
              d="M-0.4-0.4v32.7h32.7V-0.4H-0.4z M2.6,2.6h7.4v7.4H2.6V2.6z M13,2.6H19v7.4H13V2.6z M21.9,2.6h7.4v7.4h-7.4V2.6z M2.6,13h7.4
V19H2.6V13z M13,13H19V19H13V13z M21.9,13h7.4V19h-7.4V13z M2.6,21.9h7.4v7.4H2.6V21.9z M13,21.9H19v7.4H13V21.9z M21.9,21.9h7.4
v7.4h-7.4V21.9z"
            />
          </svg>

          <svg width="17" height="17" version="1.1" viewBox="0 0 17 17">
            <path
              d="M4 0v9h13v-9h-13zM16 8h-11v-7h11v7zM0 3h3v-3h-3v3zM1 1h1v1h-1v-1zM4 13h13v-3h-13v3zM5 11h11v1h-11v-1zM0 13h3v-3h-3v3zM1 11h1v1h-1v-1zM4 17h13v-3h-13v3zM5 15h11v1h-11v-1zM0 17h3v-3h-3v3zM1 15h1v1h-1v-1z"
              fill="currentColor"
            />
          </svg>
        </label>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateDriver id={selectedId} modalClosed={addToggleHandler} />
      )}
      {showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={deleteToggleHandler}
          isLoading={DeleteDriverState.inProgress}
          message="delete_driver_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_driver"
        />
      )}

      {showFilterPopup && (
        <DriverFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}

      {/* <div className={`main-content user ${showUserDetail ? 'table-container' : ''} `}> */}
      <div className={`main-content user`}>
        <SubHeader
          moduleNameString="drivers_title"
          moduleName={ModuleName.DriverManagement}
          filterHandler={openFilterHandler}
          isFilterActive={filter.isFilterActive}
          exportCSVHandler={exportFilteredLogsCSV}
          resetFilterHandler={resetFilterHandler}
          switchButton={switchButton}
          addHandler={openAddUpdateHandler}
          isAdd={true}
          addButtonTitle="add_driver"
          isFullView={false}
          isDownload={true}
          isRefresh={false}
          isFilter={true}
          isDateFilter={false}
        />
      </div>

      {inProgress && <SpinnerLoader />}
      <Container fluid className="p-0">
        {driverListData.length !== 0 ? (
          <div className="driverWrapper" id="moveContent">
            <div className={`${openPanel ? "paneIsOpen" : ""}`}>
              <div className="pt-3 px-3">
              <div className="Total-users d-flex justify-content-end mb-2">
              <FormattedMessage id="Total Drivers" /> :{" "}
              <b className="ps-1"> {driverList.TotalFilteredDrivers}</b>
       
            </div>
                {!tableView && noDataView()}
                {!tableView ? (
                  <div
                    className={`${driverListData.length > 0 ? "card_height" : ""
                      }`}
                  >
                    <div
                      className="row g-3"
                    >
                      {driverListData.length > 0 &&
                        driverListData.map((driver: IDriverList) => {
                          const dlExpiryDate = driver.DLExpiryDate
                            ? new Date(driver.DLExpiryDate)
                            : new Date();
                          const volumePercentage =
                            (driver.RemainingVolume * 100) /
                            driver.AllocationVolume;
                          return (
                            <>
                              <div
                                className="col-12 col-lg-6 col-md-6 col-xxl-4 col-xl-4"
                                key={driver.DriverId}
                              >
                                <div className={selectedCard === driver.DriverId ? "card card-body rounded shadow drivercard bg-white h-100 active-card" : "card card-body rounded shadow drivercard bg-white h-100"}>
                                  <div className="row g-0 h-100">
                                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-4 pe-2 ">
                                      <svg
                                        id="Layer_1"
                                        enableBackground="new 0 0 512 512"
                                        height="60"
                                        viewBox="0 0 512 512"
                                        width="60"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g>
                                          <path
                                            d="m443.228 425.001c36.035-42.658 57.77-97.786 57.77-158 0-135.309-109.69-244.999-244.999-244.999s-244.998 109.69-244.998 244.999c0 60.491 21.935 115.85 58.268 158.588z"
                                            fill={`${volumePercentage > 25
                                              ? volumePercentage < 75
                                                ? "#ffc107"
                                                : "#12d543"
                                              : "#f00007"
                                              }`}
                                          />
                                          <path
                                            d="m341.178 379.922c-31.892 53.969-83.313 54.442-83.313 54.442s-51.377-.473-83.27-54.379c-.731.886-1.484 1.747-2.287 2.55-4.279.987-8.494 2.076-12.665 3.241 2.294.999 4.29 2.698 5.623 4.975 19.611 33.494 53.803 73.501 92.709 73.501s73.098-40.007 92.709-73.501c1.324-2.26 3.301-3.951 5.574-4.954-4.196-1.174-8.436-2.269-12.741-3.262-.822-.821-1.592-1.704-2.339-2.613z"
                                            fill="#fcad6d"
                                          />
                                          <path
                                            d="m228.866 427.659c-16.947-6.766-38.11-20.357-54.272-47.673-.731.886-1.484 1.748-2.287 2.55-4.279.988-8.494 2.076-12.665 3.242 2.294.999 4.29 2.698 5.623 4.975 11.363 19.408 27.623 41.001 46.859 55.732.998-.382 1.851-.751 2.524-1.112 7.456-3.998 11.927-12.192 14.218-17.714z"
                                            fill="#fd995b"
                                          />
                                          <path
                                            d="m388.813 102.363c0-27.331-15.746-38.656-26.161-43.157-5.249-2.269-9.348-6.407-11.433-11.573-4.557-11.293-17.443-30.861-53.475-42.819-52.975-17.582-100.064 18.148-125.375 24.954-16.645 4.475-29.725-5.397-36.732-12.682-2.961-3.079-8.225-2.147-9.906 1.725-5.471 12.602-13.442 37.386-6.149 63.16.447 1.579-1.153 2.987-2.754 2.438-2.116-.725-4.017-1.401-5.544-1.955-1.564-.566-3.124.847-2.627 2.383 6.164 19.052 12.139 29.92 17.501 35.782 2.373 2.594 3.959 5.777 4.369 9.212.797 6.691 2.135 13.823 4.34 20.43 3.255 9.755 5.296 19.863 5.884 30.13.007.12.012.19.016.204h236.924c.011-.049.024-.22.039-.493.575-10.068 2.539-20.012 5.768-29.565 7.196-21.284 5.315-48.174 5.315-48.174z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m205.188 114.632s22.011-26.365 6.676-56.119 21.744-53.247 21.744-53.247c-25.491 7.462-46.874 20.64-61.239 24.502-16.644 4.476-29.725-5.397-36.732-12.682-2.961-3.079-8.225-2.147-9.906 1.725-5.471 12.602-13.442 37.386-6.149 63.16.447 1.579-1.153 2.987-2.754 2.438-2.116-.725-4.017-1.401-5.544-1.955-1.564-.566-3.124.847-2.627 2.383 6.164 19.052 12.139 29.921 17.501 35.782 2.373 2.594 3.959 5.777 4.369 9.212.797 6.691 2.135 13.822 4.34 20.43 3.255 9.755 5.296 19.863 5.884 30.13.007.12.012.19.016.204h49.963z"
                                            fill="#35355e"
                                          />
                                          <path
                                            d="m385.261 179.539c-5.313-4.869-15.897-2.401-20.473.581 1.003 6.243 1.059 12.629.194 18.936l-5.4 39.329c0 3.17-.1 6.281-.284 9.337 13.168 3.972 19.708-7.85 20.81-15.962.474-3.492 1.25-6.934 2.325-10.293 3.504-10.939 16.14-29.729 2.828-41.928z"
                                            fill="#fcad6d"
                                          />
                                          <g>
                                            <path
                                              d="m375.261 216.751c-4.887 0-9.204-2.395-11.876-6.064l-3.803 27.698c0 3.17-.1 6.281-.284 9.337 13.168 3.972 19.708-7.849 20.81-15.962.474-3.492 1.25-6.934 2.326-10.293.906-2.83 2.424-6.189 3.946-9.82-2.695 3.121-6.672 5.104-11.119 5.104z"
                                              fill="#fd995b"
                                            />
                                          </g>
                                          <path
                                            d="m150.05 180.872v.001z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m130.474 179.539c5.313-4.869 15.897-2.401 20.473.581-1.003 6.243-1.06 12.629-.193 18.936l5.4 39.329c0 3.17.1 6.281.284 9.337-13.167 3.972-19.708-7.85-20.81-15.962-.474-3.492-1.25-6.934-2.325-10.293-3.505-10.939-16.141-29.729-2.829-41.928z"
                                            fill="#fcad6d"
                                          />
                                          <path
                                            d="m357.996 159.694c-3.27-5.13-4.708-11.198-4.27-17.249.265-3.664.441-8.069.346-12.83-.038-.626-.058-1.305-.058-2.045-.05-1.387-.127-2.799-.231-4.227-1.282-12.745-8.132-21.924-13.587-27.32-3.643-3.604-9.26-4.312-13.747-1.838-67.438 37.186-123.327 23.448-153.418 9.904-3.646-1.641-7.852.486-8.81 4.368-3.002 12.166-2.849 25.322-2.201 34.137.441 6.004-1.037 12.01-4.282 17.101-1.87 2.934-3.366 6.04-4.563 9.114-3.882 9.971-5.013 20.786-3.559 31.375l5.401 39.338c0 .071.003.139.003.21 5.553 16.196 16.789 39.414 36.865 46.004l.254.073c12.082 3.472 21.423 13.002 25.384 25.156 5.594 17.167 21.47 21.731 40.341 21.731 18.88 0 34.745-4.567 40.339-21.725 3.963-12.155 13.302-21.689 25.386-25.162l.254-.073c20.078-6.591 31.317-29.814 36.871-46.01 0-.069.003-.136.003-.204l5.4-39.329c1.44-10.485.348-21.194-3.444-31.087-1.214-3.173-2.746-6.384-4.677-9.412z"
                                            fill="#f9ba8f"
                                          />
                                          <g>
                                            <path
                                              d="m140.474 216.751c4.887 0 9.204-2.395 11.876-6.064l3.803 27.698c0 3.17.1 6.281.283 9.337-13.168 3.972-19.708-7.849-20.81-15.962-.474-3.492-1.25-6.934-2.326-10.293-.906-2.83-2.424-6.189-3.946-9.82 2.696 3.121 6.673 5.104 11.12 5.104z"
                                              fill="#fd995b"
                                            />
                                          </g>
                                          <path
                                            d="m194.605 200.183c-1.715-15.753 1.357-30.431 6.439-40.488 2.573-5.091 3.745-11.097 3.395-17.101-.427-7.337-.581-17.683.749-27.962-12.616-2.707-23.429-6.619-32.104-10.519-3.67-1.65-7.898.436-8.862 4.342-3.003 12.167-2.849 25.324-2.202 34.139.441 6.004-1.037 12.009-4.282 17.101-6.497 10.193-10.263 24.895-8.122 40.488l5.402 39.338c0 .071.003.14.004.21 5.553 16.196 16.789 39.413 36.865 46.004l.254.073c5.751 1.653 10.87 4.69 15.094 8.717-5.103-15.877-8.346-34.211-8.346-55.004z"
                                            fill="#fcad6d"
                                          />
                                          <path
                                            d="m294.714 198.959c0-1.851.346-3.612.965-5.213-2.406 1.181-4.349 2.518-5.732 3.647-3.228 2.632-8.839 5.971-11.912 7.736-.581.334-.344 1.221.326 1.221h.303c2.851 0 5.687.445 8.395 1.337 4.697 1.547 9.191 2.316 13.347 2.57-3.415-2.342-5.692-6.524-5.692-11.298z"
                                            fill="#fff5ee"
                                          />
                                          <path
                                            d="m332.932 201.855c-5.612-5.264-11.006-8.302-15.995-9.84 1.122 2.021 1.771 4.398 1.771 6.944 0 4.161-1.731 7.872-4.436 10.307 4.084-.844 7.272-2.018 9.246-2.917 4.049-1.844 7.112-2.594 8.933-2.899.758-.126 1.041-1.069.481-1.595z"
                                            fill="#fff5ee"
                                          />
                                          <path
                                            d="m294.925 194.129c-.684 1.563-1.066 3.288-1.066 5.104 0 4.698 2.544 8.799 6.327 11.01 5.457.356 10.338-.181 14.337-1.028 2.939-2.335 4.825-5.937 4.825-9.982 0-2.65-.809-5.111-2.194-7.149-9.139-2.892-16.933-.735-22.229 2.045z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m311.357 200.033c0 2.698-2.187 4.885-4.885 4.885s-4.885-2.187-4.885-4.885 2.187-4.885 4.885-4.885 4.885 2.187 4.885 4.885z"
                                            fill="#35355e"
                                          />
                                          <path
                                            d="m343.727 183.763c-.73-4.799-3.638-15.953-14.985-18.532-11.458-2.604-35.619 2.304-45.401 4.516-2.606.589-4.671 2.571-5.37 5.15l-2.015 7.432c-.171.631.41 1.207 1.039 1.031 7.416-2.081 41.565-11.315 51.967-7.978 7.933 2.544 12.007 6.51 13.808 8.798.342.434 1.041.13.957-.417z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m223.623 198.959c0-1.851-.346-3.612-.965-5.213 2.406 1.181 4.349 2.518 5.733 3.647 3.228 2.632 8.839 5.971 11.911 7.736.581.334.344 1.221-.326 1.221h-.303c-2.851 0-5.687.445-8.395 1.337-4.697 1.547-9.191 2.316-13.347 2.57 3.415-2.342 5.692-6.524 5.692-11.298z"
                                            fill="#fff5ee"
                                          />
                                          <path
                                            d="m185.405 201.855c5.612-5.264 11.006-8.302 15.995-9.84-1.122 2.021-1.771 4.398-1.771 6.944 0 4.161 1.731 7.872 4.436 10.307-4.084-.844-7.272-2.018-9.246-2.917-4.05-1.844-7.112-2.594-8.933-2.899-.758-.126-1.041-1.069-.481-1.595z"
                                            fill="#fff5ee"
                                          />
                                          <path
                                            d="m223.412 194.129c.684 1.563 1.066 3.288 1.066 5.104 0 4.698-2.544 8.799-6.327 11.01-5.457.356-10.338-.181-14.337-1.028-2.939-2.335-4.825-5.937-4.825-9.982 0-2.65.809-5.111 2.194-7.149 9.139-2.892 16.933-.735 22.229 2.045z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m206.98 200.033c0 2.698 2.187 4.885 4.885 4.885s4.885-2.187 4.885-4.885-2.187-4.885-4.885-4.885-4.885 2.187-4.885 4.885z"
                                            fill="#35355e"
                                          />
                                          <path
                                            d="m174.609 183.763c.73-4.799 3.638-15.953 14.985-18.532 11.458-2.604 35.619 2.304 45.401 4.516 2.606.589 4.671 2.571 5.37 5.15l2.015 7.432c.171.631-.41 1.207-1.04 1.031-7.416-2.081-41.564-11.315-51.967-7.978-7.933 2.544-12.007 6.51-13.808 8.798-.34.434-1.039.13-.956-.417z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m287.792 297.78-10.908 4.301c-1.227.484-2.582.539-3.844.159l-12.571-3.791-12.57 3.791c-1.263.38-2.618.325-3.845-.159l-10.908-4.301c-3.013-1.188-4.493-4.595-3.305-7.608 1.185-3.005 4.585-4.496 7.608-3.305l9.013 3.554 12.259-3.697c1.188-.371 2.426-.344 3.497.001l12.257 3.696 9.013-3.554c3.008-1.184 6.416.284 7.608 3.305 1.19 3.014-.29 6.42-3.304 7.608z"
                                            fill="#fcad6d"
                                          />
                                          <g>
                                            <path
                                              d="m302.393 166.118c-7.6 1.149-14.73 2.652-19.052 3.629-2.606.589-4.671 2.571-5.37 5.15l-2.015 7.432c-.171.631.41 1.207 1.04 1.031 4.112-1.154 16.441-4.504 28.385-6.694-4.913-.949-6.712-7.118-2.988-10.548z"
                                              fill="#35355e"
                                            />
                                          </g>
                                          <g>
                                            <path
                                              d="m208.999 165.2c-7.376-.823-14.537-1.074-19.404.032-11.348 2.579-14.255 13.732-14.985 18.532-.083.546.615.851.957.417 1.801-2.288 5.875-6.254 13.808-8.798 3.959-1.27 11.359-.717 19.414.567-4.191-2.432-4.056-8.497.21-10.75z"
                                              fill="#35355e"
                                            />
                                          </g>
                                          <path
                                            d="m362.951 223.253s-9.442 50.939-40.523 61.142l-.248.071c-11.825 3.398-20.963 12.727-24.841 24.622-5.474 16.791-20.999 21.259-39.474 21.259-18.467 0-34.001-4.466-39.476-21.265-3.876-11.893-13.017-21.219-24.839-24.616l-.248-.071c-31.081-10.203-40.518-61.142-40.518-61.142-23.891 213.013 105.081 214.189 105.081 214.189s128.978-1.176 105.086-214.189z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m264.234 311.872h-7.529c-3.24 0-5.865 2.626-5.865 5.865 0 3.24 2.626 5.865 5.865 5.865h7.529c3.24 0 5.865-2.626 5.865-5.865s-2.626-5.865-5.865-5.865z"
                                            fill="#fcad6d"
                                          />
                                          <path
                                            d="m193.302 286.436.248.071c8.031 2.308 14.818 7.356 19.564 14.081l5.482-3.642c3.149-2.092 6.861-3.229 10.64-3.108 13.136.421 24.644-3.407 29.179-5.16 1.324-.512 2.786-.512 4.11 0 4.535 1.753 16.043 5.581 29.179 5.16 3.779-.121 7.491 1.016 10.64 3.108l2.035 1.352c4.603-5.574 10.714-9.753 17.802-11.79l.248-.071c2.925-.96 5.659-2.282 8.211-3.888-8.928-15.274-29.387-36.734-70.17-21.942-42.779-15.516-63.198 8.856-71.4 24.135 1.363.647 2.774 1.215 4.232 1.694z"
                                            fill="#41416b"
                                          />
                                          <path
                                            d="m240.792 257.403 4.472-.942c8.308 8.207 22.115 8.193 30.41 0l4.472.942c3.214.673 6.377-1.382 7.055-4.602.678-3.219-1.382-6.377-4.601-7.055l-8.023-1.689c-2.613-.55-5.162.499-6.395 2.863-3.245 5.018-12.175 5.025-15.425 0-1.233-2.364-3.783-3.411-6.395-2.863l-8.024 1.689c-3.219.678-5.279 3.837-4.601 7.055.677 3.219 3.837 5.277 7.055 4.602z"
                                            fill="#fcad6d"
                                          />
                                          <path
                                            d="m256 512c75.567 0 143.134-34.221 188.075-88.001-6.081-1.428-10.757-4.559-19.103-9.265-20.572-11.6-41.422-22.391-63.639-29.435-5.212-1.652-10.899.543-13.661 5.261-18.987 32.428-52.092 71.163-89.76 71.163s-70.773-38.734-89.76-71.163c-2.763-4.718-8.449-6.914-13.661-5.261-22.217 7.043-43.067 17.835-63.638 29.435-9.797 5.524-14.535 8.881-22.427 9.857 44.941 53.438 112.285 87.409 187.574 87.409z"
                                            fill="#fff5ee"
                                          />
                                          <path
                                            d="m168.152 390.561c-2.763-4.718-8.449-6.913-13.661-5.261-22.217 7.044-43.067 17.835-63.638 29.435-9.797 5.524-14.535 8.881-22.427 9.857 19.774 23.512 43.888 43.25 71.104 57.989 22.497-25.636 65.696-32.157 75.118-37.208.001 0 .001-.001.002-.001-19.116-14.274-35.275-35.642-46.498-54.811z"
                                            fill="#fce6d6"
                                          />
                                          <g fill="#35355e">
                                            <path d="m226.861 256.214c-20.643 3.1-32.129 17.978-37.791 28.526 1.364.648 2.774 1.216 4.232 1.695l.248.071c8.031 2.308 14.818 7.356 19.565 14.081l5.482-3.642c3.149-2.092 6.861-3.229 10.64-3.108 2.712.087 5.348-.014 7.875-.234-23.08-13.877-10.256-37.381-10.251-37.389z" />
                                            <path d="m253.19 378.561c-20.43-25.517 7.279-48.213 7.279-48.213l-.015-.034c-.857.021-1.719.034-2.59.034-18.467 0-34.002-4.466-39.476-21.265-3.876-11.893-13.016-21.219-24.839-24.617l-.248-.071c-31.081-10.203-40.518-61.142-40.518-61.142-23.891 213.013 105.081 214.189 105.081 214.189s23.028-24.281-4.674-58.881z" />
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-9 col-7">
                                      <div className="row">
                                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                                          <h4 className="m-0">
                                            {driver.DriverName}
                                          </h4>
                                          <p
                                            className={`badge ${driver.FuelDeliveryEnabled
                                              ? "bg-success"
                                              : "bg-danger"
                                              } mb-0`}
                                          >
                                            <FormattedMessage
                                              id={
                                                driver.FuelDeliveryEnabled
                                                  ? "fuel_delivery_enabled"
                                                  : "fuel_delivery_disabled"
                                              }
                                            />
                                          </p>

                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-6 col-xxl-6 col-xl-6 col-12 text-end ">
                                          <div className="drivingLicence ms-lg-auto">
                                            <small className="h6">
                                              {formatDateToText(dlExpiryDate)}
                                            </small>
                                            <p className="text-break">{driver.DLNo}</p>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <p className="mb-0 d-flex active-mute-text">

                                            <div className="text-muted">
                                              <FormattedMessage id="rfid" />: &nbsp;
                                            </div>

                                            <div className="text-break"> {driver.RFID !== emptyValue ? driver.RFID : formatMessage(notAvailable)}</div>
                                          </p>
                                        </div>
                                        <div className="col-12">
                                          {(driver.CustomerName != emptyValue) && (
                                            <p className="mb-0 active-mute-text">
                                              <span className="text-muted">
                                                <FormattedMessage id="customer_name" />
                                                : &nbsp;
                                              </span>
                                              {driver.CustomerName}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-1 col-1 text-end">
                                      <div className="d-flex justify-content-end edit-popup">
                                        <Dropdown className="border-0 enable-caret editButton">
                                          <Dropdown.Toggle
                                            variant=""
                                            id="dropdown-basic"
                                            className="shadow-none pt-1 p-0"
                                          >
                                            <span>
                                              {" "}
                                              <i className="fas fa-ellipsis-v"></i>
                                            </span>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="dropdown-menu-start p-0">
                                            {isOperationPermitted(
                                              ModuleName.DriverManagement
                                            ) && (
                                                <>
                                                  <Dropdown.Item
                                                    className="d-flex align-items-center pb-0"
                                                    onClick={() => {
                                                      openAddUpdateHandler({
                                                        Id: driver.Id,
                                                      });
                                                    }}
                                                    title="Edit"
                                                  >
                                                    <div className="">
                                                      <svg
                                                        height="14"
                                                        viewBox="0 0 492.49284 492"
                                                        width="14"
                                                        fill="#fff"
                                                      >
                                                        <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
                                                        <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
                                                      </svg>
                                                    </div>
                                                    <div className="ps-2 fs-6 text-white">
                                                      <a
                                                        className="dropdown-item  p-0 pt-1"
                                                        href="javascript:;"
                                                      >
                                                        <FormattedMessage id="label_edit" />
                                                      </a>
                                                    </div>
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    className="d-flex align-items-center pb-0"
                                                    onClick={() => {
                                                      deleteClickHandler({
                                                        Id: driver.Id,
                                                      });
                                                    }}
                                                    title="Delete"
                                                  >
                                                    <div className="">
                                                      <svg
                                                        version="1.1"
                                                        x="0px"
                                                        y="0px"
                                                        width="13"
                                                        height="13"
                                                        fill="#fff"
                                                        viewBox="0 0 512 512"
                                                      >
                                                        <g>
                                                          <g>
                                                            <path
                                                              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
      L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
      c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
      c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
      c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
      c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
                                                            />
                                                          </g>
                                                        </g>
                                                        <g>
                                                          <g>
                                                            <path
                                                              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
      c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
      c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
                                                            />
                                                          </g>
                                                        </g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                        <g></g>
                                                      </svg>
                                                    </div>
                                                    <div className="ps-2 fs-6 text-white">
                                                      <a
                                                        className="dropdown-item  p-0 pt-1"
                                                        href="javascript:;"
                                                      >
                                                        <FormattedMessage id="label_delete" />
                                                      </a>
                                                    </div>
                                                  </Dropdown.Item>
                                                </>
                                              )}
                                            <Dropdown.Item
                                              className="d-flex align-items-center"
                                              onClick={() => {
                                                rowEvents(driver);
                                              }}
                                              title="Show Details"
                                            >
                                              <div className="">
                                                <svg
                                                  enableBackground="new 0 0 519.643 519.643"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 519.643 519.643"
                                                  fill="#fff"
                                                >
                                                  <circle
                                                    cx="259.823"
                                                    cy="259.866"
                                                    r="80"
                                                  />
                                                  <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
                                                </svg>
                                              </div>
                                              <div className="ps-2 fs-6 text-white">
                                                <a
                                                  className="dropdown-item  p-0 pt-1"
                                                  href="javascript:;"
                                                >
                                                  <FormattedMessage id="label_showdetails" />
                                                </a>
                                              </div>
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>

                                      {/* <div className="dropdown">
                                    <a
                                      className="d-block pt-1 text-dark"
                                      href="#"
                                      role="button"
                                      id="dropdownMenuLink"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </a>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                     <li>
                                        
                                        <a
                                          className="dropdown-item"
                                          href="javascript:;"
                                          onClick={() => {
                                            openAddUpdateHandler({ Id: driver.Id });
                                          }}
                                        >
                                          <FormattedMessage id="label_edit" />
                                        </a>
                                      </li> 
                                      {/* <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:;"
                                          onClick={() => {
                                            deleteClickHandler({ Id: driver.Id });
                                          }}
                                        >
                                          <FormattedMessage id="label_delete" />
                                        </a>
                                      </li> */}
                                      {/* <li>
                                        <a
                                          className="dropdown-item"
                                          href="javascript:;"
                                          onClick={() => {
                                            rowEvents(driver);
                                          }}
                                        >
                                          <FormattedMessage id="label_showdetails" />
                                        </a>
                                      </li> 
                                    </ul>
                                  </div>*/}
                                    </div>
                                  </div>
                                  <div className="h-100">
                                    <div className="row bg-light active-bg-light px-1 py-4 my-3 align-items-center">
                                      <div className="col-12 text-end">
                                        <div className="progress">
                                          <div
                                            role="progressbar"
                                            className={`progress-bar ${volumePercentage > 25
                                              ? volumePercentage < 75
                                                ? "bg-warning"
                                                : "bg-success"
                                              : "bg-danger"
                                              }`}
                                            style={{
                                              width: `${volumePercentage}%`,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <p className="mb-0 active-mute-text">
                                          <small className="text-muted">
                                            <FormattedMessage id="allocation_volume" />{" "}
                                            : &nbsp;
                                          </small></p>
                                        <p className="mb-0"> {driver.AllocationVolume}
                                        </p>
                                      </div>
                                      <div className="col-6 text-end active-mute-text">
                                        <p className="mb-0">
                                          <small className="text-muted">
                                            <FormattedMessage id="remaining_volume" />{" "}
                                            : &nbsp;
                                          </small></p>
                                        <p className="mb-0">  {driver.RemainingVolume}
                                        </p>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-4 active-mute-text">
                                        <small className="text-muted">
                                          <FormattedMessage id="allocation_period" />
                                        </small>
                                        <br />
                                        <h5
                                          className={` ${allocationPeriodColorSchemeDriver(
                                            driver.AllocationPeriod
                                          )}`}
                                        >
                                          {" "}
                                          {driver.AllocationPeriod}{" "}
                                        </h5>
                                      </div>

                                      <div className="col-4 active-mute-text">
                                        <small className="text-muted">
                                          <FormattedMessage id="allocation_per_fill" />
                                        </small>
                                        <h5>{driver.AllocationPerFill}</h5>
                                      </div>

                                      <div className="col-4 text-end active-mute-text">
                                        <small className="text-muted">
                                          <FormattedMessage id="truck_allocation" />
                                        </small>
                                        <br />
                                        <h5>{driver.TruckAllocation1}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="rounded shadow">
                    <div className="Fuel-table">
                      <div
                        className="table-responsive"
                        style={getTableMaxHeight(height, width)}
                        data-testid="table"
                      >
                        {driverListData && (
                          <Grid
                            data={driverListData}
                            columns={columns}
                            isInProgress={inProgress}
                            hiddenColumns={["DriverId"]}
                          />
                        )}
                        {noDataView()}
                      </div>
                    </div>
                  </div>
                )}

                <Container fluid>
                  <Row className="tableFooter py-1 mt-0">
                    <Col xs="4" className="d-flex">
                      <b className="text-white text-hidden-m mt-1 pt-1">
                        <FormattedMessage id="show" />:{" "}
                      </b>
                      <select
                        data-testid="pageSelect"
                        className="ms-2 from-control"
                        onChange={onPageLimitChangedForUser}
                        value={pageLimit}
                      >
                        {PageLimitOptions.map((op) => (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        ))}
                      </select>
                    </Col>
                   
                    <Col className="d-flex justify-content-end" xs="8">
                     
                      <Pagination
                        totalPages={pageInfo.totalPages}
                        pageLimit={pageLimit}
                        onPageChanged={onPageChanged}
                        disablePagination={disablePagination}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <SlidingPanel
              type={"right"}
              isOpen={openPanel}
              noBackdrop={false}
              size={100}
            >
              <div>
                <div className="row g-0 border-bottom pt-3 pb-1 ps-3">
                  <Col lg={10} md={6} xs={6}>

                    <h2 className="fs-4 fw-bolder mb-0 text-break">
                      {" "}
                      {selectedRow ? selectedRow.DriverName : ""}
                    </h2>
                  </Col>
                  <Col className="text-end" lg={2} md={6} xs={6}>
                    <svg
                      onClick={closePanelHandler}
                      role="button"
                      xlinkTitle="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </Col>
                </div>
                <div className="group_detailsWrapper px-3">
                  <DriverDetails selectedData={selectedRow} />
                </div>
              </div>
            </SlidingPanel>
          </div>
        ) : (
          <div className="no_data_table background-image ">
            <div className="no_data w-100 text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default FilterPopupHOC(
  EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Driver))))
);
