import { useState } from 'react';

const PaymentDetailsHoc = (Component: any) =>
    function Comp(props: any) {
        const [showPaymentDetailsPopup, setShowPaymentDetailsPopup] = useState<boolean>(false);

        const paymentDetailsToggleHandler = () => {
            setShowPaymentDetailsPopup(!showPaymentDetailsPopup);
        }

        return <Component showPaymentDetailsPopup={showPaymentDetailsPopup} paymentDetailsToggleHandler={paymentDetailsToggleHandler} {...props} />;
    };

export default PaymentDetailsHoc;