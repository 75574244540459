/*    
<summary>
   This file is used to provide initial values or state to the role Data observables.
   Developer:Ashish Singh, Created Date:29-March-2024
</summary>
*/
import { IAddEditRole } from "../../models/response/IRoleResponse";

export const addEditRoleState:IAddEditRole = {
    RoleName: "",
    DriverManagementPermission: 0,
    VehicleManagementPermission: 0,
    TankManagementPermission: 0,
    TicketManagementPermission: 0,
}