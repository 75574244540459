import React from 'react';
import { injectIntl } from 'react-intl';
import FormikFormErrorHandler from './FormikFormErrorHandler';
import { useField } from 'formik';

interface IProps {
  children: React.ReactNode;
  className: string;
  "data-testid"?: string;
  intl: any;//IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  row?: number;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
  maxLength: number;
}

const FormikFormTextArea = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;
  const { row, validcharacter, invalidcharacter } = props;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;
    let subStrArr = ["?", ">", "<", "'", '"', "*", "^", "%","{", "}", "|", "=", '~', '\\', '`', '!']
  if (validcharacter)
    subStrArr = subStrArr.filter(el => !validcharacter.includes(el));
  if (invalidcharacter)
    invalidcharacter.forEach(el => subStrArr.push(el));

  return (
    <>
      <textarea {...field} {...props}
        placeholder={_placeholder}
        className={props.className + ' ' + errorClass}
        disabled={props.disabled}
        readOnly={props.readOnly}
        rows={row}
        maxLength={props.maxLength}
        onChange={event => {
          if (event.target.value.length > props.maxLength)
            event.target.value = event.target.value.slice(0, props.maxLength);
          if (!props.spaceAllowed && (event.target.value === '' || !subStrArr.some((subStr: any) => event.target.value.includes(subStr)))) {
            event.target.value = event.target.value.replace(/ +(?= )/g, '');
            helpers.setValue(event.target.value);
          } else if ((props.spaceAllowed === undefined || props.spaceAllowed) && (event.target.value === '' || !subStrArr.some((subStr: any) => event.target.value.includes(subStr)))) {
            helpers.setValue(event.target.value);
          }
        }}
      />
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormTextArea);