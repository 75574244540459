/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import { IOption } from "../../../models/ICommon";
import { IAddEditRole } from "../../../models/response/IRoleResponse";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { addUpdateRoleValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  id: number;
  submitHandler: (data: IAddEditRole) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditRole;
  permissionTypes: IOption[];
}

const AddUpdateRoleForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
    permissionTypes,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  return (
    <>
      <Modal className="add-user-modal " show={true} centered>
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_role" />
            ) : (
              <FormattedMessage id="update_role" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={addUpdateRoleValidateSchema}
          onSubmit={async (values) => {
            let tempValues = {
              ...values,
              RoleName: values.RoleName ? values.RoleName.trim() : values.RoleName
            }
            // submitHandler({ ...values });
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row className="form-group d-flex align-items-center">
                  <Col xs={4}>
                    <Label
                      className="form-label"
                      required={true}
                      label="role_name"
                    />
                  </Col>
                  <Col xs={8}>
                    <Field
                      data-testid="testRoleName"
                      className="form-control"
                      name="RoleName"
                      type="text"
                      validcharacter={['@', '#', '$', '-', '_', '.']}
                      placeholder="role_name"
                      as={FormikFormInput}
                    />
                  </Col>
                </Row>
                <Row className="border-box position-relative border">
                  <Col xs={12} className="pb-3">
                    <p className="h6 mt-3 fw-semi-bold heading-title">
                      <FormattedMessage id="module_permissions" />
                    </p>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="driver_management_permission"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="DriverManagementPermission"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={permissionTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="vehicle_management_permission"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="VehicleManagementPermission"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={permissionTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="tank_management_permission"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="TankManagementPermission"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={permissionTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        required={true}
                        label="ticket_management_permission"
                      />
                      <Field
                        className="form-control form-select"
                        aria-label="Default select example"
                        name="TicketManagementPermission"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={permissionTypes}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateRoleForm;
