/*    
<summary>
   This file is used to provide initial values or state to the Client Module observables.
    Developer:Aashish Singh, Created Date:04-March-2024
</summary>
*/
import { IGetClientList } from "../../models/response/IClientResponse";

export const getClientListState: IGetClientList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 10,
    Clients: []
}