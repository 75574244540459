/*    
<summary>
   This file is used to provide initial values or state to the Role Module observables.
    Developer:Aashish Singh, Created Date:29-March-2024
</summary>
*/
import { IGetRoleList } from "../../models/response/IRoleResponse";

export const getRoleListState: IGetRoleList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Roles: []
}