import { AxiosResponse } from "axios";
import base64 from "base-64";
import { makeObservable, observable, action, computed } from "mobx";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IUserState } from "../../models/state/IUserState";
import { IAddUser, IUser } from "../../models/IUser";
import { getAllUserState } from "../initialState/getAllUser.state";
import { userState } from "../initialState/userDetail.state";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IGetAllUsers } from "../../models/response/IUserResponse";
import { getClientId, getProfileImage, getUserId, getUserName, getUserType } from "../../helpers/localStorages.helper";
import UserType from "../../constants/userType.constant";
import { UserTypeEnumStringConstant } from "../../constants/common-constant";
import { IObservableInitialState } from "../../models/ICommon";
import { IProfile } from "../../models/state/IProfile";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { IUpdatePassword } from "../../models/form/IChangePassword";
import { userInitialFilterState } from "../initialState/initial-filter-states";
import { IUserInitialFilterState, IUserFilter } from "../../models/IFilter";

export class UserStore implements IUserState {
    inProgress = false;
    errors = '';
    userData = getAllUserState;


    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    };

    addNewUserInProgress = false;
    addNewUserSuccess = false;
    addNewUserError = '';
    addNewUpdateUserState: IObservableInitialState = { ...this.initialStateValue };

    // deleteUserInProgress = false;
    // deleteUserSuccess = false;
    // deleteUserError = '';
    deleteUserState: IObservableInitialState = { ...this.initialStateValue };

    // changePasswordInProgress = false;
    // changePasswordSuccess = false;
    // changePasswordError = '';

    userDetail: IUser | undefined = undefined;
    userDetailInProgress = false;
    userDetailSuccess = false;
    userDetailError = '';

    showUserDetail: IAddUser = userState;
    showUserDetailInProgress = false;
    showUserDetailError = '';

    changePasswordState = { ...this.initialStateValue }

    userProfileDetail: IProfile | undefined = undefined;
    userProfileDetailState: IObservableInitialState = { ...this.initialStateValue };
    updatUserProfileState: IObservableInitialState = { ...this.initialStateValue };

    isProfileImageUpdated: boolean = false;
    profileImage: string = "";
    totalUsers: number = 0;
    profileUserName: string = getUserName() ?? "";

    filter: IUserFilter = {
        ...userInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            inProgress: observable,
            errors: observable,
            userData: observable,
            userDetail: observable,
            showUserDetail: observable,
            addNewUpdateUserState: observable,

            addNewUserInProgress: observable,
            addNewUserSuccess: observable,
            addNewUserError: observable,

            deleteUserState: observable,
            // deleteUserInProgress: observable,
            //deleteUserSuccess: observable,
            //deleteUserError: observable,

            changePasswordState: observable,

            userDetailInProgress: observable,
            userDetailSuccess: observable,
            userDetailError: observable,

            showUserDetailInProgress: observable,
            showUserDetailError: observable,
            userProfileDetail: observable,
            userProfileDetailState: observable,

            updatUserProfileState: observable,
            isProfileImageUpdated: observable,
            profileImage: observable,

            filter: observable,
            profileUserName: observable,
            totalUsers: observable,

            reset: action,
            getAllUser: action,
            AddNewUserService: action,
            UpdateUserService: action,
            deleteUser: action,
            getUserById: action,
            GetUserProfileDetailByIdService: action,
            UpdateUserProfileService: action,
            UpdateLoggedInUserPasswordService: action,
            // duplicateUser: action,
            resetGetUserDetail: action,
            resetAddNewUser: action,
            resetGetUserProfileDetail: action,
            reseChangePasswordState: action,
            resetUpdateUserProfileState: action,
            setFilterDetail: action,
            // getShowUserDetail: action,
            allUser: computed,
            getUserDetail: computed,
            getProfileImageFromStore: computed,
            getProfileUserNameFromStore: computed
        });
    }
    addNewUserD: any;

    getAllUser = (currentPage: number, pagerSize: number, orderBy: string, column: string, searchText: string, filter: IUserInitialFilterState) => {
        this.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetAllUser}?clientId=${clientIdFromLocalStorage}`
        let requestBody = {
            ...filter, IsActive: filter.IsActive == "2" ? null : (filter.IsActive == "1" ? false : true),
            UserType: filter.UserType == -1 ? null : filter.UserType
        }
        return services.post(url, { ...requestBody })
            //      .then((response:IApiResponse<IApiSuccessResponse<IUser[]>>) => {
            // this.userData = {...getAllUserState, Users: response.data.Data};
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.userData = { ...getAllUserState, Users: response.data.Data.ClientUserList };
                this.totalUsers = response.data.Data.TotalUsers;
            })
            .catch((err: string) => {
                this.errors = err;
            })
            .finally(action(() => { this.inProgress = false; }));
    }

    AddNewUserService = (data: IAddUser) => {
        this.addNewUserInProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let requestPayload: any = {
            ...data, ClientId: clientIdFromLocalStorage
        }
        let url = `${UrlConstants.AddClientUser}?clientId=${clientIdFromLocalStorage}`;
        delete requestPayload.Id;
        return services.post(url, requestPayload)
            .then(() => {
                this.addNewUserSuccess = true;
            })
            .catch((err: string) => {
                this.addNewUserError = err;
            })
            .finally(action(() => { this.addNewUserInProgress = false; }));
    }

    UpdateUserService = (id: number, data: IAddUser) => {
        this.addNewUserInProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let url = `${UrlConstants.UpdateClientUser}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.put(url, data)
            .then(() => {
                this.addNewUserSuccess = true;
                const userId = getUserId() ? getUserId() : -1;
                if (id !== -1)
                    if (userId == id) {
                        this.profileImage = data.ProfileImage;
                        this.profileUserName = data.UserName;
                        localStorage.setItem("secure_fuel_profileImage", data.ProfileImage);
                        localStorage.setItem("secure_fuel_userName", data.UserName);
                    }
            })
            .catch((err: string) => {
                this.addNewUserError = err;
            })
            .finally(action(() => { this.addNewUserInProgress = false; }));
    }

    deleteUser = (id: number) => {
        this.deleteUserState.inProgress = true;
        // this.deleteUserInProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeleteClientUser}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then(() => {
                this.deleteUserState.success = true;
            })
            .catch((err: string) => {
                //   this.deleteUserError = err;
                this.deleteUserState.error = err;
            }).finally(action(() => { this.deleteUserState.inProgress = false; }));
    }

    getUserById = (id: number) => {
        this.userDetailInProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetClientUserById}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.userDetail = response.data.Data.ClientUser;
            })
            .catch((err: string) => {
                this.userDetailError = err;
            }).finally(action(() => { this.userDetailInProgress = false; }));
    }

    GetUserProfileDetailByIdService = (id: number) => {
        this.userProfileDetailState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetUserProfile}?id=${id}`;
        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.userProfileDetail = response.data.Data.UserProfile;
                this.userProfileDetailState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.userProfileDetailState.error = err;
            }).finally(action(() => { this.userProfileDetailState.inProgress = false; }));
    }

    resetGetUserProfileDetail = () => {
        this.userProfileDetail = undefined;
        this.userProfileDetailState = { ...this.initialStateValue };
    }

    UpdateUserProfileService = (id: number, data: IAddUser) => {

        this.updatUserProfileState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        let requestPayload: any = {
            ...data, ClientId: clientIdFromLocalStorage
        }
        let url = `${UrlConstants.UpdateClientUser}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.put(url, (id === -1) ? { ...requestPayload } : { ...data })
            .then(() => {
                localStorage.setItem("secure_fuel_profileImage", data.ProfileImage);
                localStorage.setItem("secure_fuel_userName", data.UserName);
                this.updatUserProfileState.success = true;
            })
            .catch((err: string) => {
                // this.updatUserProfileState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.updatUserProfileState.inProgress = false; }));
    }

    resetUpdateUserProfileState = () => {
        this.updatUserProfileState = { ...this.initialStateValue };
    }
    /**
    * This function is used to Change Logged in User Password by calling an API & sending new Password details. 
    * @param data : Password data
    * @returns 
    */
    UpdateLoggedInUserPasswordService = (data: IUpdatePassword) => {
        this.changePasswordState.inProgress = true;
        const url = `${UrlConstants.UpdateLoggedInUserPassword}`;
        return services.post(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.changePasswordState.success = true;
            })
            .catch((err: string) => {
                toast.error(formatMessage(err));
                // this.changePasswordState.error = err;
            }).finally(action(() => { this.changePasswordState.inProgress = false; }));
    }
    reseChangePasswordState = () => {
        this.changePasswordState = { ...this.initialStateValue };
    }
    // getUserBySearchId = (id: string) => {
    //     this.inProgress = true;
    //     const url = `${UrlConstants.User}/${id}`;
    //     return services.get(url)
    //         .then((response:IApiResponse<IUserByIdResponse>) => {
    //             const data = response.data;
    //             const obj = [{
    //                 Id: data.Id,
    //                 UserName: data.UserName,
    //                 Email: data.Email
    //             }]
    //             this.userData.TenantUsers = obj;
    //         })
    //         .catch((err:string) => {
    //             this.errors = err;
    //         }).finally(action(() => { this.inProgress = false; }));
    // }

    // getShowUserDetail = (id: string) => {
    //     this.showUserDetailInProgress = true;
    //     const url = `${UrlConstants.GetTenantUserDetail}/${id}`;
    //     return services.get(url)
    //         .then((response:IApiResponse<ITenantUserDetailResponse>) => {
    //             response.data.RolePermissions = response.data.RolePermissions ?? [];
    //             response.data.UserGroups = response.data.UserGroups ?? [];
    //             this.showUserDetail = response.data;
    //         })
    //         .catch((err:string) => {
    //             this.showUserDetailError = err;
    //         }).finally(action(() => { this.showUserDetailInProgress = false; }));
    // }

    // changePassword = (id: string, data: IChangePasswordBody) => {
    //     this.changePasswordInProgress = true;
    //     const url = `${UrlConstants.UpdateTenantUserPassword}/${id}`;

    //     const passwordData = {
    //         NewPassword: data.NewPassword
    //     }
    //     return services.put(url, passwordData)
    //         .then(() => {
    //             this.changePasswordSuccess = true;
    //         })
    //         .catch((err:string) => {
    //             this.changePasswordError = err;
    //         })
    //         .finally(action(() => { this.changePasswordInProgress = false; }));
    // }

    /*
    This function is used to set the filter details to the billingFilter observable.  
    */
    setFilterDetail = (filter: IUserFilter) => {
        this.filter = { ...filter };
    }

    reset = () => {
        this.errors = '';
        this.inProgress = false;
        this.userDetail = undefined;
        this.addNewUserInProgress = false;
        this.addNewUserError = '';
        this.deleteUserState.error = '';
        this.deleteUserState.success = false;
        this.deleteUserState.inProgress = false;
        // this.deleteUserError = '';
        // this.deleteUserSuccess = false;
        // this.deleteUserInProgress = false;
        this.addNewUserInProgress = false;
        this.userDetailInProgress = false;
        this.userDetailSuccess = false;
        this.userDetailError = '';
        // this.changePasswordError = '';
        // this.changePasswordInProgress = false;
        // this.changePasswordSuccess = false;
        this.showUserDetail = userState;
        this.showUserDetailError = '';
        this.showUserDetailInProgress = false;
    }

    resetGetUserDetail = () => {
        this.userDetail = undefined;
        this.userDetailInProgress = false;
        this.userDetailError = '';
        this.userDetailSuccess = false;

    }

    resetAddNewUser = () => {
        this.addNewUserError = '';
        this.addNewUserInProgress = false;
        this.addNewUserSuccess = false;
    }

    resetError = () => {
        this.errors = '';
        this.addNewUserError = '';
        this.deleteUserState.error = '';
        this.userDetailError = '';
        this.changePasswordState.error = '';
        this.showUserDetailError = '';
    }

    get allUser() {
        if (this.userData?.Users && this.userData.Users?.length > 0) {
            const userName = localStorage.getItem("secure_fuel_userName");
            return this.userData.Users.map((user) => {
                return { ...user, Id: user.ClientUserId };
            })
        }
        return [];
    }

    get getProfileImageFromStore(): string {
        const tempProfileImage = this.profileImage ? this.profileImage : (getProfileImage() ? getProfileImage()! : "");
        return tempProfileImage;
    }

    get getProfileUserNameFromStore(): string {
        const tempProfileUserName = this.profileUserName ? this.profileUserName : (getUserName() ? getUserName()! : "");
        return tempProfileUserName;
    }

    get getUserDetail(): IAddUser {
        const userId = getUserId() != null ? getUserId() : "-1";
        if (this.userDetail) {
            return {
                ...this.userDetail,
                UserType: this.userDetail.UserType != null ? Object.keys(UserTypeEnumStringConstant).indexOf(this.userDetail.UserType) : 2,
                UserName: this.userDetail.UserName !== null ? this.userDetail.UserName : "",
                Email: this.userDetail.Email !== null ? this.userDetail.Email : "",
                PhoneNo: this.userDetail.PhoneNo !== null ? this.userDetail.PhoneNo : "",
                WhatsAppNo: this.userDetail.WhatsAppNo !== null ? this.userDetail.WhatsAppNo : "",
                ProfileImage: this.userDetail.ProfileImage !== null ? this.userDetail.ProfileImage : "",
                CustomerId: getUserType() == UserType.CustomerAdmin ? parseInt(userId!) : (this.userDetail.CustomerId !== null ? this.userDetail.CustomerId : -1),
                RoleId: (this.userDetail.UserType == UserType.CustomerAdmin || this.userDetail.UserType == UserType.CleintAdmin) ? -1 : this.userDetail.RoleId,
                Address: this.userDetail.Address !== null ? this.userDetail.Address : "",
                City: this.userDetail.City !== null ? this.userDetail.City : "",
                State: this.userDetail.State !== null ? this.userDetail.State : "",
                Country: this.userDetail.Country !== null ? this.userDetail.Country : "",
                IsActive: this.userDetail.IsActive !== null ? this.userDetail.IsActive : false,
            };
        }
        return { ...userState, CustomerId: getUserType() == UserType.CustomerAdmin ? parseInt(userId!) : -1, UserType: getUserType() == UserType.CustomerAdmin ? 0 : -1 };
    }

}

export default new UserStore();