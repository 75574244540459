import React, { useState } from "react";
import { observer } from "mobx-react";
import { withSize } from "react-sizeme";
import SearchField from "../../shared-components/SearchField/SearchField";
import Header from "../../shared-components/Header/Header";
import MainTabsView from "../TabControl/TabsView";
import { formatMessage } from "../../translations/formatMessage";
import { FormattedMessage } from "react-intl";
import { demoLiveTracking } from "../../core/stores/data";
import OSMMap from "./components/osm-map";
import "./live-tracking.scss";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import { FilterPopupHOC } from "../../shared-components/Filters/FilterPopupHOC";
import LiveTrackingFilterForm from "./forms/livetracking-filter-form";
import { ModuleName } from "../../constants/moduleName.constant";
interface IProps {
    size: {
        height: number
    };
    showFilterPopup: boolean;
    filterToggleHandler: () => void;
  }

const LiveTracking = (props: IProps) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const {
    showFilterPopup,
    filterToggleHandler,
  } = props;

  const openFilterHandler = () => {
    filterToggleHandler();
  };


    return (
      <React.Fragment>
    {showFilterPopup && (
           <LiveTrackingFilterForm 
            initialValues={{ "sgh": "vghj" }}
            submitHandler={() => { } }
            onModalClosed={filterToggleHandler}
            actionInProgress={false}      />
        )}

       <div className={`main-content`}>
              <SubHeader moduleNameString={"livetracking_title"}    setIsFullScreen={setIsFullScreen} 
               moduleName={ModuleName.LiveTracking} isAdd={false} isFullView={true}  filterHandler={openFilterHandler}
               isDownload={false} isRefresh={false} isFilter={true} isDateFilter={false} />
          </div>
          <div className={`${isFullScreen && "map-full-screen"}`}>
        {isFullScreen && <div className={'circle-icon blue miniMizeIcon'}>
          <svg onClick={() => { setIsFullScreen(isFullScreen => !isFullScreen) }} height="22" fill="#fff" width="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path d="m21 10c0-.69033-.5596-1.24997-1.25-1.24997h-2.7322l3.9822-3.98224c.4882-.48815.4882-1.27961 0-1.76776-.4882-.48816-1.2796-.48816-1.7678 0l-3.9822 3.98223v-2.73223c0-.69036-.5596-1.25-1.25-1.25-.6903 0-1.25.55964-1.25 1.25v5.74997c0 .6904.5597 1.25 1.25 1.25h5.75c.6904 0 1.25-.5596 1.25-1.25z" /><path d="m3 14c0 .6904.55964 1.25 1.25 1.25h2.73222l-3.98222 3.9823c-.48816.4881-.48816 1.2796 0 1.7677.48815.4882 1.27961.4882 1.76777 0l3.98221-3.9822v2.7322c0 .6904.55965 1.25 1.25 1.25.69032 0 1.25002-.5596 1.25002-1.25v-5.75c0-.6903-.5597-1.25-1.25002-1.25h-5.74998c-.69036 0-1.25.5597-1.25 1.25z" /></g></svg>
        </div>}
      {
        demoLiveTracking.length > 0 ?
          <OSMMap data={demoLiveTracking} /> :
          <>
            <div className="no-device">
              <div className="message text-center">
                {/* <img src={no_device} alt="" className="no_message" /> */}
                <p className="text-muted text-center h4 mb-0 noData no_device">
                  <FormattedMessage id="no_device" />
                </p>
                <p className="mb-3">{formatMessage("no_device_message")}</p>
              </div>
            </div>
          </>
      }
      </div>
      <Footer />
      </React.Fragment>
   
       
    )
}

export default withSize({ monitorHeight: true })(FilterPopupHOC(observer(LiveTracking)));