/*    
<summary>
  This functional component "AddUpdateRole" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import { IOption } from '../../../models/ICommon';
import { IAddEditRole } from '../../../models/response/IRoleResponse';
import UpdateCustomerIdsInTransactionForm from '../forms/edit-customer-ids-in-transaction-form';
import { IUpdateTransactions } from '../../../models/ITransaction';
interface IProps {
    id: number;
    modalClosed: () => void;
}

const UpdateCustomerIdsInTransaction = (props: IProps) => {

    const { transactionsStore } = useStore();
    const { getTransactionByIdService,transactionDetailState, getTransactionDetail, 
        updateCustomerIdsInTransactionByIdService, transactionCustomerIdsUpdateState, 
        resetTransactionCustomerIdsUpdateState } = transactionsStore;
    const { id, modalClosed } = props

    const permissionTypes: IOption[] = [
        { Id: 0, Name: "READ" },
        { Id: 1, Name: "READ_WRITE" },
        { Id: 2, Name: "NONE" },
    ];

    const onModalClosed = useCallback(() => {
        modalClosed();
        resetTransactionCustomerIdsUpdateState();
    }, [modalClosed,resetTransactionCustomerIdsUpdateState])

    useEffect(() => {
        if (id > 0) {
            getTransactionByIdService(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (transactionCustomerIdsUpdateState.success) {
            toast.success(formatMessage("updated_success"));
            onModalClosed();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionCustomerIdsUpdateState.success]);

    // useEffect(() => {
    //   if (roleDetailState.error) {
    //     toast.error(formatMessage(roleDetailState.error));
    //     // resetGetRoleDetail();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [roleDetailState.error]);

    const onSubmitForm = (values: IUpdateTransactions) => {
        updateCustomerIdsInTransactionByIdService(id, values);
    }

    return (
        <React.Fragment>
            <UpdateCustomerIdsInTransactionForm
                initialValues={getTransactionDetail}
                id={id}
                submitHandler={onSubmitForm}
                onModalClosed={onModalClosed}
                addUpdateInProgress={transactionCustomerIdsUpdateState.inProgress} 
                getDataInProgress={transactionDetailState.inProgress} />
        </React.Fragment>
    );
};

export default observer(UpdateCustomerIdsInTransaction);
