import React, { FC, useState } from "react";
import { IColoredAndShowIcon, IFormatter } from "../../models/IFormatter";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { NavLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { CellContext, HeaderContext } from "@tanstack/react-table";
import { formatMessage } from "../../translations/formatMessage";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  TimeFormat,
  TimeFormatWithSeconds,
} from "../../constants/timePicker.constant";
import moment from "moment";
import Select, { MenuProps, components } from "react-select";
import { ISearchSelectOption } from "../../models/ISearchSelectOption";
import Grid from "./Grid";
import TimePicker from "rc-time-picker";
import ReactDOM from "react-dom";
// import { Tooltip } from "@mui/material";

const isErrorMessage = (errorMessage: any) => {
  return errorMessage ? true : false;
};

export const AddFormatter =
  (AddIcons: Array<any>) => (info: CellContext<any, any>) => {
    const row = info.row.original;
    return (
      <>
        {AddIcons.map((e: any, i: number) => {
          let index: number = i;
          return (
            <button
              type="button"
              key={index}
              title={formatMessage(e.title)}
              disabled={e.key ? e.key.length > 0 && !row[e.key] : false}
              onClick={() => e.handler(row, info.row.index)}
            >
              <span
                className="material-symbols-outlined fs-6 mt-1"
                title={formatMessage(e.title)}
              >
                {e.icon}
              </span>
            </button>
          );
        })}
      </>
    );
  };

export const ComponentFormatter =
  (AddIcons: any[], shouldNotShowIfDisabled?: boolean) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const hideDropdown = AddIcons.every(
        (icon) => !row[icon.key] && shouldNotShowIfDisabled && icon.key.length > 0
      );
      if (hideDropdown) {
        return null;
      }
      return (
        <>
          <div className="context-menus">

            <Dropdown drop={"end"}>
              <div className="table-edit-icon">
                <Dropdown.Toggle
                  as="span"
                  title={formatMessage("Action")}
                  id="dropdown-basic"
                >
                  <div
                    className="btn-group btn-group-sm "
                    role="group"
                    aria-label="..."
                  >
                    {AddIcons.map((icon) => {
                      let shouldShowIcon = true;
                      if (icon.key) {
                        shouldShowIcon =
                          !shouldNotShowIfDisabled ||
                          !icon.key.length ||
                          row[icon.key];
                      }
                      // if (icon.isNotAnIcon)
                      //   return (
                      //     <div key={icon.title}>
                      //       {shouldShowIcon && (
                      //         <li className="dropdown-item p-0" key={icon.title}>
                      //           <icon.component Row={row} />
                      //         </li>
                      //       )}
                      //     </div>
                      //   );
                      else
                        return (
                          <>
                            {shouldShowIcon && (
                              <button  disabled={icon.key? icon.key.length > 0 && !row[icon.key]: false} key={`${icon.title}${icon.key}`} onClick={() => icon.handler(row, info.row.index)} 
                              className={`btn btn-sm ${icon.className ? icon.className : "btn-primary"}`} title={formatMessage(icon.title)}>
                                {icon.icon}
                              </button>
                            )}
                          </>
                        );
                      <button onClick={() => icon.handler(row, info.row.index)} className="btn btn-outline-primary btn-sm">

                      </button>
                    })}
                  </div>
                </Dropdown.Toggle>
              </div>
            </Dropdown>
          </div>
        </>
      );
    };

export const SortingFormatter =
  (
    handler: (accessor: string) => void,
    sortField: string,
    orderBy: string,
    accessor: string,
    text: string
  ) =>
    () => {
      let icon = "";
      if (sortField === accessor) {
        icon = orderBy === "asc" ? "north" : "south";
      }

      return (
        <div
          onClick={() => handler(accessor)}
          data-testid={`sortingDiv${accessor}`}
        >
          <FormattedMessage id={text} />
          <span
            data-testid={icon}
            className="material-symbols-outlined sorting-arrow"
          >
            {icon}
          </span>
        </div>
      );
    };
export const DetailFormatter =
  (handler: any, text: string) => (info: CellContext<any, any>) => {
    const row = info.row.original;

    return (
      <Button variant="link" onClick={() => handler(row)}>
        {formatMessage(text)}
      </Button>
    );
  };

export const TextFormatter =
  (text: string) => (info: CellContext<any, any>) => {
    const row = info.row.original;
    return text + " " + row.PhoneId;
  };

export const ButtonFormatter =
  (text: string) => (info: CellContext<any, any>) => {
    return <Button variant="link">{formatMessage(text)}</Button>;
  };

export const SearchSelectFormatter =
  (
    options: Array<ISearchSelectOption>,
    handler: (value: string | number | undefined, rowIndex: number) => void,
    notAlwaysEditable?: boolean,
    inEditModeIndex?: number,
    disableSelectedRows?: Array<number>,
    options2?: Array<ISearchSelectOption>,
    optionChangeRowIndex?: number,
    setOptionRender?: (index: number) => any
  ) =>
    (info: CellContext<any, any>) => {
      const value = info.getValue();
      let setOptionRenderFn = (index: number) => {
        if (setOptionRender) {
          return setOptionRender(index);
        } else {
          return options2
            ? index === optionChangeRowIndex
              ? options2
              : options
            : options;
        }
      };
      const optionsToRender = setOptionRenderFn(info.row.index);
      const selectedOption = optionsToRender.find(
        (option: any) => option.value === value
      );

      const Menu = (props: MenuProps<any>) => {
        return (
          // ReactDOM.createPortal(
          <components.Menu {...props}>{props.children}</components.Menu>
          // , document.body)
        );
      };

      const SearchSelectField = (
        <div className="form-group default-select mb-0">
          {" "}
          <Select
            options={optionsToRender}
            value={selectedOption}
            onChange={(e: any) => handler(e?.value, info.row.index)}
            isDisabled={
              disableSelectedRows
                ? disableSelectedRows.includes(info.row.index)
                : false
            }
            classNamePrefix="search-select"
            components={{ Menu }}
          />
        </div>
      );

      return (
        <>
          {notAlwaysEditable ? (
            <>
              {info.row.index === inEditModeIndex
                ? SearchSelectField
                : selectedOption?.value !== 0 && selectedOption?.label}
            </>
          ) : (
            SearchSelectField
          )}
        </>
      );
    };

export const SelectAllCheckboxFormatter =
  (
    handler: (
      event: React.ChangeEvent<HTMLInputElement>,
      rowIndex: number
    ) => void,
    disableSelectedRows?: Array<number>
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();

      return (
        <div className="form-group checkbox-btn mb-0">
          <span>
            <input
              type="checkbox"
              checked={cellValue}
              onChange={(event) => handler(event, info.row.index)}
              disabled={
                disableSelectedRows
                  ? disableSelectedRows.includes(info.row.index)
                  : false
              }
            />
          </span>
        </div>
      );
    };

export const SelectAllCheckboxFormatterForHeader =
  (
    handler: (event: React.ChangeEvent<HTMLInputElement>) => void,
    displayName: string,
    headerCheckbox?: boolean
  ) =>
    (info: HeaderContext<any, any>) => {
      return (
        <div className="form-group checkbox-btn mb-0">
          <span className="d-flex align-items-center pe-0">
            <input
              type="checkbox"
              checked={headerCheckbox}
              onChange={(event) => handler(event)}
            />
            {displayName}
          </span>
        </div>
      );
    };

interface IDropDownCheckboxProps {
  data: Array<any>;
  columns: Array<any>;
  handler: (selectedRows: number[], rowIndex: number) => void;
  rowIndex: number;
  initialValue: Array<number>;
  keyField: string;
  hiddenColumns: Array<string>;
  disabled: boolean;
}

const DropdownCheckboxGroup = (props: IDropDownCheckboxProps) => {
  const [selectedRows, setSelectedRows] = useState<number[]>(
    props.initialValue
  );
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      className="multi-select-dropdown multi-select-dropdown-table"
      style={{ maxWidth: "234px" }}
      show={show}
      onToggle={() => {
        setShow(!show);
        if (show) props.handler(selectedRows, props.rowIndex);
      }}
    >
      <Dropdown.Toggle
        disabled={props.disabled}
        className="pt-0"
        variant="white"
      >
        {<FormattedMessage id="Label_Select" />}
        <span className="material-symbols-outlined">expand_more</span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="pt-0" variant="white">
        <div className="dropdown-checkbox-scroll all-checkbox-select">
          <Grid
            data={props.data}
            columns={props.columns}
            rowSelectionEnabled={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            keyField={props.keyField}
            hiddenColumns={props.hiddenColumns}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface ITimePickerProps {
  timeValue: moment.Moment;
  row: any;
  handler: (event: moment.Moment, row: any, rowIndex: number) => void;
  rowIndex: number;
}

const TimePickerField = (props: ITimePickerProps) => {
  const { timeValue, row, handler, rowIndex } = props;

  const TimePickerComponent: any = TimePicker;

  return (
    <TimePickerComponent
      showSecond={false}
      focusOnOpen={true}
      clearIcon={<></>}
      format={TimeFormat}
      value={timeValue}
      onChange={(event: moment.Moment) => handler(event, row, rowIndex)}
      inputIcon={
        <span
          className="material-symbols-outlined clock-icon"
          data-testid="timePickerOpen"
        >
          schedule
        </span>
      }
      getPopupContainer={(triggerNode: any) => {
        return triggerNode.parentNode;
      }}
    />
  );
};

export const SelectCheckBoxFormatter =
  (
    dataAndColumns: { data: Array<any>; column: Array<any> },
    handler: (selectedRows: number[], rowIndex: number) => void,
    keyField: string,
    hiddenColumns: Array<string>,
    nameField: string,
    notAlwaysEditable?: boolean,
    inEditModeIndex?: number,
    disableSelectedRows?: Array<number>
  ) =>
    (info: CellContext<any, any>) => {
      const value = [...info.getValue()];
      const { data, column } = dataAndColumns;

      const DropDownCheckboxField = (
        <DropdownCheckboxGroup
          initialValue={value}
          data={data}
          columns={column}
          handler={handler}
          rowIndex={info.row.index}
          keyField={keyField}
          hiddenColumns={hiddenColumns}
          disabled={
            disableSelectedRows
              ? disableSelectedRows.includes(info.row.index)
              : false
          }
        />
      );

      return (
        <>
          {notAlwaysEditable ? (
            <>
              {info.row.index === inEditModeIndex ? (
                DropDownCheckboxField
              ) : (
                <>
                  {data
                    .filter((op) => value.includes(op[keyField]))
                    .map((userMap) => userMap[nameField])
                    .join(", ")}
                </>
              )}
            </>
          ) : (
            DropDownCheckboxField
          )}
        </>
      );
    };

export const ActionFormatter =
  (handler: any, text: string) => (info: CellContext<any, any>) => {
    const row = info.row.original;

    return (
      <Button
        disabled={row.isEnabled}
        variant="link"
        onClick={() => handler(row)}
      >
        {row.Name}
      </Button>
    );
  };

export const HeaderIconFormatter =
  (AddIcons: Array<IFormatter>, isDisabled: boolean) => (info: any) => {
    return (
      <>
        {AddIcons.map((e, i: number) => {
          let index: number = i;
          return (
            <button
              type="button"
              className="all-delete-btn"
              key={index}
              title={e.title}
              disabled={isDisabled}
              onClick={() => e.handler({ Id: e.id })}
            >
              <span
                className="material-symbols-outlined fs-5"
                title={formatMessage(e.title)}
              >
                {e.icon}
              </span>
            </button>
          );
        })}
      </>
    );
  };

export const IncidentsDetailFormatter =
  (handler: any, text: string, route: string) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      return (
        <NavLink end onClick={() => handler(row)} to={route}>
          {text}
        </NavLink>
      );
    };

export const clickFormatter =
  (handler: any) => (info: CellContext<any, any>) => {
    const row = info.row.original;
    const value = info.getValue();

    return (
      <Button variant="link" onClick={() => handler(row)}>
        {value}
      </Button>
    );
  };

export const CheckboxGroupFormatter =
  (
    options: Array<{ Id: string; Name: string }>,
    handler: (
      event: React.ChangeEvent<HTMLInputElement>,
      row: any,
      rowIndex: number
    ) => void,
    errorMessage?: string,
    flagFieldName?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();

      //to check if field is touched
      const showErrorCondition = flagFieldName
        ? row[flagFieldName]
        : errorMessage;

      return (
        // <Tooltip placement="left" title={!cellValue.length && showErrorCondition && <FormattedMessage id={errorMessage} />} arrow>
        <div data-testid="checkboxGroupTooltipDiv">
          <div className="d-flex align-items-center">
            {options.map((option, index) => {
              return (
                <div key={option.Id} className="form-group checkbox-btn mb-0">
                  <span>
                    <input
                      name={`days${index}`}
                      id={`${option.Name}${index}`}
                      type="checkbox"
                      value={option.Id}
                      key={option.Id}
                      checked={cellValue.includes(option.Id)}
                      data-testid={`${option.Id}${index}`}
                      onChange={(event) => handler(event, row, info.row.index)}
                    />
                    <FormattedMessage id={option.Name} />
                  </span>
                </div>
              );
            })}
          </div>
          {!cellValue.length && showErrorCondition && (
            <span
              data-testid="testError"
              className="text-left bg-danger text-white errorMessage p-1 mt-1"
            >
              <FormattedMessage id={errorMessage} />
            </span>
          )}
        </div>
        // </Tooltip>
      );
    };

export const RadioGroupFormatter =
  (
    options: Array<{ Id: string; Name: string }>,
    handler: (
      event: React.ChangeEvent<HTMLInputElement>,
      row: any,
      rowIndex: number
    ) => void,
    errorMessage?: string,
    flagFieldName?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();

      //to check if field is touched
      const showErrorCondition = flagFieldName
        ? row[flagFieldName]
        : errorMessage;

      return (
        // <Tooltip placement="left" title={!cellValue.length && showErrorCondition && <FormattedMessage id={errorMessage} />} arrow>
        <div data-testid="radioGroupTooltipDiv">
          <div className="d-flex align-items-center">
            {options.map((option, index) => {
              return (
                <div key={option.Id} className="form-group radio-btn mb-0">
                  <span>
                    <input
                      name={`days${info.row.index}`}
                      id={`${option.Id}${index}`}
                      type="radio"
                      value={option.Id}
                      key={option.Id}
                      checked={option.Id === cellValue}
                      data-testid={`${option.Id}${index}`}
                      onChange={(event) => handler(event, row, info.row.index)}
                    />
                    <FormattedMessage id={option.Name} />
                  </span>
                </div>
              );
            })}
          </div>
          {!cellValue.length && showErrorCondition && (
            <span
              data-testid="testError"
              className="text-left bg-danger text-white errorMessage p-1 mt-1"
            >
              <FormattedMessage id={errorMessage} />
            </span>
          )}
        </div>
        // </Tooltip>
      );
    };

export const NumberInputFormatter =
  (
    handler: (
      event: React.ChangeEvent<HTMLInputElement>,
      row: any,
      rowIndex: number
    ) => void,
    min: number,
    max: number,
    errorMessage?: string,
    errorFieldName?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();
      const showErrorCondition = errorFieldName ? row[errorFieldName] : false;

      return (
        // <Tooltip placement="left" title={showErrorCondition && <FormattedMessage id={errorMessage} />} arrow>
        <div
          className="form-group mb-0 default-input"
          data-testid="numberInputTooltipDiv"
        >
          <input
            type="number"
            min={min}
            max={max}
            value={Number.isNaN(cellValue) ? "" : cellValue}
            onChange={(event) => handler(event, row, info.row.index)}
          />
          {showErrorCondition && (
            <span
              data-testid="testError"
              className="text-left bg-danger text-white errorMessage p-1 mt-1 errorMessage"
            >
              <FormattedMessage id={errorMessage} />
            </span>
          )}
        </div>
        // </Tooltip>
      );
    };

export const TimePickerFormatter =
  (
    handler: (event: moment.Moment, row: any, rowIndex: number) => void,
    errorMessage?: string,
    errorFieldName?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const timeValue = moment(info.getValue(), TimeFormatWithSeconds);

      const showErrorCondition = errorFieldName ? row[errorFieldName] : false;

      return (
        <>
          {/* <Tooltip placement="left" title={showErrorCondition && <FormattedMessage id={errorMessage} />} arrow> */}
          <div
            className="form-group mb-0 default-input"
            data-testid="timePickerTooltipDiv"
          >
            <TimePickerField
              handler={handler}
              timeValue={timeValue}
              row={row}
              rowIndex={info.row.index}
            />
            {showErrorCondition && (
              <span
                data-testid="testError"
                className="text-left bg-danger text-white errorMessage p-1 mt-1"
              >
                <FormattedMessage id={errorMessage} />
              </span>
            )}
          </div>
          {/* </Tooltip> */}
        </>
      );
    };

export const TwoSetOfIconsFormatter =
  (
    firstSetOfIcons: Array<IFormatter>,
    secondSetOfIcons: Array<IFormatter>,
    iconsChangeRowIndex: number
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;

      const iconsToRender =
        info.row.index === iconsChangeRowIndex
          ? secondSetOfIcons
          : firstSetOfIcons;

      return (
        <div className="context-menus">
          <Dropdown drop={"end"}>
            <div>
              <Dropdown.Toggle
                as="span"
                title={formatMessage("Action")}
                id="dropdown-basic"
              >
                <span className="material-symbols-outlined">more_vert</span>
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu as="ul">
              {iconsToRender.map((icon) => {
                return (
                  <div key={icon.title}>
                    <Dropdown.Item
                      key={icon.title}
                      as="li"
                      disabled={icon.key.length > 0 && !row[icon.key]}
                      onClick={() => icon.handler(row, info.row.index)}
                    >
                      <span
                        className="material-symbols-outlined"
                        title={formatMessage(icon.title)}
                      >
                        {icon.icon}
                      </span>
                      <span>
                        <FormattedMessage id={icon.title} />
                      </span>
                    </Dropdown.Item>
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    };

export const DateTimeFormatter = () => (info: CellContext<any, string>) => {
  //<FormattedTime value={"2022-09-22T10:59:59.137Z"} timeStyle="medium" hour12={false} timeZone="utc"  />
  //timezone='utc' is required if time comes in this form(with Z at end)
  //<FormattedTime value={"2023-04-04T19:21:38.817"} timeStyle="medium" hour12={false} />
  const value = info.getValue(); //value is of type string "2023-04-04T19:21:38.817" or "2022-09-22T10:59:59.137Z"

  return (
    <>
      <FormattedDate
        value={value}
        year="numeric"
        month="2-digit"
        day="2-digit"
      />{" "}
      &nbsp;
      <FormattedTime value={value} timeStyle="medium" hour12={false} />
    </>
  );
};

export const LineProgressBarFormatter =
  () => (info: CellContext<any, number>) => {
    const value = info.getValue();

    return (
      <>
        {value >= 0 && (
          <ProgressBar
            now={value}
            label={`${value}%`}
            variant={value === 0 ? "danger" : "success"}
          />
        )}
      </>
    );
  };

interface ITextInputFieldProps {
  info: any;
  cellValue: any;
  row: any;
  showErrorCondition: any;
  handler: (
    event: React.ChangeEvent<HTMLInputElement>,
    row: any,
    rowIndex: number
  ) => void;
  max?: number;
  disableSelectedRows?: Array<number>;
  errorMessage?: string;
}

const TextInputField: FC<ITextInputFieldProps> = (
  props: ITextInputFieldProps
) => {
  const {
    info,
    cellValue,
    row,
    showErrorCondition,
    handler,
    max,
    disableSelectedRows,
    errorMessage,
  } = props;
  return (
    <div className="form-group mb-0">
      <input
        type="text"
        name={`text${info.row.index}`}
        value={cellValue}
        maxLength={max}
        onChange={(event) => handler(event, row, info.row.index)}
        disabled={
          disableSelectedRows
            ? disableSelectedRows.includes(info.row.index)
            : false
        }
      />
      {showErrorCondition && (
        <span
          data-testid="testError"
          className="text-left ms-3 mt-2 text-danger errorMessage"
        >
          <FormattedMessage id={errorMessage} />
        </span>
      )}
    </div>
  );
};

export const TextInputFormatter =
  (
    handler: (
      event: React.ChangeEvent<HTMLInputElement>,
      row: any,
      rowIndex: number
    ) => void,
    disableSelectedRows?: Array<number>,
    min?: number,
    max?: number,
    notAlwaysEditable?: boolean,
    inEditModeIndex?: number,
    errorMessage?: string,
    errorFieldName?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();
      const showErrorCondition = errorFieldName ? row[errorFieldName] : false;

      return (
        <>
          {notAlwaysEditable ? (
            <>
              {info.row.index === inEditModeIndex ? (
                <TextInputField
                  info={info}
                  cellValue={cellValue}
                  row={row}
                  showErrorCondition={showErrorCondition}
                  handler={handler}
                  max={max}
                  disableSelectedRows={disableSelectedRows}
                  errorMessage={errorMessage}
                />
              ) : (
                cellValue
              )}
            </>
          ) : (
            <TextInputField
              info={info}
              cellValue={cellValue}
              row={row}
              showErrorCondition={showErrorCondition}
              handler={handler}
              max={max}
              disableSelectedRows={disableSelectedRows}
              errorMessage={errorMessage}
            />
          )}
        </>
      );
    };

export const CheckboxInputFormatter =
  (
    handler: (event: any, row: any, rowIndex: number) => void,
    notAlwaysEditable?: boolean,
    inEditModeIndex?: number,
    disableSelectedRows?: Array<number>,
    enable?: string,
    disable?: string
  ) =>
    (info: CellContext<any, any>) => {
      const row = info.row.original;
      const cellValue = info.getValue();
      const CheckboxField = (
        <div className="form-group mb-0">
          <input
            name={`checkbox${info.row.index}`}
            type="checkbox"
            onChange={(event) => handler(event, row, info.row.index)}
            checked={cellValue}
            disabled={
              disableSelectedRows
                ? disableSelectedRows.includes(info.row.index)
                : false
            }
          />
        </div>
      );
      const displayValue = cellValue ? "Enabled" : "Disabled";
      return (
        <>
          {notAlwaysEditable ? (
            <>
              {info.row.index === inEditModeIndex ? CheckboxField : displayValue}
            </>
          ) : (
            CheckboxField
          )}
        </>
      );
    };

export const ColoredIconFormatter =
  (icons: Array<IColoredAndShowIcon>) => (info: CellContext<any, any>) => {
    const row = info.row.original;

    return (
      <ul>
        {icons.map((icon) => {
          const color: string = row[icon.key];

          return (
            <span key={icon.title}>
              {color.length ? (
                <li style={{ display: "inline-block", marginRight: "10px" }}>
                  <span
                    style={{ color: color, fontSize: 20 }}
                    className="material-symbols-outlined mt-1"
                    title={formatMessage(icon.title)}
                  >
                    {icon.icon}
                  </span>
                </li>
              ) : (
                <></>
              )}
            </span>
          );
        })}
      </ul>
    );
  };

  export const JsxFormatter =
  (jsxElement: JSX.Element) => (info: CellContext<any, any>) => {
    const row = info.row.original;
    return (
      jsxElement
    );
  };

  export const CustomDivAlarmFormatter =
  () => (info: CellContext<any, any>) => {
    const row = info.row.original;
    const cellValue = info.getValue();
    return (
      <div className="alarm-color badge"
      // className={`${cellValue?"":""} ""`} 
      style={cellValue==="green"?{backgroundColor:"green"}:{backgroundColor:"#feecec"}}  // remove is and add className
      >
        {cellValue}
      </div>
    );
  };
