/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { useEffect } from "react";
import { Modal, Image, Row, Col, ListGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./profile.scss";
import { useStore } from "../../contexts/StoreProvider";
import SpinnerLoader from "../Loader/SpinnerLoader";
import { formatMessage } from "../../translations/formatMessage";
import { observer } from "mobx-react";
import Avatar from "../../assets/Images/defaultProfile.png";
import { getProfileImage } from "../../helpers/localStorages.helper";
import { getUserTypeStyleClass } from "../../constants/common-constant";

interface IProps {
  id: number;
  modalClosed: () => void;
  openEditProfilePopupHandler: () => void;
}

const ProfilePage = (props: IProps) => {
  const { modalClosed, openEditProfilePopupHandler, id } = props;
  const { userStore } = useStore();
  const {
    GetUserProfileDetailByIdService,
    resetGetUserProfileDetail,
    userProfileDetail,
    userProfileDetailState,
  } = userStore;
  
  // const profileImage = getProfileImage() === "" || getProfileImage() == null ? Avatar : getProfileImage() ?? Avatar;

  useEffect(() => {
    GetUserProfileDetailByIdService(id);
  }, []);

  const onModalClosed = () => {
    resetGetUserProfileDetail();
    modalClosed();
  };

  return (
    <div>
      <Modal
        className="add-user-modal modal-profile"
        show={true}
        centered
        size="lg"
      >
        <Modal.Header className="border-0">
          <Modal.Title>
            <FormattedMessage id="profile_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        {userProfileDetailState.inProgress && <SpinnerLoader />}
        <Modal.Body className="p-0">
          {userProfileDetail ? (
            <section className="profile_user">
              <Row>
                <Col xl={5} lg={5} md={5} xs={5}>
                  {/* <div className="userProfile pt-3"> */}
                  <div className="profile-user position-relative">
                    <div className="profile-ring">
                      <Image
                        // src={ profileImage}
                        src={(userProfileDetail.ProfileImage === "" || userProfileDetail.ProfileImage == null) ? Avatar : userProfileDetail.ProfileImage ?? Avatar}
                        roundedCircle
                        className="profile-image"
                        alt="Profile"
                      />
                     
                    </div>
                    <div className="profile-content">
                      {/* <h4 className="user-name fw-semi-bold text-white">
                        {userProfileDetail?.Username}
                      </h4> */}
                      {/* <small className="text-white">
                        {" "}
                        <FormattedMessage id="is_active" /> :
                        <span
                          className={`status-indicator ${
                            userProfileDetail?.IsActive
                              ? " active"
                              : " inactive"
                          }`}
                        >
                          {formatMessage(
                            userProfileDetail ? "active" : "not_active"
                          )}
                        </span>
                      </small> */}
                    </div>
                  </div>
                </Col>

                <Col xl={7} lg={7} md={7} xs={7}>
                  <div className="align-items-center ">
                    <div className="user-profile-popup pe-4">
                      <Row className="g-0">
                        <Col lg="6" xs="6">
                          <h2 className="user-name fw-semi-bold fs-3 break-all">
                            {userProfileDetail?.Username}
                          </h2>
                        </Col>
                        <Col lg="6" xs="6">
                          <div className="float-end">
                            <div
                              className="edit-profile circle-icon yellow" title="Edit"
                              onClick={openEditProfilePopupHandler}
                            >
                              <svg 
                                viewBox="0 0 492.49284 492"
                              >
                                <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
                                <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
                              </svg>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <small className="userLogin position-relative">
                      <span className={`badge badge-pill badge-primary ps-1 h6 ${getUserTypeStyleClass(userProfileDetail.LoginType??"")}`}>
                          {userProfileDetail?.LoginType
                            ? formatMessage(userProfileDetail.LoginType)
                            : ""}
                        </span>
                        <span className="status online"></span>
                      </small>
                      <br />
                      <small className="text-muted fs-6 fw-normal mt-5 ps-1 pt-1">
                        {userProfileDetail?.RoleName}
                      </small>
                    </div>
                  </div>
                  <div className="profile-address pe-4 pt-3">
                    <ListGroup>
                      {userProfileDetail.PhoneNo && (
                        <ListGroup.Item className="border-0 p-0 email bg-transparent d-flex justify-content-start mb-3">
                          <div className="circle-icon green">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              data-name="Layer 1"
                              viewBox="0 0 64 64"
                            >
                              <path d="M60.85,46.11a4.74,4.74,0,0,0-2.41-3L46.07,36.7a4.71,4.71,0,0,0-6,1.37l-2,2.74a.72.72,0,0,1-.93.21,36.3,36.3,0,0,1-8-6.17,36.31,36.31,0,0,1-6.17-8,.72.72,0,0,1,.21-.93l2.73-2a4.71,4.71,0,0,0,1.37-6L20.91,5.56A4.73,4.73,0,0,0,14.09,3.8L6.32,9A7.48,7.48,0,0,0,3,15.69a37.64,37.64,0,0,0,.89,6.57C6,30.4,11.09,38.77,18.16,45.85S33.61,58,41.75,60.09a37.67,37.67,0,0,0,6.57.89l.5,0A7.48,7.48,0,0,0,55,57.69l5.18-7.77A4.73,4.73,0,0,0,60.85,46.11Zm-4,1.58L51.7,55.47A3.5,3.5,0,0,1,48.58,57a34.57,34.57,0,0,1-5.83-.77C35.29,54.29,27.57,49.6,21,43S9.72,28.71,7.78,21.25A34.54,34.54,0,0,1,7,15.42a3.47,3.47,0,0,1,1.53-3.11l7.77-5.18a.71.71,0,0,1,.4-.12l.19,0a.72.72,0,0,1,.46.37l6.4,12.37a.73.73,0,0,1-.21.92l-2.73,2a4.72,4.72,0,0,0-1.28,6.13,40.54,40.54,0,0,0,6.8,8.81,40.52,40.52,0,0,0,8.81,6.8,4.72,4.72,0,0,0,6.13-1.28l2-2.74a.73.73,0,0,1,.92-.21l12.37,6.4a.73.73,0,0,1,.27,1.05Z" />
                            </svg>
                          </div>
                          <div className="ps-2 fs-6 Address text-break">
                            {" "}
                            {userProfileDetail.PhoneNo}
                          </div>
                        </ListGroup.Item>
                      )}

                      {userProfileDetail.WhatsAppNo && (
                        <ListGroup.Item className="border-0 p-0 email bg-transparent d-flex justify-content-start mb-3">
                          <div>
                          <div className="circle-icon blue">
                            <svg
                              version="1.1"
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              viewBox="0 0 308 308"
                              height={24}
                              width={24}
                              fill="#49B6F5"
                            >
                              <g id="XMLID_468_">
                                <path
                                  id="XMLID_469_"
                                  d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
  c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
  c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
  c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
  c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
  c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
  c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
  c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
  c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
  C233.168,179.508,230.845,178.393,227.904,176.981z"
                                />
                                <path
                                  id="XMLID_470_"
                                  d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
  c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
  c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
  M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
  l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
  c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
  C276.546,215.678,222.799,268.994,156.734,268.994z"
                                />
                              </g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                              <g></g>
                            </svg>
                          </div>
                          </div>
                          <div className="ps-2 fs-6 text-break">
                            {" "}
                            {userProfileDetail.WhatsAppNo}
                          </div>{" "}
                        </ListGroup.Item>
                      )}
                      {userProfileDetail.Email && (
                        <ListGroup.Item className="border-0 p-0 email bg-transparent d-flex justify-content-start mb-3">
                          
                          <div><div className="circle-icon purple">
                            <svg
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g id="Layer_2" data-name="Layer 2">
                                <path d="m19 20.5h-14a4.00427 4.00427 0 0 1 -4-4v-9a4.00427 4.00427 0 0 1 4-4h14a4.00427 4.00427 0 0 1 4 4v9a4.00427 4.00427 0 0 1 -4 4zm-14-15a2.00229 2.00229 0 0 0 -2 2v9a2.00229 2.00229 0 0 0 2 2h14a2.00229 2.00229 0 0 0 2-2v-9a2.00229 2.00229 0 0 0 -2-2z" />
                                <path d="m12 13.43359a4.99283 4.99283 0 0 1 -3.07031-1.0542l-6.544-5.08984a1.00035 1.00035 0 0 1 1.22852-1.5791l6.54394 5.08984a2.99531 2.99531 0 0 0 3.6836 0l6.54394-5.08984a1.00035 1.00035 0 0 1 1.22852 1.5791l-6.544 5.08984a4.99587 4.99587 0 0 1 -3.07021 1.0542z" />
                              </g>
                            </svg>
                          </div>
                          </div>
                          <div className="ps-2 fs-6 Address text-break">
                            {" "}
                            {userProfileDetail.Email}
                          </div>{" "}
                        </ListGroup.Item>
                      )}
                      {userProfileDetail.Address && (
                        <ListGroup.Item className="border-0 p-0 email bg-transparent d-flex justify-content-start mb-3">
                         <div>
                          <div className="circle-icon yellow">
                            <span className="material-symbols-outlined text-warning fs-2">
                              home
                            </span>
                          </div>
                          </div>
                          <div className="ps-2 fs-6 text-break">
                            {" "}
                            {userProfileDetail?.Address}
                          </div>{" "}
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  </div>
                </Col>
              </Row>
            </section>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default observer(ProfilePage);
