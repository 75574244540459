/*    
<summary>
  This functional component "ClientFilterForm" provides form to get data based on filter
  Developer:Aashish Singh, Created Date:20-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import { Field, Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { IOption } from "../../../models/ICommon";
import { ITransactionFilter, ITransactionInitialFilterState } from "../../../models/IFilter";
import { IUser } from "../../../models/IUser";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import FormikFormDateTimePicker from "../../../shared-components/FormikFormHandlers/FormikFormDateTimePicker";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { filterTransactionValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import Label from "../../../shared-components/label/label";
import { TransactionsEnum } from "../../../constants/enums/productIdTransactionsEnum";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";

interface IProps {
  submitHandler: (values: ITransactionFilter) => void;
  onModalClosed: () => void;
  actionInProgress: boolean;
  initialValues: ITransactionInitialFilterState;
  CustomerIdOptions: IOption[];
  TruckIdOptions: IOption[];
  productId: string;
}

const TransactionFilterForm = (props: IProps) => {
  const { submitHandler, onModalClosed, initialValues, actionInProgress, productId } =
    props;

  const getSelectIdFieldLableHandler = () =>{
    if(productId == TransactionsEnum.Deliveries)
      return "transactions_label_customerid1"
    else if(productId == TransactionsEnum.Transaction)
      return "supplier_id"
    else
      return "transfer_id"
  }
  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };


  const [err, setErr] = useState<boolean>(false);
  const { preferencesStore } = useStore();
  const date = new Date();
  const startDate: Date = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  const endDate: Date = date;
  // const [dateFilter, setDateFilter] = useState<{ startDate: Date, endDate: Date }>({ startDate: initialValues.StartDate, endDate: initialValues.EndDate });
  const [isGlobalFilterChanged, setIsGlobalFilterChanged] = useState<boolean>(false);
  return (
    <>
      <Modal
        className="add-user-modal filter-from " show={true} centered >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="filter_title" />
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={filterTransactionValidateSchema}
          onSubmit={async (values: any) => {
            // if (JSON.stringify(values) === JSON.stringify(_.omit(transactionInitialFilterState, ["isFilterActive"]))) {
            //   // toast.warn(formatMessage("please_select_at_least_1_field"));
            //   setErr(true);
            //   setTimeout(() => { setErr(false) }, 3000);
            // }
            // else {
            let data = {
              TicketNumber: values.TicketNumber ? values.TicketNumber.trim() : null,
              StartDate: values.StartDate ? moment(values.StartDate.$d as Date ? values.StartDate.$d : values.StartDate).utc().toISOString() : "",
              EndDate: values.EndDate ? moment(values.EndDate.$d as Date ? values.EndDate.$d : values.EndDate).utc().toISOString() : "",
              isFilterActive: false,
              TruckId: values.TruckId === "please_select" ? null : values.TruckId,
              CustomerID1: values.CustomerID1 === "please_select" ? null : values.CustomerID1
            }
            // setErr(false);
            props.submitHandler(data);
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              {actionInProgress && <SpinnerLoader />}
              <FormObserver />
              <Modal.Body>

                <Row className=" date-range-transaction">
                  {/* <Col xs={12}>
                    <Label className="form-label mt-2" label="Date Range" />
                  </Col> */}
                  {/* <Col xs={12}>
                    <DateRangeFilter dateFilterHandler={setDateFilter} setIsGlobalFilterChanged={setIsGlobalFilterChanged} isGlobalFilterChanged={isGlobalFilterChanged} dateFilter={dateFilter} />
                  </Col> */}
                  <Col xs={6} className="alarm-datepicker">
                    <div className="form-group datepicker-transaction">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_starttime"
                      />
                      {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                          /> */}
                      <Field
                        name="StartDate"
                        as={FormikFormDateTimePicker}
                        // label="From"
                        ampm="false"

                      />
                    </div>

                  </Col>
                  <Col xs={6} className="alarm-datepicker">
                    <div className="form-group datepicker-transaction">
                      <Label
                        className="form-label"
                        // required={true}
                        label="transactions_label_endtime"
                      />
                      {/* <Field
                            className="form-control"
                            aria-label="Default select example"
                            name="TsGen"
                            as={FormikFormDateTimePicker}
                          /> */}
                      <Field
                        name="EndDate"
                        as={FormikFormDateTimePicker}
                        // label="From"
                        ampm="false"
                      // max={getCurrentDateTime()}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="transactions_label_ticketnumber"
                      />
                      <Field
                        data-testid="testTicketNumber"
                        className="form-control"
                        name="TicketNumber"
                        type="text"
                        invalidcharacter={[" "]}
                        placeholder="transactions_label_ticketnumber"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label={getSelectIdFieldLableHandler()}
                      />
                      <Field
                        data-testid="testCustomerID1"
                        className="form-control"
                        name="CustomerID1"
                        type="text"
                        invalidcharacter={[" "]}
                        // placeholder="transactions_label_customerid1"
                        options={props.CustomerIdOptions}
                        as={FormikFormSelectField}
                        isName={true}
                      />
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        label="transactions_label_truckid"
                      />
                      <Field
                        data-testid="testCustomerID1"
                        className="form-control"
                        name="TruckId"
                        type="text"
                        validcharacter={["_"]}
                        invalidcharacter={[" "]}
                        placeholder="transactions_label_truckid"
                        options={props.TruckIdOptions}
                        as={FormikFormSelectField}
                        isName={true}
                      />
                    </div>
                  </Col>
                </Row>


                {/* {
                  err &&
                  <div className="error-text text-center w-100">
                  <span data-testid="testError" className="text-danger errorMessage ">
                    <FormattedMessage id="please_select_at_least_1_field" />
                  </span>
                  </div>
                } */}

              </Modal.Body>
              <div className="modal-footer border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={actionInProgress}
                    disabled={actionInProgress}
                    text="filter_title"
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default TransactionFilterForm;
