import { IntlProvider, ReactIntlErrorCode } from "react-intl";
import { useStore } from "../contexts/StoreProvider";
import translations from "./translation";
import { observer } from "mobx-react";
import React from "react";

type Props = {
    children?: React.ReactNode;
};

const onError = (e:any) => {
    if (e.code === ReactIntlErrorCode.MISSING_DATA) {
        return
    }
    //console.error(e)
}

const IntlProviderNew = ({ children }: Props) => {
    const { languageStore } = useStore();
    const lang = localStorage.getItem("secure_fuel_language") ?? languageStore.language;
    localStorage.setItem("secure_fuel_language",lang);
    return <IntlProvider onError={onError} defaultLocale="en" locale={languageStore.language === 'jp'? 'ja-JP': 'en'} messages={translations[languageStore.language].messages} >
        {children}
    </IntlProvider>
}

export default observer(IntlProviderNew);