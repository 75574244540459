/*    
<summary>
  This functional component "AddUpdateRole" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import { observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStore } from '../../../contexts/StoreProvider';
import { formatMessage } from '../../../translations/formatMessage';
import { IOption } from '../../../models/ICommon';
import { IAddEditRole } from '../../../models/response/IRoleResponse';
import AddUpdateRoleForm from '../form/add-edit-role-form';
import './add-edit-role.scss';
interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddUpdateRole = (props: IProps) => {

  const { roleStore } = useStore();
  const { GetRoleByIdService, resetAddUpdateRoleState, resetGetRoleDetail, roleDetailState, getRoleDetail, addUpdateRoleState, AddRoleService, UpdateRoleService} = roleStore;
  const initialValues: IAddEditRole = getRoleDetail;
  const{id, modalClosed}=props

  const permissionTypes: IOption[] = [
    { Id: 0, Name: "READ" },
    { Id: 1, Name: "READ_WRITE" },
    { Id: 2, Name: "NONE" },
  ];

  const onModalClosed = useCallback(() => {
    resetGetRoleDetail();
    resetAddUpdateRoleState();
     modalClosed();
  },[resetGetRoleDetail,resetAddUpdateRoleState,modalClosed])
 
  useEffect(() => {
    if (id > 0) {
      GetRoleByIdService(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (addUpdateRoleState.success) {
      if ( id === -1)
        toast.success(formatMessage("added_success"));
      else
        toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateRoleState.success]);

  // useEffect(() => {
  //   if (roleDetailState.error) {
  //     toast.error(formatMessage(roleDetailState.error));
  //     // resetGetRoleDetail();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [roleDetailState.error]);

  useEffect(() => {
    if (addUpdateRoleState.error) {
        toast.error(formatMessage(addUpdateRoleState.error));
        resetAddUpdateRoleState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateRoleState.error]);

  const onSubmitForm = (values: IAddEditRole) => {
    if(id>0)
        UpdateRoleService(id, values);
    else
        AddRoleService( id, values);
  }

  return (
    <React.Fragment>
      <AddUpdateRoleForm
        initialValues={initialValues}
        id={id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getDataInProgress={roleDetailState.inProgress}
        addUpdateInProgress={addUpdateRoleState.inProgress}
        permissionTypes={permissionTypes}
      />
    </React.Fragment>
  );
};

export default observer(AddUpdateRole);
