/*    
<summary>
   This file is used to provide initial values or state to the dashboard Data observables.
   Developer:Ashish Singh, Created Date:04-April-2024
</summary>
*/

import { ICustomerDashboardMetrics, ICustomerFuelStatusDetail, IDashboardMetrics, IFuelStatusDetail, IGetPricingDashboardMetrics, IPricingDashboardMetrics, IRecentAlarms, ITankLevelDetail, IVolumeConsumedDetail, IVolumeDeliveredDetail } from "../../models/response/IDashboardResponse";

export const fuelStatusInitialDetail: IFuelStatusDetail = {
    TotalTransaction: 0,
    TotalFuelDelivered: "0.00",
    TotalFuelConsumed: "0.00",
    TotalFuelInflow: "0.00"
}
export const customerFuelStatusInitialDetail: ICustomerFuelStatusDetail = {
    TotalTransaction: 0,
    TotalFuelConsumed: "0.00",
}

export const tankLevelInitialDetail: ITankLevelDetail = {
    CompartmentType: 0,
    Compartment: 1,
    Fuel1: 0,
    Fuel2: 0
}
export const volumeConsumedInitialDetail: IVolumeConsumedDetail = {
    MinConsumed: "0",
    AvgConsumed: "0",
    MaxConsumed: "0",
}
export const volumeDeliveredInitialDetail: IVolumeDeliveredDetail = {
    MinDelivered: "0",
    AvgDelivered: "0",
    MaxDelivered: "0",
}
export const dashboardMetricsInitialState: IDashboardMetrics = {
    Vehicles: 0,
    Customers: 0,
    Tanks: 0,
    Drivers: 0,
}

export const customerDashboardMetricsInitialState: ICustomerDashboardMetrics = {
    Vehicles: 0,
    Drivers: 0,
}

export const customerDashboardPricingInitialState: IPricingDashboardMetrics = {
    PricePerL: 0,
    PricePerGallon: 0,
    RemainingVolume: 0,
    ThresholdVolumeLimit: 0,
    MaxAmountLimit: 0,
    MaxVolumeLimit: 0
}

export const customerDashboardGetPricingInitialState: IGetPricingDashboardMetrics = {
    PricePerL: "0.00",
    PricePerGallon: "0.00",
    RemainingVolume: "0.00",
    ThresholdVolumeLimit: "0.00",
    MaxAmountLimit: "0.00",
    MaxVolumeLimit: "0.00"
}

export const dashboardRecentAlarmsInitialState: IRecentAlarms[] = []