import React, { FC } from 'react';
import LoginLayout from '../Layout/LoginLayout';

interface IProps {
  Component: React.JSX.Element
}

const LoginLayoutRoute: FC<IProps> = (props: IProps) => {
  const { Component } = props;

  return (
      <LoginLayout>
        {Component}
      </LoginLayout>
    )
};

export default LoginLayoutRoute;  