import React, { FC } from 'react';
import Layout from '../Layout/Layout';
import AuthProvider from '../../contexts/AuthProvider';

interface IProps {
  Component: React.JSX.Element
}

const LayoutRoute: FC<IProps> = (props: IProps) => {
  const { Component } = props;
  return (
      <Layout>
        <AuthProvider>
          {Component}
        </AuthProvider>
      </Layout>
    )
};

export default LayoutRoute;  