import React, { useState } from 'react';

const FilterPopupHOC = (Component: any) =>
function Comp(props:any){
    const [showFilterPopup,setShowFilterPopup] = useState<boolean>(false);

    const filterToggleHandler = () => {
        setShowFilterPopup(!showFilterPopup);
    }
	
	return <Component showFilterPopup={showFilterPopup} filterToggleHandler={filterToggleHandler} {...props} />;
};

export default FilterPopupHOC;