/*    
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:04-March-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Form, Formik, Field, useFormikContext } from "formik";
import { IUser } from "../../../models/IUser";
import SpinnerLoader from "../../../shared-components/Loader/SpinnerLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/Button/LoaderButton";
import Label from "../../../shared-components/label/label";
import FormikFormSelectField from "../../../shared-components/FormikFormHandlers/FormikFormSelectField";
import { IOption } from "../../../models/ICommon";
import {
  getVehicleValidateSchema,
  truckValidateSchema,
} from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { IAddEditTruck } from "../../../models/response/ITruckResponse";

interface IProps {
  id: number;
  submitHandler: (data: IAddEditTruck) => void;
  onModalClosed: () => void;
  getDataInProgress: boolean;
  addUpdateInProgress: boolean;
  initialValues: IAddEditTruck;
  compartmentTypes: IOption[];
}

const AddUpdateTruckForm = (props: IProps) => {
  const {
    id,
    submitHandler,
    onModalClosed,
    getDataInProgress,
    addUpdateInProgress,
    initialValues,
    compartmentTypes,
  } = props;

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<IUser>();
    return null;
  };

  return (
    <>
      <Modal className="add-user-modal " show={true} centered>
        <Modal.Header>
          <Modal.Title className="fs-5 ps-3">
            {id === -1 ? (
              <FormattedMessage id="add_truck" />
            ) : (
              <FormattedMessage id="update_truck" />
            )}
          </Modal.Title>
          <div onClick={onModalClosed} className="icons8-close">
            <span className="material-symbols-outlined fs-5 text-dark">
              close
            </span>
          </div>
          {getDataInProgress ? <SpinnerLoader /> : null}
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={truckValidateSchema}
          onSubmit={async (values) => {
            let tempValues = {
              DeviceId: values.DeviceId ? values.DeviceId.trim() : values.DeviceId,
              ClientId: values.ClientId,
              TruckId: values.TruckId ? values.TruckId.trim() : values.TruckId,
              Compartment: values.Compartment,
              Name: values.Name
            }
            submitHandler({ ...tempValues });
          }}
        >
          {({ values }) => (
            <Form className="user-form p-3" noValidate>
              <FormObserver />
              <Modal.Body>
                <Row>
                  <Col lg={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="truck_id"
                      />
                      <Field
                        data-testid="testTruckID"
                        className="form-control"
                        type="text"
                        name="TruckId"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        invalidcharacter={[" "]}
                        placeholder="truck_id"
                        as={FormikFormInput}
                        disabled={props.id > 0 ? true : false}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="compartment"
                      />
                      <Field
                        name="Compartment"
                        className="form-control"
                        type="number"
                        as={FormikFormSelectField}
                        typeofoptionid="number"
                        options={compartmentTypes}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3 form-group">
                      <Label
                        className="form-label"
                        required={true}
                        label="transactions_label_deviceid"
                      />
                      <Field
                        name="DeviceId"
                        data-testid="testCustId2"
                        className="form-control"
                        type="text"
                        invalidcharacter={[" "]}
                        validcharacter={['@', '#', '$', '-', '_', '.']}
                        placeholder="transactions_label_deviceid"
                        as={FormikFormInput}
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <div className="modal-footer  border-0">
                <div className="m-auto">
                  <LoaderButton
                    type="submit"
                    isLoading={addUpdateInProgress}
                    disabled={addUpdateInProgress}
                    text={id === -1 ? "button_save" : "button_edit"}
                  />
                  <Button
                    variant="btn-secondary bg-light btn-light ms-2"
                    onClick={onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AddUpdateTruckForm;
