import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { DATE_FORMAT_BACKEND, emptyValue, formatNumberWithCommas, roundWithPrecision } from "../../constants/common-constant";
import UrlConstants from "../../constants/url.constant";
import { getAmountDecimal, getClientId } from "../../helpers/localStorages.helper";
import { IObservableInitialState } from "../../models/ICommon";
import { IPaymentFilter, IPaymentInitialFilterState } from "../../models/IFilter";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IAddPayment, IGetPayment, IPayment, IPaymentList } from "../../models/response/IPaymentResponse";
import { IPaymentState } from "../../models/state/IPaymentState";
import { paymentInitialFilterState } from "../initialState/initial-filter-states";
import * as services from '../service/base-service';
import { getPaymentListState } from "../initialState/get-payment-list-state";

export class PaymentStore implements IPaymentState {
    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    addPaymentDataState: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    deletePaymentDataState: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    paymentList: IGetPayment = { ...getPaymentListState };

    paymentListState: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    filter: IPaymentFilter = {
        ...paymentInitialFilterState
    }

    constructor() {
        makeObservable(this, {
            paymentList: observable,
            addPaymentDataState: observable,
            paymentListState: observable,
            deletePaymentDataState: observable,
            filter: observable,
            GetPaymentListService: action,
            resetPaymentListState: action,
            AddPaymentDataService: action,
            resetAddPaymentDataState: action,
            setFilterDetail: action,
            DeletePaymentService: action,
            resetDeletePaymentDataState: action,
            getPaymentListData: computed,
        });
    }

    GetPaymentListService = (customerIdLong: number, customerId: string, currentPage: number, pagerSize: number, orderBy: string, column: string, filter: IPaymentInitialFilterState) => {
        this.paymentListState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.GetPaymentList}?clientId=${clientIdFromLocalStorage}&pageNo=${currentPage}&pageSize=${pagerSize}&customerIdLong=${customerIdLong}&customerId=${customerId}`;
        let startDateData = filter.StartDate ? moment(filter.StartDate).format("YYYY-MM-DD") : null;
        let endDateData = filter.EndDate ? moment(filter.EndDate).format("YYYY-MM-DD") : null;
        const tempStartDate = filter.StartDate ? startDateData + "T00:00:00Z" : null;
        const tempEndDate = filter.EndDate ? endDateData + "T23:59:59Z" : null;
        const modeOfPayment = filter.ModeOfPayment ? filter.ModeOfPayment : null;
        let requestBody = { ...filter, StartDate: tempStartDate, EndDate: tempEndDate, ModeOfPayment: modeOfPayment };
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.paymentList = response.data.Data;
            })
            .catch((err: string) => {
                this.paymentListState.error = err;
            })
            .finally(action(() => { this.paymentListState.inProgress = false; }));
    }

    get getPaymentListData(): { PaymentList: IPaymentList[], TotalAmount: number } {
        let amount: number = 0;
        let decimalValue = getAmountDecimal();
        let paymentList: IPaymentList[] = []
        if (this.paymentList?.CustomerPayment && this.paymentList?.CustomerPayment?.length > 0) {
            this.paymentList?.CustomerPayment?.map((payment: IPayment) => {
                let IsDelete: boolean = true;
                let IsEdit: boolean = true;
                amount = amount + Number(payment.AmountCr);
                paymentList.push({
                    ...payment,
                    Id: payment.Id,
                    PaymentDate: payment.PaymentDate ? moment(payment.PaymentDate).format(DATE_FORMAT_BACKEND) : emptyValue,
                    AmountCr: Number(payment.AmountCr).toFixed(decimalValue),
                    IsDeletable: IsDelete,
                    IsEditable: IsEdit,
                })
            })
        }
        return { PaymentList: paymentList, TotalAmount: roundWithPrecision(Number(amount), decimalValue) };
    }

    resetPaymentListState = () => {
        this.paymentListState = { ...this.initialStateValue }
        this.paymentList.CustomerPayment = []
    }

    AddPaymentDataService = (data: IAddPayment) => {
        this.addPaymentDataState.inProgress = true;
        let requestData = {
            ...data,
            PaymentDate: `${data.PaymentDate}T00:00:00Z`
        }
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.AddPaymentData}?clientId=${clientIdFromLocalStorage}`;
        return services.post(url, requestData)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.addPaymentDataState.success = true;
            })
            .catch((err: string) => {
                this.addPaymentDataState.error = err;
            })
            .finally(action(() => { this.addPaymentDataState.inProgress = false; }));
    }

    resetAddPaymentDataState = () => {
        this.addPaymentDataState = { ...this.initialStateValue }
    }

    DeletePaymentService = (id: number) => {
        this.deletePaymentDataState.inProgress = true;
        const clientIdFromLocalStorage = getClientId() != null ? getClientId() : -1;
        const url = `${UrlConstants.DeletePayment}?id=${id}&clientId=${clientIdFromLocalStorage}`;
        return services.deleteapi(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.deletePaymentDataState.success = true;
            })
            .catch((err: string) => {
                this.deletePaymentDataState.error = err;
            })
            .finally(action(() => { this.deletePaymentDataState.inProgress = false; }));
    }

    resetDeletePaymentDataState = () => {
        this.deletePaymentDataState = { ...this.initialStateValue }
    }

    setFilterDetail = (filter: IPaymentFilter) => {
        this.filter = { ...filter };
    }
}

export default new PaymentStore();