/*    
<summary>
   This file is used to provide initial values or state to the Customer Module observables.
    Developer:Aashish Singh, Created Date:20-March-2024
</summary>
*/

import { IGetCustomerList } from "../../models/response/ICustomerResponse";

export const getCustomerListState: IGetCustomerList = {
    TotalRecords: 1,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Customers: []
}