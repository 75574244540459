import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { format } from 'date-fns';
import { Button, Col, Dropdown, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import LoaderButton from '../../shared-components/Button/LoaderButton';

interface IProps {
  setIsGlobalFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
  dateFilterHandler: (values: { startDate: Date; endDate: Date }) => void;
  isGlobalFilterChanged: boolean
  dateFilter: { startDate: Date, endDate: Date }
}

const DateRangeFilter = (props: IProps) => {
  const { setIsGlobalFilterChanged, dateFilterHandler, isGlobalFilterChanged, dateFilter } = props;
  const [startDate, setStartDate] = useState<Date>(dateFilter.startDate);
  const [endDate, setEndDate] = useState<Date>(dateFilter.endDate);
  const [isHideMenu, setIsHideMenu] = useState<boolean>(false);
  const handleSelect = (date: any) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate)
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  return (
    <div>
      <Dropdown
        drop='down'
        className="border-0 datePicker px-2"
        onSelect={() => { setIsHideMenu((value: boolean) => { return !value }) }}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-basic"
          className="shadow-none border btn-date-picker position-relative"
        >
          <span className="me-2 fs-12">{`${format(dateFilter.startDate, "MMM/dd/yyyy")}-${format(dateFilter.endDate, "MMM/dd/yyyy")}`}</span>
          <svg height="20" viewBox="0 0 48 48" width="20" data-name="Layer 1"><rect height="6" rx="2" width="4" x="11" y="3" /><rect height="6" rx="2" width="4" x="33" y="3" /><path d="m4 18v23c0 2.209 1.791 4 4 4h32c2.209 0 4-1.791 4-4v-23zm12 20c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2zm0-11c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2zm11 11c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2zm0-11c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2zm11 11c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2zm0-11c0 1.105-.895 2-2 2h-2c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h2c1.105 0 2 .895 2 2z" /><path d="m44 16v-6c0-2.209-1.791-4-4-4h-1v1c0 2.206-1.794 4-4 4s-4-1.794-4-4v-1h-14v1c0 2.206-1.794 4-4 4s-4-1.794-4-4v-1h-1c-2.209 0-4 1.791-4 4v6z" /></svg>

        </Dropdown.Toggle>

        <Dropdown.Menu show={isHideMenu} className='pb-5 datepicker-custom'>

          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
          />

          <div className='calenderButton'>

            <Dropdown.Item>
              <LoaderButton onClick={() => {
                setIsGlobalFilterChanged(() => true);
                setIsHideMenu((value: boolean) => { return !value; });
                dateFilterHandler({
                  startDate: startDate,
                  endDate: endDate
                })
              }}
                type="submit"
                text="save" isLoading={false} />


              <Button
                variant="btn-secondary bg-light btn-light ms-2"
                onClick={() => {
                  setIsHideMenu((value: boolean) => { return !value })
                  setStartDate(dateFilter.startDate);
                  setEndDate(dateFilter.endDate);
                }}
              >
                <FormattedMessage id="button_cancel" />
              </Button>

            </Dropdown.Item>

          </div>

        </Dropdown.Menu>
      </Dropdown>

    </div>
  );
}

export default DateRangeFilter;
