import { IGetAllTransactions } from "../../models/response/ITransactionsResponse";


export const getAllTransactionState: IGetAllTransactions = {
    TotalRecords: 30,
    TotalPages: 1,
    CurrentPage: 1,
    PageSize: 100,
    Tickets: [],
    TotalTransactionCount: 0,
    TotalVolumeDelivered: 0,
    TotalVat: 0,
    TotalAmountDue: 0
}