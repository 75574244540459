/*    
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer: Aashish Singh, Created Date: 08-Mar-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from "mobx";
import { IChangePasswordState, ICommonState } from "../../models/state/ICommonState";
import IApiResponse, { IApiSuccessResponse } from "../../models/response/IApiResponse";
import { IClient } from "../../models/response/IClientResponse";
import UrlConstants from "../../constants/url.constant";
import * as services from '../service/base-service';
import { IObservableInitialState, IOption } from "../../models/ICommon";
import { formatMessage } from "../../translations/formatMessage";
import { toast } from "react-toastify";
import { IPreferenceState } from "../../models/state/IPreferenceState";
import moment from "moment";
import { ErrorMessage } from "../../constants/error.constant";
import { DATE_TIME_FORMAT_FILENAME } from "../../constants/common-constant";
import { getClientId, getToken } from "../../helpers/localStorages.helper";
import axiosDownload from "axios";

export class VerifyEmailStore implements ICommonState {
    inProgress = false;
    error = '';

    initialStateValue: IObservableInitialState = {
        success: false,
        error: '',
        inProgress: false
    }

    isUserExistsState = { ...this.initialStateValue }

    isUserExists: boolean = false;

    isUserVerifiedByOTPState = { ...this.initialStateValue }
    isUserVerifiedByOTP: boolean | undefined = false;
    isResendOTP: boolean | undefined = false;
    oneTimePassword: number | undefined = undefined;

    isPasswordUpdatedState = { ...this.initialStateValue }
    isOtpResendState = { ...this.initialStateValue }

    constructor() {
        makeObservable(this, {
            isUserExists: observable,
            isUserExistsState: observable,
            isUserVerifiedByOTPState: observable,
            isUserVerifiedByOTP: observable,
            oneTimePassword: observable,
            isPasswordUpdatedState: observable,
            isResendOTP: observable,
            isOtpResendState: observable,
            VerifyUserByOTPService: action,
            // VerifyUserPasswordResetService: action,
            UpdateUserPasswordByOTPService: action,
            resetIsUserVerifiedByOTPState: action,
            // resetIsUserCheck: action,
            resetUpdatePasswordState: action,
            ResendOTPService: action,
            resetStore: action,
        })
    }

    // /**
    // * This function is used to check if the user already Exists or not by calling an API.
    // * @param email : Login ID
    // * @returns 
    // */
    // VerifyUserPasswordResetService = (email: string) => {
    //     this.isUserExistsState.inProgress = true;
    //     // let data = { Email:email }
    //     let url = `${UrlConstants.VerfiyEmail}/${email}`;
    //     // if(email==="aashish.singh@meritech.co.in")
    //     // {
    //     //     this.isUserExists = true;
    //     //     this.isUserExistsState.success = true;
    //     // }
    //     // else{
    //     //     this.isUserExistsState.error = "not_found";
    //     // }
    //     // this.isUserExistsState.inProgress = false;

    //     return services.post(url, {})
    //         .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
    //             // this.isUserExists = response.data.Data;
    //             this.isUserExists = true;
    //             this.isUserExistsState.success = true;
    //         })
    //         .catch((err: string) => {
    //             this.isUserExistsState.error = err;
    //         })
    //         .finally(action(() => { this.isUserExistsState.inProgress = false; }));
    // }

    // /*
    // This function is used to reset all UserCheck observables to their initial values.  
    // */
    // resetIsUserCheck = () => {
    //     this.isUserExistsState = { ...this.initialStateValue };
    // }

    /**
     * This function is used to verify the received OTP to reset Password by calling an API & sending email & otp.
     * @param email : User Email / Login ID
     * @param otp : OTP received on Email
     * @returns 
     */
    VerifyUserByOTPService = (email: string, otp: number) => {
        this.isUserVerifiedByOTPState.inProgress = true;
        let data = {
            email: email,
            otp: otp
        }
        let url = `${UrlConstants.EmailVerfiyOtp}`;

        return services.post(url, data)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.isUserVerifiedByOTP = true;//response.data?.Data.IsUserVerifiedByOTP;
                this.oneTimePassword = otp;
                this.isUserVerifiedByOTPState.success = true;
            })
            .catch((err: string) => {
                this.isUserVerifiedByOTPState.error = err;
            })
            .finally(action(() => { this.isUserVerifiedByOTPState.inProgress = false; }));
    }

    ResendOTPService = (email: string) => {
        this.isOtpResendState.inProgress = true;
        let url = `${UrlConstants.EmailVerfiyOtpResend}/${email}`;

        return services.get(url)
            .then((response: IApiResponse<IApiSuccessResponse<any>>) => {
                this.isResendOTP = true;//response.data?.Data.IsUserVerifiedByOTP;
                this.isOtpResendState.success = true;
            })
            .catch((err: string) => {
                this.isOtpResendState.error = err;
            })
            .finally(action(() => { this.isOtpResendState.inProgress = false; }));
    }

    /*
    This function is used to reset isUserVerifiedByOTPState observable to their initial values.  
    */
    resetIsUserVerifiedByOTPState = () => {
        this.isUserVerifiedByOTPState = { ...this.initialStateValue };
    }

    resetIsResendOTP = () => {
        this.isOtpResendState = { ...this.initialStateValue };
    }

    /**
     * This function reset existing password with the new password by calling an API. 
     * @param data : password reset details
     * @returns 
     */
    UpdateUserPasswordByOTPService = (data: IChangePasswordState) => {
        this.isPasswordUpdatedState.inProgress = true;
        let url = `${UrlConstants.ChangePassword}/${data.Email}`;
        let requestBody = {
            Password: data.NewPassword,
            RepeatPassword: data.RepeatPassword
        }
        return services.post(url, requestBody)
            .then((response: IApiResponse<IApiSuccessResponse<boolean>>) => {
                this.isPasswordUpdatedState.success = true;
            })
            .catch((err: string) => {
                // this.isPasswordUpdatedState.error = err;
                toast.error(formatMessage(err));
            })
            .finally(action(() => { this.isPasswordUpdatedState.inProgress = false; }));
    }

    resetUpdatePasswordState = () => {
        this.isPasswordUpdatedState = { ...this.initialStateValue };
    }

    /**
     * This function is used to reset all store observables to their initial values.
     * @returns
     */
    resetStore = () => {
        this.error = '';
        this.inProgress = false;
        this.isUserExistsState = { ...this.initialStateValue };
        this.isUserExists = false;
        this.isUserVerifiedByOTPState = { ...this.initialStateValue }
        this.isUserVerifiedByOTP = false;
        this.oneTimePassword = undefined;
        this.isPasswordUpdatedState = { ...this.initialStateValue }
    }
}

export default new VerifyEmailStore();