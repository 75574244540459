/*    
<summary>
   This file is used to provide initial values or state to the change password form.
   Developer:Ashish Singh, Created Date:02-April-2024
</summary>
*/
import { IUpdatePassword } from "../../models/form/IChangePassword";

export const addEditChangePasswordState:IUpdatePassword = {
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
}