/*    
<summary>
   This functional common component provides us custom DateTime form Field  and has error handler that shows
   validation error.
   Developer: Aashish Singh, Created Date:01-May-2024
</summary>
<returns>Returns JSX</returns>
*/
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Stack from '@mui/material/Stack';
import { useField } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatMessage } from '../../translations/formatMessage';
import { DATE_FORMAT_BACKEND } from '../../constants/common-constant';

export default function FormikDateFieldMUI(props: any) {
  const { disabled, min, max, label } = props;
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {
          (error &&
            <span data-testid="testError" className="text-danger errorMessage">{formatMessage(error)}</span>)}
        <Stack spacing={3} id="root">
          <DatePicker
            renderInput={(params: any) => <TextField {...params} readOnly={true} />}
            value={field.value ? field.value : ""}
            views={['year', 'month', 'day']}
            inputFormat={DATE_FORMAT_BACKEND}
            disabled={props.disabled}
            minDate={min}
            className='datepicker-control'
            onChange={(newValue: any) => {
              helpers.setValue(newValue);
            }}
            InputProps={{ fullWidth: true }}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
}