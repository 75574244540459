/*    
<summary>
  This functional component shows the vehicle details.
  Developer:Aashish Singh, Created Date: 20-March-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../../contexts/StoreProvider";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { formatMessage } from "../../../translations/formatMessage";
import { DATE_TIME_FORMAT } from "../../../constants/common-constant";
import moment from "moment";
import NoReport from "../../../assets/Images/noReport.png";
import DeletePopupHOC from "../../../shared-components/Popup/Hoc/DeletePopupHoc";
import DeletePopup from "../../../shared-components/Popup/DeletePopup";

interface IProps {
  // selectedData: Array<IReport>;
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
}

const ReportDetails = (props: IProps) => {
  const { reportStore } = useStore();
  const {
    ExportReportById,
    downloadReportState,
    resetDownloadReportState,
    getReportList,
    DeleteReportByIdService,
    deleteReportState,
    resetDeleteReportState,
    GetReportListService
  } = reportStore;

  const [selectedId, setSelectedId] = useState<number>(-1)

  useEffect(() => {
    if (downloadReportState.error) {
      toast.error(formatMessage(downloadReportState.error));
      resetDownloadReportState();
    }
  }, [downloadReportState.error]);

  useEffect(() => {
    if (deleteReportState.error) {
      toast.error(formatMessage(deleteReportState.error));
      resetDeleteReportState();
    }
  }, [deleteReportState.error]);

  useEffect(() => {
    if (deleteReportState.success) {
      toast.success(formatMessage("delete_report_success"));
      props.deleteToggleHandler();
      resetDeleteReportState();
      GetReportListService();
    }
  }, [deleteReportState.success]);

  const deleteHandler = () => {
    DeleteReportByIdService(selectedId);
  }

  return (
    <React.Fragment>
      {props.showDeletePopup && (
        <DeletePopup
          modalSubmit={deleteHandler}
          modalClosed={props.deleteToggleHandler}
          isLoading={deleteReportState.inProgress}
          message="delete_report_confirm"
          submitBtnText="submit_button"
          cancelBtnText="button_cancel"
          modalTitle="delete_report"
        />
      )}
      {getReportList.length > 0 ? (

        <Table striped bordered hover className="mt-1 download-report">
          <thead>
            <tr>
              <th>
                {" "}
                <FormattedMessage id="file" />
              </th>
              <th>
                {" "}
                <FormattedMessage id="date" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getReportList?.map((report) => (
              <tr>

                <td>{report.ReportName}</td>
                <td className="text-start">
                  {report.CreatedAt}
                </td>
                <td>
                  {" "}
                  <div className="d-flex">
                    <div className="circle-icon purple"
                      onClick={() => {
                        ExportReportById(report.Id, report.ReportName);
                      }}>
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        fill="#fff"
                        height="12"
                        width="12"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path
                              d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64
			c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472
			c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="circle-icon maroon ms-1" onClick={() => {
                      setSelectedId(report.Id)
                      props.deleteToggleHandler();
                    }}>
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        width="12"
                        height="12"
                        fill="#fff"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path
                              d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
  L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
  c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
  c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
  c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
  c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
  c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
  c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
                            />
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                    </div>
                  </div>

                </td>
              </tr>
            ))}
          </tbody>
        </Table>

      ) : (
        <div className="no-report-data">
          <div className="no_data text-center py-2">
            <img src={NoReport} alt="NoReport" className="NoReport" />
            <p className="fs-3 fw-semi-bold">  <FormattedMessage id="message_no_datafound" /></p>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DeletePopupHOC(observer(ReportDetails));
